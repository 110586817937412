import React from 'react';
import i18n from '../../i18n/i18n';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton, Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

export class SessionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        let title = '';
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open} onClose={this.props.handleClick} PaperProps={{ height: '100%' }}
                    fullWidth={true} disableBackdropClick maxWidth="md" aria-labelledby="responsive-dialog-title2" style={{ maxWidth: 'none' }}>
                    <DialogTitle disableTypography={true} style={{ background: '#04764e', maxHeight: '100%' }} className="responsive-dialog-title2">
                        <p style={{ marginLeft: '20px' }}>{i18n.t('Data for')}: {this.props.gps_date}</p>
                        <IconButton onClick={this.props.handleClick} style={{ marginLeft: '25%' }}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ padding: '0px', backgroundColor: '#f7f7f7', overflow: 'overlay', height: '100vh', textAlign: '-webkit-center', paddingTop: '20px', paddingBottom: '20px' }}>
                        <Table style={{ textAlignLast: 'center', width: 'max-content', textAlign: '-webkit-center' }} aria-label="simple table">
                            <TableHead>
                                <TableCell style={{ padding: '8px', fontSize: '16px' }}>{i18n.t('Route Id')}</TableCell>
                                <TableCell style={{ padding: '8px', fontSize: '16px' }}>{i18n.t('Start time')}</TableCell>
                                <TableCell style={{ padding: '8px', fontSize: '16px' }}>{i18n.t('Stop time')}</TableCell>
                                <TableCell style={{ padding: '8px', fontSize: '16px' }}>{i18n.t('Average speed')}</TableCell>
                                <TableCell style={{ padding: '8px', fontSize: '16px' }}>{i18n.t('Max speed')}</TableCell>
                                <TableCell style={{ padding: '8px', fontSize: '16px' }}>{i18n.t('Idle time')}</TableCell>
                                <TableCell style={{ padding: '8px', fontSize: '16px' }}>{i18n.t('Route time')}</TableCell>
                                <TableCell style={{ padding: '8px', fontSize: '16px' }}>{i18n.t('Mileage')}</TableCell>
                            </TableHead>
                            <TableBody>
                                {this.props.session_history.map((item, i) => {
                                    return <TableRow>
                                        <TableCell style={{ padding: '8px', fontSize: '14px' }}>{item.Route}</TableCell>
                                        <TableCell style={{ padding: '8px', fontSize: '14px' }}>{item.StartTime.substr(11, 18)}</TableCell>
                                        <TableCell style={{ padding: '8px', fontSize: '14px' }}>{item.StopTime.substr(11, 18)}</TableCell>
                                        <TableCell style={{ padding: '8px', fontSize: '14px' }}>{item.SpeedAvg}</TableCell>
                                        <TableCell style={{ padding: '8px', fontSize: '14px' }}>{item.SpeedMax}</TableCell>
                                        <TableCell style={{ padding: '8px', fontSize: '14px' }}>{item.IdleTime}</TableCell>
                                        <TableCell style={{ padding: '8px', fontSize: '14px' }}>{item.RouteTime}</TableCell>
                                        <TableCell style={{ padding: '8px', fontSize: '14px' }}>{item.Mileage}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};

export default SessionInfo;