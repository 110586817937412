import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Button } from '@material-ui/core';
import i18n from '../../i18n/i18n';
import { toast } from 'react-toastify';
import AddUser from './AddUser';
import { withStyles } from '@material-ui/core/styles';
import { getUsers, deleteUser, setInitialState, setRowInReducer } from '../../actions/users/UserActions';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Delete } from '../../utils/Delete';
import { getUser } from '../../utils/CommonFunctions';
const CheckboxTable = checkboxHOC(ReactTable);
const styles = theme => ({
});
export class ViewUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            showFilters: false,
            selection: [],
            delete: false,
            selectAll: false,
            edit: false
        };
    }

    componentDidMount() {
        this.setState({
            user: getUser()
        })
    }

    componentDidUpdate(prevProps) {
        const { deleted, userPostingSuccess, deleteFailed, getUsers, setInitialState } = this.props;
        if (!prevProps.deleted && deleted) {
            const wrapped = this.checkboxTable.getWrappedInstance();
            getUsers(wrapped.state);
            this.setState({
                selection: [],
                selectAll: false,
            })
            setInitialState('userObj');
            setInitialState('deleted');
        }
        if (!prevProps.userPostingSuccess && userPostingSuccess) {
            this.handleClick();
            const wrapped = this.checkboxTable.getWrappedInstance();
            getUsers(wrapped.state);
            setInitialState('userPostingSuccess');
        }
        if (!prevProps.deleteFailed && deleteFailed) {
            toast.error(i18n.t('This item can not be deleted!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            setInitialState('deleteFailed');
        }

    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleClick = () => {
        let { edit, add, selection } = this.state;
        let { setInitialState } = this.props;
        if (edit == true && add == false) {
            this.setState({ edit: false })
            setInitialState('userObj');
        } else if (selection.length > 0 && add == false && edit == false) {
            setInitialState('userObj');
        }
        this.setState({ add: !add }, () => {
            if (add == true && edit == false) {
                this.setState({
                    selection: [],
                    selectAll: false,
                })
            }
        });
    };

    toggleSelection = (key, shift, row) => {
        const { selection: stateSelection } = this.state;
        const { setInitialState, setRowInReducer, users } = this.props;
        let selection = [...stateSelection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            setInitialState('userObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection }, function () {
            if (selection.length == 1) {
                let rowForReducer = users.filter((user) => {
                    return user.id == selection;
                })
                setRowInReducer(rowForReducer[0]);
            }
        });
    };

    toggleAll = () => {
        const { selectAll: selectAllState } = this.state;
        const selectAll = selectAllState ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });


    };

    isSelected = key => {
        const { selection } = this.state;
        return selection.includes(key);
    };

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteUser(this.state.selection);
        this.setState({ delete: false })
    }

    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }


    render() {
        const columns = [
            // {
            //     Header: i18n.t('ID'),
            //     accessor: 'id'
            // },
            {
                Header: i18n.t('Username'),
                accessor: 'username'
            },
            {
                Header: i18n.t('Client name'),
                accessor: 'klijent_naziv'
            },
            {
                Header: i18n.t('Email'),
                accessor: 'mejl'
            },
            {
                Header: i18n.t('First name'),
                accessor: 'ime'
            },
            {
                Header: i18n.t('Last name'),
                accessor: 'prezime'
            },
            {
                Header: i18n.t('Sektor'),
                accessor: 'sektor_naziv'
            },
            {
                Header: i18n.t('User role'),
                accessor: 'korisnik_grupa'
            },
            {
                Header: i18n.t('Mobile phone'),
                accessor: 'mobilni'
            },
        ];
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll, selection, showFilters, add, edit, user } = this.state;
        const { theme, users, userPages, usersFetching, getUsers } = this.props;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            },
        };

        const filterOptions = {
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };

        return (
            <div className="page">
                <div className="table-header">
                    <Button
                        onClick={this.handleClick}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-Plus headerIcon" />
                    </Button>
                    <Button
                        onClick={() => this.onSearchClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-search headerIcon" />
                    </Button>
                    {selection.length > 0 && !selection.includes(user.id) ?
                        <Button
                            onClick={() => this.onDeleteClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-Kanta headerIcon" />
                        </Button>
                        : null
                    }
                    {selection.length === 1 && !selection.includes(user.id) ?
                        <Button
                            onClick={() => this.onEditClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className="icon-Izmeni1 headerIcon" />
                        </Button>
                        : null
                    }

                </div>
                <CheckboxTable data={users}
                    ref={r => (this.checkboxTable = r)}
                    keyField={'id'}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={showFilters}
                    pages={userPages}
                    loading={usersFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => getUsers(state, instance)}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    {...filterOptions}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {add && <AddUser add={add} edit={edit}
                    handleClose={this.handleClick} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
            </div>

        )
    }


};

function mapStateToProps(state) {
    return {
        users: state.userReducer.users,
        userObj: state.userReducer.userObj,
        usersFetching: state.userReducer.usersFetching,
        userPages: state.userReducer.userPages,
        deleted: state.userReducer.deleted,
        deleteFailed: state.userReducer.deleteFailed,
        userPostingSuccess: state.userReducer.userPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getUsers: (state, instance) => dispatch(getUsers(state, instance)),
        deleteUser: (selection) => dispatch(deleteUser(selection)),
        setInitialState: (component) => dispatch(setInitialState(component)),
        setRowInReducer: (rowInfo) => dispatch(setRowInReducer(rowInfo)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewUsers))