export const styles = {
    container: { margin: '2%' },
    spinner: { marginLeft: '39%', marginTop: '14%', marginBottom: '20%' },
    card: { display: 'flex' },
    pillar: { width: '15%', textAlign: 'center', borderRight: 'inset' },
    pillar_last: { width: '15%', textAlign: 'center' },
    time: { fontSize: '26px', textAlign: 'center' },
    icon: { width: '100px', height: '100px', borderRadius: '5px', textAlign: 'center' },
    temp_container: { textAlign: 'center' },
    temp_div: { display: 'flex', placeContent: 'center' },
    temp_max: { fontSize: '22px', textAlign: 'center', color: 'black', margin: '4px' },
    temp_min: { fontSize: '22px', textAlign: 'center', color: 'silver', margin: '4px' },
    predictability_div: { width: '60%', textAlign: 'center', margin: '5px', marginBottom: '0px', marginLeft: '20%' },
    predictability_tooltip: { fontSize: '14px', color: 'silver', margin: '0px', marginTop: '2px' },
    precipitation_div: { display: 'flex', placeContent: 'center', alignItems: 'center', marginTop: '20px' },
    rain_icon: { width: '25px', height: '25px', borderRadius: '5px', textAlign: 'center' },
    precipitation_probability: { fontSize: '18px', margin: '0px', marginLeft: '5px' },
    wind_div: { marginTop: '20px' },
    wind_degrees: { fontSize: '16px', margin: '0px' },
    wind_speed: { fontSize: '16px', margin: '0px', marginBottom: '3px' },
    humidity: { fontSize: '18px', marginBottom: '0px' },
    tooltip: { fontSize: '14px', color: 'silver', margin: '0px', marginTop: '2px', marginBottom: '15px' },
    pressure: { fontSize: '18px', marginBottom: '0px' },
};