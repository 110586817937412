import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getGroup, getWorkingOperations, setInitialState, setRowInReducer, addWorkingOperationFavorite, deleteWorkingOperationFavorite } from '../../../actions/resources/WorkingOperationsActions';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import immutable from 'seamless-immutable';
import AddWorkingOperation from './AddWorkingOperation';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { withStyles } from '@material-ui/core/styles';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Delete } from "../../../utils/Delete";
import { getUser } from '../../../utils/CommonFunctions';
import styles from "./ViewWO.module.css"
import SearchBar from 'material-ui-search-bar';
import AddIcon from '@material-ui/icons/Add';
import axios from '../../../utils/AxiosWrapper';
import { wentWrong, wentRight } from '../../../utils/ToastConfig';
import ReactPaginate from "react-paginate";
import CreateWorkingOperation from './CreateWorkingOperation';

export default class ViewWO extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            user: {},

            allWorkingOperations: [],
            filteredWO: [],

            searchBarValue: "",
            selectedGroup: "",

            currentPage: 0,
            pageCount: 1,
            itemsPerPage: 7,

            createNew: false,
            editWO: false,
            itemForEditing: null,
            deleteWindow: false,


        };
    }

    componentDidMount() {
        this.fetchAllWorkingOperations();

        const storedNumber = sessionStorage.getItem('pageNumberWO');
        if (storedNumber) {
            this.setState({ currentPage: Number(storedNumber) });
        } else {
            this.setState({ currentPage: 0 });
        }

        this.setupPageCount();

        this.setState({
            user: getUser()
        }, () => console.log("this.state.user : ", this.state.user))
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.allWorkingOperations !== this.state.allWorkingOperations) {

            this.populateDropdowns();

        }

        if (prevState.filteredWO !== this.state.filteredWO) {
            this.setupPageCount();
        }

        if (prevState.searchBarValue !== this.state.searchBarValue ||
            prevState.selectedGroup !== this.state.selectedGroup) {
            this.updateFilteredWO();
        }
    }

    fetchAllWorkingOperations = () => {
        axios.get('api/working_operation/read')
            .then((response) => {
                this.setState({
                    allWorkingOperations: response.data.data.map(item => {
                        return {
                            ...item,
                            isLiked: item.favorit ? true : false,
                        }
                    })
                }, () => {

                    console.log(this.state.allWorkingOperations)
                })

            })
            .catch(function (error) {
                console.log("ERROR", error);
                wentWrong('Something went wrong, please try again!');
            });
    }

    populateDropdowns = () => {
        let woGroupName = new Set(this.state.allWorkingOperations && this.state.allWorkingOperations.map(item => item.radna_operacija_grupa_naziv));


        this.setState({

            groups: [...woGroupName],
            filteredWO: this.state.allWorkingOperations,
        }, () => {
            this.updateFiltersFromSessionStorage();
        })
    }

    handleSearchBarChange = (searchVal) => {

        this.setState({
            searchBarValue: searchVal,
        });
    }

    updateFilteredWO = () => {
        this.setState({
            filteredWO: this.state.allWorkingOperations
                .filter(item => this.state.selectedGroup === "" ? item : item.radna_operacija_grupa_naziv === this.state.selectedGroup)
                .filter(item => this.state.searchBarValue === "" ? item : item.naziv.toLowerCase().includes(this.state.searchBarValue.toLowerCase()))
        }, () => {
            this.setupPageCount();

            const preparedForSaving = JSON.stringify({
                searchBarValue: this.state.searchBarValue,
                selectedGroup: this.state.selectedGroup,

            })

            sessionStorage.setItem('preservedFilterValuesWO', preparedForSaving);


        });
    }

    updateFiltersFromSessionStorage = () => {
        const storedFilterValues = sessionStorage.getItem('preservedFilterValuesWO');

        if (storedFilterValues) {
            const parsedFilterValues = JSON.parse(storedFilterValues);

            this.setState({
                searchBarValue: parsedFilterValues.searchBarValue,
                selectedGroup: this.state.groups.includes(parsedFilterValues.selectedGroup) ? parsedFilterValues.selectedGroup : "",
            });
        }
    }

    setupPageCount = () => {
        const pageCount = Math.ceil(this.state.filteredWO.length / this.state.itemsPerPage);
        this.setState({ pageCount: pageCount }, () => {
            if (this.state.currentPage >= this.state.pageCount && this.state.pageCount > 0) {
                this.setState({ currentPage: this.state.pageCount - 1 });
            }
        });
    }

    handlePageChange = (pageNum) => {
        this.setState({
            currentPage: pageNum.selected,
        })

        sessionStorage.setItem('pageNumberWO', pageNum.selected);
    }

    handleChangeGroup = (e) => {
        this.setState(
            {
                selectedGroup: e.currentTarget.value,
            });
    }

    resetMyFilters = () => {
        this.setState({
            searchBarValue: "",
            selectedGroup: "",
            currentPage: 0,
        });
    }

    handleDeleteItem = () => {


        axios.post(' api/working_operation/delete', { selection: [this.state.itemForDeletion.id] })
            .then((res) => {
                console.log("DeLETE RESPONSE: ", res)
                this.handleCloseDeleteWindow();
                this.fetchAllWorkingOperations();
                wentRight(i18n.t('You successfully deleted a working operation'));

            })
            .catch((err) => {


                if (err.response.data.message === "The work operation cannot be deleted because it is bound in the activity!") {
                    wentWrong('The work operation cannot be deleted because it is bound in the activity!');
                } else {
                    wentWrong('Something went wrong, please try again!');
                }
                this.handleCloseDeleteWindow();
            });
    }

    addToFavorites = (id_radna_operacija) => {
        axios.post('api/working_operation/addFavorite', { id_radna_operacija })
            .then((res) => {
                console.log("ADD RESPONSE: ", res)
                this.setState({
                    allWorkingOperations: this.state.allWorkingOperations.map(item => {
                        return {
                            ...item,
                            isLiked: item.id === id_radna_operacija ? !item.isLiked : item.isLiked,
                        }
                    })
                }, () => this.updateFilteredWO())
                // this.fetchAllWorkingOperations();
            })
            .catch((err) => {
                wentWrong('Something went wrong, please try again!');
            });
    }

    removeFromFavorites = (id_radna_operacija) => {
        axios.post(`api/working_operation/deleteFavorite`, { id_radna_operacija })
            .then((res) => {
                console.log("REMOVE RESPONSE: ", res)
                // this.fetchAllWorkingOperations();
                this.setState({
                    allWorkingOperations: this.state.allWorkingOperations.map(item => {
                        return {
                            ...item,
                            isLiked: item.id === id_radna_operacija ? !item.isLiked : item.isLiked,
                        }
                    })
                }, () => this.updateFilteredWO())
            })
            .catch((err) => {
                wentWrong('Something went wrong, please try again!');
            });
    }

    closeCreateNew = () => {
        this.setState({ createNew: false });
    }

    closeEditWO = () => {
        this.setState({ editWO: false });
    }

    handleCloseDeleteWindow = () => {
        this.setState({ deleteWindow: false });
    }


    render() {


        return (
            <div className="page" style={{ overflow: 'scroll' }}>
                <div className={styles.searchWrap} >
                    <SearchBar
                        className={styles.searchBar}
                        placeholder={i18n.t('Search by name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        // onChange={(searchValue) => !searchValue && this.handleSearchBarChange('')}
                        // onRequestSearch={(searchValue) => this.handleSearchBarChange(searchValue)}
                        // onCancelSearch={() => this.handleSearchBarChange('')}
                        onChange={(searchValue) => this.handleSearchBarChange(searchValue)}
                        onCancelSearch={() => this.handleSearchBarChange('')}
                    />
                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={styles.addNewBtn}
                        onClick={() => {
                            this.setState({ createNew: true }, () => console.log("this.state.createNew : ", this.state.createNew))
                        }}
                    >
                        <AddIcon />
                        {i18n.t('Add Working Operation')}
                    </Button>

                </div>

                <div className={styles.filtersSection}>

                    <select className={styles.dropDowns} value={this.state.selectedGroup} onChange={this.handleChangeGroup} >
                        <option value="">{i18n.t("Group name")}</option>
                        {this.state.groups && this.state.groups.map((group, i) => (
                            <option key={i} value={group}>{group}</option>
                        ))}
                    </select>

                    <button className={styles.resetButton} onClick={this.resetMyFilters}>{i18n.t("Reset filters")}</button>
                </div>

                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', minHeight: 'calc(100vh - 245px)', display: 'flex', flexDirection: 'column' }}>

                    <div className={styles.tableHeader}>

                        <div className={styles.tableHeaderTitle} >{i18n.t("Working operations")}</div>

                    </div>


                    <div style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', padding: '15px 0 8px 0', borderBottom: '1px solid #9b9658', fontSize: '14px', color: '#7d7a7a' }}>
                        <div style={{ width: '50px', marginRight: "20px" }}>{i18n.t("Active")}</div>
                        <div style={{ width: '220px', marginRight: "20px" }}>{i18n.t("Name")}</div>
                        <div style={{ width: '150px', marginRight: "20px" }}>{i18n.t("Group name")}</div>
                        <div style={{ width: '117px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div style={{ width: '60px', marginRight: "20px", paddingLeft: '15px' }}>{i18n.t("Edit")}</div>
                            <div style={{ width: '60px', marginRight: "10px" }}>{i18n.t("Delete")}</div>
                        </div>
                    </div>

                    {this.state.filteredWO.length > 0 && this.state.filteredWO.slice(this.state.currentPage * this.state.itemsPerPage, (this.state.currentPage + 1) * this.state.itemsPerPage).map((item, i) => (
                        <div key={i} style={{ display: 'flex', alignItems: "center", justifyContent: 'space-between', margin: '0 16px', borderBottom: '1px solid #d2d2d2', minHeight: '60px' }}>
                            <div style={{ width: '50px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>

                                <Tooltip placement='top' title={item.isLiked ? i18n.t('Remove from active') : i18n.t('Add to active')}>
                                    <IconButton
                                        className={styles.starButton}
                                        style={{ color: item.isLiked ? '#f2ce3e' : 'gray', padding: '10px' }}
                                        onClick={() => {
                                            item.isLiked ? this.removeFromFavorites(item.id) : this.addToFavorites(item.id);
                                        }
                                        }>

                                        {
                                            item.isLiked ? <Icons.GradeRounded /> :
                                                <Icons.GradeOutlined />
                                        }
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div style={{ width: '220px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.naziv}</div>
                            <div style={{ width: '150px', marginRight: "20px", fontSize: '14px', color: '#333333' }}>{item.radna_operacija_grupa_naziv}</div>


                            <div style={{ width: '117px', display: 'flex' }}>
                                {item.id_klijent_vlasnik === this.state.user.id_klijent && <Tooltip title={i18n.t("Edit")}>
                                    <IconButton aria-label="Edit"
                                        // color="secondary"
                                        // style={{ transform: 'translateX(-15px)' }}
                                        onClick={() => {
                                            this.setState({ itemForEditing: item }, () => this.setState({ editWO: true }))
                                        }}>
                                        <Icons.Edit fontSize="large" />
                                    </IconButton>
                                </Tooltip>}

                                {item.id_klijent_vlasnik === this.state.user.id_klijent && item.isLiked === false && <Tooltip title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        // color="secondary"
                                        onClick={() => {
                                            this.setState({ itemForDeletion: item }, () => this.setState({ deleteWindow: true }));
                                        }}>
                                        <Icons.DeleteOutline fontSize="large" />
                                    </IconButton>
                                </Tooltip>}


                            </div>
                        </div>
                    ))}

                    <ReactPaginate
                        pageCount={this.state.pageCount}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        onPageChange={this.handlePageChange}
                        containerClassName={styles.paginationContainer}
                        activeClassName={styles.active}
                        previousLabel={"<"}
                        nextLabel={">"}
                        forcePage={this.state.currentPage}
                    />
                </div>
                {this.state.createNew && <CreateWorkingOperation add={true} close={this.closeCreateNew} reload={this.fetchAllWorkingOperations} />}
                {this.state.editWO && <CreateWorkingOperation add={true} edit={true} data={this.state.itemForEditing} close={this.closeEditWO} reload={this.fetchAllWorkingOperations} />}
                {this.state.deleteWindow && <Delete delete={this.state.deleteWindow} handleClose={this.handleCloseDeleteWindow} deleteItem={this.handleDeleteItem} />}
            </div>
        )
    }
};
