import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoTemperatureHumidity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                             <p className={classes.paragraph}>

                            Akumulirana temperatura je vremenski parametar koji direktno utiče na produktivnost poljoprivrednih biljaka. 
                            Svi biološki i hemijski procesi koji se odvijaju u zemljištu i biljkama povezani su sa temperaturom vazduha. 
                            Snabdevanje useva toplotom karakteriše zbir prosečnih dnevnih temperatura vazduha koje su veće od biološkog minimuma tokom vegetacionog perioda. 
                            I previsoke i preniske temperature narušavaju tok biohemijskih procesa u ćelijama, te može doći do nepovratnih promena koje zaustavljaju rast i uzrokuju odumiranja biljaka.
                            Količina vlage u vazduhu izražava se kao relativna vlažnost i zavisi od temperature vazduha. 
                            Topli vazduh ima veći kapacitet zadržavanja vlage od hladnijeg vazduha, stoga kako se temperatura vazduha povećava, relativna vlažnost opada iako količina vode ostaje konstantna. 
                            Ovo korelacija naročito je važna farmerima koji se bave proizvodnjom u zaštićenom prostoru, 
                            kako bi stvorili optimalne uslove za rast i razvoj biljaka i sprečili uslove koji pogoduju napadu i širenja infekcija i štetočina.

                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Accumulated temperature directly affects the productivity of agricultural plants. 
                            All biological and chemical processes taking place in the soil and plants are related to the air temperature. 
                            Crop heat supply is characterized by the sum of average daily air temperatures that are higher than the biological minimum during the growing season. 
                            Too high and too low temperatures disrupt the flow of biochemical processes in cells and cause irreversible changes that can stop the plant's growth and cause the plants to die.
                            The amount of moisture in the air is relative humidity and depends on the air temperature. 
                            Warm air has a higher moisture-holding capacity than colder air so as the air temperature increases the relative humidity decreases even though the amount of water remains constant. 
                            This correlation is very important for farmers who grow plants in the greenhouses and need to create optimal conditions 
                            for the growth and development of plants and prevent weather conditions that favor the attack and spread of infections and pests.

                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                             <p className={classes.paragraph}>
                            Akumulirana temperatura vremenski je parametar koji izravno utječe na produktivnost 
                            poljoprivrednih biljaka. Svi biološki i kemijski procesi koji se odvijaju u tlu i biljkama povezani 
                            su s temperaturom zraka. Opskrbljenost usjeva toplinom karakterizira zbroj prosječnih dnevnih 
                            temperatura zraka koje su više od biološkog minimuma tijekom vegetacije. I previsoke i preniske 
                            temperature remete tijek biokemijskih procesa u stanicama te mogu nastupiti nepovratne promjene 
                            koje zaustavljaju rast i uzrokuju odumiranje biljaka.
                            Količina vlage u zraku izražava se relativnom vlagom i ovisi o temperaturi zraka. Topli zrak ima 
                            veću sposobnost zadržavanja vlage od hladnijeg zraka, pa s porastom temperature zraka relativna 
                            vlažnost pada iako količina vode ostaje konstantna. Ova korelacija posebno je važna za 
                            poljoprivrednike koji se bave proizvodnjom u zaštićenom prostoru, kako bi se stvorili optimalni 
                            uvjeti za rast i razvoj biljaka te spriječili uvjeti koji pogoduju napadu i širenju zaraza i štetnika.
                            </p>
                        </div>
                    </Card>
                }
                 {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                             <p className={classes.paragraph}>
                             Akumulirana temperatura neposredno vpliva na produktivnost kmetijskih rastlin. 
                             Vsi biološki in kemični procesi, ki potekajo v tleh in rastlinah, so povezani s temperaturo zraka. 
                             Oskrbo rastlin s toploto označuje vsota povprečnih dnevnih temperatur zraka, ki so v rastni dobi višje od 
                             biološkega minimuma. Previsoke in prenizke temperature motijo potek biokemičnih procesov v celicah in povzročajo 
                             nepopravljive spremembe, ki lahko ustavijo rast rastlin in povzročijo odmiranje rastlin. Količina vlage v zraku je 
                             relativna vlažnost in je odvisna od temperature zraka. Topel zrak ima večjo sposobnost zadrževanja vlage kot hladnejši 
                             zrak, tako da se z zvišanjem temperature zraka relativna vlažnost zmanjša, čeprav količina vode ostane nespremenjena. 
                             Ta povezava je zelo pomembna za kmete, ki gojijo rastline v rastlinjakih in morajo ustvariti optimalne pogoje za rast 
                             in razvoj rastlin ter preprečevati vremenske razmere, ki spodbujajo napad in širjenje okužb in škodljivcev.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoTemperatureHumidity);