import axios from '../../utils/AxiosWrapper';
export const Type = {

    GET_MARKET_PRICES_CALL: 'GET_MARKET_PRICES_CALL',
    GET_MARKET_PRICES_SUCCESS: 'GET_MARKET_PRICES_SUCCESS',
    GET_MARKET_PRICES_FAILED: 'GET_MARKET_PRICES_FAILED',

    GET_MARKET_PRICE_BY_ID_CALL: 'GET_MARKET_PRICE_BY_ID_CALL',
    GET_MARKET_PRICE_BY_ID_SUCCESS: 'GET_MARKET_PRICE_BY_ID_SUCCESS',
    GET_MARKET_PRICE_BY_ID_FAILED: 'GET_MARKET_PRICE_BY_ID_FAILED',

    SET_INITIAL_STATE: 'SET_INITIAL_STATE'
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function getMarketPrices() {
    return (dispatch) => {
        dispatch({
            type: Type.GET_MARKET_PRICES_CALL
        });

        axios.get('api/berza/readPrice')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MARKET_PRICES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MARKET_PRICES_FAILED
                });
            });
    }
}

export function getMarketPricesById(id) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MARKET_PRICE_BY_ID_CALL
        });

        axios.get(`api/berza/getById?id=` + id)
            .then(function (response) {
                dispatch({
                    type: Type.GET_MARKET_PRICE_BY_ID_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MARKET_PRICE_BY_ID_FAILED
                });
            });
    }
}
