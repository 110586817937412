import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
//import Switch from '@material-ui/core/Switch';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
export default class AddParts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            naziv: '',
            povrsina: '',
            tabla: '', table: [],
            sorta: '', sorte: []

        }
    }

    componentDidMount() {
        this.getByURL('field/field_list_drop_down', 'table');
        this.getByURL('crop/sorta_list_drop_down', 'sorte');

        if (this.props.edit === true) {
            this.setState({
                naziv: this.props.data.naziv,
                povrsina: this.props.data.povrsina,
                tabla: this.props.data.id_tabla,
                sorta: this.props.data.id_sorta

            })
        }


        // this.getByURL('worker/filter_worker_group', 'worker_groups');
        //this.getByURL('worker/filter_sector', 'sectors');


    }

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addParts() {
        if (
            this.state.naziv

        ) {
            let data = {
                id: this.props.data.id, // da prepozna za edit na backendu
                naziv: this.state.naziv,
                povrsina: this.state.povrsina,
                id_sorta: this.state.sorta,
                id_tabla: this.state.tabla

            }
            axios.post('api/field/create_part', { data: data })
                .then(res => {
                    this.props.handleClose();
                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })
        }
        else wentWrong('Please enter all required fields!');
    }

    //onSwitchClick = () => { this.setState({ polj_radovi: !this.state.polj_radovi }); }

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit part of the field') : i18n.t('Add part of the field')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" >

                                <Grid xs={6}>
                                    <TextField required
                                        label={i18n.t('Name')}
                                        value={this.state.naziv}
                                        style={{ width: '256px', marginBottom: '10px', marginTop: '10px' }}
                                        onChange={(e) => { this.setState({ naziv: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid xs={6}>
                                    <TextField required
                                        label={i18n.t('Area')}
                                        value={this.state.povrsina}
                                        style={{ width: '256px', marginBottom: '10px', marginTop: '10px' }}
                                        onChange={(e) => { this.setState({ povrsina: e.target.value }); }} >
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Table')}
                                        select
                                        value={this.state.tabla}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ tabla: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.table.map((table) => <option key={table.id} value={table.id}>{table.naziv}</option>)}
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Variety')}
                                        select
                                        value={this.state.sorta}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ sorta: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.sorte.map((sorta) => <option key={sorta.id} value={sorta.id}>{sorta.naziv}</option>)}
                                    </TextField>
                                </Grid>


                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#04764e', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addParts()}
                                        disabled={this.props.layerPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};