import immutable from 'seamless-immutable';
import { Type as MachinesType } from '../../actions/resources/MachinesActions';


export const INITIAL_STATE = immutable({
    //dropdowns
    machinesGroupDropdown: [],
    groupFetching: false,
    groupFetchingFailed: false,
    machinesSubgroupDropdown: [],
    subgroupFetching: false,
    subgroupFetchingFailed: false,

    machineDropdown: [],
    machineDropdownFetching: false,
    machineDropdownFetchingFailed: false,
    addFavoriteSuccess: false,
    deleteFavoriteSuccess: false,

    //pogonske
    machinesPosting: false,
    machinesPostingSuccess: false,
    machinesPostingFailed: false,
    machine: [],
    machineObj: {},
    machinesFetching: false,
    machinesFetchingFailed: false,
    machinesPages: 1,

    //pogonske grupe
    machine_group: [],
    machineGroupObj: {},
    machinesGroupsFetching: false,
    machinesGroupsFetchingFailed: false,
    machinesGroupsPages: 1,
    machinesGroupsPosting: false,
    machinesGroupsPostingSuccess: false,
    machinesGroupsPostingFailed: false,

    //pogonske podgrupe
    machine_subgroup: [],
    machineSubgroupObj: {},
    machinesSubgroupsFetching: false,
    machinesSubgroupsFetchingFailed: false,
    machinesSubgroupsPages: 1,
    machinesSubgroupsPosting: false,
    machinesSubgroupsPostingSuccess: false,
    machinesSubgroupsPostingFailed: false,

    deleted: false,
    deleteMachineFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case MachinesType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case MachinesType.GET_MACHINES_GROUP_CALL: {
            const groupFetching = true;
            return state.merge({ groupFetching });
            break;
        }

        case MachinesType.GET_MACHINES_GROUP_SUCCESS: {
            const machinesGroupDropdown = action.data.data;
            const groupFetching = false;
            return state.merge({ machinesGroupDropdown, groupFetching });
            break;
        }

        case MachinesType.GET_MACHINES_GROUP_FAILED: {
            const groupFetchingFailed = true;
            const groupFetching = false;
            return state.merge({ groupFetchingFailed, groupFetching });
            break;
        }

        case MachinesType.GET_MACHINES_SUBGROUP_CALL: {
            const subgroupFetching = true;
            return state.merge({ subgroupFetching });
            break;
        }

        case MachinesType.GET_MACHINES_SUBGROUP_SUCCESS: {
            const machinesSubgroupDropdown = action.data.data;
            const subgroupFetching = false;
            return state.merge({ machinesSubgroupDropdown, subgroupFetching });
            break;
        }

        case MachinesType.GET_MACHINES_SUBGROUP_FAILED: {
            const subgroupFetchingFailed = true;
            const subgroupFetching = false;
            return state.merge({ subgroupFetchingFailed, subgroupFetching });
            break;
        }

        case MachinesType.GET_MACHINES_DROPDOWN_CALL: {
            const machineDropdownFetching = true;
            return state.merge({ machineDropdownFetching });
            break;
        }

        case MachinesType.GET_MACHINES_DROPDOWN_SUCCESS: {
            const machineDropdown = action.data.data;
            const machineDropdownFetching = false;
            return state.merge({ machineDropdown, machineDropdownFetching });
            break;
        }

        case MachinesType.GET_MACHINES_DROPDOWN_FAILED: {
            const machineDropdownFetchingFailed = true;
            const machineDropdownFetching = false;
            return state.merge({ machineDropdownFetchingFailed, machineDropdownFetching });
            break;
        }

        case MachinesType.ADD_MACHINE_CALL: {
            const machinesPosting = true;
            return state.merge({ machinesPosting });
            break;
        }

        case MachinesType.ADD_MACHINE_SUCCESS: {
            const machinesPosting = false;
            const machinesPostingSuccess = true;
            return state.merge({ machinesPostingSuccess, machinesPosting, machineObj: INITIAL_STATE.machineObj })
            break;
        }

        case MachinesType.ADD_MACHINE_FAILED: {
            const machinesPosting = false;
            const machinesPostingFailed = true;
            return state.merge({ machinesPosting, machinesPostingFailed });
            break;
        }

        case MachinesType.GET_MACHINE_CALL: {
            const machinesFetching = true;
            return state.merge({ machinesFetching });
            break;
        }

        case MachinesType.GET_MACHINE_SUCCESS: {
            const machine = action.data.data;
            const machinesFetching = false;
            const machinesPages = action.data.total_pages
            return state.merge({ machine, machinesFetching, machinesPages });
            break;
        }

        case MachinesType.GET_MACHINE_FAILED: {
            const machinesFetchingFailed = true;
            const machinesFetching = false;
            return state.merge({ machinesFetching, machinesFetchingFailed });
            break;
        }

        case MachinesType.GET_MACHINES_VIEW_CALL: {
            const machinesGroupsFetching = true;
            return state.merge({ machinesGroupsFetching });
            break;
        }

        case MachinesType.GET_MACHINES_VIEW_SUCCESS: {
            const machine_group = action.data.data;
            const machinesGroupsFetching = false;
            const machinesGroupsPages = action.data.total_pages
            return state.merge({ machine_group, machinesGroupsFetching, machinesGroupsPages });
            break;
        }

        case MachinesType.GET_MACHINES_VIEW_FAILED: {
            const machinesGroupsFetchingFailed = true;
            const machinesGroupsFetching = false;
            return state.merge({ machinesGroupsFetching, machinesGroupsFetchingFailed });
            break;
        }

        case MachinesType.ADD_MACHINE_GROUP_CALL: {
            const machinesGroupsPosting = true;
            return state.merge({ machinesGroupsPosting });
            break;
        }

        case MachinesType.ADD_MACHINE_GROUP_SUCCESS: {
            const machinesGroupsPosting = false;
            const machinesGroupsPostingSuccess = true;
            return state.merge({ machinesGroupsPostingSuccess, machinesGroupsPosting, machineGroupObj: INITIAL_STATE.machineGroupObj })
            break;
        }

        case MachinesType.ADD_MACHINE_GROUP_FAILED: {
            const machinesGroupsPosting = false;
            const machinesGroupsPostingFailed = true;
            return state.merge({ machinesGroupsPosting, machinesGroupsPostingFailed });
            break;
        }

        case MachinesType.GET_MACHINE_SUBGROUP_CALL: {
            const machinesSubgroupsFetching = true;
            return state.merge({ machinesSubgroupsFetching });
            break;
        }

        case MachinesType.GET_MACHINE_SUBGROUP_SUCCESS: {
            const machine_subgroup = action.data.data;
            const machinesSubgroupsFetching = false;
            const machinesSubgroupsPages = action.data.total_pages
            return state.merge({ machine_subgroup, machinesSubgroupsFetching, machinesSubgroupsPages });
            break;
        }

        case MachinesType.GET_MACHINE_SUBGROUP_FAILED: {
            const machinesSubgroupsFetchingFailed = true;
            const machinesSubgroupsFetching = false;
            return state.merge({ machinesSubgroupsFetching, machinesSubgroupsFetchingFailed });
            break;
        }

        case MachinesType.ADD_MACHINE_SUBGROUP_CALL: {
            const machinesSubgroupsPosting = true;
            return state.merge({ machinesSubgroupsPosting });
            break;
        }

        case MachinesType.ADD_MACHINE_SUBGROUP_SUCCESS: {
            const machinesSubgroupsPosting = false;
            const machinesSubgroupsPostingSuccess = true;
            return state.merge({ machinesSubgroupsPostingSuccess, machinesSubgroupsPosting, machineSubgroupObj: INITIAL_STATE.machineSubgroupObj })
            break;
        }

        case MachinesType.ADD_MACHINE_SUBGROUP_FAILED: {
            const machinesSubgroupsPosting = false;
            const machinesSubgroupsPostingFailed = true;
            return state.merge({ machinesSubgroupsPosting, machinesSubgroupsPostingFailed });
            break;
        }

        case MachinesType.SET_MACHINE_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case MachinesType.DELETE_MACHINE_SUCCESS: {
            const array = state[action.name].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ [action.name]: array, deleted: deleted })
            break;
        }

        case MachinesType.DELETE_MACHINE_FAILED: {
            const deleteMachineFailed = true;
            return state.merge({ deleteMachineFailed });
            break;
        }

        case MachinesType.ADD_MACHINE_FAVORITE_SUCCESS: {
            const addFavoriteSuccess = true;
            return state.merge({ addFavoriteSuccess })
        }

        case MachinesType.DELETE_MACHINE_FAVORITE_SUCCESS: {
            const deleteFavoriteSuccess = true;
            return state.merge({ deleteFavoriteSuccess })
        }

        default:
            return state;
    }
}