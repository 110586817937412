/**
 * Created by pajicv on 6/4/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import axios from '../../../utils/AxiosWrapper';

import i18n from '../../../i18n/i18n';
import { withStyles } from '@material-ui/core';

import { ALL_STATES_ID } from '../../../utils/constants';

import './ZoomTo.css'

class ZoomToParcel extends Component {

    state = {
        layers: [],
        parcels: [],
    };


    componentDidMount() {

        const { clientProfile, onLayerChange } = this.props;

        const self = this;



        axios.get('api/parcela/drop_down_by_geometry')
            .then(function (response) {
                const parcels = response.data.data.map((item) => ({ label: item.broj, value: item.id }));
                self.setState({ parcels });
            })
            .catch(function (error) {
                console.log(error);
            });



    }

    handleParcelChange = (selectedParcels) => this.props.onParcelChange(selectedParcels);

    // handleLayerChange = (selectedLayers) => {

    //     const self = this;

    //     const { onLayerChange } = this.props;

    //     axios.get('api/field/drop_down_by_season?layers=' + selectedLayers.map(layer => layer.value))
    //         .then(function (response) {
    //             const fields = response.data.data.map((item) =>  ({ label: item.naziv, value: item.id }));
    //             self.setState({ fields });
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });

    //     onLayerChange(selectedLayers);
    // };

    render() {

        const { classes, clientProfile, selectedParcels, selectedLayers } = this.props;

        const { layers, parcels } = this.state;

        return (
            <form className={classes.form} id="ZoomToParcelForm">
                <Select
                    isMulti
                    name="parcel"
                    value={selectedParcels}
                    onChange={this.handleParcelChange}
                    closeMenuOnSelect={false}
                    options={parcels}
                    className="multi-select-in-filter-dashboards"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
            </form>
        );
    }
}

const styles = theme => ({
    TheInput: {
        width: 256,
        fontWeight: 'bold',
        lineHeight: 2.4,
        marginTop: 2,
        marginBotom: 2
    },
    resize: {
        fontSize: 14,
    },
    form: {
        height: 600
    }
});

ZoomToParcel = withStyles(styles)(ZoomToParcel);

export default connect(state => ({
    // clientProfile: state.appReducer.clientProfile
}))(ZoomToParcel);