import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Card, Button, Table, Tooltip, TableBody, TableCell, TableHead, TableRow, Dialog, DialogTitle, IconButton, Typography } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import immutable from 'seamless-immutable';
import ReactSelect from '../../utils/ReactSelect';
import i18n from '../../i18n/i18n';
import * as Icons from '@material-ui/icons';
import { getImplementGroup, getImplementSubgroup, getImplement } from '../../actions/activity/ActivityActions';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';
const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.text.secondary,
        height: '35px',
        fontWeight: '500',
        fontSize: 16
    },
    body: {
        fontSize: 13,
    },
    root: {
        padding: '5px',
        textAlign: 'left',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit',
        paddingLeft: '20px',
        paddingRight: '80px',
    }
}))(TableCell);
const styles = theme => ({
    innerContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center'
    },
    card: {
        height: 500,
        width: 600,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    innerContainerTable: {
        width: '100%',
        textAlign: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
    },
    cssRoot: {
        color: '#e50000',
        '&:hover': {
            color: '#ff0000',
        },
    },
    button: {
        height: '100%',
        borderRadius: 0,
        boxShadow: 'none',
        width: '235px'
    },
    buttonsRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: '0 0 2px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
    },
    typography: {
        paddingLeft: '20px',
        color: theme.palette.text.secondary,
        fontWeight: '600',
    }
});
export class ActivityImplements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prikljucneMasine: [],
            implementDropdown: [],
            // implementGroupDropdown: [],
            // implementSubgroupDropdown: [],
            prikljucna: [],
            add: false,
            // id_prikljucna_masina_grupa: {},
            // id_prikljucna_masina_podgrupa: {},
        }
    }

    componentDidMount() {
        // this.props.getImplementGroup();
        // this.props.getImplementSubgroup();
        this.props.getImplement();
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevProps.implementGroupDropdown !== this.props.implementGroupDropdown) {
        //     this.setState({
        //         implementGroupDropdown: immutable.asMutable(this.props.implementGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        //     })
        // }
        // if (prevProps.implementSubgroupDropdown !== this.props.implementSubgroupDropdown) {
        //     this.setState({
        //         implementSubgroupDropdown: immutable.asMutable(this.props.implementSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        //     })
        // }
        if (prevProps.implementDropdown !== this.props.implementDropdown) {
            this.setState({
                implementDropdown: immutable.asMutable(this.props.implementDropdown.map((item) => { return { label: item.naziv, value: item.id, naziv: item.naziv, id_prikljucna_masina: item.id } }), { deep: true }),
            })
        }
        if (this.props.prikljucneMasine && prevState.prikljucneMasine !== this.props.prikljucneMasine) {
            this.setState({
                prikljucneMasine: this.props.prikljucneMasine
            })
        }
    }

    handleAdd = (prikljucna) => {
        const { handleAddImplements } = this.props;
        const { prikljucneMasine } = this.state;
        let diff = _.differenceBy(prikljucna, prikljucneMasine, 'id_prikljucna_masina');
        handleAddImplements('prikljucneMasine', diff);
        this.setState({
            prikljucna: [],
            add: false,
        })
    }

    handleChangeValue = name => selected => {
        this.setState((state) => {
            let prikljucna = state[name];
            prikljucna = selected;
            return { ...state, prikljucna };
        })
    }

    handleChangeGroupSubgroup = name => selected => {
        this.setState((state) => {
            let property = state[name];
            property = selected && (selected || {});
            return { ...state, [name]: property };
        })
        // ,
        //     function () {
        //         if (name === 'id_prikljucna_masina_grupa' && selected) {
        //             this.props.getImplementSubgroup(selected.value);
        //         } else if (name === 'id_prikljucna_masina_grupa' && !selected) {
        //             this.props.getImplementSubgroup();
        //         }
        //         if (name === 'id_prikljucna_masina_podgrupa' && selected) {
        //             this.props.getImplement(selected.value);
        //         } else if (name === 'id_prikljucna_masina_podgrupa' && !selected) {
        //             this.props.getImplement();
        //         }
        //     },
        // )
    }

    handlePopup = () => {
        this.setState({
            add: !this.state.add
        })
    }

    onDeleteClick = (prikljucna, index) => {
        const { handleDeleteChild } = this.props
        handleDeleteChild('prikljucneMasine', index, prikljucna);
        this.setState(prevState => {
            let newPrikljucne = prevState.prikljucneMasine;
            return { prikljucneMasine: newPrikljucne.filter((_, i) => i !== index) };
        })
    }

    render() {
        const { classes, theme, viewOnly } = this.props;
        const { prikljucneMasine, prikljucna, implementDropdown } = this.state;
        const prikljucneRender = prikljucneMasine && prikljucneMasine.map((prikljucna, i) => {
            if (!prikljucna) return null;
            return <TableRow key={i}>
                <CustomTableCell>{prikljucna.naziv && prikljucna.naziv || prikljucna.prikljucna.naziv}</CustomTableCell>
                <CustomTableCell>
                    <Tooltip title={i18n.t("Delete")}>
                        <IconButton aria-label="Delete"
                            className={classes.cssRoot}
                            disabled={viewOnly}
                            onClick={() => this.onDeleteClick(prikljucna, i)}
                        >
                            <Icons.Delete />
                        </IconButton>
                    </Tooltip>
                </CustomTableCell>
            </TableRow>
        })
        return (
            <div className={classes.innerContainer} >
                {
                    this.state.add &&
                    <Dialog
                        open={this.state.add}
                        onClose={this.handlePopup}
                        maxWidth="sm"
                        disableBackdropClick
                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogTitle style={{ background: theme.palette.secondary.dark }} disableTypography={true} className="responsive-dialog-title">{i18n.t('Add implement')}
                            <IconButton onClick={this.handlePopup}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </DialogTitle>
                        <Card raised className={classes.card}>
                            {/* <ReactSelect
                                closeMenuOnSelect={true}
                                isClearable={true}
                                maxMenuHeight={150}
                                value={id_prikljucna_masina_grupa}
                                label={i18n.t('Implements group')}
                                onChange={this.handleChangeGroupSubgroup('id_prikljucna_masina_grupa')}
                                options={this.state.implementGroupDropdown}
                                className="multi-select-activity-form"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            {Object.keys(id_prikljucna_masina_grupa).length !== 0 &&
                                <ReactSelect
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    value={id_prikljucna_masina_podgrupa}
                                    label={i18n.t('Implements subgroup')}
                                    onChange={this.handleChangeGroupSubgroup('id_prikljucna_masina_podgrupa')}
                                    options={this.state.implementSubgroupDropdown}
                                    className="multi-select-activity-form"
                                    classNamePrefix="select"
                                    placeholder={i18n.t('Select')}
                                />
                            } */}
                            <ReactSelect
                                closeMenuOnSelect={false}
                                isClearable={true}
                                isDisabled={viewOnly}
                                isMulti={true}
                                value={prikljucna}
                                maxMenuHeight={250}
                                label={i18n.t('Implements')}
                                onChange={this.handleChangeValue('id_prikljucna_masina')}
                                options={_.differenceBy(implementDropdown, prikljucneMasine, 'id_prikljucna_masina')}
                                className="multi-select-activity-form"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <div className={classes.buttonsRow}>
                                <Button disabled={Object.keys(prikljucna).length === 0} onClick={() => this.handleAdd(prikljucna)}>{i18n.t('Add')}</Button>
                                <Button onClick={this.handlePopup}>{i18n.t('Close')}</Button>
                            </div>
                        </Card>
                    </Dialog>
                }
                <div className={classes.panel}>
                    <Typography className={classes.typography} variant="subtitle1">{i18n.t('Implements')}</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        aria-label="Add"
                        disabled={viewOnly}
                        className={classes.button}
                        onClick={this.handlePopup}
                    >
                        {'+ ' + i18n.t('Add implement')}
                    </Button>
                </div>
                <div className={classes.innerContainerTable}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell >{i18n.t('Implement')}</CustomTableCell>
                                <CustomTableCell style={{ paddingRight: '36px' }}>{i18n.t('Delete')}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {prikljucneRender}
                        </TableBody>
                    </Table>
                </div>
            </div >
        )
    }

}

function mapStateToProps(state) {
    return {
        implementDropdown: state.activityReducer.implementDropdown,
        implementDropdownFetching: state.activityReducer.implementDropdownFetching,
        implementDropdownFetchingFailed: state.activityReducer.implementDropdownFetchingFailed,

        implementGroupDropdown: state.activityReducer.implementGroupDropdown,
        implementGroupFetching: state.activityReducer.implementGroupFetching,
        implementGroupFetchingFailed: state.activityReducer.implementGroupFetchingFailed,

        implementSubgroupDropdown: state.activityReducer.implementSubgroupDropdown,
        implementSubgroupFetching: state.activityReducer.implementSubgroupFetching,
        implementSubgroupFetchingFailed: state.activityReducer.implementSubgroupFetchingFailed,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getImplementGroup: () => dispatch(getImplementGroup()),
        getImplementSubgroup: (id_grupa) => dispatch(getImplementSubgroup(id_grupa)),
        getImplement: () => dispatch(getImplement()),

    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityImplements))