import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import { formatData, formatForecast, formatDisease } from './../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';
import MeteoInfoButton from '../MeteoInfoButton';
import InfoPrecipitation from '../Info/Data/InfoPrecipitation';

const styles = {};

export class DataPrecipitation extends React.Component {
    constructor(props) {
        super(props);
        this.totalPrec = this.totalPrec.bind(this);
        this.state = {
            station_id: this.props.row.uid,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            data: {},
            load: true,
            totalPrec: 0,
        };
    }

    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    getData = (path) => {
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { let obj = formatData(res); this.setState({ data: obj, load: false }); })
            .catch(err => console.log('Error: ', err))
    }

    getPessl = () => {
        if (this.state.startDate !== null && this.state.endDate !== null) {
            let start_date = moment(this.state.startDate).unix();
            let end_date = moment(this.state.endDate).unix();
            let station_id = this.state.station_id;

            // URL-ovi za podatke sa meteo stanica
            let disease_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;
            let forecast_path = '/forecast/' + station_id + '/basic-1h';
            let data_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;

            // Disease mora POST method, za Data i Forecast mora GET method
            this.getData({ path: data_path, method: "GET" });
        };
    }

    totalPrec() {
        let total = 0;
        for (let i = 0; i < this.state.data.precipitation.length; i++) {
            total += this.state.data.precipitation[i];
        }
        // toFixed vraca samo jednu decimalu na primer: 3.2 a ne 3.200000
        return total.toFixed(1);
    }

    render() {
        const { classes } = this.props;

        let graph1 = {};
        let graph2 = {};

        if (
            this.state.data.dates &&
            this.state.data
        ) {
            graph1 = {
                labels: this.state.data.dates,
                datasets: [
                    {
                        data: this.state.data.precipitation,
                        label: i18n.t('Precipitation [mm]'),
                        backgroundColor: 'rgb(70, 130, 180,0.7)',
                        borderColor: '#4682B4',
                        type: 'line',
                        borderWidth: 2,
                        pointBackgroundColor: '#4682B4', 
                        pointBorderColor: '#4682B4',
                        pointRadius: this.state.data.dates.length > 504 ? 0 : 2,
                        yAxisID: 'y-axis-3',
                    },
                    {
                        data: this.state.data.humidity,
                        label: i18n.t('Humidity [%]'),
                        backgroundColor: 'transparent',
                        borderColor: 'rgb(37, 245, 224)',
                        type: 'line',
                        borderWidth: 2,
                        pointBackgroundColor: 'rgb(37, 245, 224)', 
                        pointBorderColor: 'rgb(37, 245, 224)',
                        pointRadius: this.state.data.dates.length > 504 ? 0 : 2,
                        yAxisID: 'y-axis-1',
                    },
                ],
            };

            graph2 = {
                labels: this.state.data.dates,
                datasets: [
                    {
                        data: this.state.data.precipitation,
                        label: i18n.t('Precipitation [mm]'),
                        backgroundColor: 'rgb(70, 130, 180,0.9)',
                        borderColor: '#4682B4',
                        type: 'line',
                        borderWidth: 2,
                        pointBackgroundColor: '#4682B4', 
                        pointBorderColor: '#4682B4',
                        pointRadius: this.state.data.dates.length > 504 ? 0 : 2,
                        yAxisID: 'y-axis-3',
                    },
                    {
                        data: this.state.data.leaf_wetness,
                        label: i18n.t('Leaf wetness [min/h]'),
                        backgroundColor: '#7CFC00',
                        borderColor: '#7CFC00',
                        type: 'bar',
                        borderWidth: 2,
                        pointBackgroundColor: '#7CFC00', 
                        pointBorderColor: '#7CFC00',
                        pointRadius: this.state.data.dates.length > 504 ? 0 : 2,
                        yAxisID: 'y-axis-4',
                    },
                ],
            };
        };

        const options1 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            // beginAtZero: true,
                            min: 30,
                            max: 100,
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-3',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 7
                        }
                    },
                ],
            },
        }

        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-3',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 7
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-4',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 60
                        }
                    },
                ],
            },
        }

        return (
            <div style={{ paddingLeft: '35px' }}>
                <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4}}>{i18n.t('Precipitation and leaf wetness')}</p>
                        </div>
                        <div style={{marginLeft: 20}}>
                        <MeteoInfoButton
                            title={i18n.t('Precipitation and leaf wetness')}
                            text={<InfoPrecipitation />}
                            height='400px'
                            width='sm'
                            />
                        </div>
                </div>
                <DatePickerMeteo startDate={this.state.startDate} endDate={this.state.endDate} setDates={this.setDates} getPessl={this.getPessl} />
                {this.state.load === true && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {this.state.load === false &&
                    <div>
                        <div style={{display:'flex', flexDirection:'row', marginTop:'10px', marginBottom:'10px', width:'95%', justifyContent:'center', alignItems:'center', boxShadow:'0 5px 10px rgba(154, 160, 185, 0.2), 0 15px 40px rgba(166, 173, 201, 0.1)'}}>
                            <p style={{fontSize:'16px'}}>{i18n.t('Total precipitation for selected time period')}: </p> 
                            <p style={{marginLeft:'5px', fontWeight:'bold', fontSize:'18px'}}> {this.totalPrec()} mm </p>
                        </div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph1} height={300} width={600} options={options1} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Precipitation [mm]'), i18n.t('Humidity [%]')],
                            dates: this.state.data.dates,
                            precipitation: this.state.data.precipitation,
                            humidity: this.state.data.humidity
                        }} />
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph2} height={300} width={600} options={options2} />
                        </div>
                        <MeteoTable data={{
                            headers: [i18n.t('Date and time'), i18n.t('Precipitation [mm]'), i18n.t('Leaf wetness [min/h]')],
                            dates: this.state.data.dates,
                            precipitation: this.state.data.precipitation,
                            leaf_wetness: this.state.data.leaf_wetness
                        }} />
                          <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(DataPrecipitation);