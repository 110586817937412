/**
 * Created by pajicv on 5/16/18.
 */

import { createSelector } from 'reselect';

import immutable from 'seamless-immutable';

import {FEATURE_LOAD_STEPS} from '../utils/constants';

const getLayers = state => immutable.getIn(state.layersReducer, ['layers', 'byId']);

const getLayerGroups = state => immutable.getIn(state.layersReducer, ['layerGroups', 'byId']);

const getState = state => state.layersReducer;

const getLayerGroup = (state, layerGroupId) => immutable.getIn(state.layersReducer, ['layerGroups', 'byId', layerGroupId]);

const getBaseLayersGroup = state => getLayerGroup(state, 'baseLayers');

const getSatelliteImageryLayersGroup = state => getLayerGroup(state, 'satelliteImageryLayers');

export const getCadastralLayersGroup = state => getLayerGroup(state, 'cadastralLayers');

export const getInstitutionalLayersGroup = state => getLayerGroup(state, 'institutionalLayers');

const getGroupsOfClientLayers = state => {

    const allLayerGroups = immutable.getIn(state.layersReducer, ['layerGroups', 'byId']);

    const clientLayerGroups = immutable.without(allLayerGroups, ['baseLayers', 'satelliteImageryLayers', 'cadastralLayers', 'institutionalLayers', 'weatherLayers', 'fieldNotes', 'silos','senzor']);

    return clientLayerGroups;

};

export const getBaseLayers = createSelector(
    getBaseLayersGroup,
    getLayers,
    (layerGroup, layers) => {

        const layerIds = immutable.getIn(layerGroup, ['layers']);

        let baseLayers = immutable({
            osm: {},
            google: {}
        });

        baseLayers = immutable.setIn(baseLayers, ['osm'], layers['osm']);

        baseLayers = immutable.setIn(baseLayers, ['google'], layers['google']);

        return baseLayers;

    }
);

export const getSatelliteImageryLayers = createSelector(
    getSatelliteImageryLayersGroup,
    getLayers,
    (layerGroup, layers) => {

        const layerIds = immutable.getIn(layerGroup, ['layers']);

        const result = layerIds.map(layerId => layers[layerId]);

        return result;

    }
);

export const getClientLayers = createSelector(
    getGroupsOfClientLayers,
    getLayers,
    (layerGroups, layers) => {

        const result = immutable.flatMap(Object.keys(layerGroups), layerGroupId => {

            const layerGroup = layerGroups[layerGroupId];

            const layerIds = layerGroup.layers;

            return layerIds.map(layerId => layers[layerId]);

        });

        return result;

    }
);

//renameovati u nesto smisleno
const f = state => state.layersReducer.editedLayerId;

const checkIsEdited = (layerId, editedLayerId) => !!editedLayerId && (editedLayerId === layerId);

export const getCadastralLayers = createSelector(
    getCadastralLayersGroup,
    getLayers,
    f,
    (layerGroup, layers, editedLayerId) => layerGroup.layers.map(layerId => {
        const layer = layers[layerId];
        const isEdited = checkIsEdited(layerId, editedLayerId);
        return {...layer, isEdited};
    })
);

export const getFieldLayer = createSelector(
    getLayers,
    f,
    (layers, editedLayerId) => {
        const layer = layers['fields'];
        const isEdited = checkIsEdited("fields", editedLayerId);
        return {...layer, isEdited};
    }
);

export const getSubfieldLayer = createSelector(
  getLayers,
  f,
  (layers, editedLayerId) => {
    const layer = layers['subfields'];
    const isEdited = checkIsEdited("subfields", editedLayerId);
    return {...layer, isEdited};
  }
);


export const getParcelLayer = createSelector(
    getLayers,
    f,
    (layers, editedLayerId) => {
        const layer = layers['parcels'];
        const isEdited = checkIsEdited("parcels", editedLayerId);
        return {...layer, isEdited};
    }
);

export const getEditedLayerId = createSelector(
    f,
    editedLayerId => editedLayerId
);

export const getCadastralLayersGroupExtern = createSelector(
    getState,
    state => immutable.getIn(state, ['layerGroups', 'byId', "cadastralLayers"])
);

export const getParcelToAdd = createSelector(
    getState,
    state => {
        const newParcel = immutable.getIn(state, ['newParcel']);
        const newParcelSaveSuccess = immutable.getIn(state, ['newParcelSaveSuccess']);
        if(newParcel && newParcelSaveSuccess) {
            return newParcel;
        }
    }
);

export const getLoadedParcels = createSelector(
    getState,
    state => {
        const loadedParcels = immutable.getIn(state, ['parcelFeaturesLoaded']);
        const parcelFeaturesLoadingStep = immutable.getIn(state, ['parcelFeaturesLoadingStep']);
        if(loadedParcels && parcelFeaturesLoadingStep === FEATURE_LOAD_STEPS.SUCCESS) {
            return loadedParcels;
        }
    }
);

export const getFieldToAdd = createSelector(
    getState,
    state => {
        const newField = immutable.getIn(state, ['newField']);
        const newFieldSaveSuccess = immutable.getIn(state, ['newFieldSaveSuccess']);
        if(newField && newFieldSaveSuccess) {
            return newField;
        }
    }
);

export const getLoadedFields = createSelector(
    getState,
    state => {
        const loadedFields = immutable.getIn(state, ['fieldFeaturesLoaded']);
        const fieldFeaturesLoadingStep = immutable.getIn(state, ['fieldFeaturesLoadingStep']);
        if(loadedFields && fieldFeaturesLoadingStep === FEATURE_LOAD_STEPS.SUCCESS) {
            return loadedFields;
        }
    }
);

export const getMapBounds = createSelector(
    getState,
    state => {
        return state.mapBounds;
    }
);

export const getInstitutionalLayers = createSelector(
    getInstitutionalLayersGroup,
    getLayers,
    (layerGroup, layers) => layerGroup.layers.map(layerId => {
        const layer = layers[layerId];
        return {...layer};
    })
);

export const getSatelliteLayersGroupExtern = createSelector(
    getState,
    state => immutable.getIn(state, ['layerGroups', 'byId', "satelliteImageryLayers"])
);

export const getSeasonalForecast = createSelector(
    getState,
    state => {
        return state.seasonalForecast;
    }
);

export const getNoteLayer = createSelector(
    getLayers,
    f,
    (layers, editedLayerId) => {
        const layer = layers['notes'];
        const isEdited = checkIsEdited("notes", editedLayerId);
        return {...layer, isEdited};
    }
);

export const getLoadedNotes = createSelector(
    getState,
    state => {
        const loadeNotes = immutable.getIn(state, ['noteFeaturesLoaded']);
        const noteFeaturesLoadingStep = immutable.getIn(state, ['noteFeaturesLoadingStep']);
        if(loadeNotes && noteFeaturesLoadingStep === FEATURE_LOAD_STEPS.SUCCESS) {
            return loadeNotes;
        }
    }
);

export const getSenzorLayer = createSelector(
    getLayers,
    f,
    (layers, editedLayerId) => {
        const layer = layers['senzor'];
        const isEdited = checkIsEdited("senzor", editedLayerId);
        return {...layer, isEdited};
    }
);

export const getLoadedSenzor = createSelector(
    getState,
    state => {
        const loadeNotes = immutable.getIn(state, ['senzorFeaturesLoaded']);
        const senzorFeaturesLoadingStep = immutable.getIn(state, ['senzorFeaturesLoadingStep']);
        if(loadeNotes && senzorFeaturesLoadingStep === FEATURE_LOAD_STEPS.SUCCESS) {
            return loadeNotes;
        }
    }
);



export const getLayerTree = createSelector(
    getLayerGroups,
    getLayers,
    (layerGroups, layers) => {
        return Object.keys(layerGroups).map(layerGroupId => {
            const layerGroup = layerGroups[layerGroupId];
            const {id, visible, name} = layerGroup;
            const node = {
                id,
                isChecked: visible,
                name,
                depth: 1
            };
            node.childNodes = layerGroup.layers.map(layerId => {
                const layer = layers[layerId];
                const {id, name, visible, icon, date, layerGroupId} = layer;
                return {
                    depth: 2,
                    id,
                    name,
                    isChecked: visible,
                    icon,
                    date,
                    layerGroupId,
                    leaf: true
                }
            });
            return node;
        });
    }
);

export const getSiloLayer = createSelector(
  getLayers,
  f,
  (layers) => {
    const layer = layers['silos'];
    return {...layer};
  }
);
