import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, MenuItem
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Bar, Pie, HorizontalBar } from 'react-chartjs-2';
import immutable from 'seamless-immutable';
import Select, { components } from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';
import { getCropRotationDashboard } from '../../../actions/reports/ReportsActions';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import { getCrop, getGroup, getSubgroup } from '../../../actions/resources/CropActions';
import { getStates, getLocalGov } from '../../../actions/DropDownActions';
import { getUser } from '../../../utils/CommonFunctions';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};

export class CropRotationDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            id_opstina: [],
            id_katastarska_opstina: [],
            id_kultura: [],
            id_kultura_grupa: [],
            id_kultura_podgrupa: [],

        };
    }

    componentDidMount() {
        this.props.getCropRotationDashboard({});
        if (this.props.states.length === 0) {
            this.props.getStates();
        }
        if (this.props.localGoverments.length === 0) {
            this.props.getLocalGov();
        }
        if (this.props.crops.length === 0) {
            this.props.getCrop();
        }
        if (this.props.cropGroupDropdown.length === 0) {
            this.props.getGroup();
        }
        if (this.props.cropSubgroupDropdown.length === 0) {
            this.props.getSubgroup();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            states: immutable.asMutable(nextProps.states.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            localGoverments: immutable.asMutable(nextProps.localGoverments.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            cropGroupDropdown: immutable.asMutable(nextProps.cropGroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            cropSubgroupDropdown: immutable.asMutable(nextProps.cropSubgroupDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            crops: immutable.asMutable(nextProps.crops.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClear = () => {
        this.setState({
            id_opstina: [],
            id_katastarska_opstina: [],
            id_kultura: [],
            id_kultura_grupa: [],
            id_kultura_podgrupa: [],
        }, function () {
            this.props.getCropRotationDashboard({});
        });
    };

    handleOk = () => {
        let filters = {};
        filters['id_opstina'] = this.state.id_opstina.map((item) => {
            return item.value;
        })
        filters['id_katastarska_opstina'] = this.state.id_katastarska_opstina.map((item) => {
            return item.value;
        });
        filters['id_kultura'] = this.state.id_kultura.map((item) => {
            return item.value;
        });
        filters['id_kultura_grupa'] = this.state.id_kultura_grupa.map((item) => {
            return item.value;
        });
        filters['id_kultura_podgrupa'] = this.state.id_kultura_podgrupa.map((item) => {
            return item.value;
        });
        this.props.getCropRotationDashboard(filters);
    }

    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }


    onPieClick = () => {
        this.props.history.push('/crop_rotation_cost');
    }

    onProfitClick = () => {
        this.props.history.push('/crop_rotation_cost');
    }

    onTableClick = () => {
        this.props.history.push('/crop_rotation');
    }



    render() {
        const { anchorEl } = this.state;
        let statesData = this.states;
        let localGovData = this.localGov;
        let cropGroup = this.cropGroup;
        let crops = this.crops;
        let cropSubgroup = this.cropSubgroup;
        let user = getUser();
        if (this.props.cropRotationItems && this.props.cropRotationItems.states) {
            statesData.labels = this.props.cropRotationItems.states.labels.asMutable();
            statesData.datasets[0].data = this.props.cropRotationItems.states.count.asMutable();
            statesData.datasets[0].data1 = this.props.cropRotationItems.states.povrsina.asMutable();
        }
        if (this.props.cropRotationItems && this.props.cropRotationItems.localGoverments) {
            localGovData.labels = this.props.cropRotationItems.localGoverments.labels.asMutable();
            localGovData.datasets[0].data = this.props.cropRotationItems.localGoverments.count.asMutable();
            localGovData.datasets[0].data1 = this.props.cropRotationItems.localGoverments.povrsina.asMutable();
        }
        if (this.props.cropRotationItems && this.props.cropRotationItems.cropGroup) {
            cropGroup.labels = this.props.cropRotationItems.cropGroup.labels.asMutable();
            cropGroup.datasets[0].data = this.props.cropRotationItems.cropGroup.count.asMutable();
            cropGroup.datasets[0].data1 = this.props.cropRotationItems.cropGroup.povrsina.asMutable();
            cropGroup.datasets[0].data2 = this.props.cropRotationItems.cropGroup.percent.asMutable();
        }

        if (this.props.cropRotationItems && this.props.cropRotationItems.cropSubgroup) {
            cropSubgroup.labels = this.props.cropRotationItems.cropSubgroup.labels.asMutable();
            cropSubgroup.datasets[0].data = this.props.cropRotationItems.cropSubgroup.count.asMutable();
            cropSubgroup.datasets[0].data1 = this.props.cropRotationItems.cropSubgroup.povrsina.asMutable();
            cropSubgroup.datasets[0].data2 = this.props.cropRotationItems.cropSubgroup.percent.asMutable();
        }

        if (this.props.cropRotationItems && this.props.cropRotationItems.crop) {
            crops.labels = this.props.cropRotationItems.crop.labels.asMutable();
            crops.datasets[0].data = this.props.cropRotationItems.crop.count.asMutable();
            crops.datasets[0].data1 = this.props.cropRotationItems.crop.povrsina.asMutable();
            crops.datasets[0].data2 = this.props.cropRotationItems.crop.percent.asMutable();
        }

        return (
            <div style={{ 'padding': '20px', display: 'flex', flexDirection: 'column', width: '90%', paddingLeft: '0px', marginLeft: '-30px' }}>

                {/* <Card style={styles.card}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h1>{i18n.t('Total number of crop rotation reports')}: {this.props.cropRotationItems.total} </h1>
                        <div className="filter-panel-dashboard">
                            <IconButton
                                styles={styles.button}
                                aria-owns={anchorEl ? 'simple-menu' : null}
                                aria-haspopup="true"
                                className="action-button-filter"
                                onClick={this.handleClick}
                            >
                                <span className="icon-search headerIcon" />
                            </IconButton>
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                        >
                            <InputLabel className="filter-label">{i18n.t('States')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_opstina}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                menuPlacement='auto'
                                onChange={this.handleChange('id_opstina')}
                                options={this.state.states}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Local government')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_katastarska_opstina}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_katastarska_opstina')}
                                options={this.state.localGoverments}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Crop group')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_kultura_grupa}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_kultura_grupa')}
                                options={this.state.cropGroupDropdown}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Crop subgroup')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_kultura_podgrupa}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_kultura_podgrupa')}
                                options={this.state.cropSubgroupDropdown}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <InputLabel className="filter-label">{i18n.t('Crop')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_kultura}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_kultura')}
                                options={this.state.crops}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <div style={{ padding: '30px' }}>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk}>{i18n.t('Ok')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear}>{i18n.t('Clear')}</Button>

                            </div>
                        </Menu>
                    </div>
                </Card> */}
                {user.id_klijent_grupa !== 1 &&
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/*    <Card style={styles.cardMin}>
                            <h1>{i18n.t('Crop rotation by states')}:</h1>
                            <Pie data={statesData} options={this.povrsinaOptions} />
                        </Card> */}
                        {/*   */}
                    </div >
                }

                <Card style={styles.card}>
                    <h1>{i18n.t('Crop rotation by crops group')}:</h1>
                    <HorizontalBar height={50} data={cropGroup} options={this.options} />
                </Card>

                <Card style={styles.card}>
                    <h1>{i18n.t('Crop rotation by crops subgroup')}:</h1>
                    <HorizontalBar height={80} data={cropSubgroup} options={this.options} />
                </Card>

                <Card style={styles.card}>
                    <h1>{i18n.t('Crop rotation by crops')}: </h1>
                    <HorizontalBar height={100} data={crops} options={this.options} />
                </Card>

                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} onProfitClick={this.onProfitClick} isVisible={true} />

            </div>
        )
    }


    cropGroupColor = randomColor({
        count: 30
    })

    cropsColor = randomColor({
        count: 200
    })

    cropSubgroupColor = randomColor({
        count: 50
    })

    statesColor = randomColor({
        count: 50
    })

    localGovColor = randomColor({
        count: 50
    })

    states = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.statesColor,
            hoverBackgroundColor: this.statesColor
        }]
    };
    localGov = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.localGovColor,
            hoverBackgroundColor: this.localGovColor
        }]
    };

    cropGroup = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Crops group'),
            backgroundColor: this.cropGroupColor,
            borderColor: this.cropGroupColor,
            borderWidth: 1,
            hoverBackgroundColor: this.cropGroupColor,
            hoverBorderColor: this.cropGroupColor,
        }]
    };
    cropSubgroup = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Crops subgroup'),
            backgroundColor: this.cropSubgroupColor,
            borderColor: this.cropSubgroupColor,
            borderWidth: 1,
            hoverBackgroundColor: this.cropSubgroupColor,
            hoverBorderColor: this.cropSubgroupColor,
        }]
    };
    crops = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Crops'),
            backgroundColor: this.cropsColor,
            borderColor: this.cropsColor,
            borderWidth: 1,
            hoverBackgroundColor: this.cropsColor,
            hoverBorderColor: this.cropsColor,
        }]
    };
    options = {
        animation: { duration: 0 },
        hover: { animationDuration: 0 },
        responsiveAnimationDuration: 0,
        legend: { display: false },
        tooltips: {
            mode: 'label',
            callbacks: {
                // afterLabel: function (tooltipItem, data) {
                //     return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                // },
                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + 'ha' + '\n' + data.datasets[tooltipItem.datasetIndex].data2[tooltipItem.index] + '%';
                },
            },
        }
    };

    /*     povrsinaOptions = {
            tooltips: {
                mode: 'label',
                callbacks: {
    
                    afterLabel: function (tooltipItem, data) {
                        return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + 'ha' + '\n' + data.datasets[tooltipItem.datasetIndex].data2[tooltipItem.index] + '%';
                    },
    
                },
            }
        }; */



};

function mapStateToProps(state) {
    return {
        cropRotationItems: state.reportsReducer.cropRotationItems,
        cropSubgroupDropdown: state.cropReducer.cropSubgroupDropdown,
        cropGroupDropdown: state.cropReducer.cropGroupDropdown,
        crops: state.cropReducer.crops,
        states: state.appReducer.states,
        localGoverments: state.appReducer.localGoverments,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getCropRotationDashboard: (filters) => dispatch(getCropRotationDashboard(filters)),
        getCrop: () => dispatch(getCrop()),
        getGroup: () => dispatch(getGroup()),
        getSubgroup: () => dispatch(getSubgroup()),
        getStates: () => dispatch(getStates()),
        getLocalGov: () => dispatch(getLocalGov()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CropRotationDashboard);

const styles = {
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px'
    },
    cardMin: {
        width: '45%',
        padding: '10px',
        margin: '10px',
        position: 'relative',
        float: 'left'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};