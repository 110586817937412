import moment from 'moment';

export const formatData = (res) => {
  let data = res.data.data.data;
  let dates = res.data.data.dates;
  let obj = {};
  let short_dates = [];

  if (dates) short_dates = dates.map(date => moment(date).utc().local().format('DD/MM, HH:mm'));
  if (data && short_dates) {
    let temperatures = data.filter(function (e) {
      return e.name === 'HC Air temperature';
    });
    let precipitation = data.filter(function (e) {
      return e.name === 'Precipitation';
    });
    let leaf_wetness = data.filter(function (e) {
      return e.name === 'Leaf Wetness';
    });
    let humidity = data.filter(function (e) {
      return e.name === 'HC Relative humidity';
    });
    let dew_point = data.filter(function (e) {
      return e.name === 'Dew Point';
    });
    let vpd = data.filter(function (e) {
      return e.name === 'VPD';
    });
    let deltat = data.filter(function (e) {
      return e.name === 'DeltaT';
    });
    let soil_temperatures = data.filter(function (e) {
      return e.name === 'Soil temperature' || e.name === 'PI54D temp';
    });
    let soil_moistures = data.filter(function (e) {
      return e.name === 'Soil moisture' || e.name === 'PI54D';
    });
    let solar_radiation = data.filter(function (e) {
      return e.name === 'Solar radiation';
    });
    let wind_speed = data.filter(function (e) {
      return e.name === 'U-sonic wind speed';
    });
    let wind_dir = data.filter(function (e) {
      return e.name === 'U-sonic wind dir';
    });
    let wind_gust = data.filter(function (e) {
      return e.name === 'Wind gust';
    });
    
    obj = {
      dates: short_dates,
      temperatures: temperatures[0] ? temperatures[0].values.avg : [],
      precipitation: precipitation[0] ? precipitation[0].values.sum : [],
      leaf_wetness: leaf_wetness[0] ? leaf_wetness[0].values.time : [],
      humidity: humidity[0] ? humidity[0].values.avg : [],
      dew_point: dew_point[0] ? dew_point[0].values.avg : [],
      vpd: vpd[0] ? vpd[0].values.avg : [],
      deltat: deltat[0] ? deltat[0].values.avg : [],
      soil_temperatures: soil_temperatures[0] ? soil_temperatures[0].values.avg : [],
      soil_moistures: soil_moistures[0] ? soil_moistures[0].values.avg : [],
      solar_radiation: solar_radiation[0] ? solar_radiation[0].values.avg : [],
      wind_speed: wind_speed[0] ? wind_speed[0].values.avg : [],
      wind_dir: wind_dir[0] ? wind_dir[0].values.last : [],
      wind_gust: wind_gust[0] ? wind_gust[0].values.max : [],
    };
  };

  return obj;
}
export const formatDiseaseDailyNulls = (res, data_obj, param) => {

  console.log('3')

  let data = res.data.data.data;
  let dates = res.data.data.dates;
  let short_dates = [];

  for (let i = 0; i < data.length; i++) {
    let tempName = (data[i].name)
    let tempValues; 

    if(data[i].name == 'Leaf Wetness'){
      tempValues= data[i].values.time}
      else if (data[i].name == 'HC Air temperature' || data[i].name == 'HC Relative humidity' || data[i].name == 'Soil temperature'){
      tempValues= data[i].values.avg}
      else if (data[i].name == 'Solar radiation' && data[i][0] !== null){
      tempValues= data[i].values.avg}
      else if (data[i].name == 'Precipitation'){
      tempValues= data[i].values.sum}
      else if (data[i].name == param){
        tempName = 'diseaseValues'
        tempValues= data[i].values.result}
      else {
      tempValues= data[i].values.result}
    data_obj[tempName] = tempValues;
  
    data_obj[tempName] = tempValues;
  }
  if (dates) short_dates = dates.map(date => moment(date).utc().local().format('DD/MM, HH:mm'));
  data_obj.dates = short_dates;

    let slicedDates = [];
    let slicedValues = [];

    if (data_obj.diseaseValues) {
      for (let index = 0; index < data_obj.diseaseValues.length; index++) {
        const element = data_obj.diseaseValues[index];
        if (element !== null){
          slicedValues.push(element)
          slicedDates.push(short_dates[index])
        }       
    }
    data_obj.slicedDates = slicedDates;
    data_obj.slicedValues = slicedValues;
    // console.log('data obj', data_obj)

  return data_obj;
  }


}
export const formatForecast = (res) => {
  let data = res.data.data.data;
  let dates = res.data.data.dates;
  let obj = {};
  let short_dates = [];

  if (dates) short_dates = dates.map(date => moment(date).utc().local().format('DD/MM, HH:mm'));

  if (data && short_dates) {
    let convective_precipitation = data.filter(function (e) {
      return e.name === 'Precipitation';
    });
    let felttemperature = data.filter(function (e) {
      return e.name === 'HC Air temperature';
    });
    let isdaylight = data.filter(function (e) {
      return e.name === 'isdaylight';
    });
    let pictocode = data.filter(function (e) {
      return e.name === 'Pictocode';
    });
    let precipitation = data.filter(function (e) {
      return e.name === 'Precipitation';
    });
    let precipitation_probability = data.filter(function (e) {
      return e.name === 'Probability of Prec.';
    });
    let rainspot = data.filter(function (e) {
      return e.name === 'Rain spot';
    });
    let relativehumidity = data.filter(function (e) {
      return e.name === 'Relative humidity';
    });
    let sealevelpressure = data.filter(function (e) {
      return e.name === 'Sea level pressure';
    });
    let snowfraction = data.filter(function (e) {
      return e.name === 'Probability of snow';
    });
    let temperature = data.filter(function (e) {
      return e.name === 'Temperature';
    });
    let uvindex = data.filter(function (e) {
      return e.name === 'Global radiation - GHI';
    });
    let winddirection = data.filter(function (e) {
      return e.name === 'Wind direction';
    });
    let windspeed = data.filter(function (e) {
      return e.name === 'Wind speed';
    });
    let dew_point_temperature = data.filter(function (e) {
      return e.name === 'Dew point temperature';
    });
    let evapotranspiration = data.filter(function (e) {
      return e.name === 'Daily ET0';
    });
    let leaf_wetness = data.filter(function (e) {
      return e.name === 'Leaf Wetness';
    });
    let potential_evapotranspiration = data.filter(function (e) {
      return e.name === '';
    });
    let reference_evapotranspiration_fao = data.filter(function (e) {
      return e.name === '';
    });
    let sensible_heat_flux = data.filter(function (e) {
      return e.name === 'Global radiation - Sensible Heat Flux';
    });
    let skin_temperature = data.filter(function (e) {
      return e.name === 'Temperature';
    });
    let soil_moisture = data.filter(function (e) {
      return e.name === '';
    });
    let soil_temperature = data.filter(function (e) {
      return e.name === 'soiltemperature_0to10cm';
    });
    let wet_bulb_temperature = data.filter(function (e) {
      return e.name === 'wetbulbtemperature';
    });
    let sunshine_time = data.filter(function (e) {
      return e.name === 'Sunshine time';
    });
    let cloud_cover = data.filter(function (e) {
      return e.name === 'Cloud cover';
    });
    let wind_gust = data.filter(function (e) {
      return e.name === 'Wind gust';
    });
    let air_surface_pressure = data.filter(function (e) {
      return e.name === 'Air surface pressure';
    });
    let probability_of_snow = data.filter(function (e) {
      return e.name === 'Probability of snow';
    });
    let snow_cover = data.filter(function (e) {
      return e.name === 'Snow cover';
    });
    let snow_amount = data.filter(function (e) {
      return e.name === 'Snow amount';
    });
    let wind_orientation = data.filter(function (e) {
      return e.name === 'Wind orientation'
    })
    obj = {
      dates: short_dates,
      convective_precipitation: convective_precipitation[0] ? convective_precipitation[0].values.result : [],
      felttemperature: felttemperature[0] ? felttemperature[0].values.result : [],
      isdaylight: isdaylight[0] ? isdaylight[0].values.result : [],
      pictocode: pictocode[0] ? pictocode[0].values.result : [],
      precipitation: precipitation[0] ? precipitation[0].values.result : [],
      precipitation_probability: precipitation_probability[0] ? precipitation_probability[0].values.result : [],
      rainspot: rainspot[0] ? rainspot[0].values.result : [],
      relativehumidity: relativehumidity[0] ? relativehumidity[0].values.result : [],
      sealevelpressure: sealevelpressure[0] ? sealevelpressure[0].values.result : [],
      snowfraction: snowfraction[0] ? snowfraction[0].values.result : [],
      temperature: temperature[0] ? temperature[0].values.result : [],
      uvindex: uvindex[0] ? uvindex[0].values.result : [],
      winddirection: winddirection[0] ? winddirection[0].values.result : [],
      windspeed: windspeed[0] ? windspeed[0].values.result : [],
      dew_point_temperature: dew_point_temperature[0] ? dew_point_temperature[0].values.result : [],
      evapotranspiration: evapotranspiration[0] ? evapotranspiration[0].values.result : [],
      leaf_wetness: leaf_wetness[0] ? leaf_wetness[0].values.result : [],
      potential_evapotranspiration: potential_evapotranspiration[0] ? potential_evapotranspiration[0].values.result : [],
      reference_evapotranspiration_fao: reference_evapotranspiration_fao[0] ? reference_evapotranspiration_fao[0].values.result : [],
      sensible_heat_flux: sensible_heat_flux[0] ? sensible_heat_flux[0].values.result : [],
      skin_temperature: skin_temperature[0] ? skin_temperature[0].values.result : [],
      soil_moisture: soil_moisture[0] ? soil_moisture[0].values.result : [],
      soil_temperature: soil_temperature[0] ? soil_temperature[0].values.result : [],
      wet_bulb_temperature: wet_bulb_temperature[0] ? wet_bulb_temperature[0].values.result : [],
      sunshine_time: sunshine_time[0] ? sunshine_time[0].values.result : [],
      cloud_cover: cloud_cover[0] ? cloud_cover[0].values.result : [],
      wind_gust: wind_gust[0] ? wind_gust[0].values.result : [],
      air_surface_pressure: air_surface_pressure[0] ? air_surface_pressure[0].values.result : [],
      probability_of_snow: probability_of_snow[0] ? probability_of_snow[0].values.result : [],
      snow_cover: snow_cover[0] ? snow_cover[0].values.result : [],
      snow_amount: snow_amount[0] ? snow_amount[0].values.result : [],
      wind_orientation: wind_orientation[0] ? wind_orientation[0].values.result : [],
    };
  };

  return obj;
}
export const formatDiseaseDaily = (res, data_obj) => {

  let data = res.data.data.data;
  let dates = res.data.data.dates;
  let short_dates = [];

  for (let i = 0; i < data.length; i++) {
    let tempName = (data[i].name)
    let tempValues; 

    if(data[i].name == 'Leaf Wetness'){
      tempValues= data[i].values.time}
      else if (data[i].name == 'HC Air temperature' || data[i].name == 'HC Relative humidity'){
      tempValues= data[i].values.avg}
      else {
      tempValues= data[i].values.result}
  
    data_obj[tempName] = tempValues;
  }
  if (dates) short_dates = dates.map(date => moment(date).utc().local().format('DD/MM'));
  data_obj.dates = short_dates;

  return data_obj;



}
export const formatDisease = (res) => {

  let data = res.data.data.data
  //console.log('disease',data)
  let obj = {};
  if (data) {
    let infection = data.filter(function (e) {
      return e.name === 'Infection'
    })
    let pollen_beetle_flight = data.filter(function (e) {
      return e.name === 'Pollen Beetle Flight'
    })
    let mycotoxin = data.filter(function (e) {
      return e.name === 'Mycotoxin'
    })
    let ascospores = data.filter(function (e) {
      return e.name === 'Ascospores'
    })
    let fhb_risk = data.filter(function (e) {
      return e.name === 'FHB Risk'
    })
    let aphid_risk = data.filter(function (e) {
      return e.name === 'Aphid Risk'
    })
    let light_infection = data.filter(function (e) {
      return e.name === 'Light Infection'
    })
    let moderate_infection = data.filter(function (e) {
      return e.name === 'Moderate Infection'
    })
    let severe_infection = data.filter(function (e) {
      return e.name === 'Severe Infection'
    })
    let severity = data.filter(function (e) {
      return e.name === 'Severity'
    })
    let p_striiformis = data.filter(function (e) {
      return e.name === 'P. Striiformis'
    })
    let p_tritici = data.filter(function (e) {
      return e.name === 'P. Tritici'
    })
    let p_graminis = data.filter(function (e) {
      return e.name === 'P. Graminis'
    })
    let apothecia = data.filter(function (e) {
      return e.name === 'Apothecia'
    })
    let appresoria = data.filter(function (e) {
      return e.name === 'Appressoria Inf.'
    })
    let hydrolytic = data.filter(function (e) {
      return e.name === 'Hydrolytic Inf.'
    })
    let sporulation = data.filter(function (e) {
      return e.name === 'Sporulation[0-1]'
    })
    let risk = data.filter(function (e) {
      return e.name === 'Risk'
    })
    let div_result = data.filter(function (e) {
      return e.name === 'Div Result'
    })
    let blossom_blight = data.filter(function (e) {
      return e.name === 'Blossom Blight'
    })
    let t_sum_3 = data.filter(function (e) {
      return e.name === 'T Sum 3'
    })
    let fireblight_div = data.filter(function (e) {
      return e.name === 'Fireblight DIV'
    })
    let asco_mature = data.filter(function (e) {
      return e.name === 'Asco Mature'
    })
    let asco_free = data.filter(function (e) {
      return e.name === 'Asco Free'
    })
    let asco_cond = data.filter(function (e) {
      return e.name === 'Asco Cond'
    })
    let codling_moth = data.filter(function (e) {
      return e.name === 'Codling Moth'
    })
    let codling_moth_adult = data.filter(function (e) {
      return e.name === 'Codling Moth Adult'
    })
    let larval_instars = data.filter(function (e) {
      return e.name === 'Larval Instars'
    })
    let chilling_portions = data.filter(function (e) {
      return e.name === 'Chilling portions'
    })
    let rust_infection = data.filter(function (e) {
      return e.name === 'Rust Infection'
    })
    let inc_1 = data.filter(function (e) {
      return e.name === 'Inc. 1'
    })
    let inc_2 = data.filter(function (e) {
      return e.name === 'Inc. 2'
    })
    let inc_3 = data.filter(function (e) {
      return e.name === 'Inc. 3'
    })
    let inc_4 = data.filter(function (e) {
      return e.name === 'Inc. 4'
    })
    let inc_5 = data.filter(function (e) {
      return e.name === 'Inc. 5'
    })
    let inc_6 = data.filter(function (e) {
      return e.name === 'Inc. 6'
    })
    let inc_7 = data.filter(function (e) {
      return e.name === 'Inc. 7'
    })
    let inc_8 = data.filter(function (e) {
      return e.name === 'Inc. 8'
    })
    let inc_9 = data.filter(function (e) {
      return e.name === 'Inc. 9'
    })
    let infection_1 = data.filter(function (e) {
      return e.name === 'Infection 1'
    })
    let infection_2 = data.filter(function (e) {
      return e.name === 'Infection 2'
    })
    let ascospore_discharge = data.filter(function (e) {
      return e.name === 'Ascospore Discharge'
    })
    let leaf_spot_infection = data.filter(function (e) {
      return e.name === 'Leaf Spot Infection'
    })
    let infection_strength = data.filter(function (e) {
      return e.name === 'Infection Strength'
    })
    let spores = data.filter(function (e) {
      return e.name === 'Spores'
    })
    let infection_progress = data.filter(function (e) {
      return e.name === 'Infection progress'
    })
    let number_of_days = data.filter(function (e) {
      return e.name === 'Number of days'
    })
    let cabagge_sporulation = data.filter(function (e) {
      return e.name === 'Sporulation'
    })
    let powdery_mildew_risk = data.filter(function (e) {
      return e.name === 'Infection'
    })
    let powdery_mildew_risk2 = data.filter(function (e) {
      return e.name === 'Infection 2'
    })
    let asco_discharge = data.filter(function (e) {
      return e.name === 'Asco Discharge'
    })
    let powdery_mildew_risk_vrezasto = data.filter(function (e) {
      return e.name === 'Powdery Mildew Risk'
    })
    let botrytis_risk = data.filter(function (e) {
      return e.name === 'Botrytis Risk'
    })
    let severity_dsv = data.filter(function (e) {
      return e.name === 'Severity DSV'
    })
    let disease_severity = data.filter(function (e) {
      return e.name === 'Disease Severity';
    });
    let no_blight_severity = data.filter(function (e) {
      return e.name === 'No Blight Severity';
    });


    obj = {
      infection: infection[0] ? infection[0].values.result : [],
      powdery_mildew_risk_vrezasto: powdery_mildew_risk_vrezasto[0] ? powdery_mildew_risk_vrezasto[0].values.result : [],
      pollen_beetle_flight: pollen_beetle_flight[0] ? pollen_beetle_flight[0].values.result : [],
      mycotoxin: mycotoxin[0] ? mycotoxin[0].values.result : [],
      ascospores: ascospores[0] ? ascospores[0].values.result : [],
      fhb_risk: fhb_risk[0] ? fhb_risk[0].values.result : [],
      aphid_risk: aphid_risk[0] ? aphid_risk[0].values.result : [],
      light_infection: light_infection[0] ? light_infection[0].values.result : [],
      moderate_infection: moderate_infection[0] ? moderate_infection[0].values.result : [],
      severe_infection: severe_infection[0] ? severe_infection[0].values.result : [],
      severity: severity[0] ? severity[0].values.result : [],
      p_striiformis: p_striiformis[0] ? p_striiformis[0].values.result : [],
      p_tritici: p_tritici[0] ? p_tritici[0].values.result : [],
      p_graminis: p_graminis[0] ? p_graminis[0].values.result : [],
      apothecia: apothecia[0] ? apothecia[0].values.result : [],
      appresoria: appresoria[0] ? appresoria[0].values.result : [],
      hydrolytic: hydrolytic[0] ? hydrolytic[0].values.result : [],
      sporulation: sporulation[0] ? sporulation[0].values.result : [],
      risk: risk[0] ? risk[0].values.result : [],
      div_result: div_result[0] ? div_result[0].values.result : [],
      blossom_blight: blossom_blight[0] ? blossom_blight[0].values.result : [],
      t_sum_3: t_sum_3[0] ? t_sum_3[0].values.result : [],
      fireblight_div: fireblight_div[0] ? fireblight_div[0].values.result : [],
      asco_mature: asco_mature[0] ? asco_mature[0].values.result : [],
      asco_free: asco_free[0] ? asco_free[0].values.result : [],
      asco_cond: asco_cond[0] ? asco_cond[0].values.result : [],
      codling_moth: codling_moth[0] ? codling_moth[0].values.result : [],
      codling_moth_adult: codling_moth_adult[0] ? codling_moth_adult[0].values.result : [],
      larval_instars: larval_instars[0] ? larval_instars[0].values.result : [],
      chilling_portions: chilling_portions[0] ? chilling_portions[0].values.result : [],
      rust_infection: rust_infection[0] ? rust_infection[0].values.result : [],
      inc_1: inc_1[0] ? inc_1[0].values.result : [],
      inc_2: inc_2[0] ? inc_2[0].values.result : [],
      inc_3: inc_3[0] ? inc_3[0].values.result : [],
      inc_4: inc_4[0] ? inc_4[0].values.result : [],
      inc_5: inc_5[0] ? inc_5[0].values.result : [],
      inc_6: inc_6[0] ? inc_6[0].values.result : [],
      inc_7: inc_7[0] ? inc_7[0].values.result : [],
      inc_8: inc_8[0] ? inc_8[0].values.result : [],
      inc_9: inc_9[0] ? inc_9[0].values.result : [],
      infection_1: infection_1[0] ? infection_1[0].values.result : [],
      infection_2: infection_2[0] ? infection_2[0].values.result : [],
      ascospore_discharge: ascospore_discharge[0] ? ascospore_discharge[0].values.result : [],
      leaf_spot_infection: leaf_spot_infection[0] ? leaf_spot_infection[0].values.result : [],
      infection_strength: infection_strength[0] ? infection_strength[0].values.result : [],
      spores: spores[0] ? spores[0].values.result : [],
      infection_progress: infection_progress[0] ? infection_progress[0].values.result : [],
      number_of_days: number_of_days[0] ? number_of_days[0].values.result : [],
      cabagge_sporulation: cabagge_sporulation[0] ? cabagge_sporulation[0].values.result : [],
      powdery_mildew_risk: powdery_mildew_risk[0] ? powdery_mildew_risk[0].values.result : [],
      powdery_mildew_risk2: powdery_mildew_risk2[0] ? powdery_mildew_risk2[0].values.result : [],
      botrytis_risk: botrytis_risk[0] ? botrytis_risk[0].values.result : [],
      asco_discharge: asco_discharge[0] ? asco_discharge[0].values.result : [],
      severity_dsv: severity_dsv[0] ? severity_dsv[0].values.result : [],
      disease_severity: disease_severity[0] ? disease_severity[0].values.result : [],

    }
  }
  return obj;
}
export const formatWorkPlanningData = (res, data_obj) => {
  let data = res.data.data.data;
  let dates = res.data.data.dates;
  let short_dates = [];

  for (let i = 0; i < data.length; i++) {
    let tempName = (data[i].name)
    let tempValues = data[i].values.result;
    data_obj[tempName] = tempValues;
  }
  if (dates) short_dates = dates.map(date => moment(date).utc().local().format('DD/MM, HH:mm'));
  data_obj.dates = short_dates;

  return data_obj;
}

export const formatDiseaseUni = (res, data_obj, chosenformat) => {

  let currentFormat = '';
  if(chosenformat) currentFormat=chosenformat
  else currentFormat = 'DD/MM, HH:mm'

  let data = res.data.data.data;
  let dates = res.data.data.dates;
  let short_dates = [];

  for (let i = 0; i < data.length; i++) {
    let tempName = (data[i].name)
    let tempValues = data[i].values.result;

    if(data[i].name == 'Leaf Wetness'){
      tempValues= data[i].values.time}
      else if (data[i].name == 'HC Air temperature' || data[i].name == 'HC Relative humidity' || data[i].name == 'Soil temperature' || data[i].name == 'PI54D temp'){
        if(data[i].name == 'PI54D temp'){
          tempName = 'Soil temperature'
        }
        tempValues= data[i].values.avg}
      else if (data[i].name == 'Solar radiation' && data[i][0] !== null){
      tempValues= data[i].values.avg}
      else if (data[i].name == 'Risk'){
      tempValues= data[i].values.result}
      else if (data[i].name == 'Precipitation'){
      tempValues= data[i].values.sum}
      else {
      tempValues= data[i].values.result}
    data_obj[tempName] = tempValues;
  }
  if (dates) short_dates = dates.map(date => moment(date).utc().local().format(currentFormat));
  data_obj.dates = short_dates;

  return data_obj;
}