import { toast } from 'react-toastify';
import i18n from '../i18n/i18n';

export const config = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    draggablePercent: 80
}

export const wentWrong = (text) => toast.error(i18n.t(text), config);

export const config2 = {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    draggablePercent: 80
}

export const wentRight = (text) => toast.success(i18n.t(text), config2);