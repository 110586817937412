import immutable from 'seamless-immutable';
import { Type as SectorsType } from '../../actions/sectors/SectorsActions';


export const INITIAL_STATE = immutable({
    sectors: [],
    sectorsFetching: false,
    sectorsFetchingFailed: false,
    sectorsPages: 1,
    //sector 
    sectorObj: {},
    sectorPosting: false,
    sectorPostingSuccess: false,
    sectorPostingFailed: false,
    delete: false,
    deleteFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SectorsType.VIEW_SECTORS_CALL: {
            const sectorsFetching = true;
            return state.merge({ sectorsFetching });
            break;
        }

        case SectorsType.VIEW_SECTORS_SUCCESS: {
            const sectors = action.data.data;
            const sectorsPages = action.data.total_pages
            const sectorsFetching = false;
            return state.merge({ sectors, sectorsPages, sectorsFetching });
            break;
        }

        case SectorsType.VIEW_SECTORS_FAILED: {
            const sectorsFetchingFailed = true;
            return state.merge({ sectorsFetchingFailed });
            break;
        }


        case SectorsType.ADD_SECTORS_CALL: {
            const sectorPosting = true;
            return state.merge({ sectorPosting })
            break;
        }

        case SectorsType.ADD_SECTORS_SUCCESS: {
            const sectorPosting = false;
            const sectorPostingSuccess = true;
            return state.merge({ sectorPosting, sectorPostingSuccess });
            break;
        }

        case SectorsType.ADD_SECTORS_FAILED: {
            const sectorPosting = false;
            const sectorPostingFailed = true;
            return state.merge({ sectorPosting, sectorPostingFailed });
            break;
        }

        case SectorsType.DELETE_SECTOR_SUCCESS: {
            const array = state.sectors.asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ sectors: array, deleted: deleted })
            break;
        }

        case SectorsType.DELETE_SECTOR_FAILED: {
            const deleteFailed = true;
            return state.merge({ deleteFailed });
            break;
        }

        case SectorsType.SET_SECTOR_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ sectorObj: data })
            break;
        }

        case SectorsType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;


        default:
            return state;
    }
}
