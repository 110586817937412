/**
 * Created by pajicv on 6/4/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import axios from '../../../utils/AxiosWrapper';

import i18n from '../../../i18n/i18n';
import { withStyles } from '@material-ui/core';

import { ALL_STATES_ID } from '../../../utils/constants';

import './ZoomTo.css'

class ZoomToField extends Component {

    state = {
        layers: [],
        fields: [],
    };


    componentDidMount() {

        const { clientProfile, onLayerChange } = this.props;

        const self = this;

        axios.get('api/layer/drop_down')
            .then(response => {
                const layers = response.data.data.map((item) =>  ({ label: item.naziv, value: item.id, id_sezona: item.id_sezona }));
                const selectedLayers = layers.filter(function (element) {
                    if (clientProfile) {
                        return element.id_sezona === clientProfile.id_default_sezona;
                    }
                });

                self.setState({ layers });

                onLayerChange(selectedLayers);

                axios.get('api/field/drop_down_by_season?layers=' + selectedLayers.map(layer => layer.value))
                    .then(function (response) {
                        const fields = response.data.data.map((item) =>  ({ label: item.naziv, value: item.id }));
                        self.setState({ fields });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            })
            .catch(error => {
                console.log(error);
            });

    }

    handleFieldChange = (selectedFields) => this.props.onFieldChange(selectedFields);

    handleLayerChange = (selectedLayers) => {

        const self = this;

        const { onLayerChange } = this.props;

        axios.get('api/field/drop_down_by_season?layers=' + selectedLayers.map(layer => layer.value))
            .then(function (response) {
                const fields = response.data.data.map((item) =>  ({ label: item.naziv, value: item.id }));
                self.setState({ fields });
            })
            .catch(function (error) {
                console.log(error);
            });

        onLayerChange(selectedLayers);
    };

    render() {

        const { classes, clientProfile, selectedFields, selectedLayers } = this.props;

        const { layers, fields } = this.state;

        return (
            <form className={classes.form} id="zoomToFieldForm">
                <Select
                    isMulti
                    name="layer"
                    onChange={this.handleLayerChange}
                    value={selectedLayers}
                    closeMenuOnSelect={false}
                    options={layers}
                    className="multi-select-in-filter-dashboards"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
                <Select
                    isMulti
                    name="field"
                    value={selectedFields}
                    onChange={this.handleFieldChange}
                    closeMenuOnSelect={false}
                    options={fields}
                    className="multi-select-in-filter-dashboards"
                    classNamePrefix="select"
                    placeholder={i18n.t('Select')}
                />
            </form>
        );
    }
}

const styles = theme => ({
    TheInput: {
        width: 256,
        fontWeight: 'bold',
        lineHeight: 2.4,
        marginTop: 2,
        marginBotom: 2
    },
    resize:{
        fontSize:14,
    },
    form: {
        height: 600
    }
});

ZoomToField = withStyles(styles)(ZoomToField);

export default connect(state => ({
    clientProfile: state.appReducer.clientProfile
}))(ZoomToField);