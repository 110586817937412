/**
 * Created by pajicv on 7/1/18.
 */

import React from 'react';

import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// import { getStates } from '../../../actions/AdministrationActions';
// import { getLocalGovByState } from '../../../actions/FarmRegistarActions';

import ZoomToAdministrative from './ZoomToAdministrative';
import ZoomToField from './ZoomToField';
import ZoomToParcel from './ZoomToParcel';

import ZoomToCoordinates from './ZoomToCoordinates';

import { ALL_STATES_ID, ALL_LOCAL_GOVS_ID } from '../../../utils/constants';
import ZoomToVehicle from './ZoomToVehicle';

import i18n from '../../../i18n/i18n';

const styles = theme => ({
  dialog: {
    height: 100
  },
  button: {
    height: 10,
    padding: 0
  },
  tab: {
    minWidth: 120,
    width: 120,
  }
});

class ZoomToMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStateId: ALL_STATES_ID,
      selectedLocalGovId: ALL_LOCAL_GOVS_ID,
      selectedLayers: [],
      selectedFields: [],
      selectedParcels: [],
      field: '',
      parcel: '',
      selectedTab: 'fields',
      latitude: null,
      longitude: null,
      isLatestPositionChecked: true,
      startTime: (new Date()).toISOString().substr(0, 16),
      endTime: (new Date()).toISOString().substr(0, 16),
      selectedVehicles: []
    };
  }

  componentDidMount = () => {
    // this.props.getStates();
  };

  handleOk = () => {
    const {
      selectedStateId, selectedLocalGovId, selectedFields, selectedParcels, selectedTab, latitude, longitude, isLatestPositionChecked, selectedVehicles, startTime, endTime
    } = this.state;
    const { zoomToBounds, zoomToCoordinates, zoomToVehicles } = this.props;
    if (selectedTab === 'coordinates') {
      zoomToCoordinates(latitude, longitude);
    } else if (selectedTab === 'vehicles') {
      zoomToVehicles(selectedVehicles, startTime, endTime, isLatestPositionChecked);
    } else {
      zoomToBounds(selectedStateId, selectedLocalGovId, selectedFields, selectedParcels);
    }
  };

  onStateSelected = (selectedStateId) => {
    this.setState({ selectedStateId });

    if (Number(selectedStateId) !== ALL_STATES_ID) {
      // this.props.getLocalGov(selectedStateId);
    }
  };

  onLocalGovSelected = (selectedLocalGovId) => {
    this.setState({ selectedLocalGovId });
  };

  onFieldChange = (value) => {
    this.setState({ field: value });
  };
  onParcelChange = (value) => {
    this.setState({ field: value });
  };


  handleLayerChange = selectedLayers => this.setState({ selectedLayers });

  handleFieldChange = selectedFields => this.setState({ selectedFields });

  handleParcelChange = selectedParcels => this.setState({ selectedParcels });


  handleLatitudeChange = latitude => this.setState({ latitude });

  handleLongitudeChange = longitude => this.setState({ longitude });

  handleVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });
  };

  handleLastCheckboxChange = ({ target: { checked } }) => {
    this.setState({ isLatestPositionChecked: checked });
  };

  handleStartTimeChange = (startTime) => {
    this.setState({ startTime });
  };

  handleEndTimeChange = (endTime) => {
    this.setState({ endTime });
  };

  handleClose = () => {
    const { onZoomToMenuClose } = this.props;
    onZoomToMenuClose();
  };

  handleChangeTab = (event, selectedTab) => this.setState({ selectedTab });

  render() {
    const {
      selectedStateId, selectedLocalGovId, selectedLayers, selectedFields, selectedParcels, selectedTab, latitude, longitude, selectedVehicles, isLatestPositionChecked, startTime, endTime
    } = this.state;

    const {
      classes, open, states, localGovernments, onZoomToMenuClose
    } = this.props;

    return (
      <div>
        <Dialog
          open={open}
          onClose={this.handleClose}
        >
          <DialogTitle id="form-dialog-title">
            <AppBar position="static">
              <Tabs value={selectedTab} onChange={this.handleChangeTab} fullWidth>
                <Tab value="fields" classes={{ root: classes.tab }} label={i18n.t('Fields')} />
                <Tab value="parcels" classes={{ root: classes.tab }} label={i18n.t('Parcels')} />
                <Tab value="vehicles" classes={{ root: classes.tab }} label={i18n.t('Tracking')} />
                <Tab value="coordinates" classes={{ root: classes.tab }} label={i18n.t('Coordinates')} />
              </Tabs>
            </AppBar>
          </DialogTitle>
          <DialogContent>
            {selectedTab === 'fields'
              && (
                <ZoomToField
                  selectedLayers={selectedLayers}
                  selectedFields={selectedFields}
                  onFieldChange={this.handleFieldChange}
                  onLayerChange={this.handleLayerChange}
                />
              )
            }
            {selectedTab === 'parcels'
              && (
                <ZoomToParcel
                  selectedParcels={selectedParcels}
                  onParcelChange={this.handleParcelChange}
                />
              )
            }
            {selectedTab === 'vehicles'
              && (
                <ZoomToVehicle
                  selectedVehicles={selectedVehicles}
                  isLatestPositionChecked={isLatestPositionChecked}
                  startTime={startTime}
                  endTime={endTime}
                  onLastCheckboxChange={this.handleLastCheckboxChange}
                  onStartTimeChange={this.handleStartTimeChange}
                  onEndTimeChange={this.handleEndTimeChange}
                  onVehicleChange={this.handleVehicleChange}
                />
              )
            }
            {selectedTab === 'coordinates'
              && (
                <ZoomToCoordinates
                  latitude={latitude}
                  longitude={longitude}
                  onLatitudeChange={this.handleLatitudeChange}
                  onLongitudeChange={this.handleLongitudeChange}
                />
              )
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={onZoomToMenuClose} color="primary">
              Cancel
              </Button>
            <Button onClick={this.handleOk} color="primary">
              Ok
              </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ZoomToMenu = withStyles(styles)(ZoomToMenu);

function mapStateToProps(state) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {
    // getStates: () => dispatch(getStates()),
    // getLocalGov: stateId => dispatch(getLocalGovByState(stateId)),
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomToMenu);
