import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import NumberRangeForFilter from '../../../utils/NumberRangeForFilter';
import WorkerActivityInfo from './WorkerActivityInfo';
import { Export } from '../../../utils/Export';

var FileSaver = require('file-saver');
const styles = theme => ({ primaryMain: { backgroundColor: theme.palette.primary.main } });
const CheckboxTable = checkboxHOC(ReactTable);;

export default class WorkerActivityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workplaceType: [], fieldType: [], sectorType: [], workerUnitType: [], groupType: [], workingOperationType: [],
            showFilters: false, loading: false,
            page: 0, pageSize: 25, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalData: 0,
            add: false, edit: false, delete: false, export: false,
            data: [], valuta: '', valuta_code: ''
        };
        this.toggleSelection = this.toggleSelection.bind(this);
    }

    componentDidMount() {
        this.getValuta();
        this.getByURL('field/worker_activity', 'fieldType');
        this.getByURL('worker_unit/drop_down', 'workerUnitType');
        this.getByURL('worker/filter_sector', 'sectorType');
        this.getByURL('workplace/drop_down', 'workplaceType');
        this.getByURL('working_operation/klijent_drop_down', 'workingOperationType');
        // this.getByURL('worker/filter_worker_group', 'groupType');
    }

    getValuta() {
        axios.get(`api/valuta/get_valuta_id_naziv`)
            .then(res => {
                let data = res.data.data
                this.setState({valuta_code: data.valuta_code})
                this.setState({valuta: data.valuta})
            })
            .catch(error => { console.log('error', error); })
    }

    getData(url, state, instance) {
        this.setState({ loading: true, data: [] });
        axios.get(`api/${url}`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalData: res.data.total,
                    loading: false
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true, selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => { selection.push(item._original.id); });
        }
        this.setState({ selectAll, selection });
    };

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection], selectedRow = null;
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
        else selection.push(key);
        if (selection.length == 1) selectedRow = this.state.data.filter((el) => el.id == selection)[0];
        this.setState({ selection, selectedRow });
    };

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) this.setState({ edit: false, selectedRow: [] });
        else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) this.setState({ selectedRow: [] });
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add === false) this.getData('worker_activities/read_list', this.state);
            if (this.state.add == true && this.state.edit == false) this.setState({ selection: [], selectAll: false, });
        });
    };

    deleteItem = () => {
        axios.post(`api/worker_activities/delete`, { selection: this.state.selection })
            .then(res => {
                this.getData('worker_activities/read_list', this.state);
                this.setState({ delete: false });
            })
            .catch(err => {
                this.setState({ delete: false });
                console.log('Error: ', err);
            })
    };

    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.exportDocument(this.state.docTitle, table.state, 'api/worker_activities/read_list');
        this.setState({ export: !this.state.export });
    };

    exportDocument(title, state, url, filters) {
        let params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title };
        if (filters) params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title, filters };

        return axios({ url: url, method: 'GET', responseType: 'blob', params: params })
            .then(response => FileSaver.saveAs(new Blob([response.data]), `${title || 'file'}.xlsx`))
            .catch(err => console.log(err))
    };

    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onExportClick = () => this.setState({ export: !this.state.export });
    onEditClick() { this.setState({ add: !this.state.add, edit: true }); }
    onTableClick = () => this.props.history.push('/work_activity_list');
    onDeleteClick = () => { this.setState({ delete: !this.state.delete }); }

    render() {
        let fieldType = this.state.fieldType.map((item) => { return { label: item.naziv, value: item.id } });
        let workerUnitType = this.state.workerUnitType.map((item) => { return { label: item.naziv, value: item.id } });
        let sectorType = this.state.sectorType.map((item) => { return { label: item.naziv, value: item.id } });
        let workplaceType = this.state.workplaceType.map((item) => { return { label: item.naziv, value: item.id } });
        let workingOperationType = this.state.workingOperationType.map((item) => { return { label: item.naziv, value: item.id } });
        // let groupType = this.state.groupType.map((item) => { return { label: item.naziv, value: item.id } });

        const columns = [
            { Header: i18n.t('ID'), accessor: 'id', width: 60 },
            { Header: i18n.t('First name'), accessor: 'ime' },
            { Header: i18n.t('Last name'), accessor: 'prezime' },
            { Header: i18n.t('Workplace'), accessor: 'naziv_radno_mesto', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={workplaceType} /> },
            { Header: i18n.t('Working operation'), accessor: 'naziv_radna_operacija', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={workingOperationType} /> },
            // { Header: i18n.t('Worker group'), accessor: 'naziv_radnik_grupa', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={groupType} /> },
            { Header: i18n.t('Field'), accessor: 'naziv_tabla', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={fieldType} /> },
            { Header: i18n.t('Sector'), accessor: 'naziv_sektor', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={sectorType} /> },
            {
                Header: i18n.t('Amount'), accessor: 'kolicina',
                Cell: ({ value }) => {
                    let formatted = Math.round(value * 100) / 100;
                    return formatted;
                },
                Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => { onChange(s); }} />
            },
            {
                Header: i18n.t('Price per unit'), accessor: 'cena_jmr',
                Cell: ({ value }) => {
                    let valuta = 'din', valuta_code = 'RSD';
                    if (this.state.valuta !== '') { valuta = this.state.valuta; valuta_code = this.state.valuta_code }

                    let formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: valuta_code }).format(value);
                    formatted = formatted.replace(valuta_code, valuta)
                    return formatted;
                },
                Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} />
            },
            { Header: i18n.t('Unit'), accessor: 'naziv_jmr', Filter: ({ filter, onChange }) => <FilterSelect onChange={onChange} type={workerUnitType} /> },
            {
                Header: i18n.t('Total cost'), accessor: 'cena',
                Cell: ({ value }) => {
                    let valuta = 'din', valuta_code = 'RSD';
                    if (this.state.valuta !== '') { valuta = this.state.valuta; valuta_code = this.state.valuta_code }

                    let formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: valuta_code }).format(value);
                    formatted = formatted.replace(valuta_code, valuta)
                    return formatted;
                },
                Filter: ({ filter, onChange }) => <NumberRangeForFilter onChange={(s) => onChange(s)} />
            }, { Header: i18n.t('Username'), accessor: 'username', width: 200 },
        ];
        const url = 'worker_activities/read_list';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit", alignItems: 'flex-end' } };
                }
                return {};
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#58B4E5' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        return (
            <div className="page">
                <div className="table-header">
                    <Fab onClick={() => this.onSearchClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-search headerIcon" />
                    </Fab>
                    {this.state.selection.length === 1 ?
                        <Fab onClick={() => this.onEditClick()}
                            color="secondary"
                            aria-label="edit"
                            className="action-button">
                            <span className="icon-Informacija headerIcon" />
                        </Fab>
                        : null
                    }
                    <Fab onClick={() => this.onExportClick()}
                        color="secondary"
                        aria-label="add"
                        className="action-button">
                        <span className="icon-download headerIcon" />
                    </Fab>
                </div>
                <CheckboxTable ref={r => (this.checkboxTable = r)}
                    manual
                    data={this.state.data}
                    paging={true}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.state.totalPages}
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => { this.getData(url, state, instance); }}
                    loading={this.state.loading}
                    className="-striped -highlight"
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...checkboxProps}
                    PreviousComponent={(props) => (<button {...props} className='-btn' style={{ backgroundColor: '#58B4E5', color: '#FFFFFF' }}>{props.children}</button>)}
                    NextComponent={(props) => (<button {...props} className='-btn' style={{ backgroundColor: '#58B4E5', color: '#FFFFFF' }}>{props.children}</button>)}
                />
                {this.state.add && <WorkerActivityInfo add={this.state.add} edit={this.state.edit} handleClose={this.handleClick} data={this.state.selectedRow} />}
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => { this.setState({ docTitle: title }); }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
            </div>
        );
    }
}

function FilterSelect(props) {
    return <Select
        isMulti
        name="naziv"
        onChange={value => { props.onChange(value) }}
        closeMenuOnSelect={false}
        options={props.type}
        className="multi-select-in-filter"
        classNamePrefix="select"
        placeholder={i18n.t('Select')}
    />
}