/**
 * Created by pajicv on 5/25/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';

import { LayersControl, FeatureGroup, Popup, LayerGroup } from 'react-leaflet';

import * as LayerSelectors from '../selectors/LayerSelectors';

import * as LayerActions from '../actions/map/LayerActions';
import { updateParcelGeom } from '../actions/catastral_data/ParcelActions';


import parcels from './parcels.json';

import './ParcelLayer.css';

const { Overlay } = LayersControl;

const parcelStyle = {
    color: '#00CC00',
    weight: 1
};

class ParcelLayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedFeature: {
                attributes: {
                    broj: null,
                    povrsina: null
                }
            }
        };

        this.onParcelClicked = this.onParcelClicked.bind(this);
    }

    onParcelClicked(e) {
        const clickedFeatureAttributes = e.layer.feature.properties;
        const { isDeleting, onDelete } = this.props
        if (isDeleting) {
            return onDelete(e.layer.feature.id)
        }
        this.setState({
            clickedFeature: {
                attributes: { ...clickedFeatureAttributes }
            }
        });
    }

    componentDidUpdate(prevProps) {

        const { parcelToAdd, parcelsToAdd, drawn } = this.props;

        if (parcelsToAdd) {

            let leafletGeoJSON = new L.GeoJSON(parcelsToAdd, {
                style: parcelStyle
            });
            let leafletFG = this._editableFG.leafletElement;

            leafletFG.clearLayers();

            leafletGeoJSON.eachLayer((layer) => {

                leafletFG.addLayer(layer);
                layer.on('pm:edit', (e) => {
                    // console.log('rrrrrr',e)
                    this.props.updateParcelGeom({
                        id: e.target.feature.id,
                        geometry: e.target.getLatLngs()
                    })
                })
                //layer.bindTooltip(layer.feature.properties.broj, {permanent: true, direction: 'center'}).openTooltip();
            });

            this.props.sendLoadedParcelsDrawSuccess();

        }

        if (parcelToAdd) {

            const feature = L.polygon(parcelToAdd.geometry).toGeoJSON();
            feature.properties = { ...parcelToAdd.attributes };
            const json = L.geoJSON(feature, {
                style: parcelStyle
            });
            this._editableFG.leafletElement.addLayer(json);

            this.props.sendParcelAddToMapSuccess();

        }


    }

    render() {

        const { addOverlay, removeLayer, removeLayerControl, parcelLayer, isDeleting } = this.props;

        return (
            <Overlay checked={parcelLayer.visible}
                name="Parcels"
                addOverlay={addOverlay}
                removeLayer={removeLayer}
                removeLayerControl={removeLayerControl}>
                <LayerGroup>
                    <FeatureGroup onClick={this.onParcelClicked} ref={(reactFGref) => { this._onFeatureGroupReady(reactFGref); }}>
                        {!isDeleting && (
                            <Popup className="popup-parcel">
                                <div>
                                    <div className="popup-parcel-name">
                                        {this.state.clickedFeature.attributes.broj}
                                    </div>
                                    <div className="popup-parcel-attributes">
                                        {`${this.state.clickedFeature.attributes.povrsina} ha`}
                                    </div>
                                </div>
                            </Popup>
                        )}
                    </FeatureGroup>
                </LayerGroup>
            </Overlay>
        )

    }

    _editableFG = null;

    _onFeatureGroupReady = (reactFGref) => {

        if (this._editableFG) return;

        // populate the leaflet FeatureGroup with the geoJson layers

        let leafletGeoJSON = new L.GeoJSON(parcels, {
            style: parcelStyle
        });
        let leafletFG = reactFGref.leafletElement;

        leafletGeoJSON.eachLayer((layer) => {
            leafletFG.addLayer(layer);
        });

        // store the ref for future access to content

        this._editableFG = reactFGref;

    };

    onCreated(e) {

        //console.log(e);

    }

}

function mapStateToProps(state) {
    return {
        parcelLayer: LayerSelectors.getParcelLayer(state),
        parcelToAdd: LayerSelectors.getParcelToAdd(state),
        parcelsToAdd: LayerSelectors.getLoadedParcels(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendParcelAddToMapSuccess: () => dispatch(LayerActions.sendNewParcelAddToMapSuccess()),
        sendLoadedParcelsDrawSuccess: () => dispatch(LayerActions.sendLoadedParcelsDrawSuccess()),
        updateParcelGeom: parcelObj => dispatch(updateParcelGeom(parcelObj)),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ParcelLayer);
