import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Menu } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import MySelect from '../../../utils/MySelect';
import styles from "./addWorkerActivities.module.css"
import * as Icons from '@material-ui/icons';
import { Tooltip as Tooltip } from '@material-ui/core';

import CalendarMonthIcon from '@material-ui/icons/DateRangeSharp';

import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';

export default class PreviewWorkActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            // workplace: null, workplaces: [],
            worker: null, workers: [],
            field: [], fields: [],
            worker_unit: null, worker_units: [],
            working_operation: null, working_operations: [],

            cena_jmr: null,
            valuta: '', valuta_code: '',

            showFilters: false,
            allFieldsList: [],
            allCultures: [],
            allHybrids: [],
            selectedCulture: 'all',
            selectedHybrid: 'all',

            filteredFieldType: [],
            allCulturesFiltered: [],
            allHybridsFiltered: [],
            selectedFields: null,

            editableHybridsList: [],

            selectedDate: new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }).split('/').join('.'),

            ukupnoUcinak: 0,
            ukupnoTroskovi: 0,
            dropdownOpen: false,
            fieldType: [],

        }
    }

    componentDidMount() {

        // axios.get('api/field/drop_down_by_sector').then(res => console.log("NEW ALL FIELDS LIST : ", res)).catch(err => console.log("NEW ERROR : ", err))
        axios.get('api/field/drop_down_by_sector', { params: { id_sektor: this.props.data.id_sektor_u_momentu_kreiranja } })
            .then(res => {
                this.setState({ allFieldsList: res.data.data }, () => {

                    this.setState({
                        allCultures: [...new Set(this.state.allFieldsList.map(field => field.kultura_naziv))],
                        allHybrids: [...new Set(this.state.allFieldsList.flatMap(field => field.sorte.map(sorta => sorta.naziv)))],

                    })
                })
                let listObj = [];
                let list = res.data.data.map(item => {
                    let label = item.naziv + ' (' + (Number(item.povrsina)).toFixed(2) + 'ha)';
                    let value = item.id;
                    let id_tabla = item.id;
                    let naziv = item.naziv;
                    let povrsina = (Number(item.povrsina)).toFixed(2);
                    let deo_table = item.deo_table;
                    let kultura_naziv = item.kultura_naziv;
                    let sorte = item.sorte;
                    listObj.push({ label, value, naziv, id_tabla, povrsina, deo_table, kultura_naziv, sorte });
                    return { label, value, naziv, povrsina, kultura_naziv, sorte };
                });
                this.setState({ fieldType: list, fieldList: listObj, filteredFieldType: list }, () => {
                    this.updateFilteredCulturesAndHybrids();


                });
            })
            .catch(() => wentWrong('Something went wrong'))


        if (this.props.edit === true) {
            axios.post('api/worker_activities/get_tabla_and_sorta_povrsina', { params: { data: this.props.data } })
                .then(res => {
                    console.log("NOVI FETCH : ", res);
                    this.setState({
                        editableHybridsList: res.data.data.raspodela.map(hybrid => {
                            return {
                                id_kultura_sorta: hybrid.id_kultura_sorta,
                                id_tabla: hybrid.id_tabla,
                                kultura: hybrid.kultura_naziv,
                                povrsina: hybrid.id_kultura_sorta === null ? hybrid.tabla_povrsina : hybrid.sorta_povrsina,
                                naziv: hybrid.sorta_naziv,
                                tabla_naziv: hybrid.tabla_naziv,
                                ucinak: Number(hybrid.kolicina),
                                troskovi: 0,
                            }
                        })

                    }, () => {
                        this.calculateCosts();
                        this.removeSelectedFields();
                    })

                })
                .catch(err => wentWrong('Something went wrong'));
        }

        this.getValuta();
        this.getByURL('worker/worker_list', 'workers');
        this.getByURL('field/drop_down', 'fields');
        this.getByURL('worker_unit/drop_down', 'worker_units');
        this.getByURL('working_operation/klijent_drop_down', 'working_operations');

        if (this.props.edit === true) {

            this.setState({
                selectedDate:  moment(this.props.data.datum).format("DD.MM.YYYY"),
                cena_jmr: Number(this.props.data.cena_jmr),

            }, () => {
                this.calculateCosts();
            });
        }
    }

    removeSelectedFields() {
        let selectedFields = [];
        let filteredFieldType = this.state.filteredFieldType;
        let editableHybridsList = this.state.editableHybridsList;

        for (let i = 0; i < filteredFieldType.length; i++) {
            const field = filteredFieldType[i];

            if (field.sorte.length === 0) {
                const index = editableHybridsList.findIndex(item => item.id_tabla === field.value);
                if (index !== -1) {
                    selectedFields.push(filteredFieldType.splice(i--, 1)[0]);
                }
            } else {
                const allSortePresent = field.sorte.every(sorteItem => {
                    return editableHybridsList.some(item => item.id_kultura_sorta === sorteItem.id_kultura_sorta && item.id_tabla === field.value);
                });

                if (allSortePresent) {
                    selectedFields.push(filteredFieldType.splice(i--, 1)[0]);
                }
            }
        }

        this.setState({ selectedFields: selectedFields, filteredFieldType: filteredFieldType })
    }

    getByURL(url, obj, value, name) {
        value = value || '';
        name = name || '';
        if (value != null) {
            axios.get(`api/${url}`, { params: { [name]: value } })
                .then(res => this.setState({ [obj]: res.data.data }, () => {
                    if (this.props.edit === true) {
                        if (this.state.workers.length > 0) {
                            this.setState({ worker: this.props.data.id_radnik })
                        }

                        if (this.state.working_operations.length > 0) {
                            this.setState({ working_operation: this.props.data.id_radna_operacija })
                        }

                        if (this.state.worker_units.length > 0) {
                            this.setState({ worker_unit: this.props.data.id_jmr })
                        }
                    }
                }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    getValuta() {
        axios.get(`api/valuta/get_valuta_id_naziv`)
            .then(res => {
                let data = res.data.data
                this.setState({ valuta_code: data.valuta_code })
                this.setState({ valuta: data.valuta })
            })
            .catch(error => { console.log('error', error); })
    }

    addWorkerActivity() {
        if (
            this.state.worker
            && this.state.working_operation
            && this.state.worker_unit
            && this.state.cena_jmr
            && this.state.editableHybridsList.length > 0
            && this.state.editableHybridsList.some(hybrid => Number(hybrid.ucinak) > 0)
            && Number(this.state.ukupnoTroskovi) > 0
        ) {

            let data;

            if (this.props.edit === true) {

                data = {
                    id: this.props.data.id,
                    id_radnik: this.state.worker,
                    datum: this.state.selectedDate,
                    id_jmr: this.state.worker_unit,
                    id_radna_operacija: this.state.working_operation,
                    cena_jmr: this.state.cena_jmr,
                    ukupna_cena: this.state.ukupnoTroskovi,
                    raspodela: this.state.editableHybridsList.map(hybrid => {
                        return {
                            id_tabla: hybrid.id_tabla,
                            id_kultura_sorta: hybrid.id_kultura_sorta,
                            kolicina: hybrid.ucinak,
                        }
                    }),
                }

            } else {
                data = {
                    id_radnik: this.state.worker,
                    datum: this.state.selectedDate,
                    id_jmr: this.state.worker_unit,
                    id_radna_operacija: this.state.working_operation,
                    cena_jmr: this.state.cena_jmr,
                    ukupna_cena: this.state.ukupnoTroskovi,
                    raspodela: this.state.editableHybridsList.map(hybrid => {
                        return {
                            id_tabla: hybrid.id_tabla,
                            id_kultura_sorta: hybrid.id_kultura_sorta,
                            kolicina: hybrid.ucinak,
                        }
                    }),
                }
            }



            axios.post('api/worker_activities/create', { data: data })
                .then(res => {

                    this.props.handleClose();
                    this.props.clearSelection();

                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })

        }
        else wentWrong('Please enter all required fields!');
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCulture !== this.state.selectedCulture ||
            prevState.selectedHybrid !== this.state.selectedHybrid) {
            this.updateFilteredFieldsFilst();
            this.updateFilteredCulturesAndHybrids();
        }

        if (prevState.selectedFields !== this.state.selectedFields ||
            prevState.fieldType !== this.state.fieldType) {
            this.updateFilteredFieldsFilst();
        }
    }

    handleFiltersClick = () => {
        this.setState({ showFilters: !this.state.showFilters, selectedCulture: 'all', selectedHybrid: 'all' });
    }

    updateFilteredFieldsFilst = () => {
        this.setState({
            filteredFieldType: this.state.fieldType
                .filter(field => this.state.selectedCulture === "all" ? field : field.kultura_naziv === this.state.selectedCulture)
                .filter(field => this.state.selectedHybrid === "all" ? field : field.sorte.some(sorta => sorta.naziv === this.state.selectedHybrid))
                .filter(field => {
                    let isChosen = false;
                    if (this.state.selectedFields !== null) {
                        for (let i = 0; i < this.state.selectedFields.length; i++) {
                            if (field.value === this.state.selectedFields[i].value) { isChosen = true }
                        }
                    }

                    if (!isChosen) return field;
                })
        })
    }


    updateFilteredCulturesAndHybrids() {
        this.setState({
            allCulturesFiltered: [...new Set(this.state.fieldType.filter(field => {
                if (this.state.selectedHybrid === "all") return field;
                if (field.sorte.some(sorta => sorta.naziv === this.state.selectedHybrid)) return field;

            }).map(field => field.kultura_naziv))],

            allHybridsFiltered: [... new Set(this.state.fieldType.filter(field => {
                if (this.state.selectedCulture === "all") return field;
                if (this.state.selectedCulture === field.kultura_naziv) return field;

            }).flatMap(item => item.sorte).map(sorta => sorta.naziv))]
        });
    }

    handleDateSelect(date) {

        this.setState({ selectedDate: date });
    }

    calculateCosts() {
        let ucinak = 0;
        let troskovi = 0;


        let hybridsCalculated = this.state.editableHybridsList.map(hybrid => {
            ucinak += Number(hybrid.ucinak);
            troskovi += Number(hybrid.ucinak) * Number(this.state.cena_jmr ? this.state.cena_jmr : 0);

            return {
                ...hybrid,
                troskovi: Number((Number(hybrid.ucinak) * Number(this.state.cena_jmr ? this.state.cena_jmr : 0)).toFixed(4)),
            }
        })

        this.setState({ editableHybridsList: hybridsCalculated, ukupnoUcinak: ucinak, ukupnoTroskovi: Number(troskovi.toFixed(4)) });

    }

    render() {
        let user = this.getUser();
        let currency = '';
        if (user.id_region === 2) currency = 'RSD';
        else if (user.id_region === 20) currency = 'KM';
        else if (user.id_region === 23) currency = '€';
        else if (user.id_region === 13) currency = '₦';
        else if (user.id_region === 25) currency = '₪';
        else if (user.id_region === 27) currency = '₪';
        else if (user.id_region === 26) currency = '$';

        let total_cost = null;
        if (this.state.cena_jmr && this.state.kolicina) {
            total_cost = this.state.cena_jmr * this.state.kolicina;
        }

        let valuta = 'din', valuta_code = 'RSD';
        if (this.state.valuta !== '') { valuta = this.state.valuta; valuta_code = this.state.valuta_code }

        let formatted = '';
        if (total_cost) {
            formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: valuta_code }).format(total_cost);
            formatted = formatted.replace(valuta_code, valuta);
        }

        return (

            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    // fullWidth={true}
                    disableBackdropClick
                    // maxWidth="md"
                    maxWidth="false"
                    PaperProps={{
                        style: {
                            maxWidth: '1050px',
                            margin: 'auto',
                            height: '825px',
                        }
                    }}
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{i18n.t('Preview Worker Activity')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer" style={{ minHeight: '100%', maxHeight: 'fit-content', display: 'flex', flexDirection: 'column' }}>


                            {/* <Grid item xs={4}>
                                    <TextField required
                                        label={i18n.t('Workplace')}
                                        select
                                        value={this.state.workplace}
                                        SelectProps={{ native: true }}
                                        InputLabelProps={this.state.workplace ? { shrink: true } : null}
                                        style={{ width: '280px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                        onChange={(e) => {
                                            this.setState({ workplace: e.target.value }, () => {
                                                axios.get('api/worker/worker_list', { params: { workplace: this.state.workplace } })
                                                    .then(res => { this.setState({ workers: res.data.data, worker_disabled: false }) })
                                                    .catch(err => { console.log(err) })
                                            });
                                        }} >
                                        <option value='' />
                                        {this.state.workplaces.map((workplaces) => <option key={workplaces.id} value={workplaces.id}>{workplaces.naziv}</option>)}
                                    </TextField>
                                </Grid> */}

                            <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>
                                <TextField
                                    disabled={true}
                                    InputProps={{
                                        style: {
                                            color: 'black', // Set the text color to black
                                        },
                                    }}
                                    label={i18n.t('Worker')}
                                    select
                                    // disabled={this.state.worker_disabled}
                                    value={this.state.worker}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={this.state.worker ? { shrink: true } : null}
                                    style={{ width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                    onChange={(e) => { this.setState({ worker: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.workers.map((workers) => <option key={workers.id} value={workers.id}>{workers.naziv}</option>)}
                                </TextField>



                                <TextField
                                    InputProps={{
                                        style: {
                                            color: 'black', // Set the text color to black
                                        },
                                    }}
                                    disabled={true}
                                    label={i18n.t('Working operation')}
                                    select
                                    value={this.state.working_operation}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={this.state.working_operation ? { shrink: true } : null}
                                    style={{ width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                    onChange={(e) => { this.setState({ working_operation: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.working_operations.map((working_operations) => <option key={working_operations.id} value={working_operations.id}>{working_operations.naziv}</option>)}
                                </TextField>


                                <DatePicker
                                    InputProps={{
                                        style: {
                                            color: 'black', // Set the text color to black
                                        },
                                    }}
                                    disabled={true}
                                    label={i18n.t('Date')}
                                    format="DD.MM.YYYY"
                                    value={moment(this.state.selectedDate, 'DD.MM.YYYY')}

                                    onChange={(e) => {

                                        this.setState({ selectedDate: (moment(e, 'DD.MM.YYYY').format('DD.MM.YYYY')) });

                                    }
                                    }
                                    style={{ margin: '0px 0px 10px 10px', height: '48px', flex: '1' }}
                                // renderInput={(params) => <TextField {...params} />}
                                />

                            </div>


                            <div style={{ display: 'flex', alignItems: 'flex-end', width: '100%' }}>

                                <TextField

                                    disabled={true}
                                    InputProps={{
                                        style: {
                                            color: 'black', // Set the text color to black
                                        },
                                    }}
                                    label={i18n.t('Worker unit')}
                                    select
                                    value={this.state.worker_unit}
                                    SelectProps={{ native: true }}
                                    InputLabelProps={this.state.worker_unit ? { shrink: true } : null}
                                    style={{ width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                    onChange={(e) => { this.setState({ worker_unit: e.target.value }); }} >
                                    <option value='' />
                                    {this.state.worker_units.map((worker_units) => <option key={worker_units.id} value={worker_units.id}>{worker_units.naziv}</option>)}
                                </TextField>



                                <TextField
                                    disabled={true}
                                    InputProps={{
                                        style: {
                                            color: 'black', // Set the text color to black
                                        },
                                    }}
                                    type="number"
                                    label={i18n.t('Price per unit') + ` (${user.valuta_naziv})`}
                                    value={this.state.cena_jmr}
                                    InputLabelProps={this.state.cena_jmr ? { shrink: true } : null}
                                    style={{ width: '336px', marginBottom: '10px', marginTop: '15px', marginLeft: '10px' }}
                                    onChange={(e) => { this.setState({ cena_jmr: e.target.value }, () => this.calculateCosts()); }} >
                                </TextField>

                            </div>



                            {/* <div className={styles.allFieldsContainer}>
                                    {this.state.selectedFields !== null && this.state.selectedFields.length > 0 && this.state.selectedFields.map((field, index) => {
                                        return <div className={styles.fieldLabel} key={index}>{field.label} <div className={styles.fieldDelete} onClick={() => {
                                            this.setState(prevState => ({
                                                selectedFields: prevState.selectedFields.filter(item => item.value !== field.value),
                                                filteredFieldType: [...prevState.filteredFieldType, field],
                                            }), () => {
                                                console.log("this.state.selectedFields : ", this.state.selectedFields)
                                                console.log("this.state.filteredFieldType : ", this.state.filteredFieldType)
                                            })
                                        }}>

                                            <Icons.DeleteForeverRounded
                                                className={styles.deleteIcon}
                                            />
                                        </div>
                                        </div>
                                    })}
                                </div> */}
                            <div style={{ minHeight: '50px', padding: '25px 0', marginLeft: '10px' }}>
                                {this.state.editableHybridsList && this.state.editableHybridsList.length > 0 &&
                                    <div className={styles.content_container}>
                                        {/* <div className={styles.content_border} /> */}
                                        <div className={styles.table_div}>
                                            <table className={styles.table}>
                                                <tr className={styles.table_row}>
                                                <th className={styles.table_header}>{i18n.t("Field")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Crops")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Sort/Hibrid")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Area (ha)")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Effect")}</th>
                                                    <th className={styles.table_header}>{i18n.t("Cost")}</th>
                                                    {/* <th className={styles.table_header_end}>{i18n.t('Delete')}</th> */}
                                                </tr>
                                                {this.state.editableHybridsList.map((item, index) => {
                                                    return <tr key={index} className={index % 2 === 0 ? styles.table_row_odd : styles.table_row}>
                                                        <td style={{ maxWidth: '200px', overflowWrap: 'break-word' }} className={styles.table_row_text}>{item.tabla_naziv}</td>
                                                        <td style={{ maxWidth: '100px', overflowWrap: 'break-word' }} className={styles.table_row_text}>{item.kultura}</td>
                                                        <td className={styles.table_row_text}>{item.naziv ? item.naziv : ""}</td>
                                                        <td className={styles.table_row_text}>{Number(item.povrsina).toFixed(2)}</td>
                                                        <td className={styles.table_row_text}>
                                                            <TextField
                                                                disabled={true}
                                                                type="number"

                                                                InputProps={{
                                                                    style: {
                                                                        color: 'black', // Set the text color to black
                                                                    },
                                                                }}

                                                                value={item.ucinak}

                                                                style={{ width: '130px' }}
                                                                onChange={(e) => {


                                                                    const index = this.state.editableHybridsList.findIndex(hybrid => {
                                                                        if (item.id_kultura_sorta !== null) {
                                                                            return hybrid.id_kultura_sorta === item.id_kultura_sorta && hybrid.id_tabla === item.id_tabla;
                                                                        } else {
                                                                            return hybrid.id_tabla === item.id_tabla;
                                                                        }
                                                                    });

                                                                    const updatedEditableHybridsList = [...this.state.editableHybridsList];
                                                                    updatedEditableHybridsList[index] = {
                                                                        ...updatedEditableHybridsList[index],
                                                                        ucinak: e.target.value
                                                                    };

                                                                    this.setState({ editableHybridsList: updatedEditableHybridsList }, () => this.calculateCosts());

                                                                }} >
                                                            </TextField>
                                                        </td>
                                                        <td className={styles.table_row_text}>{item.troskovi}   {user.valuta_naziv}</td>
                                                        {/* <td className={styles.table_row_end}>
                                                            <img src="./delete.svg" height="24px" className={styles.delete_button_table} onClick={() => {


                                                                this.setState(prevState => ({
                                                                    editableHybridsList: prevState.editableHybridsList.filter(one => {

                                                                        if (item.id_kultura_sorta !== null) {
                                                                            return !(one.id_kultura_sorta === item.id_kultura_sorta && one.id_tabla === item.id_tabla);
                                                                        } else {

                                                                            return one.id_tabla !== item.id_tabla;
                                                                        }
                                                                    }),
                                                                    selectedFields: prevState.selectedFields.filter(field => field.value !== item.id_tabla)
                                                                }), () => {
                                                                    this.calculateCosts();
                                                                    console.log("this.state.editableHybridsList : ", this.state.editableHybridsList)
                                                                });
                                                            }}
                                                            />
                                                        </td> */}
                                                    </tr>
                                                })}

                                                <tr className={styles.table_row}>
                                                    <td style={{ visibility: 'none' }} className={styles.table_row_text}></td>
                                                    <td style={{ visibility: 'none' }} className={styles.table_row_text}></td>
                                                    <td style={{ visibility: 'none' }} className={styles.table_row_text}></td>
                                                    <td style={{ fontWeight: 'bold' }} className={styles.table_row_text}>{i18n.t("Total spent")}</td>
                                                    <td style={{ fontWeight: 'bold' }} className={styles.table_row_text}>{this.state.ukupnoUcinak}</td>
                                                    <td style={{ fontWeight: 'bold' }} className={styles.table_row_text}>{this.state.ukupnoTroskovi}   {user.valuta_naziv}</td>

                                                </tr>
                                            </table>
                                        </div>
                                        {/* {this.state.activityObj.table.length > 0 &&
                                            <div style={{ margin: '6px 0px 0px 8px', fontWeight: '500', fontSize: '16px' }}>
                                                {i18n.t('Total land area')}: {(this.state.activityObj.table.reduce((prev, item) => prev + Number(item.povrsina), 0)).toFixed(2)} ha
                                            </div>
                                        } */}
                                    </div>
                                }
                            </div>
                            {/* <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Field')}
                                        select
                                        value={this.state.field}
                                        SelectProps={{ native: true }}
                                        InputLabelProps={this.state.field ? { shrink: true } : null}
                                        style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ field: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.fields.map((fields) => <option key={fields.id} value={fields.id}>{fields.naziv}</option>)}
                                    </TextField>
                                </Grid> */}

                            {/* <Grid xs={6}>
                                    <TextField required
                                        type="number"
                                        label={i18n.t('Amount')}
                                        value={this.state.kolicina}
                                        InputLabelProps={this.state.kolicina ? { shrink: true } : null}
                                        style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ kolicina: e.target.value }); }} >
                                    </TextField>
                                </Grid> */}

                            {/* <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Worker unit')}
                                        select
                                        value={this.state.worker_unit}
                                        SelectProps={{ native: true }}
                                        InputLabelProps={this.state.worker_unit ? { shrink: true } : null}
                                        style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ worker_unit: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.worker_units.map((worker_units) => <option key={worker_units.id} value={worker_units.id}>{worker_units.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid xs={12}>
                                    <TextField required
                                        type="number"
                                        label={i18n.t('Price per unit')}
                                        value={this.state.cena_jmr}
                                        InputLabelProps={this.state.cena_jmr ? { shrink: true } : null}
                                        style={{ width: '256px', marginBottom: '10px', marginLeft: '10px' }}
                                        onChange={(e) => { this.setState({ cena_jmr: e.target.value }); }} >
                                    </TextField>
                                </Grid> */}

                            {/* <div style={{ height: '100px' }}></div> */}

                            {/* {this.state.cena_jmr && this.state.kolicina &&
                                <Grid item xs={12}>
                                    <Typography style={{ fontSize: '18px', marginLeft: '10px', marginTop: '10px' }}>
                                        {i18n.t('Total cost')}: {formatted}
                                    </Typography>
                                </Grid>
                            }


                            <Button style={{ alignSelf: 'start', backgroundColor: '#04764e', marginLeft: '10px', color: 'white', marginTop: 'auto', marginBottom: '10px' }}
                                onClick={() => this.addWorkerActivity()}
                                disabled={this.props.layerPosting} >
                                {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                            </Button> */}



                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};