import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import rgb from './../satelliteinfo/rgb.png';
import rgb2 from './../satelliteinfo/rgb2.png';

export class RGB extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                RGB (Red-Green-Blue Index) Označava sliku prilagođenu ljudskom vizuelnom sistemu. Njiva je prikazana onako kako bi je video čovek
                                da se nalazi na mestu satelita. U slučaju oblačnosti pojavljuju se bele površine koje pokrivaju delove
                                slike. Na ovim površinama vrednosti satelitskih indeksa neće biti merodavne.
                            </p>
                            {/* <div className={classes.image}>
                                <img src={`./satelliteinfo/rgb.png`} />
                            </div> */}
                            <div className={classes.image}>
                                <img src={rgb2} />
                            </div>
                            <p className={classes.paragraph}>
                                Ortomozaik generisan pomoću slika dobijenih iz RGB kamere (levo) i NDVI indeks mapa dobijena
                                pomoću slika iz modifikovane NDVI kamere (desno)
                            </p>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                Bathymetric - RGB visualization
                                means an image adapted to the human visual system. The field is shown as if it were to see a man on the
                                satellite. In the event of cloudiness, white surfaces that cover parts of the image will appear. On these
                                surfaces, the values of satellite indices will not be relevant. Orthomosaic generated using images obtained 
                                from an RGB camera (left) and NDVI index map obtained using images from a modified NDVI camera (right)

                            </p>
                            {/* <div className={classes.image}>
                                <img src={`./satelliteinfo/rgb.png`} />
                            </div> */}
                            <div className={classes.image}>
                                <img src={rgb2} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                RGB (Red-Green-Blue Index) označava sliku prilagođenu ljudskom vizualnom sustavu. 
                                Polje je prikazano onako kako bi ga vidjela osoba na lokaciji satelita. U slučaju oblačnosti 
                                pojavljuju se bijele površine koje prekrivaju dijelove slike. Na tim površinama vrijednosti 
                                satelitskih indeksa neće biti važeće.
                            </p>
                            {/* <div className={classes.image}>
                                <img src={`./satelliteinfo/rgb.png`} />
                            </div> */}
                            <div className={classes.image}>
                                <img src={rgb2} />
                            </div>
                            <p className={classes.paragraph}>
                                Ortomozaik generiran pomoću slika dobivenih s RGB kamere (lijevo) i NDVI indeksne karte 
                                dobivene korištenjem slika s modificirane NDVI kamere (desno).
                            </p>
                        </div>
                    </Card>
                }
                {this.props.language === 5 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            RGB (Red-Green-Blue Index) Označuje sliko, prilagojeno človeškemu vidnemu sistemu. Polje je prikazano tako, kot bi ga videl človek
                                nahajati na lokaciji satelita. V primeru oblačnosti se pojavijo bele površine, ki pokrivajo dele
                                slike. Na teh površinah vrednosti satelitskih indeksov ne bodo veljavne.  
                            </p>
                            
                            <div className={classes.image}>
                                <img src={rgb2} />
                            </div>
                            <p className={classes.paragraph}>
                            Ortomozaik, ustvarjen z uporabo slik, pridobljenih s kamero RGB (levo), in pridobljenim indeksnim zemljevidom NDVI
                                uporaba slik iz spremenjene kamere NDVI (desno)
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(RGB);