import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import { Delete } from '../../../utils/Delete';
import AddWorkers from '../workers/AddWorkers';
import WentWrong from './../../../utils/ToastConfig';
import css from './viewWorkers.module.css';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from 'material-ui-search-bar';
import { Tooltip as Tooltip2 } from '@material-ui/core';
import { Button, Tooltip, IconButton, Menu } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { PulseLoader } from 'react-spinners';
import { withStyles } from '@material-ui/core/styles';

const commonHeaderStyle = {
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#04764e',
    color: "#ffff",

};

const styles = theme => ({ 
    primaryMain: { backgroundColor: theme.palette.primary.main },
    paginationButton: {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5EB3E4',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
    headerStyle: { textAlign: 'center',
        textTransform: 'uppercase',
        paddingTop: 10,
        paddingBottom: 10
        },
 });

class ViewWorkers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workplaceType: [], groupType: [], sectorType: [],
            showFilters: false, load: false,
            page: 0, pageSize: 10, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalWorkers: 0,
            add: false, edit: false, delete: false,
            data: [],
            //----------
            editObject:  null,
            searchBarValue: "",
            deleteItemId: -1,
            selectedWorkplace: "",
            workplaces: [],
        };
    }

    componentDidMount() {
        this.getByURL('workplace/drop_down', 'workplaceType');
        // this.getByURL('worker/filter_worker_group', 'groupType');
        this.getByURL('worker/filter_sector', 'sectorType');
        this.getWorkplaces();
    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.searchBarValue !== this.state.searchBarValue ||
            prevState.selectedWorkplace !== this.state.selectedWorkplace 
        ) {
                this.setState({
                    load: true
                })
                this.reactTable.fireFetchData();
        }
    }

    getData(state) {
        this.setState({ load: true, data: [] });

        let filtered = [...state.filtered];

        if (this.state.searchBarValue) {
            filtered.push({ id: 'ime_prezime', value: this.state.searchBarValue });
        }

        if (this.state.selectedWorkplace) {
            filtered.push({ id: 'id_radno_mesto', value: this.state.selectedWorkplace });
        }

        axios.get(`api/worker/read`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalWorkers: res.data.total,
                    load: false
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getWorkplaces(edit) {
        axios.get(`api/workplace/drop_down_workplaces_for_workers`)
            .then(res => {
                const workplaces = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
                if(edit){
                    let workingSectorsEdit= workplaces
                    let temp = [...this.state.workplaces];
                    
                     for (let i = 0; i < workingSectorsEdit.length; i++) {
                         let element = workingSectorsEdit[i];
                     
                         if (!temp.some(item => item.id === element.id)) {
                             temp.push(element);
                         }
                     }
                     this.setState({
                        workplaces: temp,
                     });
                }
                else{
                    this.setState({
                        workplaces: workplaces
                        },
                    );
                }
                // console.log("workplaces", workplaces);
                //     this.setState({
                //         workplaces: workplaces
                //     },);
                
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    handleOpenNewWorker = () => {
        this.setState({add: true});
        if(this.state.edit == false){
            this.setState({
                editObject: null
            })
        }
    };

    handleCloseNewWorker = () => {
        this.setState({add: false});
    } 

    onCreate = () => {
        if (this.reactTable) {
            if(!this.state.edit){
                const { page, pageSize } = this.reactTable.state;
                if (page !== 0 || pageSize !== this.state.pageSize) {
                    this.reactTable.state.page = 0;
                }
                this.getWorkplaces();
            }
            else{
                this.getWorkplaces(true);
            }
            this.reactTable.fireFetchData();
            this.setState({
                add: false,
                load: true,
                edit: false
            })
        };
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    handleSearchBar = (searchVal) => {
        this.setState({
            searchBarValue: searchVal,
        });
    }

    deleteItem = () => {
        axios.get('api/worker_activities/check_worker', { params: { selection: [this.state.deleteItemId] } })
            .then(res => {
                if (res.data.total > 0) wentWrong(i18n.t('There are worker activities tied to this worker'));
                else if (res.data.total === 0) {


                    axios.post(`api/worker/delete`, { selection: [this.state.deleteItemId] })
                        .then(res => {
                            this.reactTable.fireFetchData();
                            this.setState({ delete: false});
                        })
                        .catch(err => {
                            this.setState({ delete: false });
                            console.log('Error: ', err);
                        })
                }
            })
            .catch(err => { console.log('Error: ', err); })
    }

    handleChangeWorkplaces = (e) => {
        this.setState(
            {
                selectedWorkplace: e.currentTarget.value,
            });
    }

    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onEditClick() { this.setState({ add: !this.state.add, edit: true }); }
    onTableClick = () => this.props.history.push('/workers');
    onDeleteClick = () => { this.setState({ delete: !this.state.delete }); }

    render() {
        const { classes } = this.props;

        const columns = [
            { 
                Header: i18n.t('Name and surname'), 
                accessor: 'ime_prezime',
                headerStyle: commonHeaderStyle
            },
            { 
                Header: i18n.t('Password'), 
                accessor: 'sifra',
                headerStyle: commonHeaderStyle
            },
            { 
                Header: i18n.t('Workplace'), 
                accessor: 'naziv_radno_mesto',
                headerStyle: commonHeaderStyle
            },
            { 
                Header: i18n.t('Category'), 
                accessor: 'kategorija',
                headerStyle: commonHeaderStyle
            },
            { 
                id: 'polj_radovi', 
                Header: i18n.t('Agricultural works'), 
                accessor: d => d.polj_radovi, Cell: ({ value }) => value != 0 ? i18n.t('Yes') : i18n.t('No'),
                headerStyle: commonHeaderStyle
            },
            {
                width: 80,
                Header: i18n.t('Edit'),
                accessor: 'izmena',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: { textAlign: 'center',
                    textTransform: 'uppercase',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "#04764e",
                    color: '#FFFFFF'
                    },
                Cell: cell => (
                    <div>
                        <Tooltip2 title={i18n.t("Edit")}>
                                    <IconButton aria-label="copy"
                                        onClick={() => {
                                            console.log("red", cell.row._original)
                                            this.setState({
                                                edit: true,
                                                editObject: cell.row._original
                                            }, () => {
                                                this.handleOpenNewWorker();
                                            });
                                        }}
                                    >
                                        <Icons.Edit fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                    </div>
                )
            },
            {
                width: 80,
                Header: i18n.t('Delete'),
                accessor: 'detalji',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: { textAlign: 'center',
                    textTransform: 'uppercase',
                    paddingTop: 10,
                    paddingBottom: 10,
                    backgroundColor: "#04764e",
                    color: '#FFFFFF'
                    },
                Cell: cell => (
                    <div>
                        <Tooltip2 title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        onClick={() => {
                                            this.setState({
                                                deleteItemId: cell.row._original.id
                                            })
                                            this.onDeleteClick()}
                                        }
                                    >
                                        <Icons.DeleteOutline fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                    </div>
                )
            },
        ];
        const url = 'worker/read';

        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit", alignItems: 'flex-end' } };
                }
                return {};
            },
            getTheadFilterProps: () => { return { style: { backgroundColor: '#58B4E5' } }; },
            getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        };

        return (
            <>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                <div className={css.searchWrap}>
                    <SearchBar
                        className={css.searchBar}
                        placeholder={i18n.t('Search by name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        onChange={(searchValue) => this.handleSearchBar(searchValue)}
                        onCancelSearch={() => this.handleSearchBar('')}
                    />
                    
                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={css.addNewBtn}
                        onClick={() => { this.handleOpenNewWorker() }}
                    >
                        <AddIcon />
                        {i18n.t('Add Worker')}
                    </Button>
                </div>
                <div className={css.filtersSection}>
                        <select className={css.dropDowns} value={this.state.selectedWorkplace} onChange={this.handleChangeWorkplaces} >
                            <option value="">{i18n.t('Workplace')}</option>
                            {this.state.workplaces && this.state.workplaces.map((workplace, i) => (
                                <option key={i} value={workplace.id}>{workplace.value}</option>
                            ))}
                        </select>
                    </div>
                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '30px 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column' }}>
                        
                    <ReactTable ref={r => (this.reactTable = r)}
                        manual
                        useSortBy
                        paging={true}
                        data={this.state.data}
                        columns={columns}
                        keyField={'id'}
                        pageRangeDisplayed={false}
                        defaultPageSize={this.state.pageSize}
                        pages={this.state.totalPages}
                        showPaginationBottom={true}
                        onFetchData={(state) => this.getData(state)}
                        firtelable={true}
                        previousText={'<'}
                        nextText={'>'}
                        pageText={''}
                        ofText={i18n.t('of')}
                        showPageSizeOptions={false}
                        showPagination={true}
                        rowsText={''}
                        PreviousComponent={(props) => (
                            <button {...props} 
                            className={
                                classes.paginationButton}
                            >{props.children}</button>
                        )}
                        NextComponent={(props) => (
                            <button {...props} 
                            className={
                                classes.paginationButton}
                            >{props.children}</button>
                        )}
                        getTrProps={() => {
                                return {
                                    style: {
                                        textAlign: 'center',
                                        paddingLeft: '10px',
                                        height: '50px',
                                        alignItems: 'center',
                                        overflowY: 'hidden',
                                        overflowX: 'hidden',
                                    }
                                };
                        }}

                        LoadingComponent={() => (
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <PulseLoader loading={this.state.load}/>
                            </div>                         
                        )}

                        onPageChange={() => {
                            this.state.load= true ;
                        }}

                        NoDataComponent={() => (
                            <div style={{ textAlign: 'center', padding: '20px', display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: -300 }}>
                                {!this.state.load && <span>{i18n.t('No rows found')}</span>}
                            </div>
                        )}
                        />
                    </div>
            </div>
                {this.state.add && <AddWorkers add={this.state.add} edit={this.state.edit} handleCreate={this.onCreate} handleClose={this.handleCloseNewWorker} data={this.state.editObject} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
            </>
        );
    }
}
export default withStyles(styles)(ViewWorkers);