import React from 'react';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Menu, MenuItem, ListItemText, Typography } from '@material-ui/core';
import Profile from '../../components/Profile';
import Settings from '../../components/Settings';
import { logout } from '../../actions/LoginActions';
import { getSelectedPage } from '../../utils/GetSelectedPage';
import { getUser } from '../../utils/CommonFunctions';
import i18n from '../../i18n/i18n';

const TopBarContainer = styled('div')`
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 260px);
    height: 65px;
    background: #04764e;
    padding: 0px 24px 0px 48px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopBarTitle = styled('p')`
    font-size: 20px;
    color: #fff;
    margin-left: 55px;
`;

const TopBarIcon = styled('span')`
    font-size: 38px;
    color: #fff;
    width: 20px;
    padding-right: 30px;
`;

const TopBarUser = styled('div')`
    display: flex;
    gap: 20px;
    color: #FFF;
    font-size: 20px;
    align-items: center;
`

class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            profile: false,
            settingsOpen: false,
            anchorEl: null
        };
    };

  handleMoreClose = () => {
      this.setState({ anchorEl: null });
  };

  handleMoreClick = event => {
      this.setState({ anchorEl: event.currentTarget });
  };

  onProfileClick = () => {
      this.setState({ profile: !this.state.profile });
      this.handleMoreClose();
  };

  onSettingsClick = () => {
      this.setState({ settingsOpen: !this.state.settingsOpen });
      this.handleMoreClose();
  };

  onLogout() {
      this.props.logout();
      this.props.history.replace('/');
    }

  render() {
      const { anchorEl } = this.state;
      const user = getUser();
      return (
          <TopBarContainer>
              <TopBarTitle>{getSelectedPage(window.location.pathname)}</TopBarTitle>
              <TopBarUser>
                <div>{user.ime_prezime !== " " ? user.ime_prezime.replace('null', '') : user.klijent_naziv}</div>
                <TopBarIcon className="icon-Profil onHover" onClick={this.handleMoreClick} />
              </TopBarUser>
              <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleMoreClose}
              >
                  <MenuItem onClick={() => this.onProfileClick()} >
                      <ListItemText disableTypography primary={<Typography style={{ fontSize: '18px' }}>{i18n.t('Profile')}</Typography>} />
                      <span className="icon-user logOutIcon" />
                  </MenuItem>
                  <MenuItem onClick={() => this.onSettingsClick()} >
                      <ListItemText disableTypography primary={<Typography style={{ fontSize: '18px' }}>{i18n.t('Settings')}</Typography>} />
                      <span className="icon-cog logOutIcon" />
                  </MenuItem>
                  <MenuItem onClick={() => this.onLogout()} >
                      <ListItemText disableTypography primary={<Typography style={{ fontSize: '18px' }}>Logout</Typography>} />
                      <span className="icon-LogOut logOutIcon" />
                  </MenuItem>
              </Menu>
              {this.state.profile && <Profile open={this.state.profile}
                  handleClose={this.onProfileClick}
                  userProfile={this.props.userProfile} />}
              {this.state.settingsOpen && <Settings open={this.state.settingsOpen}
                  handleClose={this.onSettingsClick}
                  settings={this.props.settings} />}
          </TopBarContainer>
        );
    };
};

function mapStateToProps(state) {
    return {
        userProfile: state.appReducer.userProfile
    };
};

function mapDispatchToProps(dispatch) {
    return {
        logout: open => dispatch(logout(open)),
    };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBar));
