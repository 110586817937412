import React from 'react';
import i18n from '../../../i18n/i18n';
import { withStyles } from '@material-ui/core/styles';
import { Card, LinearProgress } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import { Degrees } from './../../../utils/Degrees';
import { styles } from './DailyForecastStyles';
import { MoonLoader } from 'react-spinners';
import { Loader } from './../Loader';
import moment from 'moment';

export class DailyForecast extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            load: true,
        };
    };

    componentDidMount = () => {
        Loader(this.props, ['basic-day'], ['dailyCalled'], ['daily']);
    };

    render() {
        let data = {};
        const { classes } = this.props;
        if (Object.keys(this.props.data).length > 0) data = this.props.data.data.data_day;
        return (
            <div>
                {Object.keys(data).length === 0 &&
                    <div className={classes.spinner}> <MoonLoader loading={this.state.load} size={100} /></div>
                }
                {Object.keys(data).length > 0 && <div className={classes.container}>
                    {data.temperature_mean !== undefined &&
                        <Card>
                            <div className={classes.card}>
                                {data.temperature_max.map((item, i) => {
                                    let time = data.time[i];
                                    time = moment(time).format('ddd');
                                    time = time.toUpperCase();

                                    let icon = data.pictocode[i];
                                    icon = `${icon}.svg`;

                                    let min = data.temperature_min[i];
                                    let max = data.temperature_max[i];

                                    let string_degrees = Degrees(data.winddirection[i]);

                                    return <div className={i < 6 ? classes.pillar : classes.pillar_last} key={i}>
                                        <p className={classes.time}>{i18n.t(time)}</p>
                                        <img className={classes.icon} src={`./meteoblue/${icon}`} />
                                        <div className={classes.temp_container}>
                                            <div className={classes.temp_div}>
                                                <p className={classes.temp_max}>{Math.round(max) + `°C`}</p>
                                                <p className={classes.temp_min}>{Math.round(min) + `°C`}</p>
                                            </div>
                                            <div className={classes.predictability_div}>
                                                <LinearProgress variant="determinate" value={data.predictability[i]} />
                                                <p className={classes.predictability_tooltip}>{i18n.t('Predictability')}</p>
                                            </div>
                                        </div>
                                        <div className={classes.precipitation_div}>
                                            <img className={classes.rain_icon} src={`./meteoblue/rain.png`} />
                                            <p className={classes.precipitation_probability}>{data.precipitation_probability[i]} %</p>
                                        </div>
                                        <div className={classes.wind_div}>
                                            <NavigationIcon style={{ transform: `rotate(${data.winddirection[i] + 180}deg)`, height: '40px', width: '40px', color: 'rgb(82, 162, 209)' }} />
                                            <p className={classes.wind_degrees}>{string_degrees}</p>
                                            <p className={classes.wind_speed}>{data.windspeed_mean[i]} Km/h</p>
                                        </div>
                                        <div>
                                            <p className={classes.humidity}>{data.relativehumidity_mean[i]} %</p>
                                            <p className={classes.tooltip}>{i18n.t('Humidity')}</p>
                                        </div>
                                        <div>
                                            <p className={classes.pressure}>{data.sealevelpressure_mean[i]} mBar</p>
                                            <p className={classes.tooltip}>{i18n.t('Pressure')}</p>
                                        </div>
                                    </div>
                                })}
                            </div>
                        </Card>
                    }
                </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(DailyForecast);