import immutable from 'seamless-immutable';
import { Type as MarketPriceType } from '../../actions/market_price/MarketPriceActions';
import moment from 'moment';
import _ from 'lodash';
export const INITIAL_STATE = immutable({

    marketPrices: [],
    marketPricesFetching: false,
    marketPricesFetchingFailed: false,


    materialPrices: {},
    materialPricesFetching: false,
    materialPricesFetchingFailed: false,
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case MarketPriceType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case MarketPriceType.GET_MARKET_PRICES_CALL: {
            const marketPricesFetching = true;
            return state.merge({ marketPricesFetching });
            break;
        }

        case MarketPriceType.GET_MARKET_PRICES_SUCCESS: {
            const marketPrices = action.data.data;
            const marketPricesFetching = false;
            return state.merge({ marketPrices, marketPricesFetching });
            break;
        }

        case MarketPriceType.GET_MARKET_PRICES_FAILED: {
            const marketPricesFetchingFailed = true;
            const marketPricesFetching = false;
            return state.merge({ marketPricesFetching, marketPricesFetchingFailed });
            break;
        }

        case MarketPriceType.GET_MARKET_PRICE_BY_ID_CALL: {
            const materialPricesFetching = true;
            return state.merge({ materialPricesFetching });
            break;
        }

        case MarketPriceType.GET_MARKET_PRICE_BY_ID_SUCCESS: {
            const materialPrices = action.data.data;
            const materialPricesFetching = false;
            return state.merge({ materialPrices, materialPricesFetching });
            break;
        }

        case MarketPriceType.GET_MARKET_PRICE_BY_ID_FAILED: {
            const materialPricesFetchingFailed = true;
            const materialPricesFetching = false;
            return state.merge({ materialPricesFetching, materialPricesFetchingFailed });
            break;
        }

        default:
            return state;
    }
}