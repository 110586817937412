import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import i18n from '../../../i18n/i18n';
import Select from 'react-select';
import { Fab } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import { Delete } from '../../../utils/Delete';
import ReactSelect from './../../../utils/ReactSelect';
import { Button, IconButton } from '@material-ui/core';
import { Export } from '../../../utils/Export';
import MeteoDialog from './../../meteoblue/MeteoDialog';
import AddMachine from './AddMachine';
import AddIcon from '@material-ui/icons/Add';
import SearchBar from 'material-ui-search-bar';
import { Tooltip as Tooltip2 } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { PulseLoader } from 'react-spinners';
import { withStyles } from '@material-ui/core/styles';
import css from './viewMachines.module.css';
import { forEach } from 'lodash';
import c from 'config';
var FileSaver = require('file-saver');

const commonHeaderStyle = {
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#04764e',
    color: "#ffff",

};

const styles = theme => ({ 
    primaryMain: { backgroundColor: theme.palette.primary.main },
    paginationButton: {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5EB3E4',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
    headerStyle: { textAlign: 'center',
        textTransform: 'uppercase',
        paddingTop: 10,
        paddingBottom: 10
        },
 });

class ViewMachines extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            machineGroupType: [], 
            machineSubgroupType: [],
            machineType: [],
            showFilters: false, loading: true,
            page: 0, pageSize: 10, sorted: null, filtered: null,
            selection: [], selectAll: false, selectedRow: [],
            totalPages: 0, totalFields: 0,
            add: false, edit: false, delete: false,
            data: [],
            add: this.props.add, edit: this.props.edit,
            //---------
            editObject:  null,
            searchBarValue: "",
            deleteItemId: -1,
            selectedModel: "",
            models: [],
            selectedGroup: "",
            groups: [],
            selectedSubGroup: "",
            subgroups: [],
        };
    }

    componentDidMount() {
        localStorage.removeItem('row');

        this.getModels();
        this.getGroups();
        axios.get('api/season/drop_down')
            .then(res => {
            })
            .catch(err => { console.log(err); })

    }

    componentDidUpdate(prevProps, prevState){
        if (prevState.searchBarValue !== this.state.searchBarValue ||
            prevState.selectedModel !== this.state.selectedModel ||
            prevState.selectedGroup !== this.state.selectedGroup ||
            prevState.selectedSubGroup !== this.state.selectedSubGroup 
        ) {
                this.setState({
                    load: true
                })
                this.reactTable.fireFetchData();
        }
    }

    getData(state) {
        this.setState({ load: true, data: [] });

        let filtered = [...state.filtered];

        if (this.state.searchBarValue) {
            filtered.push({ id: 'naziv', value: this.state.searchBarValue });
        }

        if (this.state.selectedModel) {
            filtered.push({ id: 'id_pogonska_masina', value: this.state.selectedModel });
        }

        if (this.state.selectedGroup) {
            filtered.push({ id: 'id_pogonska_masina_grupa', value: this.state.selectedGroup });
        }

        if (this.state.selectedSubGroup) {
            filtered.push({ id: 'id_pogonska_masina_podgrupa', value: this.state.selectedSubGroup });
        }

        axios.get(`api/machine/get_all`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: filtered }
        })
            .then(res => {
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,
                    totalFields: res.data.total,
                }, () => {
                    if (this.state.user_layer !== null) this.setState({ load: false })
                });
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getModels(edit) {
        axios.get(`api/machine/drop_down_models_for_machines`)
            .then(res => {
                const models = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
                if(edit){
                    let workingSectorsEdit= models
                    let temp = [...this.state.models];
                    
                     for (let i = 0; i < workingSectorsEdit.length; i++) {
                         let element = workingSectorsEdit[i];
                     
                         if (!temp.some(item => item.id === element.id)) {
                             temp.push(element);
                         }
                     }
                     this.setState({
                        models: temp,
                     });
                }
                else{
                    this.setState({
                        models: models
                        },
                    );
                }
                
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    //api/machine/drop_down_groups_for_machines

    getGroups(edit) {
        axios.get(`api/machine/drop_down_groups_for_machines`)
            .then(res => {
                const groups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
                if(edit){
                    let workingSectorsEdit= groups
                    let temp = [...this.state.groups];
                    
                     for (let i = 0; i < workingSectorsEdit.length; i++) {
                         let element = workingSectorsEdit[i];
                     
                         if (!temp.some(item => item.id === element.id)) {
                             temp.push(element);
                         }
                     }
                     this.setState({
                        groups: temp,
                     });
                }
                else{
                    this.setState({
                        groups: groups
                        },
                    );
                }
                
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getSubGroups() {
        axios.post('api/machine/drop_down_subgroups_for_machines', {
            id_grupa: this.state.selectedGroup
        })
            .then(res => {
                const subgroups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
                // if(edit){
                //     let workingSectorsEdit= workplaces
                //     let temp = [...this.state.workplaces];
                    
                //      for (let i = 0; i < workingSectorsEdit.length; i++) {
                //          let element = workingSectorsEdit[i];
                     
                //          if (!temp.some(item => item.id === element.id)) {
                //              temp.push(element);
                //          }
                //      }
                //      this.setState({
                //         workplaces: temp,
                //      });
                // }
                // else{
                //     this.setState({
                //         workplaces: workplaces
                //         },
                //     );
                // }
                    this.setState({
                        subgroups: subgroups
                    },);
                
            })
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 1 (getData): ', error);
            })
    }

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    }

    handleSearchBar = (searchVal) => {
        this.setState({
            searchBarValue: searchVal,
        });
    }

    // toggleAll = () => {
    //     const selectAll = this.state.selectAll ? false : true, selection = [];
    //     if (selectAll) {
    //         const wrappedInstance = this.checkboxTable.getWrappedInstance();
    //         const currentRecords = wrappedInstance.getResolvedState().sortedData;
    //         currentRecords.forEach(item => { selection.push(item._original.id); });
    //     }
    //     this.setState({ selectAll, selection });
    // };

    // toggleSelection = (key, shift, row) => {
    //     let selection = [...this.state.selection], selectedRow = null;
    //     const keyIndex = selection.indexOf(key);
    //     if (keyIndex >= 0) selection = [...selection.slice(0, keyIndex), ...selection.slice(keyIndex + 1)];
    //     else selection.push(key);
    //     if (selection.length == 1) selectedRow = this.state.data.filter((el) => el.id == selection)[0];
    //     this.setState({ selection, selectedRow });
    // };

    handleOpenNewMachine = () => {
        this.setState({add: true});
        if(this.state.edit == false){
            this.setState({
                editObject: null
            })
        }
    };
    
    handleCloseNewMachine = () => {
        this.setState({add: false});
    } 

    onCreate = () => {
        if (this.reactTable) {
            if(!this.state.edit){
                const { page, pageSize } = this.reactTable.state;
                if (page !== 0 || pageSize !== this.state.pageSize) {
                    this.reactTable.state.page = 0;
                }
                this.getGroups();
                this.getModels();
            }
            else{
                this.getGroups(true);
                this.getModels(true);
            }
            this.reactTable.fireFetchData();
            this.setState({
                add: false,
                load: true,
                edit: false
            })
        };
    }

    // handleClick = () => {
    //     if (this.state.edit == true && this.state.add == false) this.setState({ edit: false, selectedRow: [] });
    //     else if (this.state.selection.length > 0 && this.state.add == false && this.state.edit == false) this.setState({ selectedRow: [] });
    //     this.setState({ add: !this.state.add }, () => {
    //         if (this.state.add === false) this.checkboxTable.wrappedInstance.fireFetchData();
    //         if (this.state.add == true && this.state.edit == false) this.setState({ selection: [], selectAll: false, });
    //     });
    // };

    handleClickArchived = () => {
        this.setState({ selection: [], selectAll: false, })
    };

    handleMeteoClick = () => { this.setState({ meteo: !this.state.meteo }) };

    deleteItem = () => {
        axios.post(`api/machine/delete`, { selection: [this.state.deleteItemId] })
            .then(res => {
                this.reactTable.fireFetchData();
                this.setState({ selection:[], delete: false });
            })
            .catch(err => {
                this.setState({ delete: false });
                wentWrong(i18n.t('You cannot delete a implement that is in an activity!'))
            })
    }

    handleChangeModel = (e) => {
        this.setState(
            {
                selectedModel: e.currentTarget.value,
            });
    }

    handleChangeGroup = (e) => {
        this.setState(
            {
                selectedGroup: e.currentTarget.value,
            },
            () => {
                if(this.state.selectedGroup !== ""){
                    this.getSubGroups();
                }
            }
        );
    }

    handleChangeSubGroup = (e) => {
        this.setState(
            {
                selectedSubGroup: e.currentTarget.value,
            },
        );
    }
    

    isSelected = key => { return this.state.selection.includes(key); };
    onSearchClick = () => this.setState({ showFilters: !this.state.showFilters });
    onEditClick() { this.setState({ add: !this.state.add, edit: true }); };
    onTableClick = () => this.props.history.push('/field');
    onDeleteClick = () => { this.setState({ delete: !this.state.delete }); };
    onExportClick = () => { this.setState({ export: !this.state.export }); };
    onMapClick = () => this.props.history.push('/maps');

    exportFunction = () => {
        const table = this.checkboxTable.getWrappedInstance();
        this.exportDocument(this.state.docTitle, table.state, 'api/field/read', { potpisan: this.state.potpisan, odbijen: this.state.odbijen });
        this.setState({ export: !this.state.export, docTitle: null });
    };

    async exportDocument(title, state, url, filters) {
        let params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title };
        if (filters) params = { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: state.filtered, filename: title, filters };

        try {
            const response = await axios({ url: url, method: 'GET', responseType: 'blob', params: params });
            return FileSaver.saveAs(new Blob([response.data]), `${title || 'file'}.xlsx`);
        } catch (err) {
            return console.log(err);
        }
    };

    handleButtonClick = (cell) => {
        let row = { id: cell._original.id, naziv: cell.naziv };
        localStorage.setItem('row', JSON.stringify(row));
        this.onMapClick();
    };

    handleForecast = (cell) => {
        let row = { id: cell._original.id, naziv: cell.naziv };
        this.setState({ row, meteo: true });
    };

    render() {
        // let machineGroupType = this.state.machineGroupType.map((item) => { return { label: item.naziv, value: item.id } });
        // let machineSubgroupType = this.state.machineSubgroupType.map((item) => { return { label: item.naziv, value: item.id } });
        // let machineType = this.state.machineType.map((item) => { return { label: item.naziv, value: item.id } });
        const { classes } = this.props;

        const columns = [
            { 
                Header: i18n.t('Title'), 
                accessor: 'naziv',
                headerStyle: commonHeaderStyle
            },
            { 
                Header: i18n.t('Model'), 
                accessor: 'pogonska_masina', 
                headerStyle: commonHeaderStyle
            },
            { 
                Header: i18n.t('Licence number'), 
                accessor: 'registarska_oznaka',
                headerStyle: commonHeaderStyle
            },
            { 
                Header: i18n.t('Implements group'), 
                accessor: 'grupa_naziv',
                headerStyle: commonHeaderStyle
            },
            { 
                Header: i18n.t('Implements subgroup'), 
                accessor: 'podgrupa_naziv', 
                headerStyle: commonHeaderStyle
            },
            { 
                Header: i18n.t('GPS provider'), 
                accessor: 'gps_provajder_naziv',
                headerStyle: commonHeaderStyle
            },
            { 
                Header: i18n.t('GPS device number'), 
                accessor: 'gps_broj_uredjaja',
                headerStyle: commonHeaderStyle
            },
            {
                width: 80,
                Header: i18n.t('Edit'),
                accessor: 'izmena',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: commonHeaderStyle,
                Cell: cell => (
                    <div>
                        <Tooltip2 title={i18n.t("Edit")}>
                                    <IconButton aria-label="copy"
                                        onClick={() => {
                                            this.setState({
                                                edit: true,
                                                editObject: cell.row._original
                                            }, () => {
                                                this.handleOpenNewMachine();
                                            });
                                        }}
                                    >
                                        <Icons.Edit fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                    </div>
                )
            },
            {
                width: 80,
                Header: i18n.t('Delete'),
                accessor: 'detalji',
                getHeaderProps: () => ({
                    onClick: e => e.stopPropagation(),
                    style: {
                        cursor: 'default'
                    }
                }),
                headerStyle: commonHeaderStyle,
                Cell: cell => (
                    <div>
                        <Tooltip2 title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        onClick={() => {
                                            this.setState({
                                                deleteItemId: cell.row._original.id
                                            })
                                            this.onDeleteClick()}
                                        }
                                    >
                                        <Icons.DeleteOutline fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                    </div>
                )
            },
            
        ];
        const url = this.state.user_layer !== null ? 'machine/get_all' : '';

        // const { toggleSelection, toggleAll, isSelected } = this;
        // const { selectAll } = this.state;
        // const checkboxProps = {
        //     selectAll, isSelected, toggleSelection, toggleAll, selectType: "checkbox",
        //     getTrProps: (state, rowInfo, instance) => {
        //         if (rowInfo) {
        //             const selected = this.isSelected(rowInfo.original.id);
        //             return { style: { backgroundColor: selected ? "#ACDAF1" : "inherit" } };
        //         }
        //         return {};
        //     },
        //     getTheadFilterProps: () => { return { style: { backgroundColor: '#58B4E5' } }; },
        //     getTheadTrProps: () => { return { style: { backgroundColor: '#04764e', color: '#FFFFFF' } }; },
        // };

        // const filterOptions = {
        //     defaultFiltered: [
        //             {
                   
        //         }
        //     ]
        // };

        return (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                    <div className={css.searchWrap}>
                        <SearchBar
                            className={css.searchBar}
                            placeholder={i18n.t('Search by name')}
                            cancelOnEscape={true}
                            value={this.state.searchBarValue}
                            onChange={(searchValue) => this.handleSearchBar(searchValue)}
                            onCancelSearch={() => this.handleSearchBar('')}
                        />
                        
                        <Button
                            variant="fab"
                            color="primary"
                            aria-label="Add"
                            className={css.addNewBtn}
                            onClick={() => { this.handleOpenNewMachine() }}
                        >
                            <AddIcon />
                            {i18n.t('Add machines')}
                        </Button>
                        {/*sakrio samo dugme jer ne radi dobro. Janko
                        <Button
                            onClick={() => this.onExportClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-download headerIcon" />
                        </Button> */}
                    </div>
                    <div className={css.filtersSection}>
                        <select className={css.dropDowns} value={this.state.selectedModel} onChange={this.handleChangeModel} >
                            <option value="">{i18n.t('Model')}</option>
                            {this.state.models && this.state.models.map((model, i) => (
                                <option key={i} value={model.id}>{model.value}</option>
                            ))}
                        </select>
                        <select className={css.dropDowns} value={this.state.selectedGroup} onChange={this.handleChangeGroup} >
                            <option value="">{i18n.t('Implements group')}</option>
                            {this.state.groups && this.state.groups.map((group, i) => (
                                <option key={i} value={group.id}>{group.value}</option>
                            ))}
                        </select>
                        <select className={css.dropDowns} value={this.state.selectedSubGroup} onChange={this.handleChangeSubGroup} disabled={this.state.selectedGroup === ""} >
                            <option value="">{i18n.t('Implements subgroup')}</option>
                            {this.state.subgroups && this.state.subgroups.map((subgroup, i) => (
                                <option key={i} value={subgroup.id}>{subgroup.value}</option>
                            ))}
                        </select>
                    </div>
                    <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '30px 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column' }}>
                            
                        <ReactTable ref={r => (this.reactTable = r)}
                            manual
                            useSortBy
                            paging={true}
                            data={this.state.data}
                            columns={columns}
                            keyField={'id'}
                            pageRangeDisplayed={false}
                            defaultPageSize={this.state.pageSize}
                            pages={this.state.totalPages}
                            showPaginationBottom={true}
                            onFetchData={(state) => this.getData(state)}
                            firtelable={true}
                            previousText={'<'}
                            nextText={'>'}
                            pageText={''}
                            ofText={i18n.t('of')}
                            showPageSizeOptions={false}
                            showPagination={true}
                            rowsText={''}
                            PreviousComponent={(props) => (
                                <button {...props} 
                                className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            NextComponent={(props) => (
                                <button {...props} 
                                className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            getTrProps={() => {
                                    return {
                                        style: {
                                            textAlign: 'center',
                                            paddingLeft: '10px',
                                            height: '50px',
                                            alignItems: 'center',
                                            overflowY: 'hidden',
                                            overflowX: 'hidden',
                                        }
                                    };
                            }}

                            LoadingComponent={() => (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <PulseLoader loading={this.state.load}/>
                                </div>                         
                            )}

                            onPageChange={() => {
                                this.state.load= true ;
                            }}

                            NoDataComponent={() => (
                                <div style={{ textAlign: 'center', padding: '20px', display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: -300 }}>
                                    {!this.state.load && <span>{i18n.t('No rows found')}</span>}
                                </div>
                            )}
                            />
                        </div>
                </div>
                {this.state.add && <AddMachine add={this.state.add} edit={this.state.edit} handleCreate={this.onCreate} handleClose={this.handleCloseNewMachine} data={this.state.editObject} />}
                {this.state.delete && <Delete delete={this.state.delete} handleClose={this.onDeleteClick} deleteItem={this.deleteItem} />}
                {this.state.meteo && <MeteoDialog open={this.state.meteo} handleClick={this.handleMeteoClick} row={this.state.row} />}
                </>
        );
    }
}

export default withStyles(styles)(ViewMachines);