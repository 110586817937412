/**
 * Created by pajicv on 5/25/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';

import { LayersControl, FeatureGroup, Popup, LayerGroup } from 'react-leaflet';

import * as LayerSelectors from '../selectors/LayerSelectors';

import * as LayerActions from '../actions/map/LayerActions';

import fields from './fields.json';

import './ParcelLayer.css';

import { FIELD_NOTES_MARK } from '../utils/constants';

const { Overlay } = LayersControl;

const markIcon = L.icon({
    iconUrl: 'senzor.png',
    iconSize: [32, 32]
});


class SenzorLayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedFeature: {
                attributes: {
                }
            }
        };

        this.onSenzorClicked = this.onSenzorClicked.bind(this);
    }

    onSenzorClicked(e) {
        const clickedFeatureAttributes = e.layer.feature.properties;
        this.setState({
            clickedFeature: {
                attributes: { ...clickedFeatureAttributes }
            }
        });
    }

    componentDidUpdate(prevProps) {

        const { notesToAdd, noteToAdd, drawn } = this.props;

        if (notesToAdd) {

            let leafletGeoJSON = new L.GeoJSON(notesToAdd, {
                //style: getNoteStyle
            });
            let leafletFG = this._editableFG.leafletElement;

            leafletFG.clearLayers();

            leafletGeoJSON.eachLayer((layer) => {

                switch (layer.feature.properties.vrsta_beleske_id) {

                    case FIELD_NOTES_MARK: {
                        layer.setIcon(markIcon);
                        break;
                    }
                   
                }

                leafletFG.addLayer(layer);

            });

            this.props.sendLoadedNotesDrawSuccess();

        }

        if (noteToAdd) {

            const feature = L.polygon(noteToAdd.geometry).toGeoJSON();
            feature.properties = { ...noteToAdd.attributes };
            const json = L.geoJSON(feature, {
                //style: getNoteStyle
            });
            this._editableFG.leafletElement.addLayer(json);

            this.props.sendNoteAddToMapSuccess();

        }


    }

    render() {

        const { addOverlay, removeLayer, removeLayerControl, senzorLayer } = this.props;

        return (
            <Overlay checked={senzorLayer.visible}
                name="Notes"
                addOverlay={addOverlay}
                removeLayer={removeLayer}
                removeLayerControl={removeLayerControl}>
                <LayerGroup>
                    <FeatureGroup onClick={this.onSenzorClicked} ref={(reactFGref) => { this._onFeatureGroupReady(reactFGref); }}>
                        <Popup className="popup-parcel">
                            <div>
                                <div className="popup-parcel-name">
                                    {this.state.clickedFeature.attributes.naslov}
                                </div>
                                {/* <div className="popup-parcel-subtitle">
                                    {`${this.state.clickedFeature.attributes.datum_dogadjaja}`}
                                </div>
                                <div className="popup-parcel-attributes">
                                    {`Reported by ${this.state.clickedFeature.attributes.korisnik_ime} ${this.state.clickedFeature.attributes.korisnik_prezime}`}
                                </div>
                                <div className="popup-parcel-subtitle">
                                    {`${this.state.clickedFeature.attributes.opis}`}
                                </div> */}
                            </div>
                        </Popup>
                    </FeatureGroup>
                </LayerGroup>
            </Overlay>
        )

    }

    _editableFG = null;

    _onFeatureGroupReady = (reactFGref) => {

        if (this._editableFG) return;

        // populate the leaflet FeatureGroup with the geoJson layers

        let leafletGeoJSON = new L.GeoJSON(fields, {
            //style: getNoteStyle
        });
        let leafletFG = reactFGref.leafletElement;

        leafletGeoJSON.eachLayer((layer) => {
            leafletFG.addLayer(layer);
        });

        // store the ref for future access to content

        this._editableFG = reactFGref;

    };

    onCreated(e) {

        //console.log(e);

    }

}

function mapStateToProps(state) {
    return {
        senzorLayer: LayerSelectors.getSenzorLayer(state),
        notesToAdd: LayerSelectors.getLoadedSenzor(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //sendNoteAddToMapSuccess: () => dispatch(LayerActions.sendNewNoteAddToMapSuccess()),
        sendLoadedNotesDrawSuccess: () => dispatch(LayerActions.sendLoadedNotesDrawSuccess())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SenzorLayer);
