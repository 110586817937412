import React from 'react';
import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../i18n/i18n';
import { getMarketPrices, getMarketPricesById } from '../../actions/market_price/MarketPriceActions';
import { withStyles } from '@material-ui/core/styles';
import Carousel from './Carousel'
import MaterialPricesLineChart from './MaterialPricesLineChart'

const styles = theme => ({
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px'
    }
});

export class MarketPrices extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id_kultura: '',
            selectedMaterialId: null
        };
    }
    componentDidMount() {
        this.props.getMarketPrices();
    }

    handleMaterialChange = (item) => {
        this.setState({ selectedMaterialId: item.id });
        this.props.getMarketPricesById(item.id);
        // this.props.getMarketPricesById(7419);
    }

    render() {
        const { classes, marketPrices, materialPrices } = this.props;
        const { selectedMaterialId } = this.state;
        return (
            <div style={{ 'padding': '20px', display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Card className={classes.card}>
                    <Carousel
                        data={marketPrices}
                        selectedMaterialId={selectedMaterialId}
                        handleMaterialChange={this.handleMaterialChange}
                    />
                </Card>
                <Card style={styles.card}>
                    <MaterialPricesLineChart data={materialPrices} />
                </Card>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        statisticsItems: state.reportsReducer.statisticsItems,
        marketPrices: state.marketPriceReducer.marketPrices,
        marketPricesFetching: state.marketPriceReducer.marketPricesFetching,
        materialPrices: state.marketPriceReducer.materialPrices,
        materialPricesFetching: state.marketPriceReducer.materialPricesFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getMarketPrices: () => dispatch(getMarketPrices()),
        getMarketPricesById: (id) => dispatch(getMarketPricesById(id)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(MarketPrices));
