import React from 'react';
import i18n from '../../../i18n/i18n';
import { Bar } from 'react-chartjs-2';
import { Link, Typography } from '@material-ui/core';
import { formatForecast } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import { Loader } from './../Loader';

const styles = {};

export class ForecastPrecipitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            week: false,
            load: true
        };
    };

    componentDidMount = () => {
        Loader(this.props, ['basic-1h', 'agro-1h'], ['forecastCalled', 'agroCalled'], ['forecast', 'agro'])
    };

    render() {
        let graph = {}, graph2 = {}, data = [], obj = {}, ready = true;

        this.props.data.forEach((item, i) => {
            if (Object.keys(item).length > 0) { obj = formatForecast(item); data.push(obj); }
            else data.push({});
        });

        data.forEach((item, i) => { if (Object.keys(item).length === 0) ready = false; });

        if (ready === true) {
            graph = {
                labels: this.state.week ? data[0].dates : data[0].dates.slice(0, 72),
                datasets: [
                    {
                        data: this.state.week ? data[0].precipitation : data[0].precipitation.slice(0, 72),
                        label: i18n.t('Precipitation [mm]'),
                        backgroundColor: 'rgba(134,184,217,0.3)',
                        borderColor: '#86b8d9',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: 0,
                        yAxisID: 'y-axis-3',
                    },
                    {
                        data: this.state.week ? data[0].precipitation_probability : data[0].precipitation_probability.slice(0, 72),
                        label: i18n.t('Precipitation probability [%]'),
                        type: 'line',
                        backgroundColor: 'transparent',
                        borderColor: '#0d86d6',
                        borderWidth: 2,
                        pointRadius: 0,
                        yAxisID: 'y-axis-4',
                        borderDash: [10, 5]
                    }
                ],
            };

            graph2 = {
                labels: this.state.week ? data[0].dates : data[0].dates.slice(0, 72),
                datasets: [
                    {
                        data: this.state.week ? data[0].precipitation : data[0].precipitation.slice(0, 72),
                        label: i18n.t('Precipitation [mm]'),
                        backgroundColor: 'rgba(134,184,217,0.3)',
                        borderColor: '#86b8d9',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: 0,
                        yAxisID: 'y-axis-1',
                    },
                    {
                        data: this.state.week ? data[1].leaf_wetness.map(item => item * 60) : data[1].leaf_wetness.slice(0, 72).map(item => item * 60),
                        label: i18n.t('Leaf wetness [min/h]'),
                        backgroundColor: '#7CFC00',
                        borderColor: '#7CFC00',
                        type: 'bar',
                        borderWidth: 2,
                        pointRadius: 0,
                        yAxisID: 'y-axis-2',
                    }
                ],
            };
        }

        const options1 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-3',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 4,
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-4',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[%]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 100,
                        }
                    }
                ],
            },
        };

        const options2 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[mm]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 4,
                        }
                    },
                    {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        id: 'y-axis-2',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[min/h]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            max: 60
                        }
                    },
                ],
            },
        };

        return (
            <div style={{ paddingLeft: '35px', height: '100%' }}>
                <div style={{ display: 'flex', marginLeft: '40%', paddingTop: '22px' }}>
                    <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: false }); }}
                        variant="contained"
                        color="secondary">
                        <Typography>{i18n.t('3 days')}</Typography>
                    </Link> /
                <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: true }); }}
                        variant="contained"
                        color="secondary" >
                        <Typography>{i18n.t('7 days')}</Typography>
                    </Link>
                </div>
                {Object.keys(graph).length === 0 && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {Object.keys(graph).length > 0 &&
                    <div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph} height={300} width={600} options={options1} />
                        </div>
                        <div style={{ width: '95%', marginTop: '10px', paddingBottom: '10%' }}>
                            <Bar data={graph2} height={300} width={600} options={options2} />
                        </div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(ForecastPrecipitation);