import React from 'react';
import axios from '../../utils/AxiosWrapper';
import i18n from '../../i18n/i18n';

import { Map, TileLayer, WMSTileLayer, Popup, Marker } from 'react-leaflet';
import L, { latLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'proj4leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import "leaflet-rotatedmarker";
import { GoogleLayer } from "react-leaflet-google"
import SatelliteInfo from './satellite/SatelliteInfo';
import { antPath } from 'leaflet-ant-path';

import { zoomFormat } from './ZoomAndCenter';
import { zoomFormatRoutes } from './ZoomAndCenterRoute';
import { calculateArea } from '../../utils/MapUtils';
import { wentWrong, wentRight } from '../../utils/ToastConfig';
import moment from 'moment';

import DatePicker from './../Map/LayerTree/Node/Tools/DatePicker';
import SensorDialog from './../sensor/SensorDialog';
import AddField from '../catastral_data/field/AddField';
import AddNote from '../note/AddNote';
import SessionInfo from './SessionInfo';

import { Dialog, DialogTitle, TextField, Typography, Button, IconButton, Tooltip, Radio, Checkbox, Collapse, Drawer, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Restore, ExpandLess, ExpandMore, Search, ChevronLeft, ChevronRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './styles';

import Select from 'react-select';
import MySelect from '../../utils/MySelect'
import classNames from 'classnames';
import MoonLoader from 'react-spinners';
import * as querystring from 'querystring';

import { Delete } from '../../utils/Delete';

export class Maps extends React.Component {
    constructor(props) {
        super(props);

        this.EMPTY_FEATURE = { type: null, isDrawn: false, isCompleted: false, geometry: null, attributes: null };
        this.state = {
            add: false,
            newFeature: { ...this.EMPTY_FEATURE },
            season: [], seasons: [], layer_season: [], field_list: [], field_selected: [],
            center: [45.75, 19.44], bounds: [], zoom_latitude: null, zoom_longitude: null,
            zoom: 6, mounting: true, open: true, zoomMenu: false,
            minZoom: 6, date: null, tomorrow: null, weather_date: null, max_date: null, today: moment().format('YYYY-MM-DD'),
            day_interval: 24 * 60 * 60 * 1000, forecasts: [], geoserver_region: '',
            row: {}, sensors: [], openSensorDialog: false, sensor_temp: null, flyto_state: false, satteliteDialog: false,
            stateType: [], states: [39, 19, 82, 166, 22], filter: null, meteoShown: false, clearStateColor: '#dbdbdb',
            currentSeason: null,

            baseLayersOpen: true, osmLayerShown: false, googleLayerShown: true,
            cadastralLayersOpen: true, fieldsLayerShown: true, parcelLayerShown: false,
            administrativeLayersOpen: true, administrativeLayerData: {}, selectedAdministrative: ['states'],
            satteliteLayersOpen: false, selectedSatellite: null, satelliteLayerData: {},
            transportLayersOpen: false, transportLayerData: {}, selectedTransport: [],
            fieldNotesLayersOpen: false, notesLayersShown: false, addNote: false, note_x: null, note_y: null,
            gpsLayerOpen: false, gps_devices: [], selectedGpsDevice: [], gps_date: "", gpsDeviceShown: false, session_info: false, session_history: [],
            gpsData: {}, gpsRoutesShown: false, gpsSessionDropdown: [], selectedSession: [], session_data: [], selectedGpsDeviceRoute: [],

            satteliteLayersIds: ["ndvi", "savi", "sr", "bathymetric", "ndwi", "ndmi", "lai", "evi", "burn"],
            satteliteLayers: ["NDVI", "SAVI", "SR", "BATHYMETRIC", "NDWI", "NDMI", "LAI", "EVI", "NBR"],
            satelliteLayersNames: ['NDVI', 'SAVI', 'SR', 'RGB', 'NDWI', 'NDMI', 'LAI', 'EVI', 'NBR'],

            drawPoligonActive: false,
            editLayerActive: false,
            drawMarkerActive: false,
            dragLayersActive: false,
            removeLayersActive: false,
            elementsOpen: true,
            deleteField: false,
            restrictedView: false,
        };
        this.ID_CLIENT_VIVAGRO = 32411;
        this.ID_CLIENT_SKUGRICANKA = 32649;
        this.ID_CLIENT_MILENKOOSTOJIC = 32805;
    }

    componentDidMount() {
        axios.get(`api/field/read_all_by_client`)
            .then(res => this.setState({ allParcels: res.data.data }))
            .catch(() => { })

        let user = this.getUser();
        if (user.id_region === 13) this.setState({ center: [9.0587, 7.824] });
        else if (user.id_region === 25) this.setState({ center: [31.7649, 34.954] });
        else if (user.id_region === 20) this.setState({ center: [44.1926, 17.6498] });
        else if (user.id_region === 23) this.setState({ center: [42.7717, 19.2129] });

        if (user.id_korisnik_grupa === 11) this.setState({ restrictedView: true })

        if (user.satwork_username !== null) {
            axios.get('satwork/devices', { params: { satwork_username: user.satwork_username, satwork_password: user.satwork_password } })
                .then(res => {
                    let data = res.data.deviceList;
                    let drop_down = [];
                    data.forEach((item, i) => {
                        drop_down.push({ naziv: item.deviceName, id: Number(item.deviceId) })
                    })
                    this.setState({ gps_devices: drop_down });
                })
                .catch(err => { console.log(err); })
        }

        let show_field = JSON.parse(localStorage.getItem('row'));
        let show_field_array = [];

        if (show_field !== null) {
            show_field_array.push({ label: show_field.naziv, value: show_field.id });
            this.setState({ field_selected: [] }, () => {
                this.setState({ field_selected: show_field_array });
                // localStorage.removeItem('row');
            });
        };

        axios.get('api/season/drop_down')
            .then(res => {
                this.setState({ seasons: res.data.data });
            })
            .catch(err => { console.log(err) });

        axios.get('api/season/activity_season')
            .then(res => {
                // this.setState({ currentSeason: res.data.data[0].id_default_sezona, season: [{ label: res.data.data[0].naziv, value: res.data.data[0].id_default_sezona }] });    

                this.setState({ currentSeason: res.data.data[0].id_default_sezona, season: [...this.state.season, { label: res.data.data[0].naziv, value: res.data.data[0].id_default_sezona }] },
                    () => {
                        this.loadFields();
                    });
            })
            .catch(err => { console.log(err) });

        axios.get('api/region/geoserver').then(res => {
            if (user.id === 28460 || user.id === 28461) this.setState({ geoserver_region: 'srbija' });
            else if (user.id_klijent === 32101) { this.setState({ geoserver_region: 'hrvatska' }) }
            else {
                if (res.data.data[0]) this.setState({ geoserver_region: res.data.data[0].geoserver });
            }
        }).catch(err => { console.log(err) });

        axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 2 } });

        this.setState({ weather_date: this.getTodayDate(), date: this.getTodayDate(), max_date: this.getTodayDate() });

        const map = this.map.leafletElement;
        map.removeControl(map.zoomControl);

        L.control.zoom({ position: 'bottomright' }).addTo(map);

        map.on("pm:create", (e) => {
            const that = this;

            that.drawnFeature = e.layer;

            if (e.layer._latlngs) {
                let newFeature = { ...that.state.newFeature };
                newFeature.type = that.props.editedLayerId;
                newFeature.geometry = e.layer.getLatLngs();
                newFeature.isDrawn = true;
                newFeature.attributes = { povrsina: calculateArea(newFeature.geometry) };

                this.setState({ newFeature, add: true });
            }
            else if (e.layer._latlng) {
                let x = e.layer._latlng.lng;
                let y = e.layer._latlng.lat;
                this.setState({ note_x: x, note_y: y }, () => { this.setState({ addNote: true }); })
            }
        });

        map.on('pm:drawstart', (e) => {
            map.eachLayer(layer => {
                if (layer.feature) {
                    if (layer.feature.geometry.type === "Polygon" && layer.feature.properties.boja !== undefined) {
                        layer.unbindPopup();
                    }
                }
            });
        });

        map.on('pm:remove', (e) => {
            if (e.layer.feature) {
                if (e.layer.feature.geometry.type === "Polygon" && e.layer.feature.properties.boja !== undefined) {
                    axios.get('api/field/check_field', { params: { id_tabla: e.layer.feature.id } })
                        .then(res => {
                            if (res.data) {
                                if ((res.data.data[0].count === "0")) {
                                    this.setState({ deleteField: true, fieldToDelete: e.layer.feature.id })
                                    this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                                }
                                else {
                                    wentWrong(i18n.t('There are activities tied to this field.'));
                                    this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                                    this.loadFields();
                                }
                            }
                        })
                        .catch(err => { console.log(err); })
                }
            }
            if (e.layer.options.icon) {
                if (e.layer.options.icon.options.iconUrl.includes('icon')) {
                    let id = null;
                    id = e.layer.pm._layer.options.icon.options.id;
                    if (e.layer.pm._layer && id !== null) {
                        axios.post('api/note/delete', { selection: [id] })
                            .then(res => { wentRight(i18n.t('Successfully deleted.')); })
                            .catch(err => { console.log(err); wentWrong(i18n.t('Something went wrong.')) })
                    }
                }
            }
        });
        this.setState({ user: this.getUser() });
    };

    handleCloseDeleteField = () => {
        this.setState({ deleteField: false });
        this.loadFields()
    }

    handleDeleteField = async () => {
        await axios.post('api/field/delete', { selection: [this.state.fieldToDelete] })
            .then(res => { wentRight(i18n.t('Successfully deleted')); })
            .catch(err => { wentWrong(i18n.t('Something went wrong')); })
        this.setState({ deleteField: false, fieldToDelete: null }, () => { this.loadFields(); })
    }

    getTodayDate = () => {
        let date = new Date();
        let day = date.getDate();
        let month = new String(date.getMonth());
        let year = date.getFullYear();
        if (month.length === 1) month = '0' + month;
        return year + '-' + month + '-' + day;
    };
    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    getSensorData = (filter) => {
        let user = this.getUser()
        axios.get('map/read_map', { params: { filter: filter, id_klijent: user.id_klijent } })
            .then(res => {
                this.setState({ sensors: res.data.data });
                if (filter !== null) {
                    let x = res.data.data.map(item => Number(item.x))
                    let y = res.data.data.map(item => Number(item.y))
                    let minx = Math.min(...x);
                    let miny = Math.min(...y);
                    let maxx = Math.max(...x);
                    let maxy = Math.max(...y);
                    let centerx = (minx + maxx) / 2;
                    let centery = (miny + maxy) / 2;

                    this.map.leafletElement.flyTo([centery, centerx], 10, { animate: true, duration: 0.5 })
                }
                else this.map.leafletElement.flyTo([45.64, 19.7], 9, { animate: true, duration: 0.5 });
            })
            .catch(err => { console.log(err) })
    }

    saveField = (attributes) => {
        const { newFeature: { geometry } } = this.state;
        let user = this.getUser();
        axios.post('api/field/create', { ...attributes, geometry, id_default_sezona: this.state.currentSeason })
            .then(res => {
                axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 10 } })
                this.setState({ add: false, newFeature: { ...this.EMPTY_FEATURE } }, () => {
                    this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                    this.setState({ mounting: false }, () => { this.loadFields(); })
                });
            })
            .catch(err => { console.log(err); })
        this.setState({ drawPoligonActive: false });
    };
    updateGeom = (attributes) => {
        const { newFeature: { geometry } } = this.state;
        axios.post('api/field/update_geom', { id: attributes, geometry })
            .then(res => {
                this.setState({ add: false, newFeature: { ...this.EMPTY_FEATURE } }, () => {
                    this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                    this.setState({ mounting: false }, () => { this.loadFields(); })
                });
            })
            .catch(err => { console.log(err); })
        this.setState({ drawPoligonActive: false });
    }

    // saveNote = (attributes) => {
    //     axios.post('api/note/create', { ...attributes, x: this.state.note_x, y: this.state.note_y })
    //         .then(res => {
    //             axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 12 } })
    //             this.setState({ addNote: false, note_x: null, note_y: null }, () => {
    //                 this.map.leafletElement.removeLayer(this.drawnFeature);
    //                 this.map.leafletElement.eachLayer(layer => {
    //                     if (layer._icon) {
    //                         if (layer._icon.src) {
    //                             if (layer._icon.src.includes('icon')) {
    //                                 layer.remove();
    //                             }
    //                         }
    //                     }
    //                 });
    //                 this.setState({ fieldNotesLayersOpen: true, notesLayersShown: true }, () => {
    //                     if (this.state.fieldsLayerShown === true) {
    //                         this.loadFields();
    //                         this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
    //                     }
    //                     this.loadNotes();
    //                 })
    //             })
    //         })
    // }

    loadFields = async () => {
        await axios.get(`api/field/read_all_by_client`)
            .then(res => this.setState({ allParcels: res.data.data }))
            .catch(() => { })
        const map = this.map.leafletElement;

        if (this.state.gpsRoutesShown && Object.keys(this.state.selectedGpsDevice).length > 0 && this.state.gps_date && this.state.session_data.length > 0) this.loadRoutes();

        let show_field = JSON.parse(localStorage.getItem('row'));
        let show_field_array = [];

        if (show_field !== null) {
            this.setState({
                season: [{
                    label: show_field.label_sezona,
                    value: show_field.id_sezona,
                }]
            })
            localStorage.removeItem('row');
        }

        const bounds = map.getBounds();
        const mapBounds = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];

        const bbox = mapBounds.join(',');
        const crs = 'EPSG:4326';
        let seasons = [];
        if (this.state.season) this.state.season.map((item, i) => { seasons.push(item.value) })

        if (show_field !== null) seasons = [show_field.id_sezona]

        let that = this;
        let mounting = this.state.mounting;
        let fields = [];
        if (that.state.field_selected) fields = that.state.field_selected.map((item, i) => item.value)

        axios.get('api/field/field_list', { params: { seasons } })
            .then(res => {
                let field_list = [];
                for (let i = 0; i < res.data.data.length; i++) {
                    field_list.push({ id: res.data.data[i].id, name: res.data.data[i].naziv });
                }
                this.setState({ field_list }, () => { });
            })
            .catch(err => { console.log(err) })

        axios.get('api/field?' + querystring.stringify({ bbox, seasons, crs, fields }))
            .then(response => {
                let parsed = JSON.parse(response.data.data.geojson);

                if (fields.length > 0) {
                    zoom_data = zoomFormat(parsed);
                    if (zoom_data) that.setState({ center: zoom_data.center, zoom: zoom_data.zoom + 1 });
                }
                else {
                    if (parsed.features.length === 0) {
                        axios.get('api/opstina/geom_opstina')
                            .then(res => {
                                parsed = (JSON.parse(res.data.data.rows[0].geojson));
                                if (parsed.features.length !== 0) {
                                    if (mounting === true) {
                                        zoom_data = zoomFormat(parsed);
                                        if (zoom_data) that.setState({ center: zoom_data.center, zoom: zoom_data.zoom + 1 });
                                    }
                                }
                            })
                            .catch(err => { console.log(err) })
                    }
                }

                let zoom_data = {};
                if (parsed.features.length !== 0) {
                    if (mounting === true) {
                        zoom_data = zoomFormat(parsed);
                        if (zoom_data) that.setState({ center: zoom_data.center, zoom: zoom_data.zoom });
                    }
                }

                L.geoJSON(parsed).addTo(map);

                map.eachLayer(layer => {

                    let popup = '';
                    if (layer.feature) {
                        if (layer.feature.geometry.type === "Polygon" && layer.feature.properties.boja !== undefined) {

                            let field = layer.feature.properties;
                            let slash1 = ' / ', slash2 = ' / ', new_line = '</br>';

                            let currentFieldData = this.state.allParcels.find(parcel => parcel.naziv === field.naziv && parseFloat(parcel.povrsina) == field.povrsina)

                            if (field.sorta_naziv === null) { slash1 = '' }
                            if (field.kultura_podgrupa_naziv === null) { slash2 = '' }
                            if (field.kultura_naziv === null) field.kultura_naziv = '';
                            if (field.sorta_naziv === null) field.sorta_naziv = '';
                            if (field.kultura_grupa_naziv === null) field.kultura_grupa_naziv = '';
                            if (field.kultura_podgrupa_naziv === null) field.kultura_podgrupa_naziv = '';


                            popup = L.popup().setContent(`
                                    <p style="text-align: center;font-size:20px;font-family:roboto">
                                        ${i18n.t('Name')}: ${field.naziv}

                                        <p style="font-size:18px ; font-family:roboto">
                                            Kultura: ${field.kultura_naziv ? field.kultura_naziv : ''}
                                        </p>
                                        
                                        <div style=" width: 100%;font-size:18px">
                                        ${currentFieldData.sorte !== undefined ? (currentFieldData.sorte.length > 0 ? `<div style="width: 100%; border-bottom: 1px solid black; margin-bottom: 10px; display: flex; justify-content: space-between; padding-bottom: 10px;">
                                                <div style="width: 200px;">Sorta/hibrid</div> <div style="width: 150px; font-size:18px">Površina (ha)</div>
                                        </div>` : ``) : ``}
                                        ${currentFieldData.sorte.map(item => `
                                          <span style="display: flex; justify-content: space-between; font-size:18px">
                                            <div style="width: 200px;">${item.naziv || ''}</div><div style="width: 150px;">${item.povrsina || ''}</div>
                                          </span>
                                        `).join('')}
                                      </div>

                                        
                                        <p style="font-size:18px;font-family:roboto">${i18n.t('Land area')}: ${field.povrsina} ha</p>
                                    </p>`
                            );
                            layer.bindPopup(popup);
                            layer.setStyle({ color: field.boja === null ? "#4692fb" : field.boja, opacity: 1, fillColor: field.boja === null ? "#4692fb" : field.boja, fillOpacity: this.state.selectedSatellite === null ? 0.3 : 0, weight: 2 });
                            layer.on('mouseover', (e) => { layer.setStyle({ color: field.boja === null ? "#4692fb" : field.boja, opacity: 1, fillColor: field.boja === null ? "#4692fb" : field.boja, fillOpacity: this.state.selectedSatellite === null ? 0.5 : 0, weight: 2 }); });
                            layer.on('mouseout', (e) => { layer.setStyle({ color: field.boja === null ? "#4692fb" : field.boja, opacity: 1, fillColor: field.boja === null ? "#4692fb" : field.boja, fillOpacity: this.state.selectedSatellite === null ? 0.3 : 0, weight: 2 }); });

                            if (layer.feature) {
                                layer.on('pm:edit', (e) => {
                                    axios.post('api/field/update_geom', {
                                        id: e.target.feature.id,
                                        geometry: e.target.getLatLngs(),
                                    })
                                        .then(res => {
                                            that.setState({ add: false, newFeature: { ...that.EMPTY_FEATURE } }, () => {
                                                if (this.state.editLayerActive) this.toggleEditLayerMode()
                                                that.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                                                that.setState({ mounting: false }, () => { that.loadFields(); })
                                            })
                                        })
                                        .catch(err => console.log(err))
                                });
                            }
                        }
                    }
                });
            })
            .catch(err => console.log(err));
        if (mounting === false) { this.setState({ mounting: true }) };
    };

    loadNotes = () => {
        const map = this.map.leafletElement;

        axios.get('api/note?', {})
            .then(res => {
                let parsed = JSON.parse(res.data.data.geojson);
                let markers = [];
                parsed.features.map((item, i) => {
                    let marker_coordinates = [item.geometry.coordinates[1], item.geometry.coordinates[0]];
                    let vrsta_beleske = item.properties.vrsta_beleske_id;

                    let Icon = null;
                    if (vrsta_beleske === 12) Icon = L.icon({ iconUrl: './note/fire-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 13) Icon = L.icon({ iconUrl: './note/disease-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 14) Icon = L.icon({ iconUrl: './note/pest-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 15) Icon = L.icon({ iconUrl: './note/steal-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 17) Icon = L.icon({ iconUrl: './note/mark-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 18) Icon = L.icon({ iconUrl: './note/weed-icon-new.png', iconSize: [32, 32], id: item.id });
                    else if (vrsta_beleske === 19) Icon = L.icon({ iconUrl: './note/analysis-icon-new.png', iconSize: [32, 32], id: item.id });
                    else Icon = L.icon({ iconUrl: './note/other-icon-new.png', iconSize: [32, 32], id: item.id });

                    let naslov = '', vrsta_beleske_naziv = '', datum_dogadjaja = '', opis = '';
                    if (item.properties.naslov !== null) naslov = `<div style="font-size:18px;font-weight:bold">${item.properties.naslov}</div>`;
                    if (item.properties.vrsta_beleske_naziv !== null) vrsta_beleske_naziv = `<div style="font-size:14px;font-weight:bold">${item.properties.vrsta_beleske_naziv}</div>`;
                    if (item.properties.datum_dogadjaja !== null) datum_dogadjaja = `<div style="margin-top:8px">${item.properties.datum_dogadjaja}</div>`;
                    if (item.properties.opis !== null) opis = `<div style="margin-top:4px">${item.properties.opis}</div>`;

                    let marker = L.marker(marker_coordinates, { icon: Icon }).bindPopup(
                        `<div>` + naslov + vrsta_beleske_naziv + datum_dogadjaja + opis + `</div>`
                    )
                    markers.push(marker);
                })
                L.layerGroup(markers).addTo(map)
                map.eachLayer(layer => {
                    if (layer instanceof L.Marker) {
                        layer.dragging.disable()
                    }
                })
                
            })
            .catch(err => { console.log(err) })
    }

    loadGpsDevice = () => {
        const map = this.map.leafletElement;
        this.removeGpsDevice();
        let user = this.getUser();
        let device = this.state.selectedGpsDevice;
        axios.get('satwork/positions', { params: { satwork_username: user.satwork_username, satwork_password: user.satwork_password } })
            .then(res => {
                let gpsData = res.data.positionList;
                let markers = [];
                let Icon = null;
                let positions = [];

                if (gpsData !== undefined) {
                    device.forEach((item, i) => {
                        let gpsDeviceData = {};
                        gpsData.forEach((el, e) => { if (Number(el.deviceId) === item.value) { gpsDeviceData = el; } });

                        let heading = gpsDeviceData.heading;

                        let naslov = `<div style="font-size:18px;font-weight:bold">${i18n.t('Device')}: ${item.value}</div>`;
                        let break_string = `</br>`;
                        let longitude_string = `<div style="font-size:14px;font-weight:normal">${i18n.t('Longitude')}: ${gpsDeviceData.coordinate.longitude}</div>`;
                        let latitude_string = `<div style="font-size:14px;font-weight:normal">${i18n.t('Latitude')}: ${gpsDeviceData.coordinate.latitude}</div>`;
                        let heading_string = `<div style="font-size:14px;font-weight:normal">${i18n.t('Heading')}: ${gpsDeviceData.heading}°</div>`;
                        let speed = `<div style="font-size:14px;font-weight:normal">${i18n.t('Speed')}: ${gpsDeviceData.speed}</div>`;

                        if (gpsDeviceData.dateTime.hour <= 21) gpsDeviceData.dateTime.hour = gpsDeviceData.dateTime.hour + 2;
                        else if (gpsDeviceData.dateTime.hour === 22) gpsDeviceData.dateTime.hour = 0;
                        else if (gpsDeviceData.dateTime.hour === 23) gpsDeviceData.dateTime.hour = 1;

                        let hour = gpsDeviceData.dateTime.hour.toString().length === 1 ? '0' + gpsDeviceData.dateTime.hour.toString() : gpsDeviceData.dateTime.hour.toString();
                        let minute = gpsDeviceData.dateTime.minute.toString().length === 1 ? '0' + gpsDeviceData.dateTime.minute.toString() : gpsDeviceData.dateTime.minute.toString();
                        let seconds = gpsDeviceData.dateTime.seconds.toString().length === 1 ? '0' + gpsDeviceData.dateTime.seconds.toString() : gpsDeviceData.dateTime.seconds.toString();

                        let time = `<div style="font-size:14px;font-weight:bold">${hour}:${minute}:${seconds} - ${gpsDeviceData.dateTime.day}/${gpsDeviceData.dateTime.month}/${gpsDeviceData.dateTime.year}</div>`;

                        let xy = [];
                        xy.push(gpsDeviceData.coordinate.latitude, gpsDeviceData.coordinate.longitude);
                        positions.push(xy);

                        Icon = L.icon({ iconUrl: './gps-direction.png', iconSize: [40, 40] })
                        let marker = L.marker([gpsDeviceData.coordinate.latitude, gpsDeviceData.coordinate.longitude], { icon: Icon, rotationAngle: heading }).bindPopup(
                            `<div>` + naslov + time + break_string + longitude_string + latitude_string + heading_string + speed + `</div>`
                        );
                        markers.push(marker);
                        L.layerGroup(markers).addTo(map)
                    })
                    let zoom_data = zoomFormatRoutes([positions]);
                    this.map.leafletElement.flyTo(zoom_data.center, zoom_data.zoom, { animate: true, duration: 0.5 });
                }
                else {
                    wentWrong(i18n.t('Try again in a minute'));
                }
            })
            .catch(err => console.log(err))
    }

    loadRoutes = () => {
        this.removeGpsLayer();
        const map = this.map.leafletElement;
        let routes = [];

        this.state.selectedSession.forEach((item, i) => {
            let route = [];
            this.state.session_data[item.value].forEach((el, e) => {
                let lat = el.Lat;
                let long = el.Long;
                let latlong = [lat, long];
                route.push(latlong);
            });

            L.polyline.antPath(route, {
                "delay": 1000, "dashArray": [30, 30], "weight": 4, "color": "#0000FF", "pulseColor": "#FFFFFF", "paused": false, "reverse": false, "hardwareAccelerated": true
            }).addTo(map)

            routes.push(route);
        });

        let zoom_data = zoomFormatRoutes(routes);
        this.map.leafletElement.flyTo(zoom_data.center, zoom_data.zoom, { animate: true, duration: 0.5 });
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    handleClick = (item) => { this.setState({ openSensorDialog: !this.state.openSensorDialog, row: item }) };
    handleClickSatellite = (item) => { this.setState({ satteliteDialog: !this.state.satteliteDialog, row: item }) };
    handleCloseZoomMenu = () => { this.setState({ zoomMenu: !this.state.zoomMenu }); };

    handleFields = () => {
        this.setState({ fieldsLayerShown: !this.state.fieldsLayerShown }, () => {
            if (this.state.fieldsLayerShown === true) this.loadFields();
            else this.map.leafletElement.eachLayer(layer => {
                if (layer.feature) {
                    if (layer.feature.geometry.type === "Polygon" && layer.feature.properties.boja !== undefined) {
                        layer.remove();
                    }
                }
            });
        });
    };

    handleNotes = () => {
        this.setState({ notesLayersShown: !this.state.notesLayersShown }, () => {
            if (this.state.notesLayersShown === true) this.loadNotes();
            else this.map.leafletElement.eachLayer(layer => {
                if (layer._icon) {
                    if (layer._icon.src) {
                        if (layer.options.icon.options.iconUrl.includes('icon')) layer.remove();
                    }
                }
            });
        });
    };

    handleSatellites = (key, index) => {

        this.setState({ satelliteLayerData: {} }, () => {
            if (this.state.selectedSatellite === key) {
                this.setState({ selectedSatellite: null });
                this.map.leafletElement.options.minZoom = 6;
            }
            else {
                this.setState({ selectedSatellite: key }, () => {
                    let zoom = this.map.leafletElement.getZoom();
                    if (zoom < 10) {
                        this.map.leafletElement.options.minZoom = 10;
                        this.map.leafletElement.flyTo(this.state.center, 10, { animate: true, duration: 0.5 });
                    }
                })
            };
            this.setState({ satelliteLayerData: key });
        })
    };

    handleTransports = (key) => {
        if (this.state.selectedTransport.includes(key)) {
            this.setState({ selectedTransport: this.state.selectedTransport.filter(item => item !== key) });
        }
        else this.setState({ selectedTransport: [...this.state.selectedTransport, key] });
    };

    handleTransportGroup = () => { this.setState({ transportLayersOpen: !this.state.transportLayersOpen }); };
    handleMeteo = () => {
        if (this.state.meteoShown === true) { this.setState({ filter: null }); };

        this.setState({ meteoShown: !this.state.meteoShown }, () => {
            if (this.state.meteoShown === true) { this.getSensorData(); };
        });
    };

    handleAdministrative = (item) => {
        if (this.state.selectedAdministrative.includes(item)) {
            this.setState({ selectedAdministrative: this.state.selectedAdministrative.filter(el => el !== item) });
        }
        else this.setState({ selectedAdministrative: [...this.state.selectedAdministrative, item] });

    };

    handleAdministrativeGroup = () => { this.setState({ administrativeLayersOpen: !this.state.administrativeLayersOpen }); };

    handleBaseLayers = () => { this.setState({ osmLayerShown: !this.state.osmLayerShown, googleLayerShown: !this.state.googleLayerShown }); };
    handleDateChange = (e) => { this.setState({ date: e.target.value }) };
    handleGpsDateChange = (e) => {
        this.setState({ gps_date: e.target.value, selectedSession: [], session_data: [] }, () => {
            this.removeGpsLayer();
            let start = this.state.gps_date + ' ' + '00:00:00';
            let end = this.state.gps_date + ' ' + '23:59:59';
            let user = this.getUser();
            axios.get('satwork/sessions', { params: { dateStart: start, dateEnd: end, uredjaj: this.state.selectedGpsDeviceRoute.value, satwork_username: user.satwork_username, satwork_password: user.satwork_password } })
                .then(res => {

                    let data = res.data.historyList;
                    this.setState({ session_history: data });
                    if (data.length === 0) wentWrong(i18n.t('There are no sessions for this date'));
                    else {
                        let sessions = [];
                        let session_data = [];

                        data.forEach((item, i) => {
                            let start_time = '', stop_time = '', label = '';
                            start_time = item.StartTime.substr(11, 18);
                            stop_time = item.StopTime.substr(11, 18);

                            label = start_time + ' - ' + stop_time;
                            sessions.push({ value: i, label: label });
                            axios.get('satwork/route', { params: { dateStart: item.StartTime, dateEnd: item.StopTime, uredjaj: this.state.selectedGpsDeviceRoute.value, satwork_username: user.satwork_username, satwork_password: user.satwork_password } })
                                .then(res => {
                                    session_data.push(res.data.sessionList[0].coordinates);
                                    if (i === data.length - 1) { this.setState({ session_data: session_data }) };
                                })
                                .catch(err => { console.log(err) })
                        })
                        this.setState({ gpsSessionDropdown: sessions });
                    }
                })
                .catch(err => { console.log(err) })
        })
    };

    handleGps = () => { this.setState({ gpsLayerOpen: !this.state.gpsLayerOpen }) };

    handleGpsDeviceShown = () => {
        this.setState({ gpsDeviceShown: !this.state.gpsDeviceShown }, () => {
            if (this.state.gpsDeviceShown === true && this.state.selectedGpsDevice.length > 0) this.loadGpsDevice();
            else this.removeGpsDevice();
        })
    };

    handleGpsDeviceChange = (e) => {
        this.setState({ selectedGpsDevice: e }, () => {
            if (this.state.selectedGpsDevice.length > 0) this.loadGpsDevice();
            else if (this.state.selectedGpsDevice.length === 0) this.removeGpsDevice();
        });
    }

    handleGpsRoutes = () => {
        this.setState({ gpsRoutesShown: !this.state.gpsRoutesShown }, () => {
            const map = this.map.leafletElement;
            if (this.state.gpsRoutesShown === false) {
                this.removeGpsLayer();
            }
            else {
                if (this.state.selectedSession.length > 0) this.loadRoutes();
            }
        })
    };

    handleGpsDeviceChangeRoutes = (e) => {
        if (e) this.setState({ selectedGpsDeviceRoute: e, gps_date: "" });
        else this.setState({ selectedGpsDeviceRoute: [] }, () => {
            this.removeGpsLayer();
            this.setState({ selectedSession: [], session_data: [], gps_date: "" });
        });
    };

    removeGpsLayer = () => {
        this.map.leafletElement.eachLayer(layer => {
            if (layer.options) {
                if (layer.options.delay) {
                    if (layer.options.delay === 1000) {
                        layer.remove();
                    }
                }
            }
        });
    }

    removeGpsDevice = () => {
        this.map.leafletElement.eachLayer(layer => {
            if (layer._icon) {
                if (layer._icon.src) {
                    if (layer.options.icon.options.iconUrl.includes('gps')) layer.remove();
                }
            }
        })
    }

    handleSessionChange = (e) => {
        this.setState({ selectedSession: e }, () => {
            if (this.state.selectedSession.length > 0) this.loadRoutes();
            else this.removeGpsLayer();
        })
    };

    handleSessionInfo = () => { this.setState({ session_info: !this.state.session_info }); };

    toggleDragMode = () => {
        const map = this.map.leafletElement;

        if (map.pm.Draw.Polygon.enabled()) {
            map.pm.disableDraw('Polygon');
            this.setState({ drawPoligonActive: !this.state.drawPoligonActive })
        }

        if (map.pm.Draw.Marker.enabled()) {
            map.pm.disableDraw('Marker');
            this.setState({ drawMarkerActive: !this.state.drawMarkerActive });
        }

        if (this.state.editLayerActive) {
            this.setState({ editLayerActive: !this.state.editLayerActive })
            map.pm.toggleGlobalEditMode();
        }
        if (this.state.removeLayersActive) {
            this.setState({ removeLayersActive: !this.state.removeLayersActive })
            map.pm.toggleGlobalRemovalMode();
        }
        this.setState({ dragLayersActive: !this.state.dragLayersActive });
        map.pm.toggleGlobalDragMode();
        map.eachLayer(layer => {
            if (layer instanceof L.Marker) {
                layer.dragging.disable()
            }
        })
    }

    toggleDrawPolygonMode = () => {
        const map = this.map.leafletElement;

        if (this.state.dragLayersActive) {
            map.pm.toggleGlobalDragMode();
            this.setState({ dragLayersActive: !this.state.dragLayersActive })
        }

        if (map.pm.Draw.Marker.enabled()) {
            map.pm.disableDraw('Marker');
            this.setState({ drawMarkerActive: !this.state.drawMarkerActive });
        }

        if (this.state.editLayerActive) {
            this.setState({ editLayerActive: !this.state.editLayerActive });
            map.pm.toggleGlobalEditMode();
        }

        if (this.state.removeLayersActive) {
            this.setState({ removeLayersActive: !this.state.removeLayersActive });
            map.pm.toggleGlobalRemovalMode();
        }

        if (map.pm.Draw.Polygon.enabled()) {
            map.pm.disableDraw('Polygon');
            this.setState({ drawPoligonActive: !this.state.drawPoligonActive })
        } else {
            this.setState({ drawPoligonActive: !this.state.drawPoligonActive })
            map.pm.enableDraw('Polygon', {
                snappable: true,
                snapDistance: 20,
            });
        }

    }

    removeLastVertex = (e) => {
        e.stopPropagation();
        const map = this.map.leafletElement;
        map.pm.Draw.Polygon._removeLastVertex();
        if (!map.pm.Draw.Polygon.enabled()) this.setState({ drawPoligonActive: !this.state.drawPoligonActive });
    }


    cancelDrawingPolygon = (e) => {
        e.stopPropagation();
        const map = this.map.leafletElement;
        map.pm.disableDraw('Polygon');
        this.setState({ drawPoligonActive: !this.state.drawPoligonActive });
    }

    toggleDrawMarkerMode = () => {
        const map = this.map.leafletElement;

        if (this.state.dragLayersActive) {
            this.setState({ dragLayersActive: !this.state.dragLayersActive });
            map.pm.toggleGlobalDragMode();
        }

        if (map.pm.Draw.Polygon.enabled()) {
            map.pm.disableDraw('Polygon');
            this.setState({ drawPoligonActive: !this.state.drawPoligonActive })
        }

        if (this.state.editLayerActive) {
            this.setState({ editLayerActive: !this.state.editLayerActive });
            map.pm.toggleGlobalEditMode();
        }

        if (this.state.removeLayersActive) {
            this.setState({ removeLayersActive: !this.state.removeLayersActive });
            map.pm.toggleGlobalRemovalMode();
        }


        if (map.pm.Draw.Marker.enabled()) {
            map.pm.disableDraw('Marker');
            this.setState({ drawMarkerActive: !this.state.drawMarkerActive });
        } else {
            map.pm.enableDraw('Marker');
            this.setState({ drawMarkerActive: !this.state.drawMarkerActive });
        }

    }

    toggleEditLayerMode = () => {
        const map = this.map.leafletElement;

        if (map.pm.Draw.Marker.enabled()) {
            map.pm.disableDraw('Marker');
            this.setState({ drawMarkerActive: !this.state.drawMarkerActive });
        }


        if (this.state.dragLayersActive) {
            this.setState({ dragLayersActive: !this.state.dragLayersActive });
            map.pm.toggleGlobalDragMode();
        }

        if (map.pm.Draw.Polygon.enabled()) {
            map.pm.disableDraw('Polygon');
            this.setState({ drawPoligonActive: !this.state.drawPoligonActive })
        }

        if (this.state.removeLayersActive) {
            this.setState({ removeLayersActive: !this.state.removeLayersActive });
            map.pm.toggleGlobalRemovalMode();
        }

        this.setState({ editLayerActive: !this.state.editLayerActive });
        map.pm.toggleGlobalEditMode();
    }

    toggleRemovalMode = () => {
        const map = this.map.leafletElement;

        if (map.pm.Draw.Marker.enabled()) {
            map.pm.disableDraw('Marker');
            this.setState({ drawMarkerActive: !this.state.drawMarkerActive });
        }


        if (this.state.dragLayersActive) {
            this.setState({ dragLayersActive: !this.state.dragLayersActive });
            map.pm.toggleGlobalDragMode();
        }

        if (map.pm.Draw.Polygon.enabled()) {
            map.pm.disableDraw('Polygon');
            this.setState({ drawPoligonActive: !this.state.drawPoligonActive })
        }

        if (this.state.editLayerActive) {
            this.setState({ editLayerActive: !this.state.editLayerActive });
            map.pm.toggleGlobalEditMode();
        }

        this.setState({ removeLayersActive: !this.state.removeLayersActive });
        map.pm.toggleGlobalRemovalMode();
    }

    resetView = () => {
        const map = this.map.leafletElement;

        if (map.pm.Draw.Marker.enabled()) {
            map.pm.disableDraw('Marker');
            this.setState({ drawMarkerActive: !this.state.drawMarkerActive });
        }


        if (this.state.dragLayersActive) {
            this.setState({ dragLayersActive: !this.state.dragLayersActive });
            map.pm.toggleGlobalDragMode();
        }

        if (map.pm.Draw.Polygon.enabled()) {
            map.pm.disableDraw('Polygon');
            this.setState({ drawPoligonActive: !this.state.drawPoligonActive })
        }

        if (this.state.editLayerActive) {
            this.setState({ editLayerActive: !this.state.editLayerActive });
            map.pm.toggleGlobalEditMode();
        }
        if (this.state.removeLayersActive) {
            this.setState({ removeLayersActive: !this.state.removeLayersActive });
            map.pm.toggleGlobalRemovalMode();
        }

        this.map.leafletElement.flyTo(this.state.center, this.state.zoom, { animate: true, duration: 0.5 })
    }

    openZoom = () => {
        const map = this.map.leafletElement;

        if (map.pm.Draw.Marker.enabled()) {
            map.pm.disableDraw('Marker');
            this.setState({ drawMarkerActive: !this.state.drawMarkerActive });
        }


        if (this.state.dragLayersActive) {
            this.setState({ dragLayersActive: !this.state.dragLayersActive });
            map.pm.toggleGlobalDragMode();
        }

        if (map.pm.Draw.Polygon.enabled()) {
            map.pm.disableDraw('Polygon');
            this.setState({ drawPoligonActive: !this.state.drawPoligonActive })
        }

        if (this.state.editLayerActive) {
            this.setState({ editLayerActive: !this.state.editLayerActive });
            map.pm.toggleGlobalEditMode();
        }
        if (this.state.removeLayersActive) {
            this.setState({ removeLayersActive: !this.state.removeLayersActive });
            map.pm.toggleGlobalRemovalMode();
        }

        this.setState({ zoomMenu: true })
    }

    render() {
        let user = this.getUser();
        const { classes } = this.props;
        return (
            <div className="page" style={{ height: 'calc(100vh - 65px)', position: 'relative' }}>
                <div style={{
                    width: `calc(100% ${this.state.open ? '- 270px' : ''})`, height: '63px', display: 'flex',
                    alignItems: 'center', backgroundColor: '#ededed', overflow: 'visible', paddingLeft: '20px'
                }}>
                    <div onClick={this.toggleDrawPolygonMode} className={classes.topBarItem} style={{ position: 'relative', backgroundColor: `${this.state.drawPoligonActive ? "#c2c0c0" : ""}`, display: `${this.state.restrictedView ? "none" : "flex"}` }}>{polygoneIcon()} {i18n.t('Add field')}
                        <div className={classes.polygonDropdown} style={{ display: `${this.state.drawPoligonActive ? 'block' : 'none'}` }}>
                            <div onClick={this.removeLastVertex} className={classes.polygonDropdownBtn}>Obriši poslednju tačku</div>
                            <div onClick={this.cancelDrawingPolygon} className={classes.polygonDropdownBtn}>Poništi</div>
                        </div>
                    </div>
                    <div onClick={this.toggleEditLayerMode} className={classes.topBarItem} style={{ backgroundColor: `${this.state.editLayerActive ? "#c2c0c0" : ""}`, display: `${this.state.restrictedView ? "none" : "flex"}` }}>{editIcon()}{i18n.t('Edit field')}</div>
                    <div onClick={this.toggleDrawMarkerMode} className={classes.topBarItem} style={{ backgroundColor: `${this.state.drawMarkerActive ? "#c2c0c0" : ""}` }}>{addMarkerIcon()} {i18n.t('Add note')}</div>
                    <div onClick={this.toggleDragMode} className={classes.topBarItem} style={{ backgroundColor: `${this.state.dragLayersActive ? "#c2c0c0" : ""}`, display: `${this.state.restrictedView ? "none" : "flex"}` }}>{dragIcon()} {i18n.t('Move')}</div>
                    <div onClick={this.toggleRemovalMode} className={classes.topBarItem} style={{ backgroundColor: `${this.state.removeLayersActive ? "#c2c0c0" : ""}`, display: `${this.state.restrictedView ? "none" : "flex"}` }}>{deleteIcon()} {i18n.t('Delete')}</div>
                    <div onClick={this.resetView} className={classes.topBarItem} >{resetIcon()} {i18n.t('Reset view')}</div>
                    <div onClick={this.openZoom} className={classes.topBarItem} >{searchIcon()} {i18n.t('Search')}</div>

                </div>
                <Map style={{ position: 'relative', zIndex: '1', height: 'calc(100vh - 128px)' }} center={this.state.center} zoom={this.state.zoom} className={this.state.open ? classes.mapClosed : classes.mapOpen}
                    doubleClickZoom={false} minZoom={this.state.minZoom} maxZoom={18} ref={(c) => { this.map = c }}>
                    {this.state.osmLayerShown && <TileLayer zIndex={2000} attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />}
                    {this.state.googleLayerShown && <GoogleLayer zIndex={2000} googlekey={'AIzaSyBuZJYdqpIR_vERdvSmk0zNRrCQMBiMaz0'} maptype={'SATELLITE'} />}
                    {this.state.administrativeLayerData !== {} && this.state.selectedAdministrative !== [] &&
                        this.state.selectedAdministrative.map((item, i) => {
                            if (this.state.selectedAdministrative.includes(item)) {
                                let type = '';
                                if (this.state.selectedAdministrative[i] === "states") type = "OT_opstine";
                                else if (this.state.selectedAdministrative[i] === "localGovernments") type = "OT_katastarska_opstina";
                                else if (this.state.selectedAdministrative[i] === "localParcels") type = "GT_parcela";
                                if (this.state.geoserver_region !== '') {
                                    return <WMSTileLayer
                                        key={item}
                                        format={'image/png'}
                                        layers={`${this.state.geoserver_region}:${type}`}
                                        url={'map/geoserver'}
                                        tileSize={512}
                                        zIndex={2200}
                                        transparent={true} />
                                }
                            }
                        })
                    }
                    {this.state.transportLayerData !== {} && this.state.selectedTransport !== [] && user.id_region === 2 &&
                        this.state.selectedTransport.map((item, index) => {
                            if (this.state.selectedTransport.includes(item)) {
                                let layer = '';
                                if (this.state.selectedTransport[index] === ("port")) layer = "srbija:GT_luka";
                                else if (this.state.selectedTransport[index] === ("railroad")) layer = "srbija:gt_pruga_osm";
                                return <WMSTileLayer
                                    key={index}
                                    format={'image/png'}
                                    layers={layer}
                                    url={'map/geoserver'}
                                    tileSize={512}
                                    zIndex={2300}
                                    transparent={true} />
                            }
                        })
                    }
                    {this.state.sensors.length > 0 && this.state.sensors !== undefined && this.state.meteoShown === true && this.state.sensors.map(((item, i) => {
                        return <Marker key={i} position={[Number(item.y), Number(item.x)]} onClick={() => {
                            this.setState({ sensor_temp: null });
                            let data_path = '/data/optimized/' + item.uid + '/hourly/last/1h';
                            axios.post('/api/testMeteo/meteo', { path: data_path, method: "GET" })
                                .then(res => { this.setState({ sensor_temp: res.data.data.data["18_X_X_506"].aggr.avg[0] }); })
                                .catch(err => { console.log(err) })
                        }}>
                            <Popup className="popup-sensor">
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: '18px' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {item.naziv.substring(0, 15)}
                                            {item.naziv.length > 15 && '...'}
                                            {this.state.sensor_temp !== null &&
                                                <Typography style={{ fontSize: '18px', marginTop: '0px', marginBottom: '0px' }}> {this.state.sensor_temp}°C</Typography>
                                            }
                                        </div>
                                    </Typography>
                                    <Button className={classes.open_button} onClick={() => { this.handleClick(item); }}>{i18n.t('More')}</Button>
                                </div>
                            </Popup>
                        </Marker>
                    }))}
                    {this.state.satelliteLayerData !== {} && this.state.selectedSatellite !== null && this.state.date !== null &&
                        <WMSTileLayer
                            url={"https://services.sentinel-hub.com/ogc/wms/4a0d1b51-6ef0-4545-a0e7-d46d38cf3c3d"}
                            time={this.state.date}
                            format={"image/jpeg"}
                            maxcc={20}
                            zIndex={2100}
                            layers={this.state.satelliteLayerData}
                            showLogo={false}
                        />
                    }
                </Map>
                {this.state.spinner === true && <div style={{ left: '38%', top: '40%', zIndex: 20000, position: 'absolute' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                <React.Fragment>
                    <div className={this.state.open ? classes.toggleButtonOpen : classes.toggleButtonClose}
                        onClick={() => {
                            setTimeout(() => { this.map.leafletElement.invalidateSize() }, 400);
                            this.setState({ open: !this.state.open });
                        }} >
                        {this.state.open ? <ChevronRight /> : <ChevronLeft />}
                    </div>
                    {/* <div className={this.state.open ? classes.searchButtonOpen : classes.searchButtonClose}>
                        <IconButton onClick={() => {
                            this.setState({ zoomMenu: true })
                        }}>
                            <Search style={{ fontSize: 36 }} />
                        </IconButton>
                    </div>
                    <div className={this.state.open ? classes.seasonalForecastButtonOpen : classes.seasonalForecastButtonClose}>
                        <IconButton onClick={() => {
                            this.map.leafletElement.flyTo(this.state.center, this.state.zoom, { animate: true, duration: 0.5 })
                        }}>
                            <Restore style={{ fontSize: 36 }} />
                        </IconButton>
                    </div> */}
                    <Drawer
                        variant="permanent"
                        anchor="right"
                        className={classNames(classes.drawer, { [classes.drawerOpen]: this.state.open, [classes.drawerClose]: !this.state.open, })}
                        classes={{ paper: classNames({ [classes.drawerOpen]: this.state.open, [classes.drawerClose]: !this.state.open }), }}
                        open={this.state.open}>
                        <div className={classes.toolbar} />
                        <List style={{ minHeight: 'calc(100vh - 81px)' }}>
                            {/* {this.state.season && this.state.seasons &&
                                <Select style={{ marginLeft: '16px', minWidth: '90%' }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    isClearable={true}
                                    value={this.state.season}
                                    defaultValue={this.state.season}
                                    maxMenuHeight={200}
                                    onChange={(e) => {
                                        this.setState({ season: e }, () => {
                                            this.setState({ mounting: false }, () => {
                                                this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                                                this.loadFields();
                                            })
                                        })
                                    }}
                                    options={this.state.seasons.map((item) => { return { label: item.naziv, value: item.id } })}
                                    className="map-selects"
                                    classNamePrefix="select"
                                    placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select seasons')}
                                />} */}

                            <p style={{ fontSize: '16px', color: '#9b965a', fontWeight: 'bold', marginBottom: '20px', marginLeft: '16px' }}>{i18n.t('Show on map')}</p>

                            {(this.state.season.length > 0) && (this.state.seasons.length > 0) && <div style={{ display: 'flex', alignItems: 'flex-start', paddingLeft: '16px', paddingRight: '16px', borderBottom: '1px solid rgb(204, 204, 204)', paddingBottom: '15px' }}>
                                {calendarIcon()}
                                <label style={{ fontSize: '16px', marginLeft: '10px', marginRight: '10px' }} htmlFor="seasons">Sezona:</label>
                                <select
                                    className={classes.seasonDropDown}
                                    value={this.state.season.length > 1 ? "Sve" : (this.state.season[0].value && this.state.season[0].value)}
                                    onChange={(e) => {
                                        const selectedOption = e.target.options[e.target.selectedIndex];
                                        const selectedValue = selectedOption.value;
                                        const selectedText = selectedOption.text;

                                        if (selectedValue === "all") {

                                            this.setState({
                                                field_selected: [],
                                                season: this.state.seasons.map(season => {
                                                    return {
                                                        label: season.naziv,
                                                        value: season.id,
                                                    }
                                                })
                                            }, () => {
                                                this.setState({ mounting: false }, () => {
                                                    this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                                                    this.loadFields();
                                                })
                                            })

                                        } else {
                                            this.setState({
                                                field_selected: [],
                                                season: [{
                                                    label: selectedOption.text,
                                                    value: Number(selectedOption.value),
                                                }]
                                            }, () => {
                                                this.setState({ mounting: false }, () => {
                                                    this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                                                    this.loadFields();
                                                })
                                            })
                                        }


                                    }}
                                    name="seasons"
                                    id="seasons">
                                    <option value={"all"}>Sve</option>
                                    {this.state.seasons.map((season, i) => {
                                        if (season.id === this.state.currentSeason) {
                                            return <option key={i} value={season.id}>{season.naziv.slice(-4)}</option>
                                        }
                                        return;
                                    })
                                    }
                                    {this.state.seasons.map((season, i) => {
                                        if (season.id !== this.state.currentSeason) {
                                            return <option key={i} value={season.id}>{season.naziv.slice(-4)}</option>
                                        }
                                        return;
                                    })}
                                </select>
                            </div>
                            }

                            <ListItem button onClick={() => this.setState({ elementsOpen: !this.state.elementsOpen })} style={{ paddingTop: '16px' }}>
                                <ListItemText primary={i18n.t('Elements')} />
                                {this.state.elementsOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.elementsOpen} timeout="auto" unmountOnExit>
                                {this.state.season.length > 0 &&
                                    <div>
                                        <List component="div" disablePadding>
                                            <ListItem button onClick={() => this.handleFields()} className={classes.list_item}>
                                                <ListItemIcon onClick={() => this.handleFields()}>
                                                    <img style={{ width: '25px', height: '25px' }} src='./field-new2.png' />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.t('Fields')} className={classes.listItemText} />
                                                <Checkbox edge="end" onChange={() => this.handleFields()} checked={this.state.fieldsLayerShown} />
                                            </ListItem>
                                        </List>
                                    </div>
                                }
                                {this.state.field_list && this.state.fieldsLayerShown === true && this.state.season.length > 0 &&
                                    <Select style={{ marginLeft: '16px', minWidth: '90%', marginTop: '0px' }}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        isClearable={true}
                                        value={this.state.field_selected}
                                        defaultValue={this.state.field_selected}
                                        maxMenuHeight={200}
                                        onChange={(e) => {
                                            this.setState({ field_selected: e }, () => {
                                                if (e.length === 0) {
                                                    this.setState({ mounting: true }, () => {
                                                        this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                                                        this.loadFields();
                                                    })
                                                }
                                                else this.setState({ mounting: false }, () => {
                                                    this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                                                    this.loadFields();
                                                })
                                            })
                                        }}
                                        options={this.state.field_list.map((item) => { return { label: item.name, value: item.id } })}
                                        className="map-selects"
                                        classNamePrefix="select"
                                        placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select fields')}
                                    />}
                                <div>
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => this.handleNotes()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleNotes()}>
                                                <img style={{ width: '25px', height: '25px' }} src={'./note-icon-new2.png'} />
                                            </ListItemIcon>
                                            <ListItemText primary={i18n.t('Notes')} className={classes.listItemText} />
                                            <Checkbox edge="end" onChange={() => this.handleNotes()} checked={this.state.notesLayersShown} />
                                        </ListItem>
                                    </List>
                                </div>

                                {(user.id_region === 2 || user.id_region === 20 || user.id_region === 29 || user.id_region === 30) && this.state.sensors &&
                                    <div>
                                        <List component="div" disablePadding>
                                            <ListItem button onClick={() => this.handleMeteo()} className={classes.list_item}>
                                                <ListItemIcon onClick={() => this.handleMeteo()}>
                                                    <img style={{ width: '25px', height: '25px' }} src='./meteo-icon.png' />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.t('Meteo stations')} className={classes.listItemText} />
                                                <Checkbox edge="end" onChange={() => this.handleMeteo()} checked={this.state.meteoShown} />
                                            </ListItem>
                                            <div>
                                                {this.state.meteoShown === true && this.state.user.id_klijent === 32423 &&
                                                    <div style={{ width: '92%', marginLeft: '11px', display: 'flex' }}>
                                                        <div style={{ width: '92%' }}>
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                isClearable={false}
                                                                value={this.state.filter}
                                                                defaultValue={this.state.filter}
                                                                maxMenuHeight={200}
                                                                onChange={(e) => { this.setState({ filter: { label: e.label, value: e.value } }, () => { this.getSensorData(e.value); }); }}
                                                                options={[
                                                                    { value: 39, label: 'Subotica' },
                                                                    { value: 19, label: 'Sombor' },
                                                                    { value: 82, label: 'Inđija' },
                                                                    { value: 166, label: 'Vrbas' },
                                                                    { value: 22, label: 'Bačka Topola' }
                                                                ]}
                                                                placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select states')} />
                                                        </div>
                                                        <span className={classes.sensor_span} style={{ backgroundColor: `${this.state.clearStateColor}` }}
                                                            onMouseEnter={() => { this.setState({ clearStateColor: '#e3e3e3' }) }}
                                                            onMouseLeave={() => { this.setState({ clearStateColor: '#dbdbdb' }) }}
                                                            onMouseDown={() => { this.setState({ clearStateColor: '#f0f0f0' }) }}
                                                            onClick={() => { this.setState({ filter: null }, () => { this.getSensorData(null) }); }}
                                                        >X</span>
                                                    </div>
                                                }
                                                <div style={{ height: '10px' }} />
                                            </div>
                                        </List>
                                    </div>
                                }

                            </Collapse>

                            <div className={classes.menuGroupStyle} />

                            <div>
                                <ListItem button onClick={() => this.setState({ baseLayersOpen: !this.state.baseLayersOpen })} style={{ paddingTop: '16px' }}>
                                    <ListItemText primary={i18n.t('Base layers')} />
                                    {this.state.baseLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.baseLayersOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => this.handleBaseLayers()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleBaseLayers()}>
                                                <img style={{ width: '25px', height: '25px' }} src='./google-icon.png' />
                                            </ListItemIcon>
                                            <ListItemText primary='Google Map' className={classes.listItemText} />
                                            <Radio edge="end" onChange={() => this.handleBaseLayers()} checked={this.state.googleLayerShown} />
                                        </ListItem>
                                        <ListItem button onClick={() => this.handleBaseLayers()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleBaseLayers()}>
                                                <img style={{ width: '25px', height: '25px' }} src='./osm-icon.png' />
                                            </ListItemIcon>
                                            <ListItemText primary='Open Street Map' className={classes.listItemText} />
                                            <Radio edge="end" onChange={() => this.handleBaseLayers()} checked={this.state.osmLayerShown} />
                                        </ListItem>
                                    </List>
                                </Collapse>
                            </div>

                            {user.id_region !== 26 && <div>
                                <ListItem button onClick={() => this.handleAdministrativeGroup()}>
                                    <ListItemText primary={i18n.t('Administrative layers')} />
                                    {this.state.administrativeLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.administrativeLayersOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <div>
                                            <ListItem button onClick={() => this.handleAdministrative("states")} className={classes.list_item}>
                                                <ListItemIcon onClick={() => this.handleAdministrative("states")}>
                                                    <img style={{ width: '25px', height: '25px' }} src={`./state.png`} />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.t('States')} className={classes.listItemText} />
                                                <Checkbox edge="end" onChange={() => this.handleAdministrative("states")} checked={this.state.selectedAdministrative.includes("states")} />
                                            </ListItem>
                                            {user.id_region !== 30 &&
                                                <ListItem button onClick={() => this.handleAdministrative("localGovernments")} className={classes.list_item}>
                                                    <ListItemIcon onClick={() => this.handleAdministrative("localGovernments")}>
                                                        <img style={{ width: '25px', height: '25px' }} src={`./local-gov.png`} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={i18n.t('Local governments')} className={classes.listItemText} />
                                                    <Checkbox edge="end" onChange={() => this.handleAdministrative("localGovernments")} checked={this.state.selectedAdministrative.includes("localGovernments")} />
                                                </ListItem>
                                            }
                                            {(user.id_klijent === this.ID_CLIENT_VIVAGRO || user.id_klijent === this.ID_CLIENT_SKUGRICANKA || user.id_klijent === this.ID_CLIENT_MILENKOOSTOJIC) &&
                                                <ListItem button onClick={() => this.handleAdministrative("localParcels")} className={classes.list_item}>
                                                    <ListItemIcon onClick={() => this.handleAdministrative("localParcels")}>
                                                        <img style={{ width: '25px', height: '25px' }} src={`./local-gov.png`} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={i18n.t('Local parcels')} className={classes.listItemText} />
                                                    <Checkbox edge="end" onChange={() => this.handleAdministrative("localParcels")} checked={this.state.selectedAdministrative.includes("localParcels")} />
                                                </ListItem>
                                            }
                                        </div>
                                    </List>
                                </Collapse>
                            </div>}

                            {user.id_region === 2 &&
                                <div>
                                    <ListItem button onClick={() => this.handleTransportGroup()}>
                                        <ListItemText primary={i18n.t('Transport layers')} />
                                        {this.state.transportLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={this.state.transportLayersOpen} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem button onClick={() => this.handleTransports("railroad")} className={classes.list_item}>
                                                <ListItemIcon onClick={() => this.handleTransports("railroad")}>
                                                    <img style={{ width: '25px', height: '25px' }} src={'./railroad-icon.png'} />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.t('Railroads')} className={classes.listItemText} />
                                                <Checkbox edge="end" onChange={() => this.handleTransports("railroad")} checked={this.state.selectedTransport.includes("railroad")} />
                                            </ListItem>
                                            <ListItem button onClick={() => this.handleTransports("port")} className={classes.list_item}>
                                                <ListItemIcon onClick={() => this.handleTransports("port")}>
                                                    <img style={{ width: '25px', height: '25px' }} src={'./port-icon.png'} />
                                                </ListItemIcon>
                                                <ListItemText primary={i18n.t('Ports')} className={classes.listItemText} />
                                                <Checkbox edge="end" onChange={() => this.handleTransports("port")} checked={this.state.selectedTransport.includes("port")} />
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </div>
                            }
                            <div className={classes.menuGroupStyle} />

                            <div>
                                <ListItem button onClick={() => this.setState({ satteliteLayersOpen: !this.state.satteliteLayersOpen })} style={{ paddingTop: '16px' }}>
                                    <ListItemText primary={i18n.t('Satellite Imagery')} />
                                    {this.state.satteliteLayersOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.satteliteLayersOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {this.state.satteliteLayers.map((key, index) => {
                                            return <div key={key}>
                                                <ListItem button onClick={() => this.handleSatellites(key, index)} className={classes.list_item}>
                                                    <ListItemIcon onClick={() => this.handleSatellites(key, index)}>
                                                        <img style={{ width: '25px', height: '25px', borderRadius: '5px' }} src={`./satellite/${key}.png`} />
                                                    </ListItemIcon>
                                                    <ListItemText style={{ lineHeight: '1px' }} primary={this.state.satelliteLayersNames[index]} className={classes.listItemText} />
                                                    <Radio edge="end" onChange={() => this.handleSatellites(key, index)} checked={this.state.selectedSatellite === key} />
                                                </ListItem>
                                                <Collapse disablePadding in={this.state.selectedSatellite === key}>
                                                    <List component="div" disablePadding style={{ marginRight: '10px' }}>
                                                        <div style={{ display: 'flex', marginTop: '10px', justifyContent: 'right' }}>
                                                            <DatePicker date={this.state.date} onDateChange={this.handleDateChange} />
                                                            <span className="icon-info layerIcon" style={{ cursor: 'pointer', marginLeft: '20px', marginRight: '7px' }} onClick={() => {
                                                                this.setState({ satteliteDialog: true });
                                                            }}></span>
                                                        </div>
                                                    </List>
                                                </Collapse>
                                            </div>
                                        })}
                                    </List>
                                </Collapse>
                            </div>

                            <div>

                            </div>


                            {/* {(user.id_klijent === 30539 || user.id_klijent === 31254) && <div> */}
                            <div>
                                <ListItem button onClick={() => this.handleGps()}>
                                    <ListItemText primary={i18n.t('GPS')} />
                                    {this.state.gpsLayerOpen ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.state.gpsLayerOpen} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <ListItem button onClick={() => this.handleGpsDeviceShown()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleGpsDeviceShown()}>
                                                <img style={{ width: '25px', height: '25px' }} src={`./gps-device.png`} />
                                            </ListItemIcon>
                                            <ListItemText primary={i18n.t('Last location')} className={classes.listItemText} />
                                            <Checkbox edge="end" onChange={() => this.handleGpsDeviceShown()} checked={this.state.gpsDeviceShown} />
                                        </ListItem>
                                        {this.state.gpsDeviceShown &&
                                            <MySelect style={{ marginLeft: '16px', minWidth: '90%' }}
                                                isMulti
                                                value={this.state.selectedGpsDevice}
                                                SelectProps={{ native: true }}
                                                maxMenuHeight={200}
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                allowSelectAll={true}
                                                onChange={(e) => { this.handleGpsDeviceChange(e) }}
                                                options={this.state.gps_devices.map((item) => { return { label: item.naziv, value: item.id } })}
                                                className="map-selects"
                                                classNamePrefix="select"
                                                placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select device')}
                                            />
                                        }
                                        <ListItem button onClick={() => this.handleGpsRoutes()} className={classes.list_item}>
                                            <ListItemIcon onClick={() => this.handleGpsRoutes()}>
                                                <img style={{ width: '25px', height: '25px' }} src={`./polyline.png`} />
                                            </ListItemIcon>
                                            <ListItemText primary={i18n.t('GPS Routes')} className={classes.listItemText} />
                                            <Checkbox edge="end" onChange={() => this.handleGpsRoutes()} checked={this.state.gpsRoutesShown} />
                                        </ListItem>
                                        {this.state.gpsRoutesShown &&
                                            <Select style={{ marginLeft: '16px', minWidth: '90%' }}
                                                value={this.state.selectedGpsDeviceRoute}
                                                SelectProps={{ native: true }}
                                                maxMenuHeight={200}
                                                isClearable={true}
                                                onChange={(e) => { this.handleGpsDeviceChangeRoutes(e) }}
                                                options={this.state.gps_devices.map((item) => { return { label: item.naziv, value: item.id } })}
                                                className="map-selects"
                                                classNamePrefix="select"
                                                placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select device')}
                                            />
                                        }

                                        {this.state.gpsRoutesShown && Object.keys(this.state.selectedGpsDeviceRoute).length > 0 && this.state.gps_devices.length > 0 &&
                                            <input type="date" style={{ marginLeft: '11px', marginTop: '3%', marginBottom: '3%', border: '1px solid #cccccc', width: '84%', height: '36px', borderRadius: '4px', paddingLeft: '10px', paddingRight: '10px' }} max={this.state.today} id="gpsDate" name="gpsDate" value={this.state.gps_date} onChange={this.handleGpsDateChange} />
                                        }
                                        {this.state.gpsRoutesShown && Object.keys(this.state.selectedGpsDeviceRoute).length > 0 && this.state.gps_date && this.state.session_data.length > 0 &&
                                            <MySelect style={{ marginLeft: '16px', minWidth: '90%' }}
                                                isMulti
                                                value={this.state.selectedSession}
                                                SelectProps={{ native: true }}
                                                maxMenuHeight={200}
                                                isClearable={true}
                                                closeMenuOnSelect={false}
                                                onChange={(e) => { this.handleSessionChange(e) }}
                                                options={this.state.gpsSessionDropdown.map((item) => { return { label: item.label, value: item.value } })}
                                                className="map-selects"
                                                classNamePrefix="select"
                                                allowSelectAll={true}
                                                placeholder={`‏‏‎ ‎ ‏‏‎ ‎` + i18n.t('Select session')}
                                            />
                                        }
                                        {this.state.gpsRoutesShown && Object.keys(this.state.selectedGpsDeviceRoute).length > 0 && this.state.gps_date && this.state.session_data.length > 0 &&
                                            <Button style={{ marginTop: '3%', marginBottom: '3%', marginLeft: '11px', width: '92%', padding: '6px 8px', color: 'white', backgroundColor: '#4da7df' }} onClick={this.handleSessionInfo}>{i18n.t('Session info')}</Button>
                                        }
                                        <div style={{ height: '10px' }} />
                                    </List>
                                </Collapse>
                            </div>
                            {/* } */}
                        </List>
                    </Drawer>
                </React.Fragment>
                {
                    this.state.add &&
                    <AddField
                        edit={false}
                        add={this.state.add}
                        povrsina={this.state.newFeature.attributes.povrsina}
                        seasons={this.state.season}
                        handleClose={() => {
                            this.setState({ add: false, newFeature: { ...this.EMPTY_FEATURE } });
                            this.map.leafletElement.removeLayer(this.drawnFeature);

                            this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                            this.loadFields();
                            this.setState({ drawPoligonActive: false });
                        }}
                        onSave={this.saveField}
                        onUpdateGeom={this.updateGeom}
                    />
                }
                {
                    this.state.addNote &&
                    <AddNote
                        edit={false}
                        add={this.state.addNote}
                        x = {this.state.note_x}
                        y = {this.state.note_y}
                        handleClose={() => {
                            this.setState({ addNote: false, note_x: null, note_y: null });
                            this.map.leafletElement.removeLayer(this.drawnFeature);

                            this.map.leafletElement.eachLayer(layer => { if (layer._path !== undefined) { layer.remove(); } });
                            this.loadNotes();
                        }}
                    />
                }
                {this.state.openSensorDialog && <SensorDialog open={this.state.openSensorDialog} handleClick={this.handleClick} row={this.state.row} />}
                {this.state.satteliteDialog && <SatelliteInfo open={this.state.satteliteDialog} handleClick={this.handleClickSatellite} row={this.state.row} selectedSatellite={this.state.selectedSatellite} />}
                {this.state.session_info && <SessionInfo open={this.state.session_info} handleClick={this.handleSessionInfo} session_history={this.state.session_history} gps_date={this.state.gps_date} />}
                {this.state.deleteField && <Delete delete={this.state.deleteField} handleClose={this.handleCloseDeleteField} deleteItem={this.handleDeleteField} />}
                <Dialog
                    open={this.state.zoomMenu}
                    onClose={() => this.handleCloseZoomMenu()}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle style={{ backgroundColor: '#04764e', color: '#FFFFFF' }} id="simple-dialog-title">
                        <div style={{ display: 'flex' }}>
                            <p style={{ marginLeft: '20px', color: '#FFFFFF', marginTop: '0px', marginBottom: '0px' }}>{i18n.t('Zoom')}</p>
                            <IconButton style={{ margin: '0px', marginLeft: '81%', padding: '0px' }} onClick={() => this.handleCloseZoomMenu()}>
                                <span className="icon-cancel-circle closeIcon" />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <table>
                        <tr style={{ textAlign: '-webkit-center' }}>
                            <td>
                                <TextField type="number"
                                    inputProps={{ className: 'digitsOnly' }}
                                    floatingLabelText={i18n.t('Latitude')}
                                    value={this.state.zoom_latitude}
                                    style={{ margin: '10px' }}
                                    onChange={e => this.setState({ zoom_latitude: e.target.value })}
                                    helperText={i18n.t('Latitude')}
                                    id={i18n.t('Latitude')} name={i18n.t('Latitude')} />
                            </td>
                            <td>
                                <TextField type="number"
                                    inputProps={{ className: 'digitsOnly' }}
                                    floatingLabelText={i18n.t('Longitude')}
                                    value={this.state.zoom_longitude}
                                    style={{ margin: '10px' }}
                                    helperText={i18n.t('Longitude')}
                                    onChange={e => this.setState({ zoom_longitude: e.target.value })}
                                    id={i18n.t('Longitude')} name={i18n.t('Longitude')} />
                            </td>
                            <td>
                                <Button onClick={() => {
                                    if (this.state.zoom_latitude && this.state.zoom_longitude) {
                                        this.map.leafletElement.flyTo([this.state.zoom_latitude, this.state.zoom_longitude], this.state.zoom, { animate: true, duration: 0.5 });
                                    }
                                }}>{i18n.t('Zoom')}</Button>
                            </td>
                        </tr>
                    </table>
                </Dialog >
            </div >
        )
    }
};

export default withStyles(styles)(Maps);


const polygoneIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="#333333"
    >
        <path d="M19.4206892,9.16509725 C19.1523681,8.66992914 19,8.10275831 19,7.5 C19,5.56700338 20.5670034,4 22.5,4 C24.4329966,4 26,5.56700338 26,7.5 C26,9.26323595 24.6961471,10.7219407 23,10.9645556 L23,19.0354444 C24.6961471,19.2780593 26,20.736764 26,22.5 C26,24.4329966 24.4329966,26 22.5,26 C20.736764,26 19.2780593,24.6961471 19.0354444,23 L10.9645556,23 C10.7219407,24.6961471 9.26323595,26 7.5,26 C5.56700338,26 4,24.4329966 4,22.5 C4,20.5670034 5.56700338,19 7.5,19 C8.10275831,19 8.66992914,19.1523681 9.16509725,19.4206892 L19.4206892,9.16509725 Z M20.8349073,10.5793063 L10.5793108,20.8349027 C10.6086731,20.8890888 10.6366469,20.9441372 10.6631844,21 L19.3368156,21 C19.6825775,20.272154 20.272154,19.6825775 21,19.3368156 L21,10.6631844 C20.9441372,10.6366469 20.8890888,10.6086731 20.8349027,10.5793108 Z M22.5,9 C23.3284271,9 24,8.32842712 24,7.5 C24,6.67157288 23.3284271,6 22.5,6 C21.6715729,6 21,6.67157288 21,7.5 C21,8.32842712 21.6715729,9 22.5,9 Z M22.5,24 C23.3284271,24 24,23.3284271 24,22.5 C24,21.6715729 23.3284271,21 22.5,21 C21.6715729,21 21,21.6715729 21,22.5 C21,23.3284271 21.6715729,24 22.5,24 Z M7.5,24 C8.32842712,24 9,23.3284271 9,22.5 C9,21.6715729 8.32842712,21 7.5,21 C6.67157288,21 6,21.6715729 6,22.5 C6,23.3284271 6.67157288,24 7.5,24 Z" />
    </svg>
)

const dragIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 24 24">
        <defs>
            <path id="move-a" d="M21,14 L21,10 L27,15 L21,20 L21,16 L16,16 L16,21 L20,21 L15,27 L10,21 L14,21 L14,16 L9,16 L9,20 L3,15 L9,10 L9,14 L14,14 L14,9 L10,9 L15,3 L20,9 L16,9 L16,14 L21,14 Z" />
        </defs>
        <g fill="none" fillRule="evenodd" transform="translate(-3 -3)">
            <mask id="move-b" fill="#fff">
                <use xlinkHref="#move-a" />
            </mask>
            <use fill="#333333" xlinkHref="#move-a" />
            <g fill="#333333" mask="url(#move-b)">
                <rect width="30" height="30" />
            </g>
        </g>
    </svg>
)

const editIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="#5B5B5B"
    >
        <path d="M13.5,11 C11.5670034,11 10,9.43299662 10,7.5 C10,5.56700338 11.5670034,4 13.5,4 C15.4329966,4 17,5.56700338 17,7.5 C17,9.43299662 15.4329966,11 13.5,11 Z M13.5,9 C14.3284271,9 15,8.32842712 15,7.5 C15,6.67157288 14.3284271,6 13.5,6 C12.6715729,6 12,6.67157288 12,7.5 C12,8.32842712 12.6715729,9 13.5,9 Z M12.0002889,7.52973893 C12.0125983,8.16273672 12.4170197,8.6996643 12.9807111,8.90767966 L3,15 L3,13 L12.0002889,7.52973893 Z M14.2172722,6.18228472 L19.453125,3 L22.6589355,3 L14.989102,7.68173885 C14.9962971,7.62216459 15,7.56151472 15,7.5 C15,6.93138381 14.6836098,6.4366645 14.2172722,6.18228472 Z M23.4434042,19.2851736 L20.1282799,19.2851736 L21.8729983,23.5349525 C21.9945296,23.8295773 21.8556546,24.1599209 21.5778734,24.2849208 L20.0414675,24.9545142 C19.7550613,25.0795141 19.4338738,24.9366704 19.3123426,24.6509518 L17.6544367,20.6154541 L14.9461873,23.4010151 C14.5852811,23.7721711 14,23.4860463 14,22.9992653 L14,9.57183533 C14,9.05933561 14.6225311,8.809492 14,9.17008555 L23.8340292,18.3120179 C24.1925291,18.6613615 23.9279979,19.2851736 23.4434042,19.2851736 Z" />
    </svg>
)

const addMarkerIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7" />
        <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z" />
    </svg>
)

const deleteIcon = () => (
    <svg width="25" height="25" viewBox="0 0 24 24" fill="none">
        <path d="M6 5H18M9 5V5C10.5769 3.16026 13.4231 3.16026 15 5V5M9 20H15C16.1046 20 17 19.1046 17 18V9C17 8.44772 16.5523 8 16 8H8C7.44772 8 7 8.44772 7 9V18C7 19.1046 7.89543 20 9 20Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

const resetIcon = () => (
    <svg width={25} height={25} focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation" ><path fill="none" d="M0 0h24v24H0z"></path><path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"></path></svg>
)

const searchIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none">
        <path d="M14.9536 14.9458L21 21M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)

const calendarIcon = () => (
    <svg
        className="MuiSvgIcon-root-185"
        focusable="false"
        viewBox="0 0 25 25"
        aria-hidden="true"
        role="presentation"
        width="25"
        height="25"
    >
        <path fill="none" d="M0 0h24v24H0V0z" />
        <path fill="rgba(0, 0, 0, 0.54)" d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm4-7h-3V2h-2v2H8V2H6v2H3v18h18V4zm-2 16H5V9h14v11z" />
    </svg>
)