import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import ViewWorkingOperation from './ViewWorkingOperation'
import { deleteWorkingOperation, setInitialState } from '../../../actions/resources/WorkingOperationsActions';

export class WorkingOperationMainComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            add: false,
            showFilters: false,
            selectedIndex: 0,
            selection: [],
            name: '',
            edit: false,
        };
    }

    handleClick = () => {
        if (this.state.edit == true && this.state.add == false) {
            this.setState({ edit: false })
        }
        this.setState({ add: !this.state.add }, () => {
            if (this.state.add == true && this.state.edit == false) {
                this.setState({
                    selection: [],
                    name: '',
                })
            };
        });
    };

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    handleSelection = (selection, name) => {
        this.setState({
            selection: selection,
            name: name
        })
    }

    onDeleteClick = () => {
        this.setState({ delete: !this.state.delete });
    }

    deleteItem = () => {
        this.props.deleteWorkingOperation(this.state.selection, this.state.name);
        this.setState({ delete: false })
    }



    onEditClick() {
        this.setState({ add: !this.state.add, edit: true });
    }

    render() {
        let content;
        const { anchorEl } = this.state;
        content =
            <div className="table-header">
                <Button
                    onClick={() => this.onSearchClick()}
                    variant="fab"
                    color="secondary"
                    aria-label="add"
                    className="action-button"
                >
                    <span className="icon-search headerIcon" />
                </Button>
            </div >
        return (
            <div className="page">
                {content}
                {this.state.selectedIndex === 0 && <ViewWorkingOperation setSelection={this.handleSelection} edit={this.state.edit} add={this.state.add} showFilters={this.state.showFilters}
                    handleClose={this.handleClick} />}
            </div>
        )
    }


};

function mapStateToProps(state) {
    return {
        deleteOperationFailed: state.workingOperationReducer.deleteOperationFailed,
        deleted: state.workingOperationReducer.deleted,
        workingOperationGroupsPostingSuccess: state.workingOperationReducer.workingOperationGroupsPostingSuccess,
        workingOperationPostingSuccess: state.workingOperationReducer.workingOperationPostingSuccess
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteWorkingOperation: (selection, name) => dispatch(deleteWorkingOperation(selection, name)),
        setInitialState: (component) => dispatch(setInitialState(component)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkingOperationMainComponent)