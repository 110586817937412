import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import { Tabs, Tab } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    tabs: {
        backgroundColor: theme.palette.primary.main,
    },
});

export class GlobalDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            global: true,
            value: 0,
        };
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { value } = this.state;
        const { classes } = this.props;
        return (
            <div>
                <Tabs className="tabContainer" value={value} onChange={this.handleChange}>
                    <Tab className={value === 0 ? classes.tabs : ""} label={i18n.t("Subsidies")} />
                    <Tab className={value === 1 ? classes.tabs : ""} label={i18n.t("Incentives")} />
                    <Tab className={value === 2 ? classes.tabs : ""} label={i18n.t("Financial programs")} />
                </Tabs>
            </div >
        )
    }
};

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(GlobalDashboard));
