import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText, FormGroup } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

export default class CreateWorkingOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            groups: [],
            naziv: "",
            indikator_prinosa: null,
            selectedGroup: [],
        };
    };






    componentDidMount() {


        console.log("this.props.data : ", this.props.data);

        axios.get('api/working_operation_group/drop_down').then(res => {
            this.setState({ groups: res.data.data }, () => {
                if (this.props.edit === true) {
                    this.setState({
                        naziv: this.props.data.naziv,
                        indikator_prinosa: this.props.data.indikator_prinosa,
                        selectedGroup: [{ label: this.props.data.radna_operacija_grupa_naziv, value: this.props.data.id_radna_operacija_grupa }],
                    })
                }
            })

        }).catch(err => {
            wentWrong('Something went wrong, please try again!');
            console.log("ERROR DROPDOWN : ", err)
        })

    };




    addWorkingOperation = () => {
        // api / working_operation / create zahteva id, naziv, id_radna_operacija grupa

        if (this.state.selectedGroup.length > 0 && this.state.naziv) {
            axios.post('api/working_operation/create', {
                id_radna_operacija_grupa: this.state.selectedGroup[0].value,
                naziv: this.state.naziv,
                indikator_prinosa: this.state.indikator_prinosa

            })
                .then(res => {
                    console.log("RESPONSE ZA CREATE : ", res)
                    let id = res.data.data.id;

                    axios.post('api/working_operation/addFavorite', { id_radna_operacija: id })
                        .then(res => {
                            wentRight(i18n.t('You successfully added a working operation'));
                            this.props.reload();
                            this.props.close();
                        })
                        .catch((err) => { console.log("FAVORITE ERROR : ", err) })



                }).catch(err => {
                    wentWrong('Something went wrong, please try again!');
                    console.log("ERROR DROPDOWN : ", err)
                })
        } else {
            wentWrong(i18n.t('Please enter all required fields!'))
        }
    }

    editWorkingOperation = () => {
        // api / working_operation / create zahteva id, naziv, id_radna_operacija grupa

        if (this.state.selectedGroup[0].value && this.state.naziv) {
            axios.post('api/working_operation/create', {
                id: this.props.data.id,
                id_radna_operacija_grupa: this.state.selectedGroup[0].value,
                naziv: this.state.naziv,
                indikator_prinosa: this.state.indikator_prinosa

            })
                .then(res => {
                    console.log("RESPONSE ZA EDIT : ", res)
                    wentRight(i18n.t('You successfully edited a working operation'));
                    this.props.reload();
                    this.props.close();

                }).catch(err => {
                    wentWrong('Something went wrong, please try again!');
                    console.log("ERROR DROPDOWN : ", err)
                })
        } else {
            wentWrong(i18n.t('Fill in name and working operation group'))
        }
    }



    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }



    closeEditDialog = () => {
        this.props.handleClose()
    }
    handleCloseAlert = () => {
        this.setState({ alert: !this.state.alert });
        this.props.handleCloseArchived();
    }




    render() {

        return (
            <div style={{ padding: 20, display: 'flex', flexDirection: 'column' }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    PaperProps={{
                        style: {
                            minHeight: '500px',
                            display: 'flex',
                            flexDirection: 'column'
                        }
                    }}
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">

                    <DialogTitle disableTypography={true} style={{ background: '#04764e', display: 'flex' }} className="responsive-dialog-title">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{}}>{this.props.edit ? i18n.t('Edit Working Operation') : i18n.t('Add Working Operation')}</div>
                        </div>


                        <IconButton onClick={this.props.close}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>


                    </DialogTitle>

                    <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '532px', marginLeft: '10px', display: 'inline-block', marginTop: '30px' }}>
                            <label style={{ fontSize: '12px', color: '#0000008a', opacity: `${this.state.selectedGroup.length > 0 ? 1 : 0}` }} htmlFor="">{i18n.t('Working operation group')} *</label>
                            <Select
                                isMulti={false}
                                closeMenuOnSelect={true}
                                isDisabled={false}
                                value={this.state.selectedGroup}
                                maxMenuHeight={200}
                                // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, ClearIndicator: () => null }}
                                onChange={(e) => {
                                    this.setState({ selectedGroup: [e] });
                                }}
                                options={this.state.groups.map(group => { return { label: group.naziv, value: group.id } })}
                                placeholder={i18n.t('Working operation group') + "*"}
                            // label={i18n.t('Working operation group')}
                            />


                        </div>

                        <TextField required
                            label={i18n.t('Title')}
                            value={this.state.naziv}
                            style={{ width: '532px', marginBottom: '10px', marginTop: '10px', marginLeft: '10px' }}
                            InputLabelProps={{ shrink: this.state.naziv ? true : false }}
                            onChange={(e) => { this.setState({ naziv: e.target.value }); }} >
                        </TextField>


                        <FormGroup style={{ marginLeft: '10px', marginTop: '20px' }}>
                            <FormControlLabel control={
                                <Checkbox
                                    checked={this.state.indikator_prinosa === null ? false : true}
                                    onChange={(e) => {
                                        if (e.target.checked === false) {
                                            this.setState({ indikator_prinosa: null });
                                        } else { this.setState({ indikator_prinosa: e.target.checked }); }
                                    }}></Checkbox>
                            }
                                label={i18n.t('Yield indicator')}
                                style={{ width: '256px', marginTop: '10px', marginBottom: '5px', }}></FormControlLabel>
                        </FormGroup>

                        <div style={{ display: 'flex', marginTop: 'auto' }}>
                            <Button style={{ marginLeft: '10px', marginTop: '20px' }} variant="contained" color="primary"
                                onClick={() => {
                                    this.props.edit === true ? this.editWorkingOperation() : this.addWorkingOperation();
                                }}
                            >
                                {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                            </Button>




                        </div>


                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};