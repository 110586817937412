import i18n from './../i18n/i18n';

export const Degrees = (deg) => {
    let string = '';
    if (deg > 348.75 || deg < 11.25) string = i18n.t('N');
    else if (deg > 11.25 && deg < 33.75) string = i18n.t('NNE');
    else if (deg > 33.75 && deg < 56.25) string = i18n.t('NE');
    else if (deg > 56.25 && deg < 78.75) string = i18n.t('ENE');
    else if (deg > 78.75 && deg < 101.25) string = i18n.t('E');
    else if (deg > 101.25 && deg < 123.75) string = i18n.t('ESE');
    else if (deg > 123.75 && deg < 146.25) string = i18n.t('SE');
    else if (deg > 146.25 && deg < 168.75) string = i18n.t('SSE');
    else if (deg > 168.75 && deg < 191.25) string = i18n.t('S');
    else if (deg > 191.25 && deg < 213.75) string = i18n.t('SSW');
    else if (deg > 213.75 && deg < 236.25) string = i18n.t('SW');
    else if (deg > 236.25 && deg < 258.75) string = i18n.t('WSW');
    else if (deg > 258.75 && deg < 281.25) string = i18n.t('W');
    else if (deg > 281.25 && deg < 303.75) string = i18n.t('WNW');
    else if (deg > 303.75 && deg < 326.75) string = i18n.t('NW');
    else if (deg > 326.75 && deg < 348.75) string = i18n.t('NNW');
    return string;
}