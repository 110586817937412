import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import axios from '../../../utils/AxiosWrapper';
import { wentWrong } from '../../../utils/ToastConfig';
import Select from 'react-select';
import i18n from '../../../i18n/i18n';
import immutable from 'seamless-immutable';
import AddMaterial from './AddMaterial';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import ForBooleanFilter from '../../../utils/ForBooleanFilter';
import { withStyles } from '@material-ui/core/styles';
import {
    IconButton, Tooltip,
    Button, DialogActions,
    Dialog, DialogContent, DialogTitle, Grid
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import * as Icons from '@material-ui/icons';
import { Delete } from "../../../utils/Delete";
import { getUser } from '../../../utils/CommonFunctions';
import SearchBar from 'material-ui-search-bar';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip as Tooltip2 } from '@material-ui/core';
import {  PulseLoader } from 'react-spinners';

const CheckboxTable = checkboxHOC(ReactTable);
const commonHeaderStyle = {
    textAlign: 'center',
    textTransform: 'uppercase',
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: '#04764e',
    color: "#ffff",

};
const styles = theme => ({
    searchWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '20px 30px 10px 30px',
    },
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
    center: {
        textAlign: 'center'
    },
    starButton: {
        padding: '3px',
        // color: '#f2ce3e'
    },
    addNewBtn: {
        width: 'fit-content',
        height: 40,
        padding: '0 15px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 0,
        backgroundColor: '#5EB3E4',
        '&:hover': {
            backgroundColor: '#4FA2CD',
        },
    },
    paginationButton: {
        borderRadius: '50%',
        width: '30px',
        height: '30px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5EB3E4',
        color: 'white',
        border: 'none',
        cursor: 'pointer'
    },
    tableCell:{
        textAlign: 'center',
        paddingLeft: '10px',
        height: '50px',
        alignItems: 'center',
        overflowY: 'hidden',
        overflowX: 'hidden'
    },
    dropDowns: {
        backgroundColor: '#F4F6F7',
        color: '#333333',
        fontSize: '14px',
        border: 'none',
        borderBottom: '1px solid #333333',
        marginRight: '20px',
        cursor: 'pointer',
        '&:focus': {
            outline: 'none',
        },
    },
    filtersSection: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
        paddingLeft: '30px',
        paddingTop: '18px',
    },
    resetButton:{
        height: '25px',
        border: '2px solid #9b9658',
        borderRadius: '5px',
        cursor: 'pointer',
    }
});
export class ViewMaterials extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //add: this.props.add,
            //edit: this.props.edit,
            // showFilters: this.props.showFilters,
            selection: [],
            selectAll: false,
            material: [],
            deleteFavorite: false,
            user: {},
            cena: null,
            //-----------
            deleteMaterialPopUp: false,
            deleteMaterialId: -1,
            deleteMaterialName: "",
            searchBarValue: "",
            data: [],
            totalPages: 0,
            page: 0,
            pageSize: 10,
            sorted: null, 
            filtered: [],
            load: true,
            allGroups: [],
            selectedGroup: "",
            allSubGroups: [],
            selectedSubGroup: "",
            allUnits: [],
            selectedUnit: "",
            showAddMaterial: false,
            showEditMaterial: false,
            materialObjEdit: null,
            id_klijent_vlasnik: -1,
            usesOwnMaterials: null,
            cena_ako_ne_koristi_svoje_materijale: 0
        };
    }

    async componentDidMount() {
        await axios.get('api/korisnik/check_if_uses_own_materijals',)
            .then(res => { 
                this.setState({
                    user: getUser(),
                    usesOwnMaterials: res.data.usesOwnMaterials
                })
                console.log("owns materials", res.data.usesOwnMaterials);
                this.fetchAllMaterialGroups(res.data.usesOwnMaterials);
                this.fetchAllMaterialSubGroups(res.data.usesOwnMaterials);
                this.fetchAllMaterialUnits(res.data.usesOwnMaterials);
            })
            .catch(err => { console.log(err) })
       
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedUnit !== this.state.selectedUnit || 
            prevState.selectedGroup !== this.state.selectedGroup ||
            prevState.selectedSubGroup !== this.state.selectedSubGroup ||
            prevState.searchBarValue !== this.state.searchBarValue
        ) {
            this.state.load = true;
            this.reactTable.fireFetchData();
        }
    }

    getData(state) {
        let filtered = [...this.state.filtered];

        this.setState({                 
            load: true
        });

        if (this.state.searchBarValue) {
            filtered.push({ id: 'naziv', value: this.state.searchBarValue });
        }
        if (this.state.selectedUnit) {
            filtered.push({ id: 'unit', value: this.state.selectedUnit });
        }

        if (this.state.selectedGroup) {
            filtered.push({ id: 'id_materijal_grupa', value: this.state.selectedGroup });
        }

        if (this.state.selectedSubGroup) {
            filtered.push({ id: 'id_materijal_podgrupa', value: this.state.selectedSubGroup });
        }

        this.setState({ data: [] });
        axios.get(`api/material/read`, {
            params: { page: state.page, pageSize: state.pageSize, sorted: state.sorted, filtered: filtered }
        })
            .then(res => {
                if(res.data.data.length> 0){
                    this.setState({
                        id_klijent_vlasnik: res.data.data[0].id_klijent_vlasnik,
                    })
                }
                this.setState({
                    data: res.data.data,
                    totalPages: res.data.total_pages,                  
                    load: false
                }
            );
            })
            .catch(error => {
                wentWrong(i18n.t('Something went wrong, please try again!'));
                console.log('Error 1 (getData): ', error);
            })
    }
    fetchAllMaterialGroups(usesOwnMaterials) {
        axios.get(`api/material_group/drop_down_for_materials`,{params: {usesOwnMaterials: usesOwnMaterials}})
        .then(res => {
            const groups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allGroups: groups,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    fetchAllMaterialSubGroups(usesOwnMaterials) {
        axios.get(`api/material_subgroup/drop_down_for_materials`,{params: {usesOwnMaterials: usesOwnMaterials}})
        .then(res => {
            const sub_groups = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allSubGroups: sub_groups,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    fetchAllMaterialUnits(usesOwnMaterials) {
        axios.get(`api/unit/drop_down_for_materials`,{params: {usesOwnMaterials: usesOwnMaterials}})
        .then(res => {
            const units = res.data.data
                .map(item => ({ id: item.id, value: item.naziv }));
            this.setState({ allUnits: units,
             });
        })
            .catch(() => wentWrong(i18n.t('Something went wrong')))
    }

    addMaterialToFavorite(id_materijal,cena) {
            console.log("cena", cena);
            axios.post(`api/material/addFavorite`, { id_materijal,cena })
                .then(res => {
                    console.log("Successfully added to favorites.");
                    this.reactTable.fireFetchData();
                })
                .catch(() => wentWrong(i18n.t('Something went wrong')));
        
    }

    deleteMaterialFavorite(id_materijal) {

        axios.post(`api/material/deleteFavorite`, { id_materijal })
            .then(res => {
                console.log("Successfully removed from favorites.");
            })
            .catch(() => wentWrong(i18n.t('Something went wrong')));
    }

    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    onDeleteClick = (materijalRow) => {
        this.setState({ deleteFavorite: !this.state.deleteFavorite, materijalRow });
    }

    deleteItem = () => {
        const { materijalRow } = this.state;
        this.deleteMaterialFavorite(materijalRow.original.id);
        const material = [...this.state.material];
        let materialObj = Object.assign({}, material[materijalRow.index]);
        materialObj[materijalRow.column.id] = !materijalRow.value;
        material[materijalRow.index] = materialObj;
        this.setState({ deleteFavorite: false, material });
        this.reactTable.fireFetchData();
    }

    handlePopUpFavorite = (row) => {
        if (row.value) {
            this.onDeleteClick(row);
        } else {
            this.setState({
                row
            });
            if(!this.state.usesOwnMaterials){
                this.setState({
                    cena: null
                });
            }
            else{
                this.setState({
                    cena: row.original.cena
                });
            }
            this.setState({ materialPopup: true });
        }
    }

    handleAddFavorite = () => {
        const {row, cena, materialObj } = this.state;
        this.addMaterialToFavorite((row && row.original.id) || materialObj.id, cena);
        this.setState({ materialPopup: false, cena: null });
        this.reactTable.fireFetchData();
    }

    closeFavorite = () => {
        this.setState({ materialPopup: false });
    }

    handleSearchBarChange = (searchValue) => {
        this.setState({
            searchBarValue: searchValue,
        });
    }

    handleDeleteButtonClick = (id, name) => {
        if(!this.state.deleteMaterialPopUp){
            this.setState({
                deleteMaterialId: id,
                deleteMaterialName: name,
            })
        }  
        this.setState({
            deleteMaterialPopUp: !this.state.deleteMaterialPopUp,
        })
    }

    closeDeleteMaterial = () => {
        this.setState({
            deleteMaterialPopUp: false,
        })
    }

    handleDeleteMaterial = () => {

        const { deleteMaterialId } = this.state;
    
        axios.post(`api/material/delete`, { id: deleteMaterialId })
            .then(response => {
                console.log('Delete successful:', response.data);
                this.setState({
                    deleteMaterialPopUp: false,
                })
                this.reactTable.fireFetchData();
            })
            .catch(error => {
                if (error.response.data.code == 23503) {
                    console.error('Delete failed: Material is referenced by other records.');
                    wentWrong(i18n.t('You can not delete material that is used in activities!'));
                } else {
                    console.error('Delete failed:', error);
                    wentWrong(i18n.t('Something went wrong, please try again!'));
                }
            });
    }

    handleChangeGroup = (e) => {
        this.setState({ selectedGroup: e.currentTarget.value });
        this.reactTable.state.page = 0;
    }

    handleChangeSubGroup = (e) => {
        this.setState({ selectedSubGroup: e.currentTarget.value });
        this.reactTable.state.page = 0;
    }

    handleChangeUnit = (e) => {
        this.setState({ selectedUnit: e.currentTarget.value });
        this.reactTable.state.page = 0;
    }

    handleOpenAddMaterial = () => {
        this.setState({ showAddMaterial: true });
    }

    handleCloseAddMaterial = () => {
        this.setState({ showAddMaterial: false });
    }

    handleOpenEditMaterial = () => {
        this.setState({ showEditMaterial: true });
    }

    handleCloseEditMaterial = () => {
        this.setState({ showEditMaterial: false });
    }

    handelAfterAddMaterial = (materialObj) => {

        axios.post('api/material/create', materialObj)
        .then(response => {
            const { id, cena } = response.data.data;
            if(!materialObj.id){
                axios.post(`api/material/addFavorite`, { id_materijal: id, cena })
                .then(response => {
                    if (this.reactTable) {
                        const { page, pageSize } = this.reactTable.state;
                        if (page !== 0 || pageSize !== this.state.pageSize) {
                            this.reactTable.state.page = 0;
                        }
                        this.resetMyFilters();
                    }

                    this.fetchAllMaterialGroups();
                    this.fetchAllMaterialSubGroups();
                    this.fetchAllMaterialUnits();
                    this.reactTable.fireFetchData();
                    this.setState({ 
                        showAddMaterial: false,
                     });
                     const { page, pageSize } = this.reactTable.state;
                     if (page !== 0 || pageSize !== this.state.pageSize) {
                         this.reactTable.state.page = 0;
                     }
                })
                .catch(function (error) {
                    wentWrong(i18n.t('Something went wrong, please try again!'));
                });
            }else {
                this.setState({ 
                    showEditMaterial: false,
                 });
                this.fetchAllMaterialGroups();
                this.fetchAllMaterialSubGroups();
                this.fetchAllMaterialUnits();
                this.reactTable.fireFetchData(); 
            }
        })
        .catch(function (error) {
            wentWrong(i18n.t('Something went wrong, please try again!'));
        });
    }

    handelAfterEditMaterial = () => {
        this.setState({ 
            showEditMaterial: false,
         });
        this.fetchAllMaterialGroups();
        this.fetchAllMaterialSubGroups();
        this.fetchAllMaterialUnits();
        this.reactTable.fireFetchData();
    }

    resetMyFilters = () => {
        this.setState({
            searchBarValue: "",
            selectedGroup: "",
            selectedSubGroup: "",
            selectedOperator: "",
            selectedUnit: ""
        })
        this.reactTable.state.page = 0;
    }

    render() {
        const { selectAll, user, materialPopup, cena, } = this.state;
        const { theme, classes } = this.props;

        const priceAccessor = this.state.usesOwnMaterials ? 'cena' : 'cena_ako_ne_koristi_svoje_materijale';

        const columns = [
            {
                Header: i18n.t('Active'),
                accessor: "favorit",
                filterable: false,
                width: 80,
                headerStyle: commonHeaderStyle,
                show: user.id_korisnik_grupa === 10 ? true : false,
                Cell: (row) => {
                    return (
                        <div className={classes.center}>
                            <Tooltip title={row.value ? i18n.t('Remove from active') : i18n.t('Add to active')}>
                                <IconButton
                                    className={classes.starButton}
                                    style={{ color: row.value ? '#f2ce3e' : 'gray', marginBottom: '0.4vh', height: '1.2vh' }}
                                    onClick={() => this.handlePopUpFavorite(row)}>
                                    {
                                        row.value ? <Icons.GradeRounded /> :
                                            <Icons.GradeOutlined />
                                    }
                                </IconButton>
                            </Tooltip>
                        </div >
                    );
                }
            },
            {
                Header: i18n.t('Title'),
                accessor: 'naziv',
                headerStyle: commonHeaderStyle,
            },
            {
                Header: i18n.t('Material group name'),
                accessor: 'materijal_grupa_naziv',
                headerStyle: commonHeaderStyle,
                Filter: ({ onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.materialGroupDropdown}
                        className="multi-select-in-filter"
                        placeholder={i18n.t('Select')}
                        classNamePrefix="select"
                    />
            },
            {
                Header: i18n.t('Material subgroup name'),
                accessor: 'materijal_podgrupa_naziv',
                headerStyle: commonHeaderStyle,
                Filter: ({ onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.materialSubgroupDropdown}
                        className="multi-select-in-filter"
                        placeholder={i18n.t('Select')}
                        classNamePrefix="select"
                    />
            },
            {
                Header: i18n.t('Price'),
                accessor: priceAccessor,
                width: 80,
                headerStyle: commonHeaderStyle,
            },
            {
                Header: i18n.t('Unit'),
                accessor: 'unit',
                headerStyle: commonHeaderStyle,
                width: 100,
                className: classes.center,
                Filter: ({  onChange }) =>
                    <Select
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.unit}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'organska_proizvodnja',
                Header: i18n.t('Permitted in organic production'),
                width: 200,
                headerStyle: commonHeaderStyle,
                className: classes.center,
                accessor: d => d.organska_proizvodnja,
                Cell: ({ value }) => value === true ? i18n.t('Yes') : i18n.t('No'),
                Filter: ({ onChange }) =>
                    <ForBooleanFilter
                        onChange={(s) => {
                            onChange(s);
                        }}
                    />
            },
        ];
        if (this.state.usesOwnMaterials) {
            columns.push(
                {
                    width: 80,
                    headerStyle: commonHeaderStyle,
                    Header: i18n.t('Edit'),
                    getHeaderProps: () => ({
                        onClick: e => e.stopPropagation(),
                        style: {
                            cursor: 'default'
                        }
                    }),
                    accessor: 'izmena',
                    Cell: cell => (
                        <div>
                            {user.id_klijent === this.state.id_klijent_vlasnik && (
                                <Tooltip2 title={i18n.t("Edit")}>
                                    <IconButton aria-label="copy"
                                        onClick={() => {
                                            const { id, cena, id_materijal_grupa, id_materijal_podgrupa, id_unit, naziv } = cell.row._original;
                                            this.setState({ 
                                                materialObjEdit: { id, cena, id_materijal_grupa, id_materijal_podgrupa, id_unit, naziv } 
                                            });
                                            this.handleOpenEditMaterial()
                                        }}
                                    >
                                        <Icons.Edit fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                            )}
                        </div>
                    )
                },
                {
                    width: 80,
                    headerStyle: commonHeaderStyle,
                    getHeaderProps: () => ({
                        onClick: e => e.stopPropagation(),
                        style: {
                            cursor: 'default'
                        }
                    }),
                    Header: i18n.t('Delete'),
                    accessor: 'brisanje',
                    Cell: cell => (
                        <div>
                            {user.id_klijent === this.state.id_klijent_vlasnik && !cell.row._original.favorit && (
                                <Tooltip2 title={i18n.t("Delete")}>
                                    <IconButton aria-label="Delete"
                                        onClick={() => this.handleDeleteButtonClick(cell.row._original.id, cell.row._original.naziv)}
                                    >
                                        <Icons.DeleteOutline fontSize="default" />
                                    </IconButton>
                                </Tooltip2>
                            )}
                        </div>
                    )
                }
            );
        }

        return (
            <div>
                <div className={classes.searchWrap}>
                    <SearchBar
                        className={classes.searchBar}
                        placeholder={i18n.t('Search by name')}
                        cancelOnEscape={true}
                        value={this.state.searchBarValue}
                        onChange={(searchValue) => this.handleSearchBarChange(searchValue)}
                        onCancelSearch={() => this.handleSearchBarChange('')}
                    />
                    {getUser().id_klijent === this.state.id_klijent_vlasnik && 
                    <Button
                        variant="fab"
                        color="primary"
                        aria-label="Add"
                        className={classes.addNewBtn}
                        onClick={this.handleOpenAddMaterial}
                        >
                        <AddIcon />
                        {i18n.t('Add material')}
                    </Button>}
                </div>
                <div className={classes.filtersSection}>
                    <select className={classes.dropDowns} value={this.state.selectedGroup} onChange={this.handleChangeGroup} name="group" id="group">
                        <option value="">{i18n.t('Material group')}</option>
                        {this.state.allGroups && this.state.allGroups.map((status) => (
                            <option key={status.id} value={status.id}>{status.value}</option>
                        ))}
                    </select>
                    <select className={classes.dropDowns} value={this.state.selectedSubGroup} onChange={this.handleChangeSubGroup} name="sub_group" id="sub_group">
                        <option value="">{i18n.t('Material subgroup')}</option>
                        {this.state.allSubGroups && this.state.allSubGroups.map((status) => (
                            <option key={status.id} value={status.id}>{status.value}</option>
                        ))}
                    </select>
                    <select className={classes.dropDowns} value={this.state.selectedUnit} onChange={this.handleChangeUnit} name="unit" id="unit">
                        <option value="">{i18n.t('Unit')}</option>
                        {this.state.allUnits && this.state.allUnits.map((status, i) => (
                            <option key={i} value={status.value}>{status.value}</option>
                        ))}
                    </select>
                    <button className={classes.resetButton} onClick={this.resetMyFilters}>{i18n.t('Reset filters')}</button>
                </div>
                <div style={{ border: '1px solid #cecece', backgroundColor: '#fff', margin: '0 30px 30px 30px', borderRadius: '3px', boxSizing: 'border-box', overflow: 'hidden', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column' }}>
                        <ReactTable ref={r => (this.reactTable = r)}
                            manual
                            paging={true}
                            data={this.state.data}
                            columns={columns}
                            keyField={'id'}
                            pageRangeDisplayed={false}
                            defaultPageSize={this.state.pageSize}
                            pages={this.state.totalPages}
                            showPaginationBottom={true}
                            onFetchData={(state) => this.getData(state)}
                            firtelable={true}
                            previousText={'<'}
                            nextText={'>'}
                            pageText={''}
                            ofText={i18n.t('of')}
                            showPageSizeOptions={false}
                            showPagination={true}
                            rowsText={''}
                            PreviousComponent={(props) => (
                                <button {...props} 
                                className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            NextComponent={(props) => (
                                <button {...props} className={
                                    classes.paginationButton}
                                >{props.children}</button>
                            )}
                            getTrProps={() => {
                                    return {
                                        style: {
                                            textAlign: 'center',
                                            paddingLeft: '10px',
                                            height: '50px',
                                            alignItems: 'center',
                                            overflowY: 'hidden',
                                            overflowX: 'hidden'
                                        }
                                    };
                                
                            }}
                            LoadingComponent={() => (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <PulseLoader loading={this.state.load}/>
                                </div>                         
                            )}
                            onPageChange={() => {
                                this.state.load= true ;
                              }}
                              NoDataComponent={() => (
                                <div style={{ textAlign: 'center', padding: '20px', display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: -300 }}>
                                    {!this.state.load && <span>{i18n.t('No rows found')}</span>}
                                </div>
                            )}
                    />
                </div>
                {this.state.deleteFavorite &&
                    <Delete
                        delete={this.state.deleteFavorite}
                        handleClose={this.onDeleteClick}
                        deleteItem={this.deleteItem}
                        favorite={true} />
                }
                {(materialPopup || this.props.favorit) && (
                    this.state.usesOwnMaterials ? (
                        <Dialog
                            open={materialPopup || this.props.favorit}
                            onClose={this.closePopup}
                            disableBackdropClick
                            maxWidth="sm"
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">{i18n.t('Add to active')}</DialogTitle>
                            <DialogContent>
                                {i18n.t('Do you want to add the item to active?')}
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.handleAddFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Ok')}
                                </Button>
                                <Button
                                    onClick={this.closeFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    ) : (
                        <Dialog
                            open={materialPopup || this.props.favorit}
                            onClose={this.closePopup}
                            disableBackdropClick
                            maxWidth="sm"
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">{i18n.t('Price')}</DialogTitle>
                            <DialogContent>
                                <Grid item>
                                    <TextField
                                        label={i18n.t('Price')}
                                        value={this.state.cena}
                                        style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                        onChange={(event) => this.setState({ cena: event.target.value })}
                                        type="number"
                                    />
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={this.handleAddFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Ok')}
                                </Button>
                                <Button
                                    onClick={this.closeFavorite}
                                    variant="contained"
                                    color="primary"
                                >
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                )}

                {this.state.deleteMaterialPopUp && <Delete delete={this.state.deleteMaterialPopUp} handleClose={this.closeDeleteMaterial} deleteItem={this.handleDeleteMaterial} />}
                {this.state.showAddMaterial && <AddMaterial add={this.state.showAddMaterial} handleClose={this.handleCloseAddMaterial} handleFinishAction={this.handelAfterAddMaterial}/>}
                {this.state.showEditMaterial && <AddMaterial edit={true} editMaterialObject={this.state.materialObjEdit} add={this.state.showEditMaterial} handleFinishAction={this.handelAfterAddMaterial} handleClose={this.handleCloseEditMaterial}/>}
               </div>
        )
    }
};

export default withStyles(styles, { withTheme: true })(connect(

)(ViewMaterials));