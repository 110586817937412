/**
 * Created by pajicv on 5/25/18.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';

import { LayersControl, FeatureGroup, Popup, LayerGroup } from 'react-leaflet';

import * as LayerSelectors from '../selectors/LayerSelectors';

import * as LayerActions from '../actions/map/LayerActions';
import { updateFieldGeom } from '../actions/catastral_data/FieldActions';

import fields from './fields.json';

import './ParcelLayer.css';
import { addField as addFieldAction } from "../actions/catastral_data/FieldActions"

const { Overlay } = LayersControl;

const getFieldStyle = feature => {
    if (Boolean(feature.properties.boja)) {
        return {
            color: `${feature.properties.boja}`,
            weight: 1
        }
    } else {
        return {
            color: '#ff00ff',
            weight: 1
        };
    }
};

class FieldLayer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clickedFeature: {
                attributes: {
                    broj: null,
                    povrsina: null
                }
            }
        };

        this.onFieldClicked = this.onFieldClicked.bind(this);
    }

    onFieldClicked(e) {
        const clickedFeatureAttributes = e.layer.feature.properties;
        const { isDeleting, onDelete } = this.props
        if (isDeleting) {
            return onDelete(e.layer.feature.id)
        }
        this.setState({
            clickedFeature: {
                attributes: { ...clickedFeatureAttributes }
            }
        });
    }

    componentDidUpdate(prevProps) {

        const { fieldToAdd, fieldsToAdd, drawn } = this.props;

        if (fieldsToAdd) {

            let leafletGeoJSON = new L.GeoJSON(fieldsToAdd, {
                style: getFieldStyle
            });
            let leafletFG = this._editableFG.leafletElement;

            leafletFG.clearLayers();

            leafletGeoJSON.eachLayer((layer) => {
                if (layer.feature.properties.deo_table) {
                    return
                }
                leafletFG.addLayer(layer);
                layer.on('pm:edit', (e) => {
                    // console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrr',e)
                    this.props.updateFieldGeom({
                        id: e.target.feature.id,
                        geometry: e.target.getLatLngs()
                    })
                })
                //layer.bindTooltip(layer.feature.properties.broj, {permanent: true, direction: 'center'}).openTooltip();
            });

            this.props.sendLoadedFieldsDrawSuccess();

        }

        if (fieldToAdd) {

            const feature = L.polygon(fieldToAdd.geometry).toGeoJSON();
            feature.properties = { ...fieldToAdd.attributes };
            const json = L.geoJSON(feature, {
                style: getFieldStyle
            });
            this._editableFG.leafletElement.addLayer(json);

            this.props.sendFieldAddToMapSuccess();

        }


    }

    render() {

        const { addOverlay, removeLayer, removeLayerControl, fieldLayer, isDeleting } = this.props;

        return (
            <Overlay checked={fieldLayer.visible}
                name="Fields"
                addOverlay={addOverlay}
                removeLayer={removeLayer}
                removeLayerControl={removeLayerControl}>
                <LayerGroup>
                    <FeatureGroup onClick={this.onFieldClicked} ref={(reactFGref) => { this._onFeatureGroupReady(reactFGref); }}>
                        {!isDeleting && (
                            <Popup className="popup-parcel">
                                <div>
                                    <div className="popup-parcel-name">
                                        {this.state.clickedFeature.attributes.naziv}
                                    </div>
                                    <div className="popup-parcel-subtitle">
                                        {`${this.state.clickedFeature.attributes.kultura_naziv}`}
                                    </div>
                                    <div className="popup-parcel-attributes">
                                        {`${this.state.clickedFeature.attributes.kultura_grupa_naziv} / ${this.state.clickedFeature.attributes.kultura_podgrupa_naziv}`}
                                    </div>
                                    <div className="popup-parcel-subtitle">
                                        {`${this.state.clickedFeature.attributes.povrsina} ha`}
                                    </div>
                                </div>
                            </Popup>
                        )}
                    </FeatureGroup>
                </LayerGroup>
            </Overlay>
        )

    }

    _editableFG = null;

    _onFeatureGroupReady = (reactFGref) => {

        if (this._editableFG) return;

        // populate the leaflet FeatureGroup with the geoJson layers

        let leafletGeoJSON = new L.GeoJSON(fields, {
            style: getFieldStyle
        });
        let leafletFG = reactFGref.leafletElement;

        leafletGeoJSON.eachLayer((layer) => {
            if (layer.feature.properties.deo_table) {
                return
            }
            leafletFG.addLayer(layer);
        });

        // store the ref for future access to content

        this._editableFG = reactFGref;

    };

    onCreated(e) {

        //console.log(e);

    }

}

function mapStateToProps(state) {
    return {
        fieldLayer: LayerSelectors.getFieldLayer(state),
        fieldToAdd: LayerSelectors.getFieldToAdd(state),
        fieldsToAdd: LayerSelectors.getLoadedFields(state)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendFieldAddToMapSuccess: () => dispatch(LayerActions.sendNewFieldAddToMapSuccess()),
        sendLoadedFieldsDrawSuccess: () => dispatch(LayerActions.sendLoadedFieldsDrawSuccess()),
        updateFieldGeom: fieldObj => dispatch(updateFieldGeom(fieldObj)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FieldLayer);
