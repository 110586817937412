import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoTemperature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Temperatura vazduha je značajan činilac u biljnoj proizvodnji koji određuje areal gajenja određenih biljnih vrsta, brzinu i intenzitet fizioloških procesa koji se dešavaju u biljkama, 
                            dinamiku rasta i razvića kao i kvalitet i kvantitet prinosa. 
                            Precizni podaci o temperaturi vazduha daju proizvođaču mogućnost da odabere sortiment tolerantan na klimatske uslove, 
                            da se blagovremeno pripremi za odbranu biljaka od kasnih prolećnih i ranih jesenjih mrazeva, odredi najoptimalniji momenat žetve i berbe, 
                            kao i da isplanira i sprovede zaštitu bilja od novih invazivnih korova i štetočina.

                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Air temperature is an important factor in plant production that determines the area of cultivation of plant species, 
                            the speed and intensity of plants physiological processes, the dynamic of growth and development and the quality and quantity of yields.
                            Precise air temperature data allows farmers to choose an assortment tolerant to climatic conditions, 
                            to prepare in time to protect plants from late spring and early autumn frosts, to determine the most optimal moment of harvesting and to plan and realize 
                            the plant protection from new invasive weeds and pests.

                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Temperatura zraka važan je čimbenik u biljnoj proizvodnji koji određuje područje uzgoja pojedinih 
                            biljnih vrsta, brzinu i intenzitet fizioloških procesa koji se odvijaju u biljkama, dinamiku rasta i 
                            razvoja, kao i kvalitetu i količinu prinosa. .
                            Precizni podaci o temperaturi zraka daju proizvođaču mogućnost da odabere sortiment tolerantan na 
                            klimatske uvjete, da se na vrijeme pripremi za zaštitu biljaka od kasnih proljetnih i ranih jesenskih 
                            mrazeva, da odredi najoptimalniji trenutak žetve i berbe, kao i da planira te provoditi zaštitu 
                            biljaka od korova i štetnika.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Temperatura zraka je pomemben dejavnik v pridelavi rastlin, ki določa območje gojenja rastlinskih vrst, 
                            hitrost in intenzivnost fizioloških procesov v rastlinah, dinamiko rasti in razvoja ter kakovost in količino pridelka. 
                            Natančni podatki o temperaturi zraka kmetom omogočajo izbiro sortimenta, tolerantnega na podnebne razmere, pravočasno pripravo 
                            za zaščito rastlin pred pozno spomladansko in zgodnjo jesensko pozebo, določitev najoptimalnejšega trenutka spravila 
                            ter načrtovanje in izvedbo zaščite rastlin pred novimi invazivnimi pleveli. in škodljivci.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoTemperature);