import React from 'react';
import { connect } from 'react-redux';
import i18n from '../../i18n/i18n';
import { Button, IconButton, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setInitialState, addSector } from '../../actions/sectors/SectorsActions';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
const styles = theme => ({
})
export class AddSector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectorObj: {}
        }
    }

    componentDidMount() {
        const { sectorObj } = this.props;
        if (sectorObj) {
            this.setState({
                sectorObj
            })
        }
    }


    componentDidUpdate(prevProps) {
        const { sectorPostingFailed, setInitialState } = this.props;
        if (prevProps.sectorPostingFailed !== sectorPostingFailed) {
            toast.error(i18n.t('Something went wrong, please try again!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
            setInitialState('sectorPostingFailed');
        }
    }

    addSector = () => {
        const { addSector } = this.props;
        const { sectorObj } = this.state;
        if (sectorObj.naziv) {
            addSector(
                sectorObj
            )
        } else {
            toast.error(i18n.t('Please enter all required fields!'), {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                draggablePercent: 80
            });
        }
    }

    handleChangeValue = name => event => {
        const { sectorObj } = this.state;
        let obj = Object.assign({}, sectorObj);
        obj[name] = event.target.value;
        this.setState({ sectorObj: obj });
    };


    render() {
        const { add, handleClose, edit, theme } = this.props;
        const { sectorObj } = this.state;
        return (
            <div style={{ padding: 20 }}>
                <Dialog
                    open={add}
                    onClose={handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="xs"
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{edit ? i18n.t('Edit sector') : i18n.t('Add sector')}
                        <IconButton onClick={handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container justify="space-between" alignContent="space-between" spacing={16}>
                            <Grid item>
                                <TextField
                                    required
                                    label={i18n.t('Title')}
                                    style={{ width: '256px', marginTop: '10px', marginBottom: '10px' }}
                                    value={sectorObj.naziv}
                                    onChange={this.handleChangeValue('naziv')}
                                />
                            </Grid>
                            <Grid item xs>
                                <Button
                                    style={{ alignSelf: 'center', color: 'white' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.addSector}
                                >
                                    {edit ? i18n.t('Save') : i18n.t('Add')}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        sectorPosting: state.sectorReducer.sectorPosting,
        sectorPostingFailed: state.sectorReducer.sectorPostingFailed,
        sectorObj: state.sectorReducer.sectorObj,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        addSector: (sectorObj) => dispatch(addSector(sectorObj)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSector))