
const palette = {
  // Original 
  // primary: {
  //   light: "rgba(178, 247, 117, 1)",
  //   main: "#61a50e",
  //   dark: "rgba(8, 101, 26, 1)",
  //   contrastText: "#fff"
  // },
  // secondary: {
  //   light: "#757474",
  //   main: "#555555",
  //   dark: "#444444",
  //   contrastText: "#fff"
  // },
  // 3
  primary: {
    light: "#5BBBEE",
    main: "#58B4E5",
    dark: "#4FA2CD",
    contrastText: "#fff"
  },
  secondary: {
    light: "#058457",
    main: "#04764e",
    dark: "#126038",
    contrastText: "#fff"

  },
  error: {
    light: "#e57373",
    main: "#f44336",
    dark: "#d32f2f",
    contrastText: "#fff"
  },
  text: {
    hint: "#fff"
  }
};

const baseSize = 13;

const typography = {
  baseFontSize: `${baseSize}px`,
};

const zIndex = {
  header: 1010,
  overlay: 1015,
  drawer: 1020,
  headerText: 1025,
};

export default {
  palette,
  typography,
  zIndex,
  size: size => `${size * baseSize}px`,
  size1: size => `${size * baseSize + 10}px`,
};
