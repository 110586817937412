/**
 * Created by pajicv on 5/13/18.
 */

import axios from '../../utils/AxiosWrapper';
import * as querystring from 'querystring';

export const LAYER_VISIBILITY_CHANGE = 'LAYER_VISIBILITY_CHANGE';

export const BASE_LAYER_CHANGE = 'BASE_LAYER_CHANGE';

export const EDITED_LAYER_CHANGE = 'EDITED_LAYER_CHANGE';

export const PARCEL_CREATE = 'PARCEL_CREATE';

export const PARCEL_SAVE_SEND = 'PARCEL_SAVE_SEND';

export const PARCEL_SAVE_CALL = 'PARCEL_SAVE_SUCCESS';
export const PARCEL_SAVE_SUCCESS = 'PARCEL_SAVE_SUCCESS';
export const PARCEL_SAVE_FAILED = 'PARCEL_SAVE_FAILED';

export const PARCEL_GET_CALL = 'PARCEL_GET_CALL';
export const PARCEL_GET_SUCCESS = 'PARCEL_GET_SUCCESS';
export const PARCEL_GET_FAILED = 'PARCEL_GET_FAILED';

export const FIELD_SAVE_CALL = 'FIELD_SAVE_SUCCESS';
export const FIELD_SAVE_SUCCESS = 'FIELD_SAVE_SUCCESS';
export const FIELD_SAVE_FAILED = 'FIELD_SAVE_FAILED';

export const FIELD_GET_CALL = 'FIELD_GET_CALL';
export const FIELD_GET_SUCCESS = 'FIELD_GET_SUCCESS';
export const FIELD_GET_FAILED = 'FIELD_GET_FAILED';

export const SEASONAL_FORECAST_GET_CALL = 'SEASONAL_FORECAST_GET_CALL';
export const SEASONAL_FORECAST_GET_SUCCESS = 'SEASONAL_FORECAST_GET_SUCCESS';
export const SEASONAL_FORECAST_GET_FAILED = 'SEASONAL_FORECAST_GET_FAILED';

export const NEW_PARCEL_ADD_TO_MAP_SUCCESS = 'NEW_PARCEL_ADD_TO_MAP_SUCCESS';
export const NEW_PARCEL_ADD_TO_MAP_FAILED = 'NEW_PARCEL_ADD_TO_MAP_FAILED';
export const NEW_FIELD_ADD_TO_MAP_SUCCESS = 'NEW_FIELD_ADD_TO_MAP_SUCCESS';
export const NEW_FIELD_ADD_TO_MAP_FAILED = 'NEW_FIELD_ADD_TO_MAP_FAILED';

export const LOADED_PARCELS_DRAW_SUCCESS = 'LOADED_PARCELS_DRAW_SUCCESS';
export const LOADED_PARCELS_DRAW_FAILED = 'LOADED_PARCELS_DRAW_FAILED';

export const LOADED_FIELDS_DRAW_SUCCESS = 'LOADED_FIELDS_DRAW_SUCCESS';
export const LOADED_FIELDS_DRAW_FAILED = 'LOADED_FIELDS_DRAW_FAILED';

export const AGRI_RESEARCH_INSTITUTES_GET_CALL = 'AGRI_RESEARCH_INSTITUTES_GET_CALL';
export const AGRI_RESEARCH_INSTITUTES_GET_SUCCESS = 'AGRI_RESEARCH_INSTITUTES_GET_SUCCESS';
export const AGRI_RESEARCH_INSTITUTES_GET_FAILED = 'AGRI_RESEARCH_INSTITUTES_GET_FAILED';

export const RIVER_BASIN_AUTH_GET_CALL = 'RIVER_BASIN_AUTH_GET_CALL';
export const RIVER_BASIN_AUTH_GET_SUCCESS = 'RIVER_BASIN_AUTH_GET_SUCCESS';
export const RIVER_BASIN_AUTH_GET_FAILED = 'RIVER_BASIN_AUTH_GET_FAILED';

export const AGRI_UNIVERSITIES_GET_CALL = 'AGRI_UNIVERSITIES_GET_CALL';
export const AGRI_UNIVERSITIES_GET_SUCCESS = 'AGRI_UNIVERSITIES_GET_SUCCESS';
export const AGRI_UNIVERSITIES_GET_FAILED = 'AGRI_UNIVERSITIES_GET_FAILED';

export const IMAGERY_DATE_SELECTED = 'IMAGERY_DATE_SELECTED';

export const NOTE_GET_CALL = 'NOTE_GET_CALL';
export const NOTE_GET_SUCCESS = 'NOTE_GET_SUCCESS';
export const NOTE_GET_FAILED = 'NOTE_GET_FAILED';

export const SENZOR_GET_CALL = 'SENZOR_GET_CALL';
export const SENZOR_GET_SUCCESS = 'SENZOR_GET_SUCCESS';
export const SENZOR_GET_FAILED = 'SENZOR_GET_FAILED';

export const LOADED_NOTES_DRAW_SUCCESS = 'LOADED_NOTES_DRAW_SUCCESS';
export const LOADED_NOTES_DRAW_FAILED = 'LOADED_NOTES_DRAW_FAILED';

export function setSatelliteImageryDate(layerId, selectedDate) {

    return {
        type: IMAGERY_DATE_SELECTED,
        layerId,
        selectedDate
    }

}

export function setLayerVisibility(payload) {

    return {
        type: LAYER_VISIBILITY_CHANGE,
        payload
    }

}

export function setBaseLayer(payload) {

    return {
        type: BASE_LAYER_CHANGE,
        payload
    }

}

export function changeEditedLayer(payload) {

    return {
        type: EDITED_LAYER_CHANGE,
        payload
    }

}

export function saveParcel(payload) {

    //mock success response
    return {
        type: PARCEL_SAVE_SUCCESS,
        payload
    }

}

export function sendNewParcelAddToMapSuccess(payload) {

    return {
        type: NEW_PARCEL_ADD_TO_MAP_SUCCESS
    }

}

export function sendLoadedParcelsDrawSuccess() {

    return {
        type: LOADED_PARCELS_DRAW_SUCCESS
    }

}

export function getParcels(bbox, crs = 'EPSG:4326') {
    return (dispatch) => {

        // console.log(bbox);
        // console.log(crs);

        dispatch({
            type: PARCEL_GET_CALL
        });

        const params = querystring.stringify({bbox, crs});
        const url = 'api/parcela?' + params;

        axios.get(url)
            .then(function (response) {
                try {
                    const parcels = JSON.parse(response.data.data.geojson);
                    dispatch({
                        type: PARCEL_GET_SUCCESS,
                        data: parcels
                    });
                } catch (err) {
                    console.log(err);
                    dispatch({
                        type: PARCEL_GET_FAILED
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: PARCEL_GET_FAILED
                });
            });
    }
}

export function getAgriResearchInstitutes() {
    return (dispatch) => {

        dispatch({
            type: RIVER_BASIN_AUTH_GET_CALL
        });

        const url = 'api/agri_research_institute';

        axios.get(url)
            .then(function (response) {
                try {
                    const markers = response.data.data;
                    dispatch({
                        type: AGRI_RESEARCH_INSTITUTES_GET_SUCCESS,
                        data: markers
                    });
                } catch (err) {
                    console.log(err);
                    dispatch({
                        type: AGRI_RESEARCH_INSTITUTES_GET_FAILED
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: AGRI_RESEARCH_INSTITUTES_GET_FAILED
                });
            });
    }
}

export function getRiverBasinAuth() {
    return (dispatch) => {

        dispatch({
            type: RIVER_BASIN_AUTH_GET_CALL
        });

        const url = 'api/river_basin_auth';

        axios.get(url)
            .then(function (response) {
                try {
                    const markers = response.data.data;
                    dispatch({
                        type: RIVER_BASIN_AUTH_GET_SUCCESS,
                        data: markers
                    });
                } catch (err) {
                    console.log(err);
                    dispatch({
                        type: RIVER_BASIN_AUTH_GET_FAILED
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: RIVER_BASIN_AUTH_GET_FAILED
                });
            });
    }
}

export function getAgriUniversities() {
    return (dispatch) => {

        dispatch({
            type: AGRI_UNIVERSITIES_GET_CALL
        });

        const url = 'api/agri_university';

        axios.get(url)
            .then(function (response) {
                try {
                    const markers = response.data.data;
                    dispatch({
                        type: AGRI_UNIVERSITIES_GET_SUCCESS,
                        data: markers
                    });
                } catch (err) {
                    console.log(err);
                    dispatch({
                        type: AGRI_UNIVERSITIES_GET_FAILED
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: AGRI_UNIVERSITIES_GET_FAILED
                });
            });
    }
}

export function getSeasonalForecast() {
    return (dispatch) => {

        dispatch({
            type: SEASONAL_FORECAST_GET_CALL
        });


    }
}

export function saveField(payload) {

    //mock success response
    return {
        type: FIELD_SAVE_SUCCESS,
        payload
    }

}

export function sendNewFieldAddToMapSuccess(payload) {

    return {
        type: NEW_FIELD_ADD_TO_MAP_SUCCESS
    }

}

export function sendLoadedFieldsDrawSuccess() {

    return {
        type: LOADED_FIELDS_DRAW_SUCCESS
    }

}

export function getFields(bbox, seasons, crs = 'EPSG:4326') {
    return (dispatch) => {

        // console.log(bbox);
        // console.log(crs);

        dispatch({
            type: FIELD_GET_CALL
        });

        const params = querystring.stringify({bbox, seasons, crs});
        const url = 'api/field?' + params;

        axios.get(url)
            .then(function (response) {
                try {
                    const fields = JSON.parse(response.data.data.geojson);
                    dispatch({
                        type: FIELD_GET_SUCCESS,
                        data: fields
                    });
                } catch (err) {
                    console.log(err);
                    dispatch({
                        type: FIELD_GET_FAILED
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: FIELD_GET_FAILED
                });
            });
    }
}

export function fetchNotes(bbox, crs = 'EPSG:4326') {
    return (dispatch) => {

        dispatch({
            type: NOTE_GET_CALL
        });

        const params = querystring.stringify({bbox, crs});
        const url = 'api/note?' + params;

        axios.get(url)
            .then(function (response) {
                try {
                    const notes = JSON.parse(response.data.data.geojson);
                    dispatch({
                        type: NOTE_GET_SUCCESS,
                        data: notes
                    });
                } catch (err) {
                    console.log(err);
                    dispatch({
                        type: NOTE_GET_FAILED
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: FIELD_GET_FAILED
                });
            });
    }
}

export function fetchSenzor(bbox, crs = 'EPSG:4326') {
    return (dispatch) => {

        dispatch({
            type: SENZOR_GET_CALL
        });

        const params = querystring.stringify({bbox, crs});
        const url = 'api/senzor?' + params;

        axios.get(url)
            .then(function (response) {
                try {
                    const notes = JSON.parse(response.data.data.geojson);
                    dispatch({
                        type: SENZOR_GET_SUCCESS,
                        data: notes
                    });
                } catch (err) {
                    console.log(err);
                    dispatch({
                        type: SENZOR_GET_FAILED
                    });
                }
            })
            .catch(function (error) {
                console.log(error);
                dispatch({
                    type: FIELD_GET_FAILED
                });
            });
    }
}

export function sendLoadedNotesDrawSuccess() {

    return {
        type: LOADED_NOTES_DRAW_SUCCESS
    }

}