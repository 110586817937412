import axios from '../../utils/AxiosWrapper';
export const Type = {
    //VIEW
    GET_STORAGES_CALL: 'GET_STORAGES_CALL',
    GET_STORAGES_SUCCESS: 'GET_STORAGES_SUCCESS',
    GET_STORAGES_FAILED: 'GET_STORAGES_FAILED',
    //ADD
    ADD_STORAGE_CALL: 'ADD_STORAGE_CALL',
    ADD_STORAGE_SUCCESS: 'ADD_STORAGE_SUCCESS',
    ADD_STORAGE_FAILED: 'ADD_STORAGE_FAILED',
    //DELETE
    DELETE_STORAGE_CALL: 'DELETE_STORAGE_CALL',
    DELETE_STORAGE_SUCCESS: 'DELETE_STORAGE_SUCCESS',
    DELETE_STORAGE_FAILED: 'DELETE_STORAGE_FAILED',
    //DROPDOWN
    GET_STORAGE_DROPDOWN_CALL: 'GET_STORAGE_DROPDOWN_CALL',
    GET_STORAGE_DROPDOWN_SUCCESS: 'GET_STORAGE_DROPDOWN_SUCCESS',
    GET_STORAGE_DROPDOWN_FAILED: 'GET_STORAGE_DROPDOWN_FAILED',
    GET_MATERIAL_DROPDOWN_CALL: 'GET_MATERIAL_DROPDOWN_CALL',
    GET_MATERIAL_DROPDOWN_SUCCESS: 'GET_MATERIAL_DROPDOWN_SUCCESS',
    GET_MATERIAL_DROPDOWN_FAILED: 'GET_MATERIAL_DROPDOWN_FAILED',

    //TRANSACTIONS
    GET_TRANSACTION_CALL: 'GET_TRANSACTION_CALL',
    GET_TRANSACTION_SUCCESS: 'GET_TRANSACTION_SUCCESS',
    GET_TRANSACTION_FAILED: 'GET_TRANSACTION_FAILED',

    //VIEW
    GET_LOADS_CALL: 'GET_LOADS_CALL',
    GET_LOADS_SUCCESS: 'GET_LOADS_SUCCESS',
    GET_LOADS_FAILED: 'GET_LOADS_FAILED',
    //ADD
    ADD_LOAD_CALL: 'ADD_LOAD_CALL',
    ADD_LOAD_SUCCESS: 'ADD_LOAD_SUCCESS',
    ADD_LOAD_FAILED: 'ADD_LOAD_FAILED',
    //DELETE
    DELETE_LOAD_CALL: 'DELETE_LOAD_CALL',
    DELETE_LOAD_SUCCESS: 'DELETE_LOAD_SUCCESS',
    DELETE_LOAD_FAILED: 'DELETE_LOAD_FAILED',
    //UTILS
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_ROW_IN_REDUCER: 'SET_ROW_IN_REDUCER',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function getStorageDropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_STORAGE_DROPDOWN_CALL
        });

        axios.get('api/silos/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_STORAGE_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_STORAGE_DROPDOWN_FAILED
                });
            });
    }
}

export function getMaterialsDropdown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_MATERIAL_DROPDOWN_CALL
        });

        axios.get('api/material/drop_down_materijal_silos')
            .then(function (response) {
                dispatch({
                    type: Type.GET_MATERIAL_DROPDOWN_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_MATERIAL_DROPDOWN_SUCCESS
                });
            });
    }
}

export function addStorage(silosObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_STORAGE_CALL
        });

        axios.post('api/silos/create',
            silosObj
        )
            .then(function (response) {
                dispatch({
                    type: Type.ADD_STORAGE_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_STORAGE_FAILED
                });
            });
    }
}

export function getStorages(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_STORAGES_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/silos/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_STORAGES_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_STORAGES_FAILED
                });
            });
    }
}

export function deleteStorage(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_STORAGE_CALL,
        });

        axios.post(`api/silos/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_STORAGE_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_STORAGE_FAILED
                });
            });
    }
}

export function addLoad(loadObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_LOAD_CALL
        });

        axios.post('api/silos/create_silos_materijal',
            loadObj
        )
            .then(function (response) {
                dispatch({
                    type: Type.ADD_LOAD_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_LOAD_FAILED,
                    error: error.response.status
                });
            });
    }
}

export function getLoads(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_LOADS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/silos/read_silos_materijal', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_LOADS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_LOADS_FAILED
                });
            });
    }
}

export function deleteLoad(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_LOAD_CALL,
        });

        axios.post(`api/silos/delete_silos_materijal`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_LOAD_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_LOAD_FAILED
                });
            });
    }
}


export function getTransaction(id_silos) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_TRANSACTION_CALL,
        })

        axios.get(`api/silos/getTransactionForSilos?id_silos=` + id_silos)
            .then((response) => {
                dispatch({
                    type: Type.GET_TRANSACTION_SUCCESS,
                    data: response.data
                })
            }).catch((error) => {
                dispatch({
                    type: Type.GET_TRANSACTION_FAILED,
                    error: error
                });
            });
    }
}
