import immutable from 'seamless-immutable';
import { Type as ParcelType } from '../../actions/catastral_data/ParcelActions';


export const INITIAL_STATE = immutable({
    //parcele
    parcelPosting: false,
    parcelPostingSuccess: false,
    parcelPostingFailed: false,
    parcel: [],
    parcelObj: {},
    parcelFetching: false,
    parcelFetchingFailed: false,
    //DROPDOWN

    // parcelDropDown: [],
    // parcelFetchingDropDown: false,
    // parcelFetchingFailedDropDown: false
    parcelItems: {},
    parcelItemsFetching: false,
    parcelItemsFetchingFailed: false,
    ownershipDropDown: [],
    ownershipFetchingDropDown: false,
    ownershipFetchingFailedDropDown: false,
    parcelPages: 1,
    deleted: false,
    deleteParcelFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ParcelType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;

        case ParcelType.SET_PARCEL_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ parcelObj: data })
            break;
        }

        case ParcelType.ADD_PARCELS_CALL: {
            const parcelPosting = true;
            return state.merge({ parcelPosting });
            break;
        }

        case ParcelType.ADD_PARCELS_SUCCESS: {
            const parcelPosting = false;
            const parcelPostingSuccess = true;
            return state.merge({ parcelPostingSuccess, parcelPosting })
            break;
        }

        case ParcelType.ADD_PARCELS_FAILED: {
            const parcelPosting = false;
            const parcelPostingFailed = true;
            return state.merge({ parcelPosting, parcelPostingFailed });
            break;
        }

        case ParcelType.GET_VIEW_PARCELS_CALL: {
            const parcelFetching = true;
            return state.merge({ parcelFetching });
            break;
        }

        case ParcelType.GET_VIEW_PARCELS_SUCCESS: {
            const parcel = action.data.data;
            const parcelFetching = false;
            const parcelPages = action.data.total_pages
            return state.merge({ parcel, parcelFetching, parcelPages });
            break;
        }

        case ParcelType.GET_VIEW_PARCELS_FAILED: {
            const parcelFetchingFailed = true;
            const parcelFetching = false;
            return state.merge({ parcelFetching, parcelFetchingFailed });
            break;
        }

        case ParcelType.GET_PARCEL_DASHBOARD_CALL: {
            const parcelItemsFetching = true;
            return state.merge({ parcelItemsFetching });
            break;
        }

        case ParcelType.GET_PARCEL_DASHBOARD_SUCCESS: {
            const parcelItems = action.data;
            const parcelItemsFetching = false;
            return state.merge({ parcelItems, parcelItemsFetching });
            break;
        }

        case ParcelType.GET_PARCEL_DASHBOARD_FAILED: {
            const parcelItemsFetchingFailed = true;
            const parcelItemsFetching = false;
            return state.merge({ parcelItemsFetching, parcelItemsFetchingFailed });
            break;
        }



        case ParcelType.GET_OWNERSHIP_CALL: {
            const ownershipFetchingDropDown = true;
            return state.merge({ ownershipFetchingDropDown });
            break;
        }

        case ParcelType.GET_OWNERSHIP_SUCCESS: {
            const ownershipDropDown = action.data.data;
            const ownershipFetchingDropDown = false;
            return state.merge({ ownershipDropDown, ownershipFetchingDropDown });
            break;
        }

        case ParcelType.GET_OWNERSHIP_FAILED: {
            const ownershipFetchingFailedDropDown = true;
            const ownershipFetchingDropDown = false;
            return state.merge({ ownershipFetchingDropDown, ownershipFetchingFailedDropDown });
            break;
        }

        case ParcelType.DELETE_PARCEL_SUCCESS: {
            const array = state['parcel'].asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ [action.parcel]: array, deleted: deleted })
            break;

        }

        case ParcelType.DELETE_PARCEL_FAILED: {
            const deleteParcelFailed = true;
            return state.merge({ deleteParcelFailed });
            break;
        }

        default:
            return state;
    }
}