import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import { DateRangePicker } from "react-dates";

export class DatePickerForFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null
        }
    }

    onChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate })
        startDate = startDate ? startDate.format('MM.DD.YYYY') : null;
        endDate = endDate ? endDate.format('MM.DD.YYYY') : null;
        // onChange({ startDate, endDate });
        this.props.onChange({ startDate, endDate });

    }

    render() {
        return (
            <div>
                <DateRangePicker
                    startDate={this.state.startDate}
                    startDateId="START_DATE"
                    endDate={this.state.endDate}
                    endDateId="END_DATE"
                    readOnly={true}
                    onDatesChange={({ startDate, endDate }) => this.onChange({ startDate, endDate })}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    numberOfMonths={1}
                    minimumNights={0}
                    isOutsideRange={() => false}
                    customArrowIcon={' '}
                    showClearDates
                    displayFormat="DD.MM.YYYY"
                    startDatePlaceholderText={i18n.t('From')}
                    endDatePlaceholderText={i18n.t('To')}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {

    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DatePickerForFilter);