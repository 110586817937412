import axios from '../utils/AxiosWrapper';
var FileSaver = require('file-saver');
export const Type = {
    EXPORT_CALL: 'EXPORT_CALL'
};


export function exportDocument(title, state, url, filters) {
    return (dispatch) => {
        dispatch({
            type: Type.EXPORT_CALL,
        });
        let params = {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered,
            filename: title
        }
        if (filters) {
            params = {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
                filename: title,
                filters
            }
        }
        return axios({
            url: url,
            method: 'GET',
            responseType: 'blob',
            params: params
        })
            .then(function (response) {
                FileSaver.saveAs(new Blob([response.data]), `${title || 'file'}.xlsx`);

            })
            .catch(function (error) {
                console.log(error);
            });
    }
}