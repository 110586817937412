import 'core-js/es6/map';
import 'core-js/es6/set';

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import './assets/css/main.css';

// function registerServiceWorker() {
//   var OneSignal = window.OneSignal || [];
//   if (OneSignal.installServiceWorker) {
//     OneSignal.installServiceWorker();
//   } else {
//     navigator.serviceWorker.register('/OneSignalSDKWorker.js?appId=YOUR-APP-ID')
//       .then(registration => {
//         Notification.requestPermission();
//         console.log(
//           "ServiceWorker registered with scope:",
//           registration.scope
//         );
//       })
//       .catch(e => console.error("ServiceWorker failed:", e));;
//   }

// }


// if (navigator && navigator.serviceWorker) {
//   registerServiceWorker();
// }

ReactDOM.render(
  <Root />,
  document.getElementById('root')
);
