import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import {
    IconButton
} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
const drawerWidth = 56;

const styles = theme => ({
    drawerPaper: {
        position: 'fixed',
        width: drawerWidth,
        zIndex: 1,
        background: '#333333',
        minHeight: '1050px'
    },

});

export class DashboardDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchor: 'right',
            isProfitVisible: false
        };
    }

    render() {

        const { classes } = this.props;
        const { anchor } = this.state;

        const drawer = (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor={anchor}
            >
                {this.props.isVisible === true &&
                    <IconButton style={{ paddingRight: '23px', marginTop: '60px' }}
                        className="dashboardDrawer" onClick={() => this.props.onProfitClick()}>
                        {window.location.pathname.includes('cost') &&
                            <span className='icon-coin-euro dashboardIcon-selected' />
                        }
                        {window.location.pathname.includes('cost') !== true &&
                            <span className='icon-coin-euro dashboardIcon' />
                        }
                    </IconButton>
                }
                <Divider />

                {/* <IconButton style={{ paddingRight: '23px' }}
                    className="dashboardDrawer" onClick={() => this.props.onPieClick()}>
                    <span className={`icon-Hamburger ${window.location.pathname.includes('used') || window.location.pathname.includes('dashboard') ? 'dashboardIcon-selected' : 'dashboardIcon'}`} />
                </IconButton>
                <Divider /> */}

                <IconButton style={{ paddingRight: '26px' }}
                    className="dashboardDrawer" onClick={() => this.props.onTableClick()}>
                    <span className={`icon-table2 ${window.location.pathname.includes('used') || window.location.pathname.includes('cost') || window.location.pathname.includes('dashboard') ? 'dashboardIcon' : 'dashboardIcon-selected'}`} />
                </IconButton>
            </Drawer>
        );

        let after = null;
        after = drawer;

        return (
            <div>
                <div>
                    {after}
                </div>
            </div>
        )
    };


};

DashboardDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardDrawer));