import immutable from 'seamless-immutable';
import { Type as ActivityType } from '../../actions/activity/ActivityActions';
import moment from 'moment';
import _ from 'lodash';
export const INITIAL_STATE = immutable({
    //VIEW
    activity: [],
    activityFetching: false,
    activityPages: 1,

    activityObj: {
        datum_pocetka: moment().format('DD.MM.YYYY'),
        datum_zavrsetka: moment().format('DD.MM.YYYY'),
        vreme_pocetka: null,
        vreme_zavrsetka: null,
        id_status: 3,
    },
    activityObjFetching: false,
    activityObjFetchingFailed: false,
    activityPostingSuccess: false,

    deleteActivitySuccess: false,

    distribution: [],
    distributionFetching: false,
    distributionFetchingFailed: false,
    //DROPDOWN
    cropGroupDropdown: [],
    cropGroupFetching: false,
    cropGroupFetchingFailed: false,
    cropSubgroupDropdown: [],
    cropSubgroupFetching: false,
    cropSubgroupFetchingFailed: false,
    crops: [],
    cropFetching: false,
    cropFetchingFailed: false,

    woGroupDropdown: [],
    wogroupFetching: false,
    wogroupFetchingFailed: false,
    woDropdown: [],
    woDropdownFetching: false,
    woDropdownFetchingFailed: false,

    machinesGroupDropdown: [],
    machinesGroupFetching: false,
    machinesGroupFetchingFailed: false,
    machinesSubgroupDropdown: [],
    machinesSubgroupFetching: false,
    machinesSubgroupFetchingFailed: false,
    machineDropdown: [],
    machineDropdownFetching: false,
    machineDropdownFetchingFailed: false,

    implementGroupDropdown: [],
    implementGroupFetching: false,
    implementGroupFetchingFailed: false,
    implementSubgroupDropdown: [],
    implementSubgroupFetching: false,
    implementSubgroupFetchingFailed: false,
    implementDropdown: [],
    implementDropdownFetching: false,
    implementDropdownFetchingFailed: false,

    materialGroupDropdown: [],
    materialGroupFetching: false,
    materialGroupFetchingFailed: false,
    materialSubgroupDropdown: [],
    materialSubgroupFetching: false,
    materialSubgroupFetchingFailed: false,
    materialDropdown: [],
    materialDropdownFetching: false,
    materialDropdownFetchingFailed: false,

    unitDropdown: [],
    unitDropdownFetching: false,
    unitDropdownFetchingFailed: false,

    statusDropdown: [],
    statusFetching: false,
    statusFetchingFailed: false,

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ActivityType.GET_DISTRIBUTION_CALL: {
            const distributionFetching = true;
            return state.merge({ distributionFetching });
            break;
        }

        case ActivityType.GET_DISTRIBUTION_SUCCESS: {
            const distributionFetching = false;
            let distribution = action.data.data;
            let md = distribution.reduce((accumulator, currentValue, index) => {
                if (accumulator.length > 0 && accumulator.some((item) => {
                    return item.id_materijal == currentValue.id_materijal
                })) {
                    accumulator.push(Object.assign(currentValue, { header: false }));
                } else {
                    accumulator.push(Object.assign(currentValue, { header: true }));
                }
                return accumulator;
            }, [])
            let grouped = _.orderBy(md, 'id_materijal')
            return state.merge({ distributionFetching, distribution: grouped });
            break;
        }


        case ActivityType.GET_DISTRIBUTION_FAILED: {
            const distributionFetching = false;
            const distributionFetchingFailed = true;
            return state.merge({ distributionFetching, distributionFetchingFailed });
            break;
        }

        case ActivityType.DELETE_ACTIVITY_SUCCESS: {
            const deleteActivitySuccess = true;
            return state.merge({ deleteActivitySuccess });
            break;
        }


        case ActivityType.GET_VIEW_ACTIVITIES_CALL: {
            const activityFetching = true;
            return state.merge({ activityFetching });
            break;
        }

        case ActivityType.GET_VIEW_ACTIVITIES_SUCCESS: {
            const activity = action.data.data;
            const activityFetching = false;
            const activityPages = action.data.total_pages
            return state.merge({ activity, activityFetching, activityPages });
            break;
        }

        case ActivityType.GET_VIEW_ACTIVITIES_FAILED: {
            const activityFetchingFailed = true;
            const activityFetching = false;
            return state.merge({ activityFetching, activityFetchingFailed });
            break;
        }

        case ActivityType.ADD_ACTIVITY_CALL: {
            const activityPosting = true;
            return state.merge({ activityPosting });
            break;
        }

        case ActivityType.ADD_ACTIVITY_SUCCESS: {
            const activityPosting = false;
            const activityPostingSuccess = true;
            return state.merge({ activityPosting, activityPostingSuccess, activityObj: INITIAL_STATE.activityObj })
        }

        case ActivityType.ADD_ACTIVITY_FAILED: {
            const activityPosting = false;
            const activityPostingFailed = true;
            return state.merge({ activityPosting, activityPostingFailed });
            break;
        }

        case ActivityType.GET_ACTIVITY_BY_ID_CALL: {
            const activityObjFetching = true;
            return state.merge({ activityObjFetching });
            break;
        }

        case ActivityType.GET_ACTIVITY_BY_ID_SUCCESS: {
            const activityObj = action.data.data;
            const activityObjFetching = false;
            if (action.options && action.options.copy == true) {
                delete activityObj.id;
            }
            return state.merge({ activityObj, activityObjFetching });
            break;
        }

        case ActivityType.GET_ACTIVITY_BY_ID_FAILED: {
            const activityObjFetchingFailed = true;
            const activityObjFetching = false;
            return state.merge({ activityObjFetching, activityObjFetchingFailed });
            break;
        }
        //************************ */
        case ActivityType.GET_CROP_GROUP_DROPDOWN_CALL: {
            const cropGroupFetching = true;
            return state.merge({ cropGroupFetching });
            break;
        }

        case ActivityType.GET_CROP_GROUP_DROPDOWN_SUCCESS: {
            const cropGroupDropdown = action.data.data;
            const cropGroupFetching = false;
            return state.merge({ cropGroupDropdown, cropGroupFetching });
            break;
        }

        case ActivityType.GET_CROP_GROUP_DROPDOWN_FAILED: {
            const cropGroupFetchingFailed = true;
            const cropGroupFetching = false;
            return state.merge({ cropGroupFetchingFailed, cropGroupFetching });
            break;
        }

        case ActivityType.GET_CROP_SUBGROUP_DROPDOWN_CALL: {
            const cropSubgroupFetching = true;
            return state.merge({ cropSubgroupFetching });
            break;
        }

        case ActivityType.GET_CROP_SUBGROUP_DROPDOWN_SUCCESS: {
            const cropSubgroupDropdown = action.data.data;
            const cropSubgroupFetching = false;
            return state.merge({ cropSubgroupDropdown, cropSubgroupFetching });
            break;
        }

        case ActivityType.GET_CROP_SUBGROUP_DROPDOWN_FAILED: {
            const cropSubgroupFetchingFailed = true;
            const cropSubgroupFetching = false;
            return state.merge({ cropSubgroupFetchingFailed, cropSubgroupFetching });
            break;
        }

        case ActivityType.GET_CROP_CALL: {
            const cropFetching = true;
            return state.merge({ cropFetching });
            break;
        }

        case ActivityType.GET_CROP_SUCCESS: {
            const crops = action.data.data;
            const cropFetching = false;
            return state.merge({ crops, cropFetching });
            break;
        }

        case ActivityType.GET_CROP_FAILED: {
            const cropFetchingFailed = true;
            const cropFetching = false;
            return state.merge({ cropFetchingFailed, cropFetching });
            break;
        }

        case ActivityType.GET_WORKING_OPERATION_GROUP_CALL: {
            const wogroupFetching = true;
            return state.merge({ wogroupFetching });
            break;
        }

        case ActivityType.GET_WORKING_OPERATION_GROUP_SUCCESS: {
            const woGroupDropdown = action.data.data;
            const wogroupFetching = false;
            return state.merge({ woGroupDropdown, wogroupFetching });
            break;
        }

        case ActivityType.GET_WORKING_OPERATION_GROUP_FAILED: {
            const wogroupFetchingFailed = true;
            const wogroupFetching = false;
            return state.merge({ wogroupFetchingFailed, wogroupFetching });
            break;
        }
        case ActivityType.GET_WORKING_OPERATION_DROPDOWN_CALL: {
            const woDropdownFetching = true;
            return state.merge({ woDropdownFetching });
            break;
        }

        case ActivityType.GET_WORKING_OPERATION_DROPDOWN_SUCCESS: {
            const woDropdown = action.data.data;
            const woDropdownFetching = false;
            return state.merge({ woDropdown, woDropdownFetching });
            break;
        }

        case ActivityType.GET_WORKING_OPERATION_DROPDOWN_FAILED: {
            const woDropdownFetchingFailed = true;
            const woDropdownFetching = false;
            return state.merge({ woDropdownFetchingFailed, woDropdownFetching });
            break;
        }


        case ActivityType.GET_MACHINES_GROUP_CALL: {
            const machinesGroupFetching = true;
            return state.merge({ machinesGroupFetching });
            break;
        }

        case ActivityType.GET_MACHINES_GROUP_SUCCESS: {
            const machinesGroupDropdown = action.data.data;
            const machinesGroupFetching = false;
            return state.merge({ machinesGroupDropdown, machinesGroupFetching });
            break;
        }

        case ActivityType.GET_MACHINES_GROUP_FAILED: {
            const machinesGroupFetchingFailed = true;
            const machinesGroupFetching = false;
            return state.merge({ machinesGroupFetchingFailed, machinesGroupFetching });
            break;
        }

        case ActivityType.GET_MACHINES_SUBGROUP_CALL: {
            const machinesSubgroupFetching = true;
            return state.merge({ machinesSubgroupFetching });
            break;
        }

        case ActivityType.GET_MACHINES_SUBGROUP_SUCCESS: {
            const machinesSubgroupDropdown = action.data.data;
            const machinesSubgroupFetching = false;
            return state.merge({ machinesSubgroupDropdown, machinesSubgroupFetching });
            break;
        }

        case ActivityType.GET_MACHINES_SUBGROUP_FAILED: {
            const machinesSubgroupFetchingFailed = true;
            const machinesSubgroupFetching = false;
            return state.merge({ machinesSubgroupFetchingFailed, machinesSubgroupFetching });
            break;
        }

        case ActivityType.GET_MACHINES_DROPDOWN_CALL: {
            const machineDropdownFetching = true;
            return state.merge({ machineDropdownFetching });
            break;
        }

        case ActivityType.GET_MACHINES_DROPDOWN_SUCCESS: {
            const machineDropdown = action.data.data;
            const machineDropdownFetching = false;
            return state.merge({ machineDropdown, machineDropdownFetching });
            break;
        }

        case ActivityType.GET_MACHINES_DROPDOWN_FAILED: {
            const machineDropdownFetchingFailed = true;
            const machineDropdownFetching = false;
            return state.merge({ machineDropdownFetchingFailed, machineDropdownFetching });
            break;
        }

        case ActivityType.GET_IMPLEMENTS_GROUP_CALL: {
            const implementGroupFetching = true;
            return state.merge({ implementGroupFetching });
            break;
        }

        case ActivityType.GET_IMPLEMENTS_GROUP_SUCCESS: {
            const implementGroupDropdown = action.data.data;
            const implementGroupFetching = false;
            return state.merge({ implementGroupDropdown, implementGroupFetching });
            break;
        }

        case ActivityType.GET_IMPLEMENTS_GROUP_FAILED: {
            const implementGroupFetchingFailed = true;
            const implementGroupFetching = false;
            return state.merge({ implementGroupFetchingFailed, implementGroupFetching });
            break;
        }

        case ActivityType.GET_IMPLEMENTS_SUBGROUP_CALL: {
            const implementSubgroupFetching = true;
            return state.merge({ implementSubgroupFetching });
            break;
        }

        case ActivityType.GET_IMPLEMENTS_SUBGROUP_SUCCESS: {
            const implementSubgroupDropdown = action.data.data;
            const implementSubgroupFetching = false;
            return state.merge({ implementSubgroupDropdown, implementSubgroupFetching });
            break;
        }

        case ActivityType.GET_IMPLEMENTS_SUBGROUP_FAILED: {
            const implementSubgroupFetchingFailed = true;
            const implementSubgroupFetching = false;
            return state.merge({ implementSubgroupFetchingFailed, implementSubgroupFetching });
            break;
        }

        case ActivityType.GET_IMPLEMENTS_DROPDOWN_CALL: {
            const implementDropdownFetching = true;
            return state.merge({ implementDropdownFetching });
            break;
        }

        case ActivityType.GET_IMPLEMENTS_DROPDOWN_SUCCESS: {
            const implementDropdown = action.data.data;
            const implementDropdownFetching = false;
            return state.merge({ implementDropdown, implementDropdownFetching });
            break;
        }

        case ActivityType.GET_IMPLEMENTS_DROPDOWN_FAILED: {
            const implementDropdownFetchingFailed = true;
            const implementDropdownFetching = false;
            return state.merge({ implementDropdownFetchingFailed, implementDropdownFetching });
            break;
        }

        case ActivityType.GET_MATERIAL_GROUP_CALL: {
            const materialGroupFetching = true;
            return state.merge({ materialGroupFetching });
            break;
        }

        case ActivityType.GET_MATERIAL_GROUP_SUCCESS: {
            const materialGroupDropdown = action.data.data;
            const materialGroupFetching = false;
            return state.merge({ materialGroupDropdown, materialGroupFetching });
            break;
        }

        case ActivityType.GET_MATERIAL_GROUP_FAILED: {
            const materialGroupFetchingFailed = true;
            const materialGroupFetching = false;
            return state.merge({ materialGroupFetchingFailed, materialGroupFetching });
            break;
        }
        case ActivityType.GET_STATUS_CALL: {
            const statusFetching = true;
            return state.merge({ statusFetching });
            break;
        }

        case ActivityType.GET_STATUS_SUCCESS: {
            const statusDropdown = action.data.data;
            const statusFetching = false;
            return state.merge({ statusDropdown, statusFetching });
            break;
        }

        case ActivityType.GET_STATUS_FAILED: {
            const statusFetchingFailed = true;
            const statusFetching = false;
            return state.merge({ statusFetchingFailed, statusFetching });
            break;
        }

        case ActivityType.GET_MATERIAL_SUBGROUP_CALL: {
            const materialSubgroupFetching = true;
            return state.merge({ materialSubgroupFetching });
            break;
        }

        case ActivityType.GET_MATERIAL_SUBGROUP_SUCCESS: {
            const materialSubgroupDropdown = action.data.data;
            const materialSubgroupFetching = false;
            return state.merge({ materialSubgroupDropdown, materialSubgroupFetching });
            break;
        }

        case ActivityType.GET_MATERIAL_SUBGROUP_FAILED: {
            const materialSubgroupFetchingFailed = true;
            const materialSubgroupFetching = false;
            return state.merge({ materialSubgroupFetchingFailed, materialSubgroupFetching });
            break;
        }

        case ActivityType.GET_MATERIAL_DROPDOWN_CALL: {
            const materialDropdownFetching = true;
            return state.merge({ materialDropdownFetching });
            break;
        }

        case ActivityType.GET_MATERIAL_DROPDOWN_SUCCESS: {
            const materialDropdown = action.data.data;
            const materialDropdownFetching = false;
            return state.merge({ materialDropdown, materialDropdownFetching });
            break;
        }

        case ActivityType.GET_MATERIAL_DROPDOWN_FAILED: {
            const materialDropdownFetchingFailed = true;
            const materialDropdownFetching = false;
            return state.merge({ materialDropdownFetchingFailed, materialDropdownFetching });
            break;
        }

        case ActivityType.GET_UNIT_DROPDOWN_CALL: {
            const unitDropdownFetching = true;
            return state.merge({ unitDropdownFetching });
            break;
        }

        case ActivityType.GET_UNIT_DROPDOWN_SUCCESS: {
            const unitDropdown = action.data.data;
            const unitDropdownFetching = false;
            return state.merge({ unitDropdown, unitDropdownFetching });
            break;
        }

        case ActivityType.GET_UNIT_DROPDOWN_FAILED: {
            const unitDropdownFetchingFailed = true;
            const unitDropdownFetching = false;
            return state.merge({ unitDropdownFetchingFailed, unitDropdownFetching });
            break;
        }

        case ActivityType.SET_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ fieldObj: data })
            break;
        }

        case ActivityType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;


        default:
            return state;
    }
}