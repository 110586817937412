import React from 'react';
import i18n from './../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './workingoperation.module.css';
import ReactSelect from './../../../utils/ReactSelect';

export default class WorkingOperation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workingOperationType: [],
            // workingOperationDescription: '',
            workingOperation: {}
        };
    };

    componentDidMount() {
        this.getByURL('working_operation/radna_operacija_klijent', 'workingOperationType');
        if (this.props.workingOperation) this.setState({ workingOperation: this.props.workingOperation }, () => {
            // if (this.props.workingOperationDescription) this.setState({ workingOperationDescription: this.props.workingOperationDescription })
        });
    };

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    };

    handleAdd = () => {
        if (this.state.workingOperation) {
            if (this.state.workingOperation.label && this.state.workingOperation.value) {
                let hasYieldIndicator = this.state.workingOperationType.some(type => {
                    if (type.id === this.state.workingOperation.value && type.indikator_prinosa === true) { return true };
                })
                this.props.handleWorkingOperationObj(
                    this.state.workingOperation,
                    hasYieldIndicator
                    // this.state.workingOperationDescription
                );
            }
        }
        else wentWrong(i18n.t('You have to select a working operation'));
    };

    render() {

        let workingOperationType = this.state.workingOperationType.map(item => { return { label: item.naziv, value: item.id } });
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {i18n.t('Add Working Operation')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className={styles.content}>
                            <ReactSelect
                                closeMenuOnSelect={true}
                                isClearable={true}
                                isMulti={false}
                                value={this.state.workingOperation}
                                label={i18n.t('Working operation')}
                                maxMenuHeight={320}
                                onChange={(e) => this.setState({ workingOperation: e })}
                                options={workingOperationType}
                                className="multi-select-activity-form-new"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            {/* <TextField
                                label={i18n.t('Working operation description')}
                                value={this.state.workingOperationDescription}
                                style={{ width: '510px', marginTop: '20px', marginLeft: '20px' }}
                                multiline
                                rows={12}
                                variant='outlined'
                                onChange={(e) => { this.setState({ workingOperationDescription: e.target.value }); }} >
                            </TextField> */}
                        </div>
                        <div className={styles.center}>
                            <Button onClick={() => this.handleAdd()} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Add')}</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};