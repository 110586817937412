import axios from './../../utils/AxiosWrapper';

export const Loader = (props, packages, type, data) => {

    let key = 'OUP4p7JqGlARwkMF';

    props.called.forEach((item, i) => {
        if (item === false) {
            axios.get(`https://my.meteoblue.com/packages/${packages[i]}?lat=${props.zoom_data.center[0]}&lon=${props.zoom_data.center[1]}&apikey=${key}`)
                .then(response => { props.handleCalled(response, type[i], data[i]); })
                .catch(err => { console.log(err); })
        }
    })
};
