import React from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import checkboxHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css';
import {
    getEnterpriseReport
} from '../../actions/reports/ReportsActions';
import { getSeasonDropDown, getCropForPreceding, getWODropdown, getMaterialDropdown } from '../../actions/DropDownActions';
import { getFieldDropDown, getSectorDropDown } from '../../actions/catastral_data/FieldActions';
import { getImplement } from '../../actions/activity/ActivityActions';
import { getMachinesDropdown } from '../../actions/activity/ActivityActions';
import i18n from '../../i18n/i18n';
import ReactSelect from '../../utils/ReactSelect';
import immutable from 'seamless-immutable';
import { Button } from '@material-ui/core';
import DatePickerForFilter from '../../utils/DatePickerForFilter';
import { exportDocument } from '../../actions/ExportActions';
import { Export } from '../../utils/Export';
import DashboardDrawer from '../../utils/DashboardDrawer';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import ActivitiesMain from '../activities/ActivitiesMain';
import {
    setInitialState, getById
} from '../../actions/activity/ActivityActions';
const CheckboxTable = checkboxHOC(ReactTable);

const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
});
export class Enterprise extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            view: false,
            selection: [],
            selectAll: false
        };
        this.tableScrollTop = 0;
    }

    componentDidMount() {
        if (this.props.seasonDropDown.length === 0) {
            this.props.getSeasonDropDown();
        }
        if (this.props.fieldDropDown.length == 0) {
            this.props.getFieldDropDown();
        }
        if (this.props.woDropdown.length === 0) {
            this.props.getWODropdown();
        }
        if (this.props.materialDropdown.length === 0) {
            this.props.getMaterialDropdown();
        }
        if (this.props.implementDropdown.length === 0) {
            this.props.getImplement();
        }
        if (this.props.machineDropdown.length === 0) {
            this.props.getMachinesDropdown();
        }
        if (this.props.sectorDropDown.length === 0) {
            this.props.getSectorDropDown();
        }
    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            seasonDropDown: immutable.asMutable(nextProps.seasonDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            fieldDropDown: immutable.asMutable(nextProps.fieldDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            woDropdown: immutable.asMutable(nextProps.woDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            materialDropdown: immutable.asMutable(nextProps.materialDropdown.map((item) => { return { label: item.label, value: item.value } }), { deep: true }),
            implementDropdown: immutable.asMutable(nextProps.implementDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            machineDropdown: immutable.asMutable(nextProps.machineDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
            sectorDropDown: immutable.asMutable(nextProps.sectorDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    toggleSelection = (key, shift, row) => {
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        if (keyIndex >= 0) {
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
            this.props.setInitialState('activityObj');
        } else {
            selection.push(key);
        }
        this.setState({ selection });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            currentRecords.forEach(item => {
                selection.push(item._original.id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };


    onSearchClick() {
        this.setState({ showFilters: !this.state.showFilters });
    }

    onExportClick = () => {
        this.setState({ export: !this.state.export });
    }
    exportFunction = () => {
        const table = this.reactTable;
        this.props.exportDocument(this.state.docTitle, table.state, 'api/report/statistic');
        this.setState({ export: !this.state.export });
    }

    onPieClick = () => {
        this.props.history.push('/enterprise_cost');
    }

    onProfitClick = () => {
        this.props.history.push('/enterprise_cost');
    }

    onTableClick = () => {
        this.props.history.push('/enterprise');
    }

    handleChangeValue = name => selected => {
        this.setState((state) => {
            let tabla = state[name];
            tabla = selected;
            return { ...state, tabla };
        })
    }

    returnName = (d, name, child, sector) => {
        let returnValue = ""
        if (d[name] && d[name].length > 0) {
            return d[name].map((item, index) => {
                returnValue = item[child] && item[child].naziv;
                if (sector) {
                    returnValue = item[child] && item[child][sector] ? item[child][sector].naziv : ''
                }
                if (index != d[name].length - 1) {
                    returnValue += ' , ';
                }
                return returnValue
            })
        }
    }

    returnNameMaterijal = (d) => {
        let returnValue = ""
        if (d.materijali && d.materijali.length > 0) {
            return d.materijali.map((item, index) => {
                returnValue = item.naziv;
                if (index != d.materijali.length - 1) {
                    returnValue += ' , ';
                }
                return returnValue
            })
        }
    }

    closeModal = () => {
        this.setState({
            view: false
        })
    }

    onViewClick = () => {
        const { getById } = this.props;
        const { selection } = this.state;
        this.setState({
            view: true
        }, () => {
            getById([selection])
        })
    }


    render() {
        const { theme, clientProfile } = this.props;
        const columns = [
            // {
            //     Header: i18n.t('ID'),
            //     accessor: 'id'
            // },
            {
                Header: i18n.t('Season'),
                accessor: 'sezona',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        defaultValue={this.state.seasonDropDown && this.state.seasonDropDown.find(function (element) {
                            if (clientProfile) {
                                return element.value === clientProfile.id_default_sezona;
                            }
                        })}
                        closeMenuOnSelect={false}
                        options={this.state.seasonDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('Sector'),
                // accessor: 'sektor_naziv',
                accessor: d => this.returnName(d, 'table', 'tabla', 'sektor'),
                id: 'table.tabla.id_sektor',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.sectorDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'table.id_tabla',
                Header: i18n.t('Field'),
                accessor: d => this.returnName(d, 'table', 'tabla'),
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.fieldDropDown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'datum_pocetka',
                Header: i18n.t('Start date'),
                accessor: d => d.datum_pocetka ? moment(d.datum_pocetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}

                    />
            },
            {
                id: 'datum_zavrsetka',
                Header: i18n.t('End date'),
                accessor: d => d.datum_zavrsetka ? moment(d.datum_zavrsetka, 'DD.MM.YYYY').format('DD.MM.YYYY') : '',
                Filter: ({ filter, onChange }) =>
                    <DatePickerForFilter
                        onChange={({ startDate, endDate }) => {
                            onChange({ startDate, endDate });
                        }}
                    />
            },
            {
                Header: i18n.t('Working operation'),
                accessor: 'radna_operacija',
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.woDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },


            {
                id: 'materijali.id_materijal',
                Header: i18n.t('Material'),
                accessor: d => this.returnNameMaterijal(d),
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.materialDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'pogonskeMasine.pogonska.id_pogonska_masina',
                Header: i18n.t('Machines'),
                accessor: d => this.returnName(d, 'pogonskeMasine', 'pogonska'),
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.machineDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                id: 'prikljucneMasine.id_prikljucna_masina',
                Header: i18n.t('Implements'),
                accessor: d => this.returnName(d, 'prikljucneMasine', 'prikljucna'),
                Filter: ({ filter, onChange }) =>
                    <ReactSelect
                        filter
                        isMulti
                        name="naziv"
                        onChange={value => {
                            onChange(value)
                        }}
                        closeMenuOnSelect={false}
                        options={this.state.implementDropdown}
                        className="multi-select-in-filter"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
            },
            {
                Header: i18n.t('User'),
                accessor: 'username'
            }

        ];
        const filterOptions = {
            defaultFiltered:
                [
                    {
                        id: 'sezona',
                        value: clientProfile && this.state.seasonDropDown ? [this.state.seasonDropDown.find(function (element) {
                            return element.value === clientProfile.id_default_sezona;
                        })] : []
                    }
                ],
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            },
        };
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll } = this.state;
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (state, rowInfo, instance) => {
                if (rowInfo) {
                    const selected = this.isSelected(rowInfo.original.id);
                    return {
                        style: {
                            backgroundColor: selected ? "#ACDAF1" : "inherit"
                        }
                    };
                }
                return {};
            },
            getTheadFilterProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.primary.main
                    }
                };
            },
            getTheadTrProps: () => {
                return {
                    style: {
                        backgroundColor: theme.palette.secondary.main,
                        color: '#FFFFFF'
                    }
                };
            }
        };
        return (
            <div className="page">
                <div className="table-header">
                    {!this.props.kontakt_osoba ?
                        <Button
                            onClick={() => this.onSearchClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="add"
                            className="action-button"
                        >
                            <span className="icon-search headerIcon" />
                        </Button>
                        : null}
                    <Button
                        onClick={() => this.onExportClick()}
                        variant="fab"
                        color="secondary"
                        aria-label="add"
                        className="action-button"
                    >
                        <span className="icon-download headerIcon" />
                    </Button>
                    {/* {this.state.selection.length === 1 &&
                        <Button
                            onClick={() => this.onViewClick()}
                            variant="fab"
                            color="secondary"
                            aria-label="edit"
                            className="action-button"
                        >
                            <span className="icon-Informacija headerIcon" />
                        </Button>
                    } */}
                </div>

                <CheckboxTable
                    data={this.props.enterpriseReport}
                    ref={r => (this.checkboxTable = r)}
                    getTheadFilterThProps={() => { return { style: { position: "inherit", overflow: "inherit" } } }}
                    columns={columns}
                    keyField={'id'}
                    pageSizeOptions={[10, 25, 50, 100]}
                    defaultPageSize={25}
                    filterable={this.state.showFilters}
                    pages={this.props.enterpriseReportPages}
                    loading={this.props.enterpriseReportFetching}
                    manual
                    showPaginationTop
                    showPaginationBottom={false}
                    onFetchData={(state, instance) => this.props.getEnterpriseReport(state, instance)}
                    className={"-striped -highlight "}
                    previousText={i18n.t('Previous')}
                    nextText={i18n.t('Next')}
                    loadingText={i18n.t('Loading...')}
                    noDataText={i18n.t('No rows found')}
                    pageText={i18n.t('Page')}
                    ofText={i18n.t('of')}
                    rowsText={i18n.t('rows')}
                    {...filterOptions}
                    {...checkboxProps}
                    PreviousComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                    NextComponent={(props) => (
                        <button {...props} className='-btn' style={{
                            backgroundColor: theme.palette.primary.main,
                            color: '#FFFFFF'
                        }}>{props.children}</button>
                    )}
                />
                {this.state.view && <ActivitiesMain add={this.state.view}
                    handleClose={this.closeModal} readOnly={true} />}
                {/* <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} onProfitClick={this.onProfitClick} isVisible={true} /> */}
                {this.state.export && <Export
                    export={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    handleClose={this.onExportClick}
                    exportDoc={this.exportFunction} />
                }
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        enterpriseReport: state.reportsReducer.enterpriseReport,
        enterpriseReportFetching: state.reportsReducer.enterpriseReportFetching,
        enterpriseReportPages: state.reportsReducer.enterpriseReportPages,
        seasonDropDown: state.appReducer.seasonDropDown,
        seasonDropDownFetching: state.appReducer.seasonDropDownFetching,
        seasonDropDownFetchingFailed: state.appReducer.seasonDropDownFetchingFailed,
        materialDropdown: state.appReducer.materialDropdown,
        woDropdown: state.appReducer.woDropdown,
        fieldDropDown: state.fieldReducer.fieldDropDown,
        machineDropdown: state.activityReducer.machineDropdown,
        implementDropdown: state.activityReducer.implementDropdown,
        clientProfile: state.appReducer.clientProfile,
        sectorDropDown: state.fieldReducer.sectorDropDown,
        sectorFetchingDropDown: state.fieldReducer.sectorFetchingDropDown,
        sectorFetchingFailedDropDown: state.fieldReducer.sectorFetchingFailedDropDown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getEnterpriseReport: (state, instance) => dispatch(getEnterpriseReport(state, instance)),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),
        getCropForPreceding: () => dispatch(getCropForPreceding()),
        getFieldDropDown: () => dispatch(getFieldDropDown()),
        setInitialState: (component) => dispatch(setInitialState(component)),
        getById: (id) => dispatch(getById(id)),
        getWODropdown: () => dispatch(getWODropdown()),
        getMaterialDropdown: () => dispatch(getMaterialDropdown()),
        getMachinesDropdown: () => dispatch(getMachinesDropdown()),
        getImplement: () => dispatch(getImplement()),
        getSectorDropDown: () => dispatch(getSectorDropDown()),
        exportDocument: (title, state, url) => dispatch(exportDocument(title, state, url)),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(Enterprise));