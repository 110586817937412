import immutable from 'seamless-immutable';
import { Type as TrafficType } from '../../actions/traffic/TrafficActions';


export const INITIAL_STATE = immutable({
    //silosi
    storages: [],
    storagesFetching: false,
    storagesFetchingFailed: false,
    storagesPages: 1,
    storageObj: {},
    storagePosting: false,
    storagePostingSuccess: false,
    storagePostingFailed: false,
    silosDropdown: [],
    materialDropdown: [],
    //loads
    loads: [],
    loadsFetching: false,
    loadsFetchingFailed: false,
    loadsPages: 1,
    loadsObj: {},
    loadsPosting: false,
    loadsPostingSuccess: false,
    loadsPostingFailed: false,
    delete: false,
    deleteFailed: false,

    //transactions
    transactions: [],
    transactionsFetching: false,
    transactionsFetchingFailed: false,
    errorStatus: null
});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {


        case TrafficType.GET_TRANSACTION_CALL: {
            const transactionsFetching = true;
            return state.merge({ transactionsFetching });
            break;
        }

        case TrafficType.GET_TRANSACTION_SUCCESS: {
            const transactions = action.data.data;
            const transactionsFetching = false;
            return state.merge({ transactions, transactionsFetching });
            break;
        }

        case TrafficType.GET_TRANSACTION_FAILED: {
            const transactionsFetchingFailed = true;
            return state.merge({ transactionsFetchingFailed });
            break;
        }


        case TrafficType.GET_STORAGES_CALL: {
            const storagesFetching = true;
            return state.merge({ storagesFetching });
            break;
        }

        case TrafficType.GET_STORAGES_SUCCESS: {
            const storages = action.data.data;
            const storagesPages = action.data.total_pages
            const storagesFetching = false;
            return state.merge({ storages, storagesPages, storagesFetching });
            break;
        }

        case TrafficType.GET_STORAGES_FAILED: {
            const storagesFetchingFailed = true;
            return state.merge({ storagesFetchingFailed });
            break;
        }

        case TrafficType.GET_STORAGE_DROPDOWN_SUCCESS: {
            const silosDropdown = action.data.data;
            return state.merge({ silosDropdown });
            break
        }

        case TrafficType.GET_MATERIAL_DROPDOWN_SUCCESS: {
            const materialDropdown = action.data.data;
            return state.merge({ materialDropdown });
            break
        }

        case TrafficType.ADD_STORAGE_CALL: {
            const storagePosting = true;
            return state.merge({ storagePosting })
            break;
        }

        case TrafficType.ADD_STORAGE_SUCCESS: {
            const storagePosting = false;
            const storagePostingSuccess = true;
            return state.merge({ storagePosting, storagePostingSuccess });
            break;
        }

        case TrafficType.ADD_STORAGE_FAILED: {
            const storagePosting = false;
            const storagePostingFailed = true;
            return state.merge({ storagePosting, storagePostingFailed });
            break;
        }

        case TrafficType.DELETE_STORAGE_SUCCESS: {
            const array = state.storages.asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ storages: array, deleted: deleted })
            break;
        }

        case TrafficType.DELETE_STORAGE_FAILED: {
            const deleteFailed = true;
            return state.merge({ deleteFailed });
            break;
        }

        case TrafficType.SET_ROW_IN_REDUCER: {
            const data = action.rowInfo.asMutable();
            return state.merge({ [action.name]: data })
            break;
        }

        case TrafficType.GET_LOADS_CALL: {
            const loadsFetching = true;
            return state.merge({ loadsFetching });
            break;
        }

        case TrafficType.GET_LOADS_SUCCESS: {
            const loads = action.data.data;
            const loadsPages = action.data.total_pages
            const loadsFetching = false;
            return state.merge({ loads, loadsPages, loadsFetching });
            break;
        }

        case TrafficType.GET_LOADS_FAILED: {
            const loadsFetchingFailed = true;
            return state.merge({ loadsFetchingFailed });
            break;
        }


        case TrafficType.ADD_LOAD_CALL: {
            const loadsPosting = true;
            return state.merge({ loadsPosting })
            break;
        }

        case TrafficType.ADD_LOAD_SUCCESS: {
            const loadsPosting = false;
            const loadsPostingSuccess = true;
            return state.merge({ loadsPosting, loadsPostingSuccess });
            break;
        }

        case TrafficType.ADD_LOAD_FAILED: {
            const loadsPosting = false;
            const loadsPostingFailed = true;
            const errorStatus = action.error;
            return state.merge({ loadsPosting, loadsPostingFailed, errorStatus });
            break;
        }

        case TrafficType.DELETE_LOAD_SUCCESS: {
            const array = state.loads.asMutable();
            const forDelete = action.forDelete;
            for (let i = 0; i < array.length; i++) {
                for (let j = 0; j <= forDelete.length; j++) {
                    if (array[i] && array[i].id === forDelete[j]) {
                        array.splice(i, 1);
                    }
                }
            }
            const deleted = true;
            return state.merge({ loads: array, deleted: deleted })
            break;
        }

        case TrafficType.DELETE_LOAD_FAILED: {
            const deleteFailed = true;
            return state.merge({ deleteFailed });
            break;
        }


        case TrafficType.SET_INITIAL_STATE:
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;


        default:
            return state;
    }
}
