import axios from '../../utils/AxiosWrapper';
export const Type = {
    //VIEW
    VIEW_SENSOR_CALL: 'VIEW_SENSOR_CALL',
    VIEW_SENSOR_SUCCESS: 'VIEW_SENSOR_SUCCESS',
    VIEW_SENSOR_FAILED: 'VIEW_SENSOR_FAILED',
    //ADD
    ADD_SENSOR_CALL: 'ADD_SENSOR_CALL',
    ADD_SENSOR_SUCCESS: 'ADD_SENSOR_SUCCESS',
    ADD_SENSOR_FAILED: 'ADD_SENSOR_FAILED',
    //DELETE
    DELETE_SENSOR_CALL: 'DELETE_SENSOR_CALL',
    DELETE_SENSOR_SUCCESS: 'DELETE_SENSOR_SUCCESS',
    DELETE_SENSOR_FAILED: 'DELETE_SENSOR_FAILED',

    //UTILS
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_SENSOR_IN_REDUCER: 'SET_SENSOR_IN_REDUCER',

    VIEW_SENSOR_DATA_CALL: 'VIEW_SENSOR_DATA_CALL',
    VIEW_SENSOR_DATA_SUCCESS: 'VIEW_SENSOR_DATA_SUCCESS',
    VIEW_SENSOR_DATA_FAILED: 'VIEW_SENSOR_DATA_FAILED',

};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_SENSOR_IN_REDUCER,
            rowInfo: rowInfo,
        })
    }
}

export function addSensor(sensorObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_SENSOR_CALL
        });

        axios.post('api/sensor/create_sensor',
            sensorObj
        )
            .then(function (response) {
                dispatch({
                    type: Type.ADD_SENSOR_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_SENSOR_FAILED
                });
            });
    }
}

export function getSensors(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.VIEW_SENSOR_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/sensor/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.VIEW_SENSOR_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.VIEW_SENSOR_FAILED
                });
            });
    }
}

export function deleteSensor(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_SENSOR_CALL,
        });

        axios.post(`api/sensor/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_SENSOR_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_SENSOR_FAILED
                });
            });
    }
}

export function getSensorData(uid, value) {
    return (dispatch) => {

        dispatch({
            type: Type.VIEW_SENSOR_DATA_CALL
        });

        var config = {
            params: { uid, value }
        };
        axios.get('api/sensor/readSensorData', config)
            .then(function (response) {
                dispatch({
                    type: Type.VIEW_SENSOR_DATA_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.VIEW_SENSOR_DATA_FAILED
                });
            });
    }
}

