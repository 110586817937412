import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styles } from './ImageryStyles';
import ndwi from './../satelliteinfo/ndwi.png';

export class NDWI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.language === 1 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NDWI (Indeks normalizovane razlike vode) je vegetativni indeks koji označava količinu vode prisutnu u
                                biljkama, te je dobar pokazatelj suše. Ukoliko je zemljište golo ili usevi nisu sasvim spojili redove, na
                                osnovu njega se mogu pronaći delovi njive na kojima se skuplja voda. ima opseg vrednosti od -1 do +1.
                                Vodene površine imaju pozitivne vrednosti a zemljište I vegetacija imaju vrednosti oko 0 ili negativne
                                vrednosti.
                            </p>
                            <p className={classes.paragraph}>
                                Refleksija u kratkotalasno-infracrvenom delu spektra odražava promene u sadržaju vode u vegetaciji a
                                refleksija u blisko-infracrvenom delu spektra zavisi od interne strukture lišća i sadržaja suve materije u
                                lišću ali ne i sadržaja vode.
                            </p>
                            <div className={classes.image}>
                                <img src={ndwi} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NDWI (Normalized Difference Water Index)
                                The NDWI index is most appropriate for water body mapping. The water body has strong absorbability
                                and low radiation in the range from visible to infrared wavelengths. The index uses the green and Near
                                Infra-red bands of remote sensing images based on this phenomenon. The NDWI can enhance the water
                                information effectively in most cases. It is sensitive to built-up land and often results in over-estimated
                                water bodies.
                            </p>
                            <p className={classes.paragraph}>
                                Values description: Values of water bodies are larger than 0.5. Vegetation has much smaller values,
                                which results in distinguishing vegetation from water bodies easier. Built-up features have positive values
                                between zero and 0.2.
                            </p>
                            <div className={classes.image}>
                                <img src={ndwi} />
                            </div>
                        </div>
                    </Card>
                }
                 {this.props.language === 4 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                                NDWI (Normalized Difference Water Index) je vegetativni indeks koji pokazuje količinu vode prisutne u biljkama, 
                                te je dobar pokazatelj suše. Ako je tlo golo ili usjevi nisu potpuno spojeni u redove, njime se mogu pronaći 
                                dijelovi polja gdje se skuplja voda. ima raspon vrijednosti od -1 do +1. Vodene površine imaju pozitivne vrijednosti, 
                                a tlo i vegetacija imaju vrijednosti oko 0 ili negativne vrijednosti.
                            </p>
                            <p className={classes.paragraph}>
                                Refleksija u kratkovalnom infracrvenom dijelu spektra odražava promjene sadržaja vode u vegetaciji, 
                                a refleksija u bliskom infracrvenom dijelu spektra ovisi o unutarnjoj građi lišća i sadržaju suhe tvari 
                                u listovima lišću, ali ne i o sadržaju vode.
                            </p>
                            <div className={classes.image}>
                                <img src={ndwi} />
                            </div>
                        </div>
                    </Card>
                }
                {this.props.language === 5 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            NDWI (Normalized Difference Water Index) je vegetacijski indeks, ki označuje količino vode, prisotne v
                                rastlin in je dober pokazatelj suše. Če so tla gola ali se pridelki niso popolnoma združili v vrste, na
                                deli polja, kjer se zbira voda, so na njenem dnu. ima razpon vrednosti od -1 do +1.
                                Vodna telesa imajo pozitivne vrednosti, tla in vegetacija pa okoli 0 ali negativne vrednosti
                                vrednote.

                            </p>
                            <p className={classes.paragraph}>
                            Odboj v kratkovalovnem infrardečem delu spektra odraža spremembe vsebnosti vode v vegetaciji a
                                odboj v bližnjem infrardečem delu spektra je odvisen od notranje zgradbe listov in vsebnosti suhe snovi v njih
                                listov, vendar ne vsebnosti vode.
                            </p>
                            <div className={classes.image}>
                                <img src={ndwi} />
                            </div>
                        </div>
                    </Card>
                }
            </div>
        )
    }
};

export default withStyles(styles)(NDWI);