import React from 'react';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import axios from '../../../utils/AxiosWrapper';
import DashboardFilters from '../DashboardFilters';
import Chart from './Chart';

const styles = theme => ({
    card: { minWidth: 275, padding: '10px', width: '90%', },
    title: { marginBottom: 16, fontSize: 14, },
});

export default class WorkersReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: null,
            season: [], sector: [], crop: [], field: [], plan: [], operation: [],
            workerLabels: [], workerValues: [],
            workPlaceLabels: [], workPlaceValues: [],
            colorsW: [], colorsWP: [],
            valuta: ''
        }
    }

    componentDidMount() {

        // axios.get('api/report/read_workers_report')
        //     .then(res => {
        //         let data = res.data.data;
        //         let sum = 0;
        //         data.forEach((item, i) => { sum += Number(item.cena); })
        //         this.setState({ total: sum });

        //         let temp_labels = [], labels = [], values = [];

        //         data.forEach((item, i) => temp_labels = getLabels(item['radno_mesto_naziv'], temp_labels).sort());
        //         temp_labels.forEach(item => { let filter = data.filter(i => i['radno_mesto_naziv'] === item); values = getData(values, filter, 'cena') });
        //         values = values.map(item => Number(item));

        //         console.log(temp_labels)
        //         console.log(values)

        //         if (data[0].id_region === 20) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' KM');
        //         else if (data[0].id_region === 13) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' $');
        //         else if (data[0].id_region === 2) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' din');
        //         else if (data[0].id_region === 23) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' €');

        //         let object = { labels: labels, values: values, colors: randomColor({ count: values.length }) };
        //         console.log(object);

        //     })
        //     .catch(err => console.log(err))



        this.getValuta();
        this.getChartData([], [], [], [], [], [], 'api/report/read_workers_report');
    }

    getValuta() {
        axios.get(`api/valuta/valuta`)
            .then(res => {
                let regionId = res.data.data[0].id

                if (regionId === 2) this.setState({ valuta: ' RSD' });
                else if (regionId === 20) this.setState({ valuta: ' BAM' });
                else if (regionId === 21) this.setState({ valuta: ' BAM' });
                else if (regionId === 22) this.setState({ valuta: ' EUR' });
                else if (regionId === 23) this.setState({ valuta: ' EUR' });
                else if (regionId === 13) this.setState({ valuta: ' USD' });
                else if (regionId === 25) this.setState({ valuta: ' ILS' });
                else if (regionId === 26) this.setState({ valuta: ' CAD' })
                else if (regionId === 27) this.setState({ valuta: ' ILS' })
                else if (regionId === 29) this.setState({ valuta: ' EUR' })
                else if (regionId === 30) this.setState({ valuta: ' EUR' })
            })
            .catch(error => { console.log('error', error); })
    }

    getChartData(season, sector, crop, field, plan, operation, tabela) {
        axios.get(tabela, { params: { season: season, sector: sector, crop: crop, field: field, plan: plan, operation: operation, tabela: tabela, } })
            .then(response => {

                if (response.data.data.length === 0) this.setState({
                    workerLabels: [], workerValues: [], colorsW: [],
                    workPlaceLabels: [], workPlaceValues: [], colorsWP: [],
                });

                else {
                    let objW = formatData(response.data.data, 'radnik_naziv', 'cena');
                    let objWP = formatData(response.data.data, 'radno_mesto_naziv', 'cena');

                    this.setState({
                        workerLabels: objW.labels, workerValues: objW.values, colorsW: objW.colors,
                        workPlaceLabels: objWP.labels, workPlaceValues: objWP.values, colorsWP: objWP.colors,
                    });
                }
            })
            .catch(error => console.log('Error: ', error));
    }

    getValues(items, states) {
        for (let i = 0; i < states.length; i++) items.push(states[i].value);
        return items;
    }

    onPieClick = () => { this.props.history.push('/workers_report'); }
    onTableClick = () => { this.props.history.push('/workers_report'); }
    onProfitClick = () => { this.props.history.push('/workers_report'); }
    handleChange = name => selectedOption => { this.setState({ [name]: selectedOption }); }

    render() {
        let totalCost = null;
        totalCost = this.state.workerValues.reduce((a, b) => a + b, 0);
        totalCost = Math.round(totalCost * 100) / 100;

        let stringTotal = 0;
        if (this.state.workerValues !== null) stringTotal = (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(totalCost));

        let valutaRegion;
        if (this.state.valuta !== '') valutaRegion = this.state.valuta;
        else valutaRegion = ' ';

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ 'padding': '20px', width: '94%' }}>
                    <Card style={styles.card}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <h1 style={{ marginLeft: '10px', marginBottom: '10px' }}>{i18n.t('Total Worker Cost')}: {stringTotal} {valutaRegion}</h1>
                        </div>
                    </Card>
                </div>
                <div style={{ display: 'flex', alignSelf: 'center', marginLeft: '-10px' }}>
                    <div style={{ marginTop: '0px', marginRight: '1px' }}>
                        <DashboardFilters
                            handleChange={this.handleChange}
                            season={this.state.season} sector={this.state.sector} crop={this.state.crop} field={this.state.field} plan={this.state.plan} operation={this.state.operation} planned_invisible={false} operation_visible={true}
                        />
                    </div>
                    <div style={{ display: 'flex', alignSelf: 'center', marginRight: '10px' }}>
                        <Button
                            onClick={(e) => {
                                let seasonValues = [], sectorValues = [], cropValues = [], fieldValues = [], planValues = [], operationValues = [];

                                seasonValues = this.getValues(seasonValues, this.state.season);
                                sectorValues = this.getValues(sectorValues, this.state.sector);
                                cropValues = this.getValues(cropValues, this.state.crop);
                                fieldValues = this.getValues(fieldValues, this.state.field);
                                operationValues = this.getValues(operationValues, this.state.operation);
                                // planValues = this.getValues(planValues, this.state.plan); // radnici nisu vezani za radne naloge

                                this.getChartData(seasonValues, sectorValues, cropValues, fieldValues, planValues, operationValues, 'api/report/read_workers_report');
                            }}
                            style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '80px', marginTop: '20px', marginLeft: '20px' }}>
                            {i18n.t('Submit')}
                        </Button>
                        <Button
                            onClick={(e) => {
                                this.setState({ season: [], sector: [], crop: [], field: [], plan: [], operation: [] }, () => {
                                    let seasonValues = [], sectorValues = [], cropValues = [], fieldValues = [], planValues = [], operationValues = [];

                                    seasonValues = this.getValues(seasonValues, this.state.season);
                                    sectorValues = this.getValues(sectorValues, this.state.sector);
                                    cropValues = this.getValues(cropValues, this.state.crop);
                                    fieldValues = this.getValues(fieldValues, this.state.field);
                                    operationValues = this.getValues(operationValues, this.state.operation);
                                    // planValues = this.getValues(planValues, this.state.plan); // radnici nisu vezani za radne naloge

                                    this.getChartData(seasonValues, sectorValues, cropValues, fieldValues, planValues, operationValues, 'api/report/read_workers_report');
                                });
                            }}
                            style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '80px', marginTop: '20px', marginLeft: '10px', marginRight: '25px' }}
                        >
                            {i18n.t('Reset')}
                        </Button>
                    </div>
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '20px' }}>
                    <Chart width={100} title={i18n.t('Cost by Workplace')} color={this.state.colorsWP} labels={this.state.workPlaceLabels} values={this.state.workPlaceValues} currency={true} />
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '20px' }}>
                    <Chart width={100} title={i18n.t('Cost by Worker')} color={this.state.colorsW} labels={this.state.workerLabels} values={this.state.workerValues} currency={true} />
                </div>
            </div >
        )
    }
}

function getLabels(item, labels) {
    labels.push(item);
    let unique = new Set(labels);
    labels = [...unique];
    return labels;
};

function getData(values, filter, cena) {
    let quant = 0;
    filter.map((mat) => {
        if (mat[cena] !== undefined && mat[cena] > 0) {
            quant += parseFloat(mat[cena]);
        }
    });
    values.push(quant.toFixed(2));
    return values;
};

function formatData(data, name, cena) {
    let temp_labels = [], labels = [], values = [];

    data.forEach((item, i) => temp_labels = getLabels(item[name], temp_labels).sort());
    temp_labels.forEach(item => { let filter = data.filter(i => i[name] === item); values = getData(values, filter, cena) });
    values = values.map(item => Number(item));
    if (data[0].id_region === 20) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' BAM');
    else if (data[0].id_region === 13) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' NGN');
    else if (data[0].id_region === 21) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' BAM');
    else if (data[0].id_region === 2) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' RSD');
    else if (data[0].id_region === 22) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
    else if (data[0].id_region === 23) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
    else if (data[0].id_region === 25) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' ILS');
    else if (data[0].id_region === 26) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' CAD');
    else if (data[0].id_region === 27) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' ISL');
    else if (data[0].id_region === 29) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
    else if (data[0].id_region === 30) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');

    let object = { labels: labels, values: values, colors: randomColor({ count: values.length }) };
    return object;
}