import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { HorizontalBar } from 'react-chartjs-2';
import Select, { components } from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';
import { getEnterpriseDashboard } from '../../../actions/reports/ReportsActions';
import DashboardDrawer from '../../../utils/DashboardDrawer';

const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};

const styles = {
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px'
    },
    cardMin: {
        width: '45%',
        padding: '10px',
        margin: '10px',
        position: 'relative',
        float: 'left'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};

export class EnterpriseDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {
        this.props.getEnterpriseDashboard({});

    }

    onPieClick = () => {
        this.props.history.push('/enterprise_cost');
    }

    onProfitClick = () => {
        this.props.history.push('/enterprise_cost');
    }

    onTableClick = () => {
        this.props.history.push('/enterprise');
    }

    render() {
        let materialsSpent = this.materialsSpent;
        let materialsYield = this.materialsYield;
        let height = 80;
        if (this.props.enterpriseDashboard && this.props.enterpriseDashboard.total > 100) {
            height = 150;
        }

        if (this.props.enterpriseDashboard && this.props.enterpriseDashboard.material) {
            materialsSpent.labels = this.props.enterpriseDashboard.material.labels.asMutable();
            // materialsSpent.datasets[0].data = this.props.enterpriseDashboard.material.count.asMutable();
            materialsSpent.datasets[0].data = this.props.enterpriseDashboard.material.quantity.asMutable();
        }

        if (this.props.enterpriseDashboard && this.props.enterpriseDashboard.materialPrinos) {
            materialsYield.labels = this.props.enterpriseDashboard.materialPrinos.labels.asMutable();
            // materialsYield.datasets[0].data = this.props.enterpriseDashboard.materialPrinos.count.asMutable();
            materialsYield.datasets[0].data = this.props.enterpriseDashboard.materialPrinos.prinos.asMutable();
        }

        return (
            <div style={{ 'padding': '20px', display: 'flex', flexDirection: 'column', width: '90%' }}>
                <Card style={styles.card}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h1>{i18n.t('Total number of activity report')} : {this.props.enterpriseDashboard.activityNumber} </h1>
                    </div>
                </Card>
                {this.props.enterpriseDashboard && this.props.enterpriseDashboard.material && this.props.enterpriseDashboard.material.count.length > 0 &&
                    <Card style={styles.card}>
                        <h1>{i18n.t('Material consumption')}: </h1>
                        <HorizontalBar height={height} width={100} data={materialsSpent} options={this.options} />

                    </Card>
                }
                {this.props.enterpriseDashboard && this.props.enterpriseDashboard.materialPrinos && this.props.enterpriseDashboard.materialPrinos.count.length > 0 &&
                    <Card style={styles.card}>
                        <h1>{i18n.t('Material yield')}: </h1>
                        <HorizontalBar height={20} width={100} data={materialsYield} options={this.optionsYield} />
                    </Card>
                }
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} onProfitClick={this.onProfitClick} isVisible={true} />
            </div>
        )
    }

    materialsSpentColor = randomColor({
        count: 200
    })

    materialsYieldColor = randomColor({
        count: 200
    })

    materialsSpent = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Material consumption'),
            backgroundColor: this.materialsSpentColor,
            borderColor: this.materialsSpentColor,
            borderWidth: 1,
            hoverBackgroundColor: this.materialsSpentColor,
            hoverBorderColor: this.materialsSpentColor,
        }]
    };

    materialsYield = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            label: i18n.t('Material yield'),
            backgroundColor: this.materialsYieldColor,
            borderColor: this.materialsYieldColor,
            borderWidth: 1,
            hoverBackgroundColor: this.materialsYieldColor,
            hoverBorderColor: this.materialsYieldColor,
        }]
    };
    options = {
        animation: { duration: 0 },
        hover: { animationDuration: 0 },
        responsiveAnimationDuration: 0,
        tooltips: {
            mode: 'label',
            callbacks: {
                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index];
                },
            },
        }
    };
    optionsYield = {
        animation: { duration: 0 },
        hover: { animationDuration: 0 },
        responsiveAnimationDuration: 0,
        tooltips: {
            mode: 'label',
            callbacks: {
                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index];
                },
            },
        },
        scales: {
            yAxes: [{ ticks: { beginAtZero: true } }],
            xAxes: [{ ticks: { beginAtZero: true } }],
        }
    };
};

function mapStateToProps(state) {
    return {
        enterpriseDashboard: state.reportsReducer.enterpriseDashboard,

    }
}

function mapDispatchToProps(dispatch) {
    return {
        getEnterpriseDashboard: (filters) => dispatch(getEnterpriseDashboard(filters)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnterpriseDashboard);