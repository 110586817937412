import React from 'react';
import i18n from '../../../i18n/i18n';
import { Bar } from 'react-chartjs-2';
import { Link, Typography } from '@material-ui/core';
import { formatForecast } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import { MoonLoader } from 'react-spinners';
import { Loader } from './../Loader';

const styles = {
    test: { color: 'red' }
};

export class ForecastConvectivePrecipitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            week: false,
            load: true
        };
    };

    componentDidMount = () => {
        Loader(this.props, ['basic-1h'], ['forecastCalled'], ['forecast'])
    };

    render() {
        let graph1 = {};
        let data = [];
        let obj = {};

        this.props.data.forEach((item, i) => {
            if (Object.keys(item).length > 0) {
                obj = formatForecast(item);
                data.push(obj);
            }
        });

        data.forEach((item, i) => {
            if (item) {
                if (item.dates !== undefined) {
                    graph1 = {
                        labels: this.state.week ? item.dates : item.dates.slice(0, 72),
                        datasets: [
                            {
                                data: this.state.week ? item.convective_precipitation : item.convective_precipitation.slice(0, 72),
                                label: i18n.t('Convective precipitation'),
                                backgroundColor: 'rgba(126, 228, 237, 0.6)',
                                borderColor: 'rgb(126, 228, 237)',
                                type: 'line',
                                borderWidth: 4,
                                pointRadius: 0,
                            }
                        ],
                    };
                }
            }
        })

        const options1 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        gridLines: {
                            drawOnChartArea: false
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    },
                ],
            },
        };

        return (
            <div style={{ paddingLeft: '35px', height: '100%' }}>
                <div style={{ display: 'flex', marginLeft: '40%', paddingTop: '22px' }}>
                    <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: false }); }}
                        variant="contained"
                        color="secondary">
                        <Typography>{i18n.t('3 days')}</Typography>
                    </Link> /
                <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: true }); }}
                        variant="contained"
                        color="secondary" >
                        <Typography>{i18n.t('7 days')}</Typography>
                    </Link>
                </div>
                {Object.keys(data).length === 0 && <div style={{ marginLeft: '39%', marginTop: '14%' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {Object.keys(data).length > 0 &&
                    <div style={{ width: '95%', marginTop: '10px' }}>
                        <Bar data={graph1} height={300} width={600} options={options1} />
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(ForecastConvectivePrecipitation);