import React from 'react';
import i18n from './../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './dateandtime.module.css';
import ReactSelect from './../../../utils/ReactSelect';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import moment from 'moment';

export default class DateAndTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            startTime: null,
            endTime: null,

            endDateDisabled: true,
            endTimeDisabled: true,

            startDateMUI: moment().format('DD.MM.YYYY'),
            endDateMUI: moment().format('DD.MM.YYYY'),
            startTimeMUI: moment().format('HH:mm'),
            endTimeMUI: moment().format('HH:mm'),
            minDate: moment().format('MM.DD.YYYY'),
        };
    };

    componentDidMount() {
        // if (this.props.activityObj) {
        //     if (this.props.activityObj.datum_pocetka) this.setState({ startDate: (moment(this.props.activityObj.datum_pocetka, 'DD.MM.YYYY')) }, () => {
        //         this.setState({ startDateMUI: (moment(this.props.activityObj.datum_pocetka, 'DD.MM.YYYY')) }, () => {
        //             this.setState({ endDateDisabled: false });
        //         });
        //     })
        //     if (this.props.activityObj.datum_zavrsetka) this.setState({ endDate: (moment(this.props.activityObj.datum_zavrsetka, 'DD.MM.YYYY')) }, () => {
        //         this.setState({ endDateMUI: (moment(this.props.activityObj.datum_zavrsetka, 'DD.MM.YYYY')) });
        //     })
        //     if (this.props.activityObj.vreme_pocetka) this.setState({ startTime: this.props.activityObj.vreme_pocetka }, () => {
        //         this.setState({ startTimeMUI: this.props.activityObj.vreme_pocetka }, () => {
        //             this.setState({ endTimeDisabled: false });
        //         });
        //     })
        //     if (this.props.activityObj.vreme_zavrsetka) this.setState({ endTime: this.props.activityObj.vreme_pocetka }, () => {
        //         this.setState({ endTimeMUI: this.props.activityObj.vreme_pocetka });
        //     })
        // }
    };



    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    };


    // Function to check if start time is greater than end time
    isStartTimeGreaterThanEndTime = (startTime, endTime) => {
        const start = moment(startTime, 'HH:mm');
        const end = moment(endTime, 'HH:mm');
        return start.isAfter(end);
    };

    handleAdd = (startDate, endDate, startTime, endTime) => {
        // Default to empty strings if undefined
        startDate = startDate || '';
        endDate = endDate || '';
        startTime = startTime || '';
        endTime = endTime || '';
    
        // Check if start and end dates are the same
        if (startDate === endDate) {
            // Validation: Check if start time is greater than end time
            if (this.isStartTimeGreaterThanEndTime(startTime, endTime)) {
                wentWrong(i18n.t('End time cannot be earlier than start time!'));
                return;
            }
        }
    
        // Proceed with passing the data if validation is successful
        this.props.handleDateAndTimeObj(startDate, endDate, startTime, endTime);
    };

    render() {
        const { startDate, endDate, startTime, endTime } = this.state;
        const areDatesEqual = startDate === endDate;
    
        // Determine if there is an error with the end time based on time validation
        const isEndTimeInError = 
            startTime && 
            endTime && 
            startDate && 
            endDate &&
            areDatesEqual &&
            this.isStartTimeGreaterThanEndTime(startTime, endTime);
        

        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    fullWidth={false}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {i18n.t('Add date and time')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className={styles.content}>
                            <div className={styles.center}>
                                <DatePicker
                                    label={i18n.t('Start date')}
                                    format="DD.MM.YYYY"
                                    value={this.state.startDate ? moment(this.state.startDateMUI, 'DD.MM.YYYY') : null}
                                    onChange={(e) => this.setState({ startDate: (moment(e, 'DD.MM.YYYY').format('DD.MM.YYYY')), endDate: (moment(e, 'DD.MM.YYYY').format('DD.MM.YYYY')) }, () => {
                                        this.setState({ startDateMUI: e, endDateMUI: e }, () => {
                                            if (this.state.startDate) this.setState({ endDateDisabled: false }, () => {
                                                this.setState({ minDate: (moment(e, 'MM.DD.YYYY').format('MM.DD.YYYY')) })
                                            });
                                        })
                                    })}
                                    style={{ margin: '0px 20px 0px 20px' }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <DatePicker
                                    label={i18n.t('End date')}
                                    format="DD.MM.YYYY"
                                    minDate={this.state.startDateMUI}
                                    value={this.state.endDate ? moment(this.state.endDateMUI, 'DD.MM.YYYY') : null}
                                    onChange={(e) => {
                                        this.setState({ endDate: (moment(e, 'DD.MM.YYYY').format('DD.MM.YYYY')) }, () => {
                                            this.setState({ endDateMUI: e });
                                        })
                                    }}
                                    disabled={this.state.endDateDisabled}
                                    style={{ margin: '0px 20px 0px 20px' }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </div>
                            <div className={styles.center}>
                                <TimePicker
                                    label={i18n.t('Start time')}
                                    value={this.state.startTime ? moment(this.state.startTimeMUI, 'HH:mm') : null}
                                    format="HH:mm"
                                    ampm={false}
                                    onChange={(e) => this.setState({ startTime: (moment(e, 'HH:mm').format('HH:mm')) }, () => {
                                        this.setState({ startTimeMUI: e }, () => {
                                            if (this.state.startTime) this.setState({ endTimeDisabled: false })
                                        })
                                    })}
                                    style={{ margin: '20px 20px 0px 20px' }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                <TimePicker
                                    label={i18n.t('End time')}
                                    value={this.state.endTime ? moment(this.state.endTimeMUI, 'HH:mm') : null}
                                    format="HH:mm"
                                    ampm={false}
                                    onChange={(e) => this.setState({ endTime: (moment(e, 'HH:mm').format('HH:mm')) }, () => {
                                        this.setState({ endTimeMUI: e })
                                    })}
                                    style={{
                                        margin: '20px 20px 0px 20px',
                                        backgroundColor: isEndTimeInError ? 'rgba(255, 160, 122, 0.5)' : 'transparent' // lightsalmon with 50% opacity
                                    }}
                                    disabled={this.state.endTimeDisabled}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                               
                            </div>
                        </div>
                        <div className={styles.center}>
                            <Button variant='contained' color='primary' style={{ margin: '10px 20px 0px 0px' }}
                                onClick={() => this.handleAdd(this.state.startDate, this.state.endDate, this.state.startTime, this.state.endTime)}>
                                {i18n.t('Add')}
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};