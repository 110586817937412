import React from 'react';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import axios from '../../../utils/AxiosWrapper';
import DashboardFilters from '../DashboardFilters';
import Chart from './Chart';

const styles = theme => ({
    card: { minWidth: 275, padding: '10px', width: '90%', },
    title: { marginBottom: 16, fontSize: 14, },
});

export default class RevenueCost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            total: null,
            season: [], sector: [], crop: [], field: [], plan: [],
            revenueLabels: [], revenueValues: [],
            revenueSubgroupLabels: [], revenueSubgroupValues: [],
            colorsR: [], colorsRSG: [],
            valuta: ''
        }
    }

    componentDidMount() {
        this.getValuta();
        this.getChartData([], [], [], [], [], 'IV_prinos_enterprise_reports');
    }

    getValuta() {
        axios.get(`api/valuta/valuta`)
            .then(res => {
                let regionId = res.data.data[0].id

                if (regionId === 2) this.setState({ valuta: ' RSD' });
                else if (regionId === 20) this.setState({ valuta: ' BAM' });
                else if (regionId === 21) this.setState({ valuta: ' BAM' });
                else if (regionId === 22) this.setState({ valuta: ' EUR' });
                else if (regionId === 23) this.setState({ valuta: ' EUR' });
                else if (regionId === 13) this.setState({ valuta: ' USD' });
                else if (regionId === 25) this.setState({ valuta: ' ILS' });
                else if (regionId === 26) this.setState({ valuta: ' CAD' })
                else if (regionId === 27) this.setState({ valuta: ' ILS' })
                else if (regionId === 29) this.setState({ valuta: ' EUR' })
                else if (regionId === 30) this.setState({ valuta: ' EUR' })
            })
            .catch(error => { console.log('error', error); })
    }

    getChartData(season, sector, crop, field, plan, tabela) {
        axios.get('api/report/read_dashboard', { params: { season: season, sector: sector, crop: crop, field: field, plan: plan, tabela: tabela, } })
            .then(response => {
                if (response.data.data.length === 0) {
                    this.setState({
                        revenueLabels: [], revenueValues: [],
                        revenueSubgroupLabels: [], revenueSubgroupValues: [],
                        colorsR: [], colorsRSG: []
                    })
                }

                else {
                    let objR = formatData(response.data.data, 'materijal_naziv', 'cena_prinos');
                    let objRSG = formatData(response.data.data, 'materijal_podgrupa_naziv', 'cena_prinos');

                    this.setState({
                        revenueLabels: objR.labels, revenueValues: objR.values, colorsR: objR.colors,
                        revenueSubgroupLabels: objRSG.labels, revenueSubgroupValues: objRSG.values, colorsRSG: objRSG.colors,
                    });
                }
            })
            .catch(error => console.log('Error: ', error));
    }

    getValues(items, states) {
        for (let i = 0; i < states.length; i++) items.push(states[i].value);
        return items;
    }

    onPieClick = () => { this.props.history.push('/revenue_used'); }
    onTableClick = () => { this.props.history.push('/revenue_table'); }
    onProfitClick = () => { this.props.history.push('/revenue_cost'); }
    handleChange = name => selectedOption => { this.setState({ [name]: selectedOption }); }

    render() {
        let totalCost = null;
        totalCost = this.state.revenueValues.reduce((a, b) => a + b, 0);
        totalCost = Math.round(totalCost * 100) / 100;

        let stringTotal = 0;
        if (this.state.revenueValues !== null) stringTotal = (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(totalCost));

        let valutaRegion;
        if (this.state.valuta !== '') valutaRegion = this.state.valuta
        else valutaRegion = ' '

        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div style={{ 'padding': '20px', width: '94%' }}>
                    <Card style={styles.card}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <h1 style={{ marginLeft: '10px', marginBottom: '10px' }}>{i18n.t('Total Revenue')}: {stringTotal} {valutaRegion}</h1>
                        </div>
                    </Card>
                </div>
                <div style={{ display: 'flex', alignSelf: 'center', marginLeft: '-10px' }}>
                    <div style={{ marginTop: '0px', marginRight: '1px' }}>
                        <DashboardFilters
                            handleChange={this.handleChange}
                            season={this.state.season} sector={this.state.sector} crop={this.state.crop} field={this.state.field} plan={this.state.plan}
                        />
                    </div>
                    <div style={{ display: 'flex', alignSelf: 'center', marginRight: '10px' }}>
                        <Button
                            onClick={(e) => {
                                let seasonValues = [], sectorValues = [], cropValues = [], fieldValues = [], planValues = [];

                                seasonValues = this.getValues(seasonValues, this.state.season);
                                sectorValues = this.getValues(sectorValues, this.state.sector);
                                cropValues = this.getValues(cropValues, this.state.crop);
                                fieldValues = this.getValues(fieldValues, this.state.field);
                                planValues = this.getValues(planValues, this.state.plan);

                                this.getChartData(seasonValues, sectorValues, cropValues, fieldValues, planValues, 'IV_prinos_enterprise_reports');
                            }}
                            style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '80px', marginTop: '20px', marginLeft: '20px' }}>
                            {i18n.t('Submit')}
                        </Button>
                        <Button
                            onClick={(e) => {
                                this.setState({ season: [], sector: [], crop: [], field: [], plan: [] }, () => {
                                    let seasonValues = [], sectorValues = [], cropValues = [], fieldValues = [], planValues = [];

                                    seasonValues = this.getValues(seasonValues, this.state.season);
                                    sectorValues = this.getValues(sectorValues, this.state.sector);
                                    cropValues = this.getValues(cropValues, this.state.crop);
                                    fieldValues = this.getValues(fieldValues, this.state.field);
                                    planValues = this.getValues(planValues, this.state.plan);

                                    this.getChartData(seasonValues, sectorValues, cropValues, fieldValues, planValues, 'IV_prinos_enterprise_reports');
                                });
                            }}
                            style={{ backgroundColor: '#04764e', color: 'white', height: '3em', width: '80px', marginTop: '20px', marginLeft: '10px', marginRight: '25px' }}
                        >
                            {i18n.t('Reset')}
                        </Button>
                    </div>
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '20px' }}>
                    <Chart width={100} title={i18n.t('Revenue by Subgroup')} color={this.state.colorsRSG} labels={this.state.revenueSubgroupLabels} values={this.state.revenueSubgroupValues} currency={true} />
                </div> <br></br>
                <div style={{ width: '94%', marginLeft: '20px' }}>
                    <Chart width={100} title={i18n.t('Revenue')} color={this.state.colorsR} labels={this.state.revenueLabels} values={this.state.revenueValues} currency={true} />
                </div>
                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} onProfitClick={this.onProfitClick} isVisible={true} />
            </div >
        )
    }
}

function getLabels(item, labels) {
    labels.push(item);
    let unique = new Set(labels);
    labels = [...unique];
    return labels;
};

function getData(values, filter, cena) {
    let quant = 0;
    filter.map((mat) => {
        if (mat[cena] !== undefined && mat[cena] > 0) {
            quant += parseFloat(mat[cena]);
        }
    });
    values.push(quant.toFixed(2));
    return values;
};

function formatData(data, name, cena) {
    let temp_labels = [], labels = [], values = [];

    data.forEach((item, i) => temp_labels = getLabels(item[name], temp_labels).sort());
    temp_labels.forEach(item => { let filter = data.filter(i => i[name] === item); values = getData(values, filter, cena) });
    values = values.map(item => Number(item));
    if (data[0].id_region === 20) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' BAM');
    else if (data[0].id_region === 13) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' NGN');
    else if (data[0].id_region === 21) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' BAM');
    else if (data[0].id_region === 2) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' RSD');
    else if (data[0].id_region === 22) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
    else if (data[0].id_region === 23) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
    else if (data[0].id_region === 25) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' ILS');
    else if (data[0].id_region === 26) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' CAD');
    else if (data[0].id_region === 27) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' ISL');
    else if (data[0].id_region === 29) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');
    else if (data[0].id_region === 30) labels = temp_labels.map((item, i) => item + ': ' + (new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 }).format(values[i])) + ' EUR');

    let object = { labels: labels, values: values, colors: randomColor({ count: values.length }) };
    return object;
}