import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoHealtFlux extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Količina toplotne energije koja se kreće kroz površinu zemljišta u jedinici vremena je toplotni tok ili toplotni fluks. 
                            Sposobnost zemljišta da provodi toplotu određuje koliko se brzo menja njegova temperatura tokom dana ili između godišnjih doba. 
                            Veće vrednosti na grafikonu prikazuju višu temperaturu zemljišta i brži protok toplote, dok niže vrednosti na grafikonu označavaju da je temperatura zemljišta u padu i samim tim je tok toplote sporiji. 
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                               The amount of heat energy that moves through the soil surface in a unit of time is called heat flow or heat flux.
                               The ability of soil to conduct heat determines how quickly its temperature changes during the day or between seasons.
                               Higher values on the graph show higher soil temperature and faster heat flow, while lower values indicate that the soil temperature is falling and heat flow is slower.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Količina toplinske energije koja se kreće kroz površinu tla u jedinici vremena je toplinski fluks ili toplinski tok.
                            Sposobnost tla da provodi toplinu određuje koliko se brzo njegova temperatura mijenja tijekom dana ili između godišnjih doba.
                            Više vrijednosti na grafikonu pokazuju višu temperaturu tla i brži protok topline, dok niže vrijednosti na grafikonu pokazuju da temperatura tla pada i samim tim je protok topline sporiji.
                            </p>
                        </div>
                    </Card>
                }
                 {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Količino toplotne energije, ki se v enoti časa premakne skozi površino tal, imenujemo toplotni tok ali toplotni tok. 
                            Sposobnost prsti, da prevaja toploto, določa, kako hitro se spreminja njena temperatura čez dan ali med letnimi časi. 
                            Višje vrednosti na grafu kažejo višjo temperaturo tal in hitrejši pretok toplote, medtem ko nižje vrednosti kažejo, 
                            da temperatura tal pada in je pretok toplote počasnejši.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoHealtFlux);