import React from 'react';
import i18n from './../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography, Checkbox, DialogContentText } from '@material-ui/core';
import { wentRight, wentWrong } from "../../../utils/ToastConfig";
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import styles from './implements.module.css';
import ReactSelect from './../../../utils/ReactSelect';

export default class Implements extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            machines: null,
            machineType: [],
            machineList: []
        };
    };

    componentDidMount() {
        axios.get('api/implement/klijent_drop_down')
            .then(res => {
                let listObj = [];
                let list = res.data.data.map(item => {
                    let naziv = item.naziv;
                    let label = item.naziv;
                    let id = item.id;
                    let value = item.id;
                    let id_prikljucna_masina = item.id;
                    let registarska_oznaka = null;
                    let id_prikljucna_masina_klijent = item.id_prikljucna_masina_klijent;
                    listObj.push({ id, naziv, registarska_oznaka, id_prikljucna_masina_klijent, id_prikljucna_masina });
                    return { label, value };
                });
                this.setState({ machineType: list, machineList: listObj });
            })
            .catch(() => wentWrong('Something went wrong'))

        if (this.props.activityObj.prikljucneMasine) {
            let temp = this.props.activityObj.prikljucneMasine.map(item => {
                let obj = {};
                obj.label = item.naziv;
                obj.value = item.id;
                return obj;
            });
            this.setState({ machines: temp });
        }
    };

    getByURL(url, obj) {
        axios.get(`api/${url}`)
            .then(res => this.setState({ [obj]: res.data.data }))
            .catch(error => {
                wentWrong('Something went wrong, please try again!');
                console.log('Error 2 (getByURL): ', error, ', URL: ', url);
            })
    };

    handleAdd = () => {
        if (this.state.machines) {
            if (this.state.machines.length > 0) {
                let machineObj = [];
                let selectedIds = [];

                this.state.machines.map(item => selectedIds.push(item.value));
                this.state.machineList.map((item, i) => { if (selectedIds.includes(item.id)) machineObj.push(item) });

                this.props.handleImplementsObj(machineObj);
            }
            else wentWrong('You have to select at least one machine');
        }
        else wentWrong('You have to select at least one machine');
    };

    render() {
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">
                        {i18n.t('Add implements')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <div className={styles.content}>
                            <ReactSelect
                                closeMenuOnSelect={false}
                                isClearable={true}
                                isMulti={true}
                                value={this.state.machines}
                                label={i18n.t('Implement')}
                                maxMenuHeight={150}
                                onChange={(e) => this.setState({ machines: e })}
                                options={this.state.machineType}
                                className="multi-select-activity-form-new"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                        </div>
                        <div className={styles.center}>
                            <Button onClick={() => this.handleAdd()} variant='contained' color='primary' style={{ margin: '0px 20px 10px 0px' }}>{i18n.t('Add')}</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div >
        );
    };
};