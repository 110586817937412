import axios from '../../utils/AxiosWrapper';
export const Type = {
    //VIEW
    VIEW_SECTORS_CALL: 'VIEW_SECTORS_CALL',
    VIEW_SECTORS_SUCCESS: 'VIEW_SECTORS_SUCCESS',
    VIEW_SECTORS_FAILED: 'VIEW_SECTORS_FAILED',
    //ADD
    ADD_SECTORS_CALL: 'ADD_SECTORS_CALL',
    ADD_SECTORS_SUCCESS: 'ADD_SECTORS_SUCCESS',
    ADD_SECTORS_FAILED: 'ADD_SECTORS_FAILED',
    //DELETE
    DELETE_SECTOR_CALL: 'DELETE_SECTOR_CALL',
    DELETE_SECTOR_SUCCESS: 'DELETE_SECTOR_SUCCESS',
    DELETE_SECTOR_FAILED: 'DELETE_SECTOR_FAILED',

    //UTILS
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_SECTOR_IN_REDUCER: 'SET_SECTOR_IN_REDUCER',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_SECTOR_IN_REDUCER,
            rowInfo: rowInfo,
        })
    }
}

export function addSector(sectorObj) {
    return (dispatch) => {

        dispatch({
            type: Type.ADD_SECTORS_CALL
        });

        axios.post('api/sektor/create',
            sectorObj
        )
            .then(function (response) {
                dispatch({
                    type: Type.ADD_SECTORS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_SECTORS_FAILED
                });
            });
    }
}

export function getSectors(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.VIEW_SECTORS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/sektor/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.VIEW_SECTORS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.VIEW_SECTORS_FAILED
                });
            });
    }
}

export function deleteSector(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_SECTOR_CALL,
        });

        axios.post(`api/sektor/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_SECTOR_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_SECTOR_FAILED
                });
            });
    }
}
