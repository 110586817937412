import React from 'react';
import { connect } from 'react-redux';
import i18n from '../i18n/i18n';
import {
    Dialog, DialogContent, DialogTitle,
    Avatar, Card,
    IconButton
} from '@material-ui/core';
import {
    editClientProfile, setInitialState, editClientProfilePicture
} from '../actions/LoginActions';
import Dropzone from 'react-dropzone';
import { CircleLoader } from 'react-spinners';
import ReactSelect from '../utils/ReactSelect';
import immutable from 'seamless-immutable';
import { getSeasonDropDown } from '../actions/DropDownActions';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
    },
});
export class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            settings: this.props.settings,
            seasonDropDown: [],
            isFocused: false
        };
    }

    toastConf = {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 80
    }

    componentDidMount() {
        this.props.getSeasonDropDown();
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            clientProfile: nextProps.clientProfile,
            // seasonDropDown: nextProps.seasonDropDown,
            seasonDropDown: immutable.asMutable(nextProps.seasonDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),

        });
    }

    onDropPicture = ({ accepted, rejected }) => {
        if (!!accepted && accepted.length !== 0) {
            this.props.editClientProfilePicture(accepted);
        }
    }

    onChangeSeason = selectedOption => {
        let obj = Object.assign({}, this.state.clientProfile);
        obj['id_default_sezona'] = selectedOption.value;
        this.setState({ clientProfile: obj }, () => {
            this.props.editClientProfile({
                id: this.state.clientProfile.id,
                id_default_sezona: selectedOption.value
            });
            window.location.href = '/';

        }, () => {
            window.location.href = '/';
        });
    }

    render() {
        var avatarSrc = require('../assets/images/profileImage.png');
        if (this.props.clientProfile.slika) avatarSrc = this.props.clientProfile.signedURL;
        var id_default_sezona = this.props.clientProfile.id_default_sezona;
        const { theme } = this.props;
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.handleClose}
                disableBackdropClick
                maxWidth="md"
                aria-labelledby="responsive-dialog-title"
                PaperProps={{ className: "dialogOverlow" }}>
                <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{i18n.t('Settings')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="dialogOverlow">
                    <Card className="userProfile-img" >
                        <Avatar
                            src={avatarSrc}
                            className="userProfile-avatar"
                        />
                        {!this.props.clientProfilePictureEditedFetching ?
                            <Dropzone
                                accept={['image/jpeg', 'image/png', 'image/bmp', 'image/svg+xml', 'image/gif']}
                                maxSize={5242880}
                                className="dropZone-profile"
                                multiple={false}
                                onDrop={(accepted, rejected) => this.onDropPicture({ accepted, rejected })}
                            >
                                {({ isDragActive, isDragReject, acceptedFiles, rejectedFiles }) => {
                                    return <div className="dropZoneDiv">
                                        <span className=" icon-Izmeni1 dropZoneIcon" />
                                    </div>
                                }}
                            </Dropzone>
                            :
                            <CircleLoader
                                color={'#58B4E5'}
                                loading={this.props.clientProfilePictureEditedFetching}
                            />
                        }
                    </Card>
                    <ReactSelect
                        closeMenuOnSelect={true}
                        // onChange={this.handleChange}
                        autoFocus={true}
                        isClearable={false}
                        value={this.state.seasonDropDown.find(function (element) {
                            return element.value === id_default_sezona;
                        })}
                        maxMenuHeight={180}
                        label={i18n.t('Default season')}
                        onChange={this.onChangeSeason}
                        options={this.state.seasonDropDown}
                        className="multi-select-in-filter-dashboards"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                </DialogContent>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        clientProfile: state.appReducer.clientProfile,
        clientProfilePictureEditedFetching: state.appReducer.clientProfilePictureEditedFetching,
        seasonDropDown: state.appReducer.seasonDropDown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInitialState: (component) => dispatch(setInitialState(component)),
        editClientProfile: (obj) => dispatch(editClientProfile(obj)),
        editClientProfilePicture: (obj) => dispatch(editClientProfilePicture(obj)),
        getSeasonDropDown: () => dispatch(getSeasonDropDown()),

    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings));