import { Button } from "@material-ui/core";
import React from "react";
import styles from './meteotable.module.css';
import i18n from '../../../src/i18n/i18n';

export default class MeteoTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            data: {},
            tableData: [],
            sort: [],
            original: [],
            avgs: []
        };
    };

    componentDidMount() {
        this.formatOnRender();
    };

    componentWillReceiveProps() {
        this.formatOnRender();
    };

    formatOnRender = () => {
        if (this.props.data.dates.length > 0) {
            this.setState({ data: this.props.data }, () => {
                let tableData = [];
                if (Object.keys(this.props.data).length > 0) {
                    Object.keys(this.props.data).forEach((item, i) => {
                        if (i > 0) tableData.push(this.props.data[item]);
                    })
                };

                if (tableData.length > 0) {

                    //provera ukoliko nije dobijen parametar od stanice, onda se splice-uje i ne prikazuje se u tabeli
                    for (let i = tableData.length; i >= 0; i--) {
                        const element = tableData[i];
                        if (element === undefined) {
                            tableData.splice(i,1)
                        }
                    }
                   
                    tableData = tableData[0].map((_, colIndex) => tableData.map(row => row[colIndex]));
                    let original = [...tableData];
                    this.setState({ tableData, original }, () => {
                        this.getAvgs();
                        let sort = [];
                        tableData.forEach(element => sort.push(''))
                        
                        this.setState({ sort });
                    });
                };
            });
        };
    };

    isOdd = (num) => { return num % 2; };

    getAvgs = () => {
        const sum = (r, a) => r.map((b, i) => a[i] + b);
        let avgs = [];
        if (this.state.tableData.length > 0) avgs = this.state.tableData.reduce(sum);
        avgs.shift();
        avgs = avgs.map(item => ( isNaN(item) ?  '' : (item / this.state.tableData.length).toFixed(2)));
        
        
       
        this.setState({ avgs });
    };

    sortData = (i) => {
        let tableData = this.state.tableData;
        let sort = this.state.sort;

        sort.forEach((el, e) => { if (e !== i) sort[e] = '' });

        if (sort[i] === '') sort[i] = '+';
        else if (sort[i] === '+') sort[i] = '-';
        else if (sort[i] === '-') sort[i] = '';

        this.setState({ sort }, () => {

            if (i === 0) {
                if (sort[i] === '') tableData = [...this.state.original];
                else if (sort[i] === '-') tableData = [...this.state.original].reverse();
                else if (sort[i] === '+') tableData = [...this.state.original];
            }
            else {
                if (sort[i] === '') tableData = [...this.state.original];
                else if (sort[i] === '-') tableData = tableData.sort((a, b) => b[i] - a[i]);
                else if (sort[i] === '+') tableData = tableData.sort((a, b) => a[i] - b[i]);
            }
            this.setState({ tableData }, () => {
                this.getAvgs();
            });
        })
    };

    render() {
        return (
            <div>
                {this.state.show === false && <div style={{ textAlign: 'center' }}>
                    <Button
                        style={{ width: '150px' }}
                        variant="contained"
                        color="secondary"
                        onClick={() => { this.setState({ show: !this.state.show }) }}>
                        {i18n.t('Show table')}
                    </Button>
                </div>}
                {this.state.show && <div style={{ textAlign: 'center' }}>
                    <Button
                        style={{ width: '150px' }}
                        variant="contained"
                        color="secondary"
                        onClick={() => { this.setState({ show: !this.state.show }) }}>
                        {i18n.t('Hide table')}
                    </Button>
                    <div className={styles.table_div}>
                        <table className={styles.table}>
                            <tr className={styles.table_row_sticky_header}>
                                {Object.keys(this.props.data).length > 0 && this.props.data.headers.map((item, i) => {
                                    return <th className={styles.table_header} onClick={() => {
                                        this.sortData(i)
                                    }}>
                                        {item}
                                        {this.state.sort[i] === '' ? '' : (this.state.sort[i] === '+' ? '▲' : '▼')}
                                    </th>
                                })}
                            </tr>
                            {this.state.tableData.length > 0 && this.state.tableData.map((item, i) => {
                                return <tr className={this.isOdd(i) === 0 ? styles.table_row_odd : styles.table_row}>
                                    {item.map((el, e) => {
                                        return <td className={styles.table_row_text}>
                                            {el}
                                        </td>
                                    })}
                                </tr>
                            })}
                            <tr className={styles.table_footer}>
                                {this.state.tableData.length > 0 && this.state.tableData[0].map((item, i) => {
                                    return <td className={styles.table_header} style={{cursor: 'default' }}>
                                        {i === 0 ? i18n.t('Average value') + ':' : null}
                                        {i > 0 ? (this.state.avgs.length > 0 ? (
                                            this.state.avgs[i - 1]
                                        ) : null) : null}
                                    </td>
                                })}
                            </tr>
                        </table>
                    </div>
                </div >
                }
            </div>
        )
    };
};