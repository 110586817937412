import React, { Component } from 'react';
import { connect } from 'react-redux';
import L from 'leaflet';
import {
  LayersControl, FeatureGroup, Marker, Popup, Tooltip, Polyline
} from 'react-leaflet';

import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'

import * as LayerSelectors from '../selectors/LayerSelectors';

import * as LayerActions from '../actions/map/LayerActions';

import './InstitutionalLayers.css';

// work around because leaflet can not find the icons
const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [24, 36],
  iconAnchor: [12, 36],
})

L.Marker.prototype.options.icon = DefaultIcon

const { Overlay } = LayersControl;

const epsilon = 0.000001;

const colinear = (point1, point2, queryPoint) => {
  return Math.abs((point2.longitude - point1.longitude) * (queryPoint.latitude - point1.latitude) - (queryPoint.longitude - point1.longitude) * (point2.latitude - point1.latitude)) < epsilon;
};

const within = (coordinate1, coordinate2, queryCoordinate) => ((coordinate1 <= queryCoordinate && queryCoordinate <= coordinate2) || (coordinate2 <= queryCoordinate && queryCoordinate <= coordinate1));

const isBetween = (point1, point2, queryPoint) => (
  colinear(point1, point2, queryPoint)
  && (
    (point1.longitude !== point2.longitude)
      ? within(point1.longitude, point2.longitude, queryPoint.longitude)
      : within(point1.latitude, point2.latitude, queryPoint.latitude)
  ));

class GPSLayer extends Component {

  state = {
    timeAtPoint: (new Date()).getTime()
  };

  render() {
    const {
      lastLocations, trajectory, layer, removeLayer, removeLayerControl, addOverlay, vehicleLabel
    } = this.props;

    const { timeAtPoint, tooltipX, tooltipY } = this.state;

    return (
      <Overlay
        checked
        name="GPS"
        addOverlay={addOverlay}
        removeLayer={removeLayer}
        removeLayerControl={removeLayerControl}
      >
        <FeatureGroup>
          {lastLocations.map((marker) => {
            if (!marker.latitude || !marker.longitude) return;

            const position = [marker.latitude, marker.longitude];

            return (
              <Marker position={position}>
                <Tooltip className="institutional-layer-label-tooltip" direction="bottom" permanent>
                  <p className="institutional-layer-label-tooltip-text">{vehicleLabel}</p>
                </Tooltip>
              </Marker>
            );
          })}
        </FeatureGroup>
        { trajectory && (
          <Polyline positions={trajectory.map(marker => L.latLng(marker.latitude, marker.longitude))}>
          </Polyline>
        )}
      </Overlay>
    );
  }
}

export default GPSLayer;
