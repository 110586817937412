import React from 'react';
import i18n from '../../../i18n/i18n';
import axios from '../../../utils/AxiosWrapper';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Button, Card, Link, Typography } from '@material-ui/core';
import DatePickerMeteo from '../../../utils/DatePickerMeteo';
import { formatData, formatForecast, formatDisease } from '../DataFormatters';
import { withStyles } from '@material-ui/core/styles';
import NavigationIcon from '@material-ui/icons/Navigation';
import { MoonLoader } from 'react-spinners';
import MeteoTable from '../MeteoTable';
import MeteoInfoButton from '../MeteoInfoButton';
import InfoWind from '../Info/Data/InfoWind';


const styles = {};

export class ForecastWind extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            station_id: this.props.row.uid,
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            forecast: {},
            week: false,
            load: true,
            half: false,
        };
    }

    componentDidMount() {
        this.getPessl();
    }

    setDates = (start, end) => { this.setState({ startDate: start, endDate: end }, () => { this.getPessl(); }); }

    getForecast = (path) => {
        axios.post('/api/testMeteo/meteo', path)
            .then(res => { let obj = formatForecast(res); this.setState({ forecast: obj, load: false }); })
            .catch(err => console.log('Error: ', err))
    }

    getPessl = () => {
        let start_date = moment(this.state.startDate).unix();
        let end_date = moment(this.state.endDate).unix();
        let station_id = this.state.station_id;

        // URL-ovi za podatke sa meteo stanica
        let disease_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;
        let forecast_path = '/forecast/' + station_id + '/hourly';
        let data_path = '/data/' + station_id + '/hourly/from/' + start_date + '/to/' + end_date;

        this.getForecast({ path: forecast_path, name: 'general7', method: "POST" });
    }

    render() {
        const { classes } = this.props;

        let wind_dir = [];
        let dates_dir = [];

        let graph1 = {};
        if (
            this.state.forecast.dates !== undefined
        ) {
            var now = moment();
            now = now.format("HH");

            if (this.state.week === true) {
                wind_dir = distributedCopy(this.state.forecast.winddirection, 12);
                dates_dir = distributedCopy(this.state.forecast.dates, 12);
            }
            else if (this.state.week === false) {
                wind_dir = distributedCopy(this.state.forecast.winddirection.slice(0, 72), 12);
                dates_dir = distributedCopy(this.state.forecast.dates.slice(0, 72), 12);
            }

            graph1 = {
                labels: this.state.week ? this.state.forecast.dates : this.state.forecast.dates.slice(0, 72),
                datasets: [
                    {
                        data: this.state.week ? this.state.forecast.windspeed : this.state.forecast.windspeed.slice(0, 72),
                        label: i18n.t('Wind speed [km/h]'),
                        backgroundColor: 'rgba(82, 162, 209, 0.4)',
                        borderColor: 'rgb(82, 162, 209)',
                        type: 'line',
                        borderWidth: 2,
                        pointRadius: 2,
                    },
                ],
            };

            if (this.state.half === true) {
                wind_dir = distributedCopy(this.state.forecast.winddirection.slice(Number(now), Number(now) + 13), 12);
                dates_dir = distributedCopy(this.state.forecast.dates.slice(Number(now), Number(now) + 13), 12);

                graph1 = {
                    labels: this.state.forecast.dates.slice(Number(now), Number(now) + 13),
                    datasets: [
                        {
                            data: this.state.forecast.windspeed.slice(Number(now), Number(now) + 13),
                            label: i18n.t('Wind speed [km/h]'),
                            backgroundColor: 'rgba(82, 162, 209, 0.4)',
                            borderColor: 'rgb(82, 162, 209)',
                            type: 'line',
                            borderWidth: 2,
                            pointRadius: 2,
                            pointBackgroundColor: 'rgb(82, 162, 209)', 
                            pointBorderColor: 'rgb(82, 162, 209)',
                        }
                    ],
                };
            }
        }
        const options1 = {
            scales: {
                yAxes: [
                    {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        // id: 'y-axis-1',
                        gridLines: {
                            drawOnChartArea: false,
                        },
                        scaleLabel: {
                            display: true,
                            labelString: '[m/s]'
                        },
                        ticks: {
                            beginAtZero: true,
                            min: 0,
                            // max: 100,
                        }
                    },
                ],
            },
        }

        return (
            <div style={{ paddingLeft: '35px' }}>
                <div style= {{display: 'flex', flexDirection: 'row', alignItems:'center', justifyContent: 'center'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                        <p style={{fontSize: 20, marginRight: 4}}>{i18n.t('Wind speed and direction')}</p>
                        </div>
                        <div style={{marginLeft: 20}}>
                        <MeteoInfoButton
                            title={i18n.t('Wind speed and direction')}
                            text={<InfoWind />}
                            height='400px'
                            width='sm'
                            />
                        </div>
                </div>
                <div style={{ display: 'flex', marginLeft: '40%', marginTop: '22px' }}>
                    <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: false, half: true }); }}
                        variant="contained"
                        color="secondary">
                        <Typography>{i18n.t('12 hours')}</Typography>
                    </Link> /
                    <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: false, half: false }); }}
                        variant="contained"
                        color="secondary">
                        <Typography>{i18n.t('3 days')}</Typography>
                    </Link> /
                <Link style={{ marginRight: '3px', marginLeft: '3px', fontSize: '18px', color: 'black', cursor: 'pointer' }}
                        onClick={() => { this.setState({ week: true, half: false }); }}
                        variant="contained"
                        color="secondary" >
                        <Typography>{i18n.t('7 days')}</Typography>
                    </Link>
                </div>
                {this.state.load === true && <div style={{ marginLeft: '39%', marginTop: '14%', paddingBlockEnd: '20px' }}> <MoonLoader loading={this.state.load} size={100} /></div>}
                {this.state.load === false &&
                    <div>
                        <div style={{ width: '95%', marginTop: '10px' }}>
                            <Bar data={graph1} height={300} width={600} options={options1} />
                        </div>

                        <Typography style={{ textAlign: 'center', fontSize: '18px', margin: '10px', marginTop: '3%', marginBottom: '10px' }}>{i18n.t('Wind direction')}:</Typography>
                        <table style={{ marginBottom: '58px', marginLeft: '15px' }}>
                       
                            <tbody>
                                <tr>
                                    {wind_dir !== [] && this.state.forecast.winddirection !== undefined && wind_dir.map((item, i) => {
                                        let first = i;
                                        return <td style={{ width: '75px', height: '50px', textAlign: 'center' }} key={first}>
                                            <NavigationIcon style={{ transform: `rotate(${item + 180}deg)`, height: '40px', width: '40px', color: 'rgb(82, 162, 209)' }} />
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    {wind_dir !== [] && this.state.forecast.winddirection !== undefined && wind_dir.map((item, i) => {
                                        let second = i;
                                        let string_degrees = degress(item);
                                        return <td style={{ width: '70px', height: '30px', textAlign: 'center' }} key={second}>
                                            <Typography>{string_degrees}</Typography>
                                        </td>
                                    })}
                                </tr>
                                <tr>
                                    {dates_dir !== [] && this.state.forecast.winddirection !== undefined && dates_dir.map((item, i) => {
                                        let third = i;
                                        return <td style={{ width: '75px', height: '40px', textAlign: 'center' }} key={third}>
                                            <Typography>{item.substring(0, 5)}</Typography>
                                            <Typography>{item.substring(7, 12)}</Typography>
                                        </td>
                                    })}
                                </tr>
                            </tbody>
                        </table>
                        <MeteoTable data={{
                        headers: [i18n.t('Date and time'), i18n.t('Wind speed [km/h]')],
                        dates: this.state.week ? this.state.forecast.dates : this.state.forecast.dates.slice(0, 72),
                        windspeed: this.state.week ? this.state.forecast.windspeed : this.state.forecast.windspeed.slice(0, 72)
                    }} />
                    <div style={{ marginTop: '20px' }}></div>
                    </div>
                }
            </div>
        );
    }
};

export default withStyles(styles)(ForecastWind);

function distributedCopy(items, n) {
    var elements = [items[0]];
    var totalItems = items.length - 2;
    var interval = Math.floor(totalItems / (n - 2));
    for (var i = 1; i < n - 1; i++) {
        elements.push(items[i * interval]);
    }
    elements.push(items[items.length - 1]);
    return elements;
}

function degress(deg) {
    let string = '';
    if (deg > 348.75 || deg < 11.25) string = i18n.t('N');
    else if (deg > 11.25 && deg < 33.75) string = i18n.t('NNE');
    else if (deg > 33.75 && deg < 56.25) string = i18n.t('NE');
    else if (deg > 56.25 && deg < 78.75) string = i18n.t('ENE');
    else if (deg > 78.75 && deg < 101.25) string = i18n.t('E');
    else if (deg > 101.25 && deg < 123.75) string = i18n.t('ESE');
    else if (deg > 123.75 && deg < 146.25) string = i18n.t('SE');
    else if (deg > 146.25 && deg < 168.75) string = i18n.t('SSE');
    else if (deg > 168.75 && deg < 191.25) string = i18n.t('S');
    else if (deg > 191.25 && deg < 213.75) string = i18n.t('SSW');
    else if (deg > 213.75 && deg < 236.25) string = i18n.t('SW');
    else if (deg > 236.25 && deg < 258.75) string = i18n.t('WSW');
    else if (deg > 258.75 && deg < 281.25) string = i18n.t('W');
    else if (deg > 281.25 && deg < 303.75) string = i18n.t('WNW');
    else if (deg > 303.75 && deg < 326.75) string = i18n.t('NW');
    else if (deg > 326.75 && deg < 348.75) string = i18n.t('NNW');
    return string;
}