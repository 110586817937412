import immutable from 'seamless-immutable';
import { Type as LoginType } from '../actions/LoginActions';
import { Type as DropDownType } from '../actions/DropDownActions';
import i18n from '../i18n/i18n';

export const INITIAL_STATE = immutable({
    token: null,
    logInFailed: false,
    checkingCredentials: false,
    loginSuccess: false,
    openAlert: false,
    alertText: '',
    alertTitle: '',
    isDrawerOpen: true,
    isMenuOpen: false,
    user: {},
    bundle: null,
    clientProfile: {},
    clientProfileFetching: false,
    clientProfileFetchingFailed: false,
    userProfile: {},
    userProfileFetching: false,
    userProfileFetchingFailed: false,
    passwordChange: false,
    passwordChangeFetching: false,
    passwordChangeFetchingFailed: false,
    profileEdited: false,
    profileEditedFetching: false,
    profileEditedFetchingFailed: false,
    profilePictureEdited: false,
    profilePictureEditedFetching: false,
    profilePictureEditedFetchingFailed: false,
    languages: [],
    languagesFetching: false,
    languagesFetchingFailed: false,
    clientProfilePictureEditedFetching: false,
    localGoverments: [],
    localGovermentsFetching: false,
    localGovermentsFetchingFailed: false,
    kat_kulture: [],
    kat_kultureFetching: false,
    kat_kultureFetchingFailed: false,
    seasonDropDown: [],
    seasonFetchingDropDown: false,
    seasonFetchingFailedDropDown: false,
    layerDropDown: [],
    layerFetchingDropDown: false,
    layerFetchingFailedDropDown: false,
    localGoverments: [],
    localGovermentsFetching: false,
    localGovermentsFetchingFailed: false,
    states: [],
    statesFetching: false,
    statesFetchingFailed: false,
    cropsDropdown: [],
    cropFetching: false,
    cropFetchingFailed: false,
    cropGroupDropdown: [],
    cropGroupFetching: false,
    cropGroupFetchingFailed: false,
    cropSubgroupDropdown: [],
    cropSubgroupFetching: false,
    cropSubgroupFetchingFailed: false,
    materialGroupDropdown: [],
    materialGroupFetching: false,
    materialGroupFetchingFailed: false,
    materialSubgroupDropdown: [],
    materialSubgroupFetching: false,
    materialSubgroupFetchingFailed: false,
    materialDropdown: [],
    materialDropdownFetching: false,
    materialDropdownFetchingFailed: false,
    woDropdown: [],
    woDropdownFetching: false,
    woDropdownFetchingFailed: false,
    ticketSentSuccess: false,
    ticketSentFailed: false,
    message: '',
    usernameCheck: false,
    usernameCheckFetching: false,
    usernameCheckFetchingFailed: false,
    createpassword: false,
    createpasswordFetching: false,
    createpasswordFetchingFailed: false

});

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case LoginType.LOGOUT:
            localStorage.clear();
            window.location.replace(window.location.origin);
            return INITIAL_STATE;
            break;

        case LoginType.SET_INITIAL_STATE: {
            let componentToSetInitialState = {};
            let value = INITIAL_STATE[action.data];
            componentToSetInitialState[action.data] = value;
            return state.merge({ ...componentToSetInitialState });
            break;
        }

        case LoginType.LOGIN_CALL: {
            const checkingCredentials = true;
            return state.merge({ checkingCredentials });
            break;
        }

        case LoginType.LOGIN_SUCCESS: {
            const token = action.data.token;
            const user = action.data.user;
            const checkingCredentials = false;
            const loginSuccess = true;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(user));
            return state.merge({ token, user, checkingCredentials, loginSuccess });
            break;
        }

        case LoginType.LOGIN_FAILED: {
            const checkingCredentials = false;
            const logInFailed = true;
            const message = action.message;
            return state.merge({ logInFailed, message });
            break;
        }

        case LoginType.FORGOT_PASSWORD_CALL: {
            const usernameCheck = false;
            const usernameCheckFetching = true;
            const usernameCheckFetchingFailed = false;
            return state.merge({ usernameCheck, usernameCheckFetching, usernameCheckFetchingFailed });
            break;
        }
        case LoginType.FORGOT_PASSWORD_SUCCESS: {
            const usernameCheck = true;
            const usernameCheckFetching = false;
            return state.merge({ usernameCheck, usernameCheckFetching });
            break;
        }
        case LoginType.FORGOT_PASSWORD_FAILED: {
            const usernameCheckFetching = false;
            const usernameCheckFetchingFailed = true;
            return state.merge({ usernameCheckFetching, usernameCheckFetchingFailed });
            break;
        }

        case LoginType.CREATE_NEW_PASSWORD_CALL: {
            const createpassword = false;
            const createpasswordFetchingFailed = false;
            const createpasswordFetching = true;
            return state.merge({ createpassword, createpasswordFetching, createpasswordFetchingFailed });
            break;
        }

        case LoginType.CREATE_NEW_PASSWORD_SUCCESS: {
            const createpassword = true;
            const createpasswordFetching = false;
            return state.merge({ createpassword, createpasswordFetching });
            break;
        }

        case LoginType.CREATE_NEW_PASSWORD_FAILED: {
            const createpasswordFetchingFailed = true;
            const createpasswordFetching = false;
            return state.merge({ createpasswordFetchingFailed, createpasswordFetching });
            break;
        }

        case LoginType.SHOW_MENU_DRAWER: {
            const isDrawerOpen = action.data;
            return state.merge({ isDrawerOpen });
            break;
        }
        case LoginType.SHOW_MENU: {
            const isMenuOpen = action.data;
            return state.merge({ isMenuOpen });
            break;
        }

        case LoginType.CLIENT_PROFILE_CALL: {
            const clientProfileFetching = true;
            return state.merge({ clientProfileFetching });
            break;
        }

        case LoginType.CLIENT_PROFILE_SUCCESS: {
            const clientProfile = action.data.data[0];
            const clientProfileFetching = false;
            return state.merge({ clientProfile, clientProfileFetching });
            break;
        }

        case LoginType.CLIENT_PROFILE_FAILED: {
            const clientProfileFetchingFailed = true;
            const clientProfileFetching = false;
            return state.merge({ clientProfileFetchingFailed, clientProfileFetching });
            break;
        }

        case LoginType.USER_PROFILE_CALL: {
            const userProfileFetching = true;
            return state.merge({ userProfileFetching });
            break;
        }

        case LoginType.USER_PROFILE_SUCCESS: {
            const userProfile = action.data.data;
            const userProfileFetching = false;
            i18n.changeLanguage(userProfile.jezik.naziv);
            return state.merge({ userProfile, userProfileFetching });
            break;
        }

        case LoginType.USER_PROFILE_FAILED: {
            const userProfileFetchingFailed = true;
            const userProfileFetching = false;
            return state.merge({ userProfileFetchingFailed, userProfileFetching });
            break;
        }

        case LoginType.CHANGE_PASSWORD_CALL: {
            const passwordChangeFetching = true;
            return state.merge({ passwordChangeFetching });
            break;
        }

        case LoginType.CHANGE_PASSWORD_SUCCESS: {
            const passwordChange = true;
            const passwordChangeFetching = false;
            return state.merge({ passwordChange, passwordChangeFetching });
            break;
        }

        case LoginType.CHANGE_PASSWORD_FAILED: {
            const passwordChangeFetchingFailed = true;
            const passwordChangeFetching = false;
            return state.merge({ passwordChangeFetchingFailed, passwordChangeFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_CALL: {
            const profileEditedFetching = true;
            return state.merge({ profileEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_SUCCESS: {
            const profileEdited = true;
            const profileEditedFetching = false;
            return state.merge({ profileEdited, profileEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_FAILED: {
            const profileEditedFetchingFailed = true;
            const profileEditedFetching = false;
            return state.merge({ profileEditedFetchingFailed, profileEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_PICTURE_CALL: {
            const profilePictureEditedFetching = true;
            return state.merge({ profilePictureEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_PICTURE_SUCCESS: {
            const profilePictureEdited = true;
            const profilePictureEditedFetching = false;
            return state.merge({ profilePictureEdited, profilePictureEditedFetching });
            break;
        }

        case LoginType.EDIT_PROFILE_PICTURE_FAILED: {
            const profilePictureEditedFetchingFailed = true;
            const profilePictureEditedFetching = false;
            return state.merge({ profilePictureEditedFetchingFailed, profilePictureEditedFetching });
            break;
        }
        case LoginType.EDIT_CLIENT_PROFILE_PICTURE_CALL: {
            const clientProfilePictureEditedFetching = true;
            return state.merge({ clientProfilePictureEditedFetching });
            break;
        }

        case LoginType.EDIT_CLIENT_PROFILE_PICTURE_SUCCESS: {
            const clientProfilePictureEditedFetching = false;
            return state.merge({ clientProfilePictureEditedFetching });
            break;
        }

        case LoginType.GET_LANGUAGE_CALL:
            languagesFetching = true;
            return state.merge({ languagesFetching });
            break;

        case LoginType.GET_LANGUAGE_SUCCESS:
            let languages = action.data;
            let languagesFetching = false;
            return state.merge({ languages, languagesFetching });
            break;

        case LoginType.GET_LANGUAGE_FAILED:
            languagesFetching = false;
            let languagesFetchingFailed = true;
            return state.merge({ languagesFetching, languagesFetchingFailed });
            break;

        case LoginType.GET_LOCAL_GOV_CALL: {
            const localGovermentsFetching = true;
            return state.merge({ localGovermentsFetching });
            break;
        }

        case LoginType.GET_LOCAL_GOV_SUCCESS: {
            const localGoverments = action.data.data;
            const localGovermentsFetching = false;
            return state.merge({ localGoverments, localGovermentsFetching });
            break;
        }

        case LoginType.GET_LOCAL_GOV_FAILED: {
            const localGovermentsFetchingFailed = true;
            const localGovermentsFetching = false;
            return state.merge({ localGovermentsFetchingFailed, localGovermentsFetching });
            break;
        }

        case DropDownType.GET_KAT_KULTURA_CALL: {
            const kat_kultureFetching = true;
            return state.merge({ kat_kultureFetching });
            break;
        }

        case DropDownType.GET_KAT_KULTURA_SUCCESS: {
            const kat_kulture = action.data.data;
            const kat_kultureFetching = false;
            return state.merge({ kat_kulture, kat_kultureFetching });
            break;
        }

        case DropDownType.GET_KAT_KULTURA_FAILED: {
            const kat_kultureFetchingFailed = true;
            const kat_kultureFetching = false;
            return state.merge({ kat_kultureFetchingFailed, kat_kultureFetching });
            break;
        }

        case DropDownType.GET_SEASON_CALL: {
            const seasonFetchingDropDown = true;
            return state.merge({ seasonFetchingDropDown });
            break;
        }

        case DropDownType.GET_SEASON_SUCCESS: {
            const seasonDropDown = action.data.data;
            const seasonFetchingDropDown = false;
            return state.merge({ seasonDropDown, seasonFetchingDropDown });
            break;
        }

        case DropDownType.GET_SEASON_FAILED: {
            const seasonFetchingFailedDropDown = true;
            const seasonFetchingDropDown = false;
            return state.merge({ seasonFetchingDropDown, seasonFetchingFailedDropDown });
            break;
        }

        case DropDownType.GET_LAYERS_CALL_DROP_DOWN: {
            const layerFetchingDropDown = true;
            return state.merge({ layerFetchingDropDown });
            break;
        }

        case DropDownType.GET_LAYERS_SUCCESS_DROP_DOWN: {
            const layerDropDown = action.data.data;
            const layerFetchingDropDown = false;
            const layerPages = action.data.total_pages
            return state.merge({ layerDropDown, layerFetchingDropDown, layerPages });
            break;
        }

        case DropDownType.GET_LAYERS_FAILED_DROP_DOWN: {
            const layerFetchingFailedDropDown = true;
            const layerFetchingDropDown = false;
            return state.merge({ layerFetchingDropDown, layerFetchingFailedDropDown });
            break;
        }


        case DropDownType.GET_STATES_CALL: {
            const statesFetching = true;
            return state.merge({ statesFetching });
            break;
        }

        case DropDownType.GET_STATES_SUCCESS: {
            const states = action.data.data;
            const statesFetching = false;
            return state.merge({ states, statesFetching });
            break;
        }

        case DropDownType.GET_LOCAL_GOV_CALL: {
            const localGovermentsFetching = true;
            return state.merge({ localGovermentsFetching });
            break;
        }

        case DropDownType.GET_LOCAL_GOV_SUCCESS: {
            const localGoverments = action.data.data;
            const localGovermentsFetching = false;
            return state.merge({ localGoverments, localGovermentsFetching });
            break;
        }

        case DropDownType.GET_LOCAL_GOV_FAILED: {
            const localGovermentsFetchingFailed = true;
            const localGovermentsFetching = false;
            return state.merge({ localGovermentsFetchingFailed, localGovermentsFetching });
            break;
        }

        case DropDownType.GET_CROP_DROPDOWN_CALL: {
            const cropFetching = true;
            return state.merge({ cropFetching });
            break;
        }

        case DropDownType.GET_CROP_DROPDOWN_SUCCESS: {
            const cropsDropdown = action.data.data;
            const cropFetching = false;
            return state.merge({ cropsDropdown, cropFetching });
            break;
        }

        case DropDownType.GET_CROP_DROPDOWN_FAILED: {
            const cropFetchingFailed = true;
            const cropFetching = false;
            return state.merge({ cropFetchingFailed, cropFetching });
            break;
        }

        case DropDownType.GET_CROP_GROUP_DROPDOWN_CALL: {
            const cropGroupFetching = true;
            return state.merge({ cropGroupFetching });
            break;
        }

        case DropDownType.GET_CROP_GROUP_DROPDOWN_SUCCESS: {
            const cropGroupDropdown = action.data.data;
            const cropGroupFetching = false;
            return state.merge({ cropGroupDropdown, cropGroupFetching });
            break;
        }

        case DropDownType.GET_CROP_GROUP_DROPDOWN_FAILED: {
            const cropGroupFetchingFailed = true;
            const cropGroupFetching = false;
            return state.merge({ cropGroupFetchingFailed, cropGroupFetching });
            break;
        }

        case DropDownType.GET_CROP_SUBGROUP_DROPDOWN_CALL: {
            const cropSubgroupFetching = true;
            return state.merge({ cropSubgroupFetching });
            break;
        }

        case DropDownType.GET_CROP_SUBGROUP_DROPDOWN_SUCCESS: {
            const cropSubgroupDropdown = action.data.data;
            const cropSubgroupFetching = false;
            return state.merge({ cropSubgroupDropdown, cropSubgroupFetching });
            break;
        }

        case DropDownType.GET_CROP_SUBGROUP_DROPDOWN_FAILED: {
            const cropSubgroupFetchingFailed = true;
            const cropSubgroupFetching = false;
            return state.merge({ cropSubgroupFetchingFailed, cropSubgroupFetching });
            break;
        }

        case DropDownType.GET_MATERIAL_GROUP_DROPDOWN_CALL: {
            const materialGroupFetching = true;
            return state.merge({ materialGroupFetching });
            break;
        }

        case DropDownType.GET_MATERIAL_GROUP_DROPDOWN_SUCCESS: {
            const materialGroupDropdown = action.data.data;
            const materialGroupFetching = false;
            return state.merge({ materialGroupDropdown, materialGroupFetching });
            break;
        }

        case DropDownType.GET_MATERIAL_GROUP_DROPDOWN_FAILED: {
            const materialGroupFetchingFailed = true;
            const materialGroupFetching = false;
            return state.merge({ materialGroupFetchingFailed, materialGroupFetching });
            break;
        }

        case DropDownType.GET_MATERIAL_SUBGROUP_DROPDOWN_CALL: {
            const materialSubgroupFetching = true;
            return state.merge({ materialSubgroupFetching });
            break;
        }

        case DropDownType.GET_MATERIAL_SUBGROUP_DROPDOWN_SUCCESS: {
            const materialSubgroupDropdown = action.data.data;
            const materialSubgroupFetching = false;
            return state.merge({ materialSubgroupDropdown, materialSubgroupFetching });
            break;
        }

        case DropDownType.GET_MATERIAL_SUBGROUP_DROPDOWN_FAILED: {
            const materialSubgroupFetchingFailed = true;
            const materialSubgroupFetching = false;
            return state.merge({ materialSubgroupFetchingFailed, materialSubgroupFetching });
            break;
        }

        case DropDownType.GET_MATERIAL_DROPDOWN_CALL: {
            const materialDropdownFetching = true;
            return state.merge({ materialDropdownFetching });
            break;
        }

        case DropDownType.GET_MATERIAL_DROPDOWN_SUCCESS: {
            const materialDropdown = action.data.data;
            const materialDropdownFetching = false;
            return state.merge({ materialDropdown, materialDropdownFetching });
            break;
        }

        case DropDownType.GET_MATERIAL_DROPDOWN_FAILED: {
            const materialDropdownFailed = true;
            const materialDropdown = false;
            return state.merge({ materialDropdownFailed, materialDropdown });
            break;
        }

        case DropDownType.GET_WORKING_OPERATION_DROPDOWN_CALL: {
            const woDropdownFetching = true;
            return state.merge({ woDropdownFetching });
            break;
        }

        case DropDownType.GET_WORKING_OPERATION_DROPDOWN_SUCCESS: {
            const woDropdown = action.data.data;
            const woDropdownFetching = false;
            return state.merge({ woDropdown, woDropdownFetching });
            break;
        }

        case DropDownType.GET_WORKING_OPERATION_DROPDOWN_FAILED: {
            const woDropdownFetchingFailed = true;
            const woDropdownFetching = false;
            return state.merge({ woDropdownFetchingFailed, woDropdownFetching });
            break;
        }

        case LoginType.TICKET_REST_SUCCESS: {
            const ticketSentSuccess = true;
            return state.merge({ ticketSentSuccess });
            break;
        }

        case LoginType.TICKET_REST_FAILED: {
            const ticketSentFailed = true;
            return state.merge({ ticketSentFailed });
            break;
        }

        default:
            return state;
    }
}
