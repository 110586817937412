import React from 'react';
import i18n from '../../../i18n/i18n';
import { Button, TextField, Grid, IconButton, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { wentWrong } from "../../../utils/ToastConfig";
//import Switch from '@material-ui/core/Switch';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
export default class AddVarieties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            naziv: '',
            kultura: '', kulture: [],
        }
    }

    componentDidMount() {
        this.getByURL('crop/kultura_za_sortu', 'kulture');
        //this.getByURL('crop/sorta_list_drop_down', 'sorte');

        if (this.props.edit === true) {
            this.setState({
                naziv: this.props.data.naziv,
                kultura: this.props.data.kultura
            })
        }


        // this.getByURL('worker/filter_worker_group', 'worker_groups');
        //this.getByURL('worker/filter_sector', 'sectors');


    }

    getByURL(url, obj, event, required) {
        event = event || '';
        required = required || '';
        if (event != null) {
            axios.get(`api/${url}`, { params: { [required]: event } })
                .then(res => this.setState({ [obj]: res.data.data }))
                .catch(error => wentWrong('Something went wrong, please try again!'));
        }
    }

    addSort() {
        if (
            this.state.naziv

        ) {
            let data = {
                id: this.props.data.id, // da prepozna za edit na backendu
                naziv: this.state.naziv,
                id_kultura: this.state.kultura
            }
            axios.post('api/crop/create_sorta', { data: data })
                .then(res => {
                    this.props.handleClose();
                })
                .catch(error => {
                    wentWrong('Something went wrong, please try again!');
                    console.log(`Error: ${error}`);
                })
        }
        else wentWrong('Please enter all required fields!');
    }

    //onSwitchClick = () => { this.setState({ polj_radovi: !this.state.polj_radovi }); }

    render() {
        console.log(this.state.naziv, this.state.kultura)
        console.log(this.props.data)
        return (
            <div style={{ padding: 20 }}>
                <Dialog open={this.props.add}
                    onClose={this.props.handleClose}
                    fullWidth={true}
                    disableBackdropClick
                    maxWidth="sm"
                    aria-labelledby="responsive-dialog-title">
                    <DialogTitle disableTypography={true} style={{ background: '#04764e' }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit variety') : i18n.t('Add variety')}
                        <IconButton onClick={this.props.handleClose}>
                            <span className="icon-cancel-circle closeIcon" />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <form id="add-layer">
                            <Grid container justify="space-between" alignContent="space-between" >

                                <Grid xs={6}>
                                    <TextField required
                                        label={i18n.t('Name')}
                                        value={this.state.naziv}
                                        style={{ width: '256px', marginBottom: '10px', marginTop: '10px' }}
                                        onChange={(e) => { this.setState({ naziv: e.target.value }); }} >
                                    </TextField>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField required
                                        label={i18n.t('Crop group')}
                                        select
                                        value={this.state.kultura}
                                        SelectProps={{ native: true, }}
                                        style={{ width: '256px', marginBottom: '10px' }}
                                        onChange={(e) => { this.setState({ kultura: e.target.value }); }} >
                                        <option value='' />
                                        {this.state.kulture.map((kultura) => <option key={kultura.id} value={kultura.id}>{kultura.naziv}</option>)}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12}>
                                    <Button style={{ alignSelf: 'center', backgroundColor: '#04764e', color: 'white', marginTop: '20px', marginBottom: '10px' }}
                                        onClick={() => this.addSort()}
                                        disabled={this.props.layerPosting} >
                                        {this.props.edit ? i18n.t('Save') : i18n.t('Add')}
                                    </Button>
                                </Grid>

                                <Grid item xs={3}>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
};