import React from 'react';
import { connect } from 'react-redux';
import {
    Card, IconButton, Divider, Button, TextField, Tooltip, DialogActions
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import i18n from '../../i18n/i18n';
import { addActivity, deleteChildFromActivity, updateChild } from '../../actions/activity/ActivityActions';
import { withStyles } from '@material-ui/core/styles';
import ActivityInfo from './ActivityInfo';
import ActivityField from './ActivityField';
import ActivityMachine from './ActivityMachine';
import ActivityImplements from './ActivityImplements';
import ActivityMaterials from './ActivityMaterials';
import MaterialDistribution from './MaterialDistribution';
import immutable from 'seamless-immutable';
import axios from '../../utils/AxiosWrapper';

const styles = theme => ({
    root: {
        width: '100%',
        padding: 0
    },
    connectorLine: {
        transition: theme.transitions.create('border-color'),
    },
    actionsContainer: {
        marginBottom: theme.spacing.unit * 2,
    },
    primaryMain: {
        backgroundColor: theme.palette.primary.main,
        height: 50,
        minHeight: 50
    },
    secondaryMain: {
        backgroundColor: theme.palette.secondary.main,
        background: theme.palette.secondary.main,
    },
    secondaryDark: {
        backgroundColor: theme.palette.secondary.dark,
        background: theme.palette.secondary.dark,
    },
    secondaryLight: {
        backgroundColor: theme.palette.secondary.light,
        background: theme.palette.secondary.light,
    },
    cardWrapper: {
        padding: 20,
        margin: 25,
    },
    cardWrapper1: {
        padding: 0,
        width: 'calc(100% - 50px)',
        margin: 20,
        overflow: 'visible',
        borderRadius: 3
    },
    cardWrapper2: {
        padding: 0,
        // width: 'calc(50% - 10px)',
        width: '47.9%',
        margin: 0,
        overflow: 'visible',
        borderRadius: 3
    },
    cardWrapper3: {
        padding: 0,
        // width: 'calc(50% - 10px)',
        width: '47.9%',
        margin: 0,
        overflow: 'visible',
        borderRadius: 3,
        marginLeft: 15
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 20,
    },
    floatingFooter: {
        background: theme.palette.secondary.dark,
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        height: '50px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    descriptionField: {
        width: 'calc(100% - 40px)',
        margin: 20,
        // paddingBottom: 50
    },
    tooltipLeft: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        height: 50
    }
});

export class ActivitiesMain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activityObj: {}
        };
    }

    handleChangeProperty = (name, value) => {
        this.setState((state) => {
            let activityObj = state.activityObj;
            if (value) {
                activityObj[name] = value.value && value.value || value;
            } else {
                activityObj[name] = null;
            }
            return { ...state, activityObj };
        })
    }

    componentDidMount() {
        const { activityObj, readOnly } = this.props;
        if (Object.keys(activityObj).length) {
            this.setState({
                activityObj: Object.assign({}, activityObj)
            })
        }
        if (readOnly) {
            this.setState({
                readOnly
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activityObj !== this.props.activityObj) {
            this.setState({
                activityObj: Object.assign({}, this.props.activityObj)
            })
        }
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.activityObjFetching === false && nextProps.activityObjFetching === true) {
            return false;
        }
        return true;
    }

    handleAddChild = (name, data, index) => {
        if (this.state.activityObj.id) {
            this.props.updateChild(this.state.activityObj.id, name, data);
        } else if (this.state.activityObj) {
            this.setState((state) => {
                let activityObj = immutable.isImmutable(activityObj) ? immutable.asMutable(state.activityObj, { deep: true }) : state.activityObj;
                if (name !== 'materijali') {
                    if (activityObj.hasOwnProperty(name) === true) {
                        activityObj[name] = [...activityObj[name], ...data];
                    } else {
                        activityObj[name] = []
                        activityObj[name] = [...activityObj[name], ...data];
                    }
                } else {
                    if (activityObj.hasOwnProperty(name) === true && index !== null) {
                        activityObj[name][index] = data;
                    }
                    else if (activityObj.hasOwnProperty(name) === true && index === null) {
                        activityObj[name] = [...activityObj[name], data];
                    }
                    else {
                        activityObj[name] = []
                        activityObj[name] = [...activityObj[name], data];
                    }
                }
                return { ...state, activityObj };
            })
        }
    }

    handleChangeTextField = (name, value) => {
        const { activityObj } = this.state;
        activityObj[name] = value;
        this.setState({
            activityObj: activityObj
        })
    }

    handleDeleteChild = (name, index, child) => {
        if (child.id && this.state.activityObj.id) {
            this.props.deleteChildFromActivity(name, [child.id], this.state.activityObj.id);
        } else {
            this.setState((state) => {
                let activityObj = state.activityObj;
                activityObj[name] = [...activityObj[name].filter((_, i) => i !== index)]
                return { ...state, activityObj };
            })
        }
    }

    handleEditChild = (name, data) => {
        this.props.updateChild(this.state.activityObj.id, name, data);
    }

    onAddActivity = () => {
        const { activityObj } = this.state;
        axios.get('api/season/activity_season')
            .then(res => {
                // console.log('res ', res.data.data[0].id_default_sezona)
                let activity = {
                    activityObj: activityObj,
                    season: res.data.data[0].id_default_sezona
                }
                this.props.addActivity(activity);
                if (this.props.edit === true) axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 16 } })
                else axios.get('api/events/add', { params: { id_vrsta_dogadjaja: 13 } })
            })
            .catch(err => console.log('Error: ', err))
    }

    cancelActivity = () => {
        const { handleClose } = this.props;
        const { activityObj } = this.state;
        if (activityObj.id_radna_operacija && activityObj.id_status && activityObj.datum_pocetka && activityObj.datum_zavrsetka && activityObj.table) {
            this.setState({
                activityCanceled: true
            })
        } else {
            handleClose();
        }
    }

    isDisabled(activityObj) {
        if (activityObj.id_radna_operacija && activityObj.id_status && activityObj.datum_pocetka && activityObj.datum_zavrsetka && (activityObj.table && activityObj.table.length)) {
            return false;
        } else {
            return true;
        }
    }

    renderTooltip() {
        const { activityObj } = this.state;
        if (!activityObj.id && activityObj.id_radna_operacija && activityObj.id_status && activityObj.datum_pocetka && activityObj.datum_zavrsetka && (activityObj.table && activityObj.table.length)) {
            return i18n.t('Add');
        } else if (activityObj.id) {
            return i18n.t('Edit');
        } else {
            return i18n.t('Enter required fields marked with *');
        }
    }

    activityId = () => {
        return this.props.activityObj.id && this.props.activityObj.id || ''
    }

    viewOnly = (activityObj) => {
        const { readOnly } = this.state;
        if (activityObj.id && activityObj.id_status == 4 || readOnly) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const { activityObj } = this.state;
        const { classes, theme, activityObjFetching, readOnly } = this.props;
        let content;
        if (activityObjFetching || Object.keys(activityObj).length === 0) {
            content = <div style={{ display: 'flex', aligItems: 'center', justifyContent: 'center', textAlign: 'center', marginTop: '20%', fontSize: 20, fontWeight: 'bold' }}>{i18n.t('Loading activity...')}</div>
        } else {
            content = <div>
                {/* <Card raised className={classes.cardWrapper}> */}
                <Card raised className={classes.cardWrapper1}>
                    <ActivityInfo activityObj={activityObj} handleDateChange={this.handleDateChange} viewOnly={this.viewOnly(activityObj)} handleChangeProperty={this.handleChangeProperty} />
                </Card>
                <Card raised className={classes.cardWrapper1}>
                    <ActivityField handleDeleteChild={this.handleDeleteChild} table={activityObj.table} viewOnly={this.viewOnly(activityObj)} handleAddFields={this.handleAddChild} />
                    {activityObj.id && activityObj.table && activityObj.table.length > 0 && activityObj.materijali && activityObj.materijali.length > 0 &&
                        <MaterialDistribution id={activityObj.id} viewOnly={this.viewOnly(activityObj)} />
                    }
                </Card>
                <Card raised className={classes.cardWrapper1}>
                    <ActivityMaterials handleDeleteChild={this.handleDeleteChild} viewOnly={this.viewOnly(activityObj)} handleEditChild={this.handleEditChild} handleAddMaterial={this.handleAddChild} activityObj={activityObj} />
                </Card>
                <div className="panel-width93">
                    <Card raised className={classes.cardWrapper2}>
                        <ActivityMachine handleDeleteChild={this.handleDeleteChild} viewOnly={this.viewOnly(activityObj)} pogonskeMasine={activityObj.pogonskeMasine} handleAddMachines={this.handleAddChild} />
                    </Card>
                    <Card raised className={classes.cardWrapper3}>
                        <ActivityImplements handleDeleteChild={this.handleDeleteChild} viewOnly={this.viewOnly(activityObj)} prikljucneMasine={activityObj.prikljucneMasine} handleAddImplements={this.handleAddChild} />
                    </Card>
                </div>
                <Card raised className={classes.cardWrapper1}>
                    <TextField
                        label={i18n.t('Description')}
                        multiline
                        rowsMax={8}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.descriptionField}
                        value={activityObj.komentar}
                        onChange={(e) => this.handleChangeTextField('komentar', e.target.value)}
                    />
                </Card>
                <div><p>‏‏‎ ‎</p> </div>  <div><p>‎‏‏‎ ‎</p> </div>
                {/* </Card> */}
                <Card raised className={classes.floatingFooter}>
                    {!readOnly &&
                        <div>
                            <Tooltip title={this.renderTooltip()} className={classes.tooltipLeft}>
                                <div>
                                    <Button onClick={this.onAddActivity} disabled={this.isDisabled(activityObj)} className="add-rn-button">{activityObj.id && i18n.t('Save') || i18n.t('Add')}</Button>
                                </div>
                            </Tooltip>
                            <Button onClick={this.cancelActivity} className="cancel-rn-button">{i18n.t('Cancel')}</Button>
                        </div>
                    }
                </Card>
            </div >
        }
        return (
            <Dialog
                open={this.props.add}
                onClose={this.props.handleClose}
                disableBackdropClick
                fullScreen={true}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle disableTypography={true} style={{ background: theme.palette.secondary.dark }} className="responsive-dialog-title">{this.props.edit ? i18n.t('Edit activity') + ' - ' + this.activityId() : i18n.t('Add activity')}
                    <IconButton onClick={this.props.handleClose}>
                        <span className="icon-cancel-circle closeIcon" />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.root}>
                    {content}
                </DialogContent>
                <Dialog
                    open={this.state.activityCanceled}
                    onClose={this.props.handleClose}
                    disableBackdropClick>
                    <DialogTitle id="responsive-dialog-title">{i18n.t('Cancel')}</DialogTitle>
                    <DialogContent>
                        {i18n.t('Are you sure you want to discard this activity?')}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.props.handleClose}
                            variant="contained"
                            color="primary">
                            {i18n.t('Yes')}
                        </Button>
                        <Button
                            onClick={this.onAddActivity}
                            variant="contained"
                            color="primary">
                            {i18n.t('Save and close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Dialog>
        )
    }
};

function mapStateToProps(state) {
    return {
        activityObj: state.activityReducer.activityObj,
        activityObjFetching: state.activityReducer.activityObjFetching,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addActivity: (activity) => dispatch(addActivity(activity)),
        deleteChildFromActivity: (name, id, act_id) => dispatch(deleteChildFromActivity(name, id, act_id)),
        updateChild: (id, name, data) => dispatch(updateChild(id, name, data))
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivitiesMain));