/**
 * Created by pajicv on 12/4/18.
 */
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { FormControlLabel, Switch } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SearchIcon from '@material-ui/icons/Search';
import SunIcon from '@material-ui/icons/WbSunny';
import classNames from 'classnames';
import { getLayerTree } from '../../../../selectors/LayerSelectors';
import NodeItem from './NodeItem';
import SeasonSelect from './SeasonSelect';

const styles = theme => ({
    drawer: {
        width: 360,
        flexShrink: 0,
        whiteSpace: 'nowrap'
    },
    drawerOpen: {
        width: 360,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: 0
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        height: 20,
    },
    toggleButtonOpen: {
        zIndex: 1000,
        position: 'absolute',
        top: '50%',
        right: 360,
        width: 24,
        height: 60,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    toggleButtonClose: {
        zIndex: 1000,
        position: 'absolute',
        top: '50%',
        width: 24,
        height: 60,
        right: 1,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchButtonOpen: {
        zIndex: 1000,
        position: 'absolute',
        top: 11,
        right: 370,
        width: 50,
        height: 50,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,1)',
    },
    searchButtonClose: {
        zIndex: 1000,
        position: 'absolute',
        top: 11,
        width: 50,
        height: 50,
        right: 10,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,1)',
    },
    seasonSelectOpen: {
        zIndex: 1000,
        position: 'absolute',
        top: 11,
        right: 430,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        width: 256,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#fff',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,1)',
    },
    seasonSelectClose: {
        zIndex: 1000,
        position: 'absolute',
        top: 11,
        right: 70,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 256,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#fff',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,1)',
    },
    seasonalForecastButtonOpen: {
        zIndex: 1000,
        position: 'absolute',
        top: 11,
        right: 696,
        width: 50,
        height: 50,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,1)',
    },
    seasonalForecastButtonClose: {
        zIndex: 1000,
        position: 'absolute',
        top: 11,
        width: 50,
        height: 50,
        right: 336,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,1)',
    },
    switchOpen: {
        zIndex: 1000,
        position: 'absolute',
        top: 11,
        right: 756,
        width: 110,
        height: 50,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,1)',
    },
    switchClose: {
        zIndex: 1000,
        position: 'absolute',
        top: 11,
        width: 110,
        height: 50,
        right: 396,
        backgroundColor: 'rgba(228, 228, 228, 1)',
        transition: theme.transitions.create('right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        borderRadius: 5,
        border: '1px solid rgba(0,0,0,1)',
    },
});

class TreePanel extends React.Component {

    state = {
        open: false,
    };

    toggleDrawerOpen = () => {
        this.setState(state => ({ open: !state.open }));
    };

    render() {

        const { open } = this.state;

        const { classes, layerTree, onZoomToMenuOpen, seasons, selectedSeasons, onSeasonSelect, onSeasonalForecastMenuOpen } = this.props;

        // console.log(this.props);

        return (
            <React.Fragment>
                <div className={open ? classes.switchOpen : classes.switchClose}>
                    <FormControlLabel
                        control={<Switch checked={this.props.parcelFieldSwitch} onChange={this.props.handleChangeSwitch('parcelFieldSwitch')} />}
                        label={this.props.parcelFieldSwitch ? 'Parcel' : 'Field'}
                        labelPlacement="start"
                    />
                </div>
                <div
                    className={open ? classes.toggleButtonOpen : classes.toggleButtonClose}
                    onClick={this.toggleDrawerOpen}
                >
                    {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </div>
                <div
                    className={open ? classes.seasonSelectOpen : classes.seasonSelectClose}
                >
                    <SeasonSelect seasons={seasons} selectedSeasons={selectedSeasons} onSeasonSelect={onSeasonSelect} />
                </div>
                <div className={open ? classes.searchButtonOpen : classes.searchButtonClose}>
                    <IconButton
                        onClick={onZoomToMenuOpen}
                    >
                        <SearchIcon style={{ fontSize: 36 }} />
                    </IconButton>
                </div>
                <div className={open ? classes.seasonalForecastButtonOpen : classes.seasonalForecastButtonClose}>
                    <IconButton
                        onClick={onSeasonalForecastMenuOpen}
                    >
                        <SunIcon style={{ fontSize: 36 }} />
                    </IconButton>
                </div>
                <Drawer
                    variant="permanent"
                    anchor="right"
                    className={classNames(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: classNames({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                    open={open}
                >
                    <div className={classes.toolbar} />
                    {
                        layerTree.map(node => {
                            return (
                                <NodeItem
                                    {...node}
                                    key={node.id}
                                    isCollapsed={true}
                                />
                            )
                        })
                    }
                </Drawer>
            </React.Fragment>
        )
    }
}

export default connect(
    state => ({ layerTree: getLayerTree(state) })
)(withStyles(styles)(TreePanel));

