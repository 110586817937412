import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoSoilMoisture extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Ovaj termin se odnosi na količinu vode u porama zemlje i na njenoj površini. Sadržaj vlage u zemljištu zavisi od vremenskih uslova, sastava zemljišta i biljaka koje se na zemljištu gaje. Vlažnost zemljišta je vitalan parametar u poljoprivredi, jer ukoliko postoji deficit ili suficit vlage, biljke mogu u potpunosti odumreti. Takođe, vlaga utiče na vazdušni kapacitet zemljišta, salinitet i količinu toksičnih materija kao i na strukturu zemljišta i na njegovu temperaturu i toplotni kapacitet.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            This term refers to the amount of water in the pores of the earth and on its surface. 
                            The moisture content of the soil depends on the weather conditions, the composition of the soil and the plants that are grown on the soil. 
                            Soil moisture is a vital parameter in agriculture, because if there is a deficit or surplus of moisture, plants can die completely. 
                            Also, moisture affects the air capacity of the soil, salinity and the amount of toxic substances, as well as the structure of the soil and its temperature and heat capacity.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Ovaj pojam odnosi se na količinu vode u porama zemlje i na njenoj površini. 
                            Vlažnost tla ovisi o vremenskim prilikama, sastavu tla i biljkama koje se na tlu uzgajaju. 
                            Vlažnost tla je vitalni parametar u poljoprivredi, jer ako postoji manjak ili višak vlage, biljke mogu potpuno uginuti. 
                            Također, vlaga utječe na zračni kapacitet tla, slanost i količinu otrovnih tvari, kao i na strukturu tla te njegov temperaturni i toplinski kapacitet.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Ta izraz se nanaša na količino vode v porah zemlje in na njeni površini. 
                            Vlažnost tal je odvisna od vremenskih razmer, sestave tal in rastlin, ki jih gojimo na tleh. 
                            Vlažnost tal je pomemben parameter v kmetijstvu, saj lahko rastline v primeru pomanjkanja ali presežka vlage popolnoma odmrejo. 
                            Prav tako vlaga vpliva na zračnost tal, slanost in količino strupenih snovi ter strukturo tal ter njihovo temperaturo in toplotno kapaciteto.
                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoSoilMoisture);