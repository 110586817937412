import React from 'react';
import { Card, withStyles, } from '@material-ui/core';
import axios from '../../../../utils/AxiosWrapper';
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../InfoStyles';

export class InfoRelativeHumidity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 1
        };
    };

    componentDidMount() {
        axios.get('api/map/language')
            .then(res => {
                let response = res.data.data[0].id_jezik;
                if (response) this.setState({ language: response });
            })
            .catch(err => { console.log(err) })
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.language === 1 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Opitimalna temperatura vazduha u kombinaciji sa dovoljnim nivoom vlažnosti omogućava zdrav i ujednačen rast biljaka. 
                            Nizak nivo vlažnosti može izazvati stres kod biljaka, uzrokujući usporavanje ili čak potpuno zaustavljanje rasta. 
                            To je posebno slučaj za relativnu vlažnost ispod 30%.
                            Optimalna vlažnost vazduha takođe je veoma značajna kada je u pitanju proces zrenja, kao i svežina i rok trajanja poljoprivrednih proizvoda tokom skladištenja.

                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 2 &&
                    <Card className={classes.margin}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Optimum air temperature combined with a sufficient level of humidity enables healthy and uniform plant growth. 
                            Low humidity can cause plant stress and stunting or complete plant growth arrest. It is especially when humidity is below 30%.
                            Optimum air humidity is very important for the ripening process, as well as the freshness and shelf life of agricultural products during storage.

                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 4 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            Optimalna temperatura zraka u kombinaciji s dovoljnom razinom vlage omogućuje zdrav i ujednačen rast biljaka. 
                            Niska razina vlažnosti može izazvati stres za biljke, uzrokujući usporavanje ili čak potpuno zaustavljanje rasta. 
                            To je osobito slučaj kod relativne vlažnosti ispod 30%.
                            Optimalna vlažnost zraka također je vrlo važna kada je u pitanju proces zrenja, kao i svježina i trajnost 
                            poljoprivrednih proizvoda tijekom skladištenja.
                            </p>
                        </div>
                    </Card>
                }
                {this.state.language === 5 &&
                    <Card className={classes.margin} style={{marginTop: 30}}>
                        <div className={classes.margin}>
                            <p className={classes.paragraph}>
                            
                            Optimalna temperatura zraka v kombinaciji z zadostno stopnjo vlažnosti omogoča zdravo in enakomerno rast rastlin. 
                            Nizka vlažnost lahko povzroči stres rastlin in zaostajanje v rasti ali popolno zaustavitev rasti rastlin. 
                            Še posebej, če je vlažnost pod 30%. Optimalna zračna vlaga je zelo pomembna za proces zorenja, 
                            pa tudi svežino in obstojnost kmetijskih pridelkov med skladiščenjem.

                            </p>
                        </div>
                    </Card>
                }
            </div>
        )
    };
};

export default withStyles(styles)(InfoRelativeHumidity);