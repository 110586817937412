import React from 'react';
import { connect } from 'react-redux';
import {
    Button, IconButton, Menu, MenuItem
} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { Doughnut, Bar, Pie, HorizontalBar } from 'react-chartjs-2';
import immutable from 'seamless-immutable';
import Select, { components } from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import 'react-toastify/dist/ReactToastify.css';
import i18n from '../../../i18n/i18n';
import randomColor from 'randomcolor';
import DashboardDrawer from '../../../utils/DashboardDrawer';
import { getNoteGroupDropDown, getNoteDashboard } from '../../../actions/note/NotesActions';


const IndicatorsContainer = (props) => {
    return (
        <div style={{ background: '#61a50e' }}>
            <components.IndicatorsContainer {...props} />
        </div>
    );
};
export class NoteDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            id_terenska_intervencija_vrsta: []
        };
    }

    componentDidMount() {
        this.props.getNoteDashboard({});
        if (this.props.noteGroupDropDown.length === 0) {
            this.props.getNoteGroupDropDown();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            noteGroupDropDown: immutable.asMutable(nextProps.noteGroupDropDown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }),
        })
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClear = () => {
        this.setState({
            id_terenska_intervencija_vrsta: []
        }, function () {
            this.props.getNoteDashboard({});
        });
    };


    handleOk = () => {
        let filters = {};
        filters['id_terenska_intervencija_vrsta'] = this.state.id_terenska_intervencija_vrsta.map((item) => {
            return item.value;
        });
        this.props.getNoteDashboard(filters);
    }

    handleChange = name => selectedOption => {
        this.setState({
            [name]: selectedOption
        })
    }


    onPieClick = () => {
        this.props.history.push('/notes_dashboard');
    }

    onTableClick = () => {
        this.props.history.push('/notes');
    }



    render() {
        const { anchorEl } = this.state;
        let noteTypeData = this.noteType;


        if (this.props.noteItems && this.props.noteItems.notesType) {
            noteTypeData.labels = this.props.noteItems.notesType.labels.asMutable();
            noteTypeData.datasets[0].data = this.props.noteItems.notesType.count.asMutable();
            noteTypeData.datasets[0].data1 = this.props.noteItems.notesType.percent.asMutable();
        }

        return (
            <div style={{ 'padding': '20px', display: 'flex', flexDirection: 'column', width: '90%' }}>

                <Card style={styles.card}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h1>{i18n.t('Total number of note reports')} : {this.props.noteItems.total} </h1>
                        <div className="filter-panel-dashboard">
                            <IconButton
                                styles={styles.button}
                                aria-owns={anchorEl ? 'simple-menu' : null}
                                aria-haspopup="true"
                                className="action-button-filter"
                                onClick={this.handleClick}
                            >
                                <span className="icon-search headerIcon" />
                            </IconButton>
                        </div>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={this.handleClose}
                        >
                            <InputLabel className="filter-label">{i18n.t('Notes type')}:</InputLabel>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                isClearable={false}
                                value={this.state.id_terenska_intervencija_vrsta}
                                components={{
                                    IndicatorsContainer: IndicatorsContainer
                                }}
                                maxMenuHeight={150}
                                onChange={this.handleChange('id_terenska_intervencija_vrsta')}
                                options={this.state.noteGroupDropDown}
                                className="multi-select-in-filter-dashboards"
                                classNamePrefix="select"
                                placeholder={i18n.t('Select')}
                            />
                            <div style={{ padding: '30px' }}>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleOk}>{i18n.t('Ok')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClose}>{i18n.t('Cancel')}</Button>
                                <Button style={{ alignSelf: 'center', backgroundColor: '#61a50e', color: 'white', margin: '5px' }} onClick={this.handleClear}>{i18n.t('Clear')}</Button>

                            </div>
                        </Menu>
                    </div>
                </Card>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Card style={styles.cardMin}>
                        <h1>{i18n.t('Note reports by note type')}:</h1>
                        <Pie data={noteTypeData} options={this.options} />
                    </Card>

                </div >

                <DashboardDrawer onPieClick={this.onPieClick} onTableClick={this.onTableClick} />
            </div>
        )
    }


    noteTypeColor = randomColor({
        count: 50
    })

    noteType = {
        labels: [],
        datasets: [{
            data: [],
            data1: [],
            backgroundColor: this.noteTypeColor,
            hoverBackgroundColor: this.noteTypeColor
        }]
    };


    options = {
        tooltips: {
            mode: 'label',
            callbacks: {

                afterLabel: function (tooltipItem, data) {
                    return data.datasets[tooltipItem.datasetIndex].data1[tooltipItem.index] + '%';
                },

            },
        }
    };

};

function mapStateToProps(state) {
    return {
        noteItems: state.noteReducer.noteItems,
        noteGroupDropDown: state.noteReducer.noteGroupDropDown,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getNoteDashboard: (filters) => dispatch(getNoteDashboard(filters)),
        getNoteGroupDropDown: () => dispatch(getNoteGroupDropDown()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoteDashboard);

const styles = {
    card: {
        minWidth: 275,
        padding: '10px',
        margin: '10px'
    },
    cardMin: {
        width: '100%',
        padding: '10px',
        margin: '10px',
        position: 'relative',
        float: 'left'
    },
    title: {
        marginBottom: 16,
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};