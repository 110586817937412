import axios from '../../utils/AxiosWrapper';
export const Type = {
    //VIEW
    GET_VIEW_FIELDS_CALL: 'GET_VIEW_FIELDS_CALL',
    GET_VIEW_FIELDS_SUCCESS: 'GET_VIEW_FIELDS_SUCCESS',
    GET_VIEW_FIELDS_FAILED: 'GET_VIEW_FIELDS_FAILED',
    //ADD
    ADD_FIELD_CALL: 'ADD_FIELD_CALL',
    ADD_FIELD_SUCCESS: 'ADD_FIELD_SUCCESS',
    ADD_FIELD_FAILED: 'ADD_FIELD_FAILED',
    //DROPDOWN
    GET_FIELD_CALL: 'GET_FIELD_CALL',
    GET_FIELD_SUCCESS: 'GET_FIELD_SUCCESS',
    GET_FIELD_FAILED: 'GET_FIELD_FAILED',
    GET_FIELD_GEOM_NULL_CALL: 'GET_FIELD_GEOM_NULL_CALL',
    GET_FIELD_GEOM_NULL_SUCCESS: 'GET_FIELD_GEOM_NULL_SUCCESS',
    GET_FIELD_GEOM_NULL_FAILED: 'GET_FIELD_GEOM_NULL_FAILED',

    GET_TYPE_PRODUCTION_CALL: 'GET_TYPE_PRODUCTION_CALL',
    GET_TYPE_PRODUCTION_SUCCESS: 'GET_TYPE_PRODUCTION_SUCCESS',
    GET_TYPE_PRODUCTION_FAILED: 'GET_TYPE_PRODUCTION_FAILED',
    GET_SECTOR_CALL: 'GET_SECTOR_CALL',
    GET_SECTOR_SUCCESS: 'GET_SECTOR_SUCCESS',
    GET_SECTOR_FAILED: 'GET_SECTOR_FAILED',
    //DELETE
    DELETE_FIELD_CALL: 'DELETE_FIELD_CALL',
    DELETE_FIELD_SUCCESS: 'DELETE_FIELD_SUCCESS',
    DELETE_FIELD_FAILED: 'DELETE_FIELD_FAILED',
    //DASHBOARD
    GET_FIELDS_DASHBOARD_CALL: 'GET_FIELDS_DASHBOARD_CALL',
    GET_FIELDS_DASHBOARD_SUCCESS: 'GET_FIELDS_DASHBOARD_SUCCESS',
    GET_FIELDS_DASHBOARD_FAILED: 'GET_FIELDS_DASHBOARD_FAILED',

    //CROP ROTATION
    GET_CROP_ROTATION_CALL: 'GET_CROP_ROTATION_CALL',
    GET_CROP_ROTATION_SUCCESS: 'GET_CROP_ROTATION_SUCCESS',
    GET_CROP_ROTATION_FAILED: 'GET_CROP_ROTATION_FAILED',
    //SETTERS
    SET_FIELD_ROW_IN_REDUCER: 'SET_FIELD_ROW_IN_REDUCER',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
};

export function setInitialState(component) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_INITIAL_STATE,
            data: component
        });
    }
}

export function setRowInReducer(rowInfo, name) {
    return (dispatch) => {
        dispatch({
            type: Type.SET_FIELD_ROW_IN_REDUCER,
            rowInfo: rowInfo,
            name: name
        })
    }
}

export function addField(fieldObj) {

    return (dispatch) => {

        dispatch({
            type: Type.ADD_FIELD_CALL
        });

        axios.post('api/field/create', fieldObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_FIELD_SUCCESS,
                    data: response.data
                });

            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_FIELD_FAILED
                });
            });
    }
}

export function updateFieldGeom(fieldObj) {

    return (dispatch) => {

        dispatch({
            type: Type.ADD_FIELD_CALL
        });

        axios.post('api/field/update_geom', fieldObj)
            .then(function (response) {
                dispatch({
                    type: Type.ADD_FIELD_SUCCESS,
                    data: response.data
                });

            })
            .catch(function (error) {
                dispatch({
                    type: Type.ADD_FIELD_FAILED
                });
            });
    }
}

export function getCropRotation(id_kultura, id_predkultura) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_CROP_ROTATION_CALL
        });

        var config = {
            params: {
                id_kultura: id_kultura,
                id_predkultura: id_predkultura
            }
        };

        axios.get('api/field/getPlodored', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_CROP_ROTATION_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_CROP_ROTATION_FAILED
                });
            });
    }
}

export function getField(state, instance) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_VIEW_FIELDS_CALL
        });

        var config = {
            params: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered
            }
        };
        axios.get('api/field/read', config)
            .then(function (response) {
                dispatch({
                    type: Type.GET_VIEW_FIELDS_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_VIEW_FIELDS_FAILED
                });
            });
    }
}

export function getFieldDropDown(id_default_sezona) {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FIELD_CALL
        });

        axios.get('api/field/drop_down', { params: { id_default_sezona } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_FIELD_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FIELD_FAILED
                });
            });
    }
}

export function getFieldGeomNullDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_FIELD_GEOM_NULL_CALL
        });

        axios.get('api/field/drop_down_null_geometry')
            .then(function (response) {
                dispatch({
                    type: Type.GET_FIELD_GEOM_NULL_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FIELD_GEOM_NULL_FAILED
                });
            });
    }
}

export function getSectorDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_SECTOR_CALL
        });

        axios.get('api/sektor/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_SECTOR_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_SECTOR_FAILED
                });
            });
    }
}

export function getTypeProductiondDropDown() {
    return (dispatch) => {

        dispatch({
            type: Type.GET_TYPE_PRODUCTION_CALL
        });

        axios.get('api/vrsta_proizvodnje/drop_down')
            .then(function (response) {
                dispatch({
                    type: Type.GET_TYPE_PRODUCTION_SUCCESS,
                    data: response.data
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_TYPE_PRODUCTION_FAILED
                });
            });
    }
}

export function deleteField(selection) {
    return (dispatch) => {

        dispatch({
            type: Type.DELETE_FIELD_CALL,
        });

        axios.post(`api/field/delete`, { selection: selection })
            .then(function (response) {
                dispatch({
                    type: Type.DELETE_FIELD_SUCCESS,
                    data: response.data,
                    forDelete: selection
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.DELETE_FIELD_FAILED
                });
            });
    }
}

export function getFieldsDashboards(filters) {
    return (dispatch) => {
        dispatch({
            type: Type.GET_FIELDS_DASHBOARD_CALL,
        });

        return axios.get('api/field/dashboard', { params: { filters } })
            .then(function (response) {
                dispatch({
                    type: Type.GET_FIELDS_DASHBOARD_SUCCESS,
                    data: response.data,
                });
            })
            .catch(function (error) {
                dispatch({
                    type: Type.GET_FIELDS_DASHBOARD_FAILED
                });
            });
    }
}