import React from 'react';
import { connect } from 'react-redux';
import { withStyles, Typography, Checkbox, FormControlLabel } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker, TimePicker } from 'material-ui-pickers';
import moment from 'moment';
import immutable from 'seamless-immutable';
import ReactSelect from '../../utils/ReactSelect';
import i18n from '../../i18n/i18n';
import { getWOGroup, getWODropdown, getStatusDropdown } from '../../actions/activity/ActivityActions';
const styles = theme => ({
    innerContainer: {
        width: '100%',
        textAlign: 'center',
        marginRight: 20,
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    circleGreen: {
        height: '50px',
        width: '50px',
        backgroundColor: 'darkgreen',
        borderRadius: '50%',
    },
    circleYellow: {
        height: '50px',
        width: '50px',
        backgroundColor: 'gold',
        borderRadius: '50%',
    },
    circleRed: {
        height: '50px',
        width: '50px',
        backgroundColor: 'red',
        borderRadius: '50%',
    },
    circleBlue: {
        height: '50px',
        width: '50px',
        backgroundColor: '#000080',
        borderRadius: '50%',
    },
    panel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: '0 0 2px',
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main,
    },
    typography: {
        padding: '10px 20px',
        color: theme.palette.text.secondary,
        fontWeight: '600',
    }
});
export class ActivityInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activityObj: {
                planski: false
            },
            woDropdown: [],
            statusDropdown: [],
            summaryVisible: false,
        }
    }

    componentDidMount() {
        this.props.getStatusDropdown();
        this.props.getWODropdown();
    }

    componentDidUpdate(prevProps, prevState) {
        let user = this.getUser();

        if (user.id_region === 13) {
            if (prevProps.statusDropdown !== this.props.statusDropdown) {
                this.setState({ statusDropdown: immutable.asMutable(this.props.statusDropdown.map((item) => { return { label: item.naziv_en, value: item.id } }), { deep: true }), });
            }
        }
        else {
            if (prevProps.statusDropdown !== this.props.statusDropdown) {
                this.setState({ statusDropdown: immutable.asMutable(this.props.statusDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }), });
            }
        }
        if (prevProps.woDropdown !== this.props.woDropdown) {
            this.setState({ woDropdown: immutable.asMutable(this.props.woDropdown.map((item) => { return { label: item.naziv, value: item.id } }), { deep: true }), });
        }
        if (this.props.activityObj && prevState.activityObj !== this.props.activityObj) {
            if (this.props.activityObj.planski === undefined) {
                let obj = this.props.activityObj;
                obj.planski = false;
                this.setState({ activityObj: obj });
            }
            else this.setState({ activityObj: this.props.activityObj })
        }
    }

    getUser() {
        let user = localStorage.getItem('user');
        let userObject = JSON.parse(user);
        !user && window.location.replace(window.location.origin);
        return userObject;
    }

    handleChangeValue = name => selected => {
        const { activityObj } = this.state;
        const { handleChangeProperty } = this.props
        activityObj[name] = selected && selected.value;
        this.setState({
            activityObj: activityObj
        }, function () {
            handleChangeProperty(name, selected);
        });
        if (activityObj.id_status === 5) this.setState({ summaryVisible: false });
        else this.setState({ summaryVisible: true });
    }

    handleChangeDateValue = name => date => {
        const { activityObj } = this.state;
        const { handleChangeProperty } = this.props
        activityObj[name] = moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY');
        this.setState({ activityObj: activityObj }, function () {
            handleChangeProperty(name, this.state.activityObj[name])
        });
    }

    handleChangeTime = name => time => {
        const { activityObj } = this.state;
        const { handleChangeProperty } = this.props;
        activityObj[name] = moment(time, 'HH:mm').format('HH:mm');
        this.setState({ activityObj: activityObj }, function () {
            handleChangeProperty(name, this.state.activityObj[name])
        });
    }

    handleChangeCheckbox = name => event => {
        const { activityObj } = this.state;
        const { handleChangeProperty } = this.props
        activityObj[name] = event.target.checked;
        this.setState({ activityObj: activityObj }, function () {
            handleChangeProperty(name, this.state.activityObj[name])
        });
    };

    renderLabel = (date) => {
        if (date.isValid()) {
            return date.format('DD.MM.YYYY');
        } else {
            return '';
        }
    }

    renderTimeLabel = (time) => {
        if (moment(time, 'HH:mm').isValid()) {
            return time.format('HH:mm');
        } else {
            return i18n.t('Pick time');
        }
    }

    render() {
        const { classes, theme, viewOnly } = this.props;
        const { activityObj } = this.state;
        const renderSemaphore = () => {
            let circle;
            switch (activityObj.id_status) {
                case 3:
                    circle = <div className={classes.circleGreen} />
                    break;
                case 2:
                    circle = <div className={classes.circleYellow} />
                    break;
                case 1:
                    circle = <div className={classes.circleRed} >
                        <span className="icon-Stiklic icon" />
                    </div>
                    break;
                case 4:
                    circle = <div className={classes.circleRed}>
                        <span className="icon-Kljuc icon" />
                    </div>
                    break;
                case 5:
                    circle = <div className={classes.circleBlue} />
                    break;

                default:
                    circle = <div className={classes.circleGreen} />
            }
            return circle;
        }
        return (
            <div>
                <div className={classes.panel}>
                    <Typography className={classes.typography} variant="subtitle1">{i18n.t('Basic information')}</Typography>
                </div>
                <div className={classes.innerContainer}>
                    {renderSemaphore()}
                    <ReactSelect
                        closeMenuOnSelect={true}
                        isClearable={true}
                        isDisabled={viewOnly}
                        label={i18n.t('Status') + '*'}
                        value={this.state.statusDropdown.find(function (element) {
                            return element.value === activityObj.id_status;
                        })}
                        maxMenuHeight={150}
                        menuPlacement='auto'
                        onChange={
                            // e => {
                            this.handleChangeValue('id_status')
                            // if (e.value === 5) this.setState({ summaryVisible: true });
                            // else this.setState({ summaryVisible: false });
                        }
                        // }
                        options={this.state.statusDropdown}
                        className="multi-select-in-filter-dashboards"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                    />
                    <ReactSelect
                        closeMenuOnSelect={true}
                        isClearable={true}
                        isDisabled={viewOnly}
                        value={this.state.woDropdown.find(function (element) {
                            return element.value === activityObj.id_radna_operacija;
                        })}
                        label={i18n.t('Working operation') + '*'}
                        onChange={this.handleChangeValue('id_radna_operacija')}
                        options={this.state.woDropdown}
                        autoSize={true}
                        maxMenuHeight={250}
                        className="multi-select-in-filter-dashboards-wo"
                        classNamePrefix="select"
                        placeholder={i18n.t('Select')}
                        style={{ width: '200px', margin: '10px' }}
                    />
                    <DatePicker
                        keyboard
                        disabled={viewOnly}
                        label={i18n.t('Start date *')}
                        format="DD.MM.YYYY"
                        value={moment(activityObj.datum_pocetka, 'DD.MM.YYYY')}
                        onChange={this.handleChangeDateValue('datum_pocetka')}
                        style={{ width: '150px', margin: '10px' }}
                        disableOpenOnEnter
                        labelFunc={this.renderLabel}
                    />
                    <DatePicker
                        keyboard
                        disabled={viewOnly}
                        label={i18n.t('End date *')}
                        format="DD.MM.YYYY"
                        value={moment(activityObj.datum_zavrsetka, 'DD.MM.YYYY')}
                        onChange={this.handleChangeDateValue('datum_zavrsetka')}
                        style={{ width: '150px', margin: '10px' }}
                        disableOpenOnEnter
                        labelFunc={this.renderLabel}
                    />
                    <TimePicker ampm={false} disabled={viewOnly} labelFunc={this.renderTimeLabel} style={{ width: '120px', margin: '10px' }} label={i18n.t('Start time')} value={moment(activityObj.vreme_pocetka, 'HH:mm')} onChange={this.handleChangeTime('vreme_pocetka')} />
                    <TimePicker ampm={false} disabled={viewOnly} labelFunc={this.renderTimeLabel} style={{ width: '120px', margin: '10px' }} label={i18n.t('End time')} value={moment(activityObj.vreme_zavrsetka, 'HH:mm')} onChange={this.handleChangeTime('vreme_zavrsetka')} />
                    {activityObj.planski !== undefined &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={activityObj.planski}
                                    onChange={this.handleChangeCheckbox('planski')}
                                    // name="checkedB"
                                    color="primary"
                                    style={{ margin: 0 }}
                                    labelPlacement="start"
                                    value="planski"
                                    disabled={this.state.summaryVisible}
                                    isDisabled={viewOnly}
                                />
                            }
                            label={i18n.t('Summary plan')}
                        />
                    }
                </div>
            </div>
        )

    }

}


function mapStateToProps(state) {
    return {
        woDropdown: state.activityReducer.woDropdown,
        statusDropdown: state.activityReducer.statusDropdown,
        woDropdownFetching: state.activityReducer.woDropdownFetching
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getWOGroup: () => dispatch(getWOGroup()),
        getWODropdown: () => dispatch(getWODropdown()),
        getStatusDropdown: () => dispatch(getStatusDropdown()),
    }
}

export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityInfo))