import React from 'react';
import i18n from '../i18n/i18n';
import {
    GridList, GridListTile, GridListTileBar,
    IconButton, ExpansionPanel,
    ExpansionPanelSummary, ExpansionPanelDetails
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import axios from './AxiosWrapper';
var FileSaver = require('file-saver');

export class DocumentGridList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    onDownloadClick = (path, naziv) => {
        axios({
            url: `api/document/download?path=${path}&naziv=${naziv}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then(function (response) {
                FileSaver.saveAs(new Blob([response.data]), naziv);

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    onDeleteClick = (document) => {
        this.props.deleteDoc(document);
    }

    render() {

        var tileArray = [];
        this.props.documentArray.map(tile => {
            var pathParts = tile.path.split('.');
            var extension = pathParts[pathParts.length - 1];
            var sourcee

            if (extension.toLowerCase() == 'jpg' || extension.toLowerCase() == 'jpeg' || extension.toLowerCase() == 'png' || extension.toLowerCase() == 'apng' || extension.toLowerCase() == 'bmp' || extension.toLowerCase() == 'gif' || extension.toLowerCase() == 'svg' || extension.toLowerCase() == 'bmp ico' || extension.toLowerCase() == 'png ico') {
                sourcee = tile.signedURL;
            } else {
                sourcee = require('../assets/images/document.png');
            }
            tileArray.push(<GridListTile key={tile.img}>
                <img src={sourcee} alt={tile.naziv} />
                <GridListTileBar
                    title={tile.naziv}
                    className='documentTileBar'
                    // subtitle={<span>by: {tile.author}</span>}
                    actionIcon={
                        <div>
                            <IconButton onClick={() => this.onDownloadClick(tile.path, tile.naziv)}>
                                <Icons.CloudDownload style={{ color: 'white' }} />
                            </IconButton>
                            {this.props.showDelete &&
                                <IconButton onClick={() => this.onDeleteClick(tile)}>
                                    <Icons.Delete style={{ color: 'white' }} />
                                </IconButton>}
                        </div>
                    }
                />
            </GridListTile>
            )
        });

        return (
            <ExpansionPanel style={{ background: '#f7f7f7', width: '100%' }}>
                <ExpansionPanelSummary expandIcon={<Icons.ExpandMore style={{ color: 'hhhhhh', height: '50px' }} />}>
                    {i18n.t('Documents')}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails >
                    <GridList cellHeight={180} className='documentGridList'>
                        {tileArray}

                    </GridList>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default DocumentGridList;